import { Grid, TextField, FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { Avatar } from "../../../../../types/Avatar";
import { useUpdateEffect } from "usehooks-ts";

interface AvatarDetailsFieldsProps {
  avatarId: string;
  avatar: Avatar | undefined;
  updateAvatar: (avatar: Avatar) => void;
}

export const AvatarDetailsFields = ({ avatar, avatarId, updateAvatar }: AvatarDetailsFieldsProps) => {
  const [name, setName] = useState<string>("");
  const [orderIndex, setOrderIndex] = useState<number>(0);
  const [color, setColor] = useState<string>("");
  const [hidden, setHidden] = useState<boolean>(false);
  const [isUnlockOnly, setIsUnlockOnly] = useState<boolean>(false);
  const [isClaimable, setIsClaimable] = useState<boolean>(false);
  const [rarity, setRarity] = useState<Avatar["rarity"]>("silver");

  useEffect(() => {
    if (!avatar) return;

    setName(avatar.name);
    setOrderIndex(avatar.orderIndex);
    setColor(avatar.color);
    setHidden(avatar.hidden || false);
    setIsUnlockOnly(avatar.isUnlockOnly || false);
    setIsClaimable(avatar.isClaimable || false);
    setRarity(avatar.rarity);
  }, [avatar]);

  useUpdateEffect(() => {
    if (!avatarId) return;

    updateAvatar({
      id: avatarId,
      name,
      orderIndex,
      color,
      hidden,
      isUnlockOnly,
      isClaimable,
      rarity,
    });
  }, [avatarId, name, orderIndex, color, hidden, isUnlockOnly, isClaimable, rarity]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item>
          <TextField
            id="outlined"
            label="Id"
            sx={{ width: 375 }}
            value={avatarId}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </Grid>
        <Grid item>
          <TextField
            id="outlined"
            label="Name"
            sx={{ width: 375 }}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={4}>
        <Grid item>
          <TextField
            id="outlined"
            label="Order Index"
            sx={{ width: 375 }}
            value={orderIndex}
            onChange={(e) => {
              setOrderIndex(parseInt(e.target.value));
            }}
            type="number"
          />
        </Grid>
        <Grid item>
          <TextField
            id="outlined"
            label="Color"
            sx={{ width: 375 }}
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
            type="text"
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={4}>
        <Grid item>
          <FormControl className="w-full">
            <InputLabel className="">Rarity</InputLabel>
            <Select
              label="Type"
              className="pointer-events-none"
              value={rarity}
              sx={{ width: 375 }}
              onChange={(e) => {
                if (!e.target.value) return;

                setRarity(e.target.value as Avatar["rarity"]);
              }}
            >
              {["silver", "gold", "diamond"].map((action) => (
                <MenuItem key={action} value={action}>
                  {action}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <FormControlLabel
        control={<Switch />}
        checked={hidden}
        onChange={(_, checked) => {
          setHidden(checked);
        }}
        label="Hidden"
      />
      <br />
      <FormControlLabel
        control={<Switch />}
        checked={isUnlockOnly}
        onChange={(_, checked) => {
          setIsUnlockOnly(checked);
        }}
        label="Is Unlock Only"
      />
      <br />
      <FormControlLabel
        control={<Switch />}
        checked={isClaimable}
        onChange={(_, checked) => {
          setIsClaimable(checked);
        }}
        label="Is Claimable"
      />
    </>
  );
};
