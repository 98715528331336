import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Autocomplete, Avatar, Paper, Tab, Tabs, TextField, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useInView } from "react-intersection-observer";

import { PublicUserProfile } from "../../../../../types/users";
import { Message } from "../../../../../types/Message";

import { ChatMessage, ChatModActions } from "../index";

import useFetchChatMessages from "../../hooks/useFetchChatMessages";
import useFetchSuspensions from "../../hooks/useFetchSuspensions";
import useUsernameSearch from "../../../../../hooks/useUsernameSearch";

import getModActionStatus from "../../helpers/getModActionStatus";
import { generateImageUrl } from "../../../../../helpers/images/generateImageUrl";
import { generateAssetsId } from "../../../../../helpers/images/generateImageUrl/generateAssetsId";

interface UserChatHistoryProps {
  selectedMessage: null | Message;
  setSelectedMessage: Dispatch<SetStateAction<null | Message>>;
}

const UserChatHistory = ({ selectedMessage, setSelectedMessage }: UserChatHistoryProps) => {
  const [selectedTab, setSelectedTab] = useState<"mod-actions" | "messages">("mod-actions");

  const [username, setUsername] = useState("");
  const [selectedUser, setSelectedUser] = useState<null | PublicUserProfile>(null);
  const unselectUser = useCallback(() => {
    setSelectedUser(null);
    setUsername("");
  }, []);
  const { data: userProfiles, isLoading } = useUsernameSearch({
    beginsWith: username,
  });

  const {
    data: messagesResponse,
    isLoading: isLoadingMessages,
    fetchNextPage,
  } = useFetchChatMessages({
    enabled: !!selectedUser?.id,
    userId: selectedUser?.id,
    includeSuspensions: true,
    excludeAutomaticallyDeletedMessages: false,
    onlyDeletedMessages: selectedTab === "mod-actions",
    keepPreviousData: false,
  });
  const { data: suspensions } = useFetchSuspensions(selectedUser?.id);

  const containerRef = useRef<HTMLDivElement>(null);
  const { ref: lastElementRef, inView: lastElementInView } = useInView({
    rootMargin: "150px",
    skip: isLoadingMessages,
    root: containerRef.current,
  });

  const selectMessageClickHandler = useCallback(
    (message: Message) => {
      setSelectedMessage((currentSelectedMessage) =>
        !currentSelectedMessage || currentSelectedMessage.messageId !== message.messageId ? message : null,
      );
    },
    [setSelectedMessage],
  );
  const unselectMessage = useCallback(() => {
    setSelectedMessage(null);
  }, [setSelectedMessage]);

  const messages = useMemo(() => {
    if (!messagesResponse) {
      return [];
    }

    return messagesResponse.pages.flatMap(({ messages }) => {
      return messages;
    });
  }, [messagesResponse]);

  const loadMore = useCallback(() => {
    void fetchNextPage();
  }, [fetchNextPage]);

  useEffect(() => {
    if (lastElementInView) {
      loadMore();
    }
  }, [lastElementInView, loadMore]);

  return (
    <Paper sx={{ width: 400 }} className="flex h-full resize-x flex-col overflow-auto p-1">
      <ChatModActions
        selectedMessage={selectedMessage}
        unselectMessage={unselectMessage}
        selectedUser={selectedUser}
        unselectUser={unselectUser}
      />
      <h4 className="my-2">Search user chat history by username:</h4>
      <Autocomplete
        disablePortal
        options={userProfiles ?? []}
        getOptionLabel={(option) => option.username}
        sx={{ width: 360 }}
        onChange={(_, user) => {
          setSelectedUser(user);
          (document.activeElement as HTMLInputElement).blur?.();
        }}
        value={selectedUser}
        renderInput={(params) => (
          <TextField
            {...params}
            value={username}
            onKeyDown={(event) => {
              event.stopPropagation();
              event.nativeEvent.stopPropagation();
            }}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
        )}
        loading={!!username && isLoading}
      />
      {selectedUser && (
        <>
          <Tabs
            value={selectedTab}
            onChange={(_, newValue: typeof selectedTab) => setSelectedTab(newValue)}
            className="mb-2 w-full"
          >
            <Tab value="mod-actions" label="Mod Actions" className="w-1/2" />
            <Tab value="messages" label="Messages" className="w-1/2" />
          </Tabs>
          <div className="flex-1 overflow-auto pb-4" ref={containerRef}>
            {selectedTab === "mod-actions" && (
              <>
                <h5 className="my-2">Suspensions</h5>
                {suspensions?.map((suspension) => (
                  <div key={suspension.suspensionId} className="mb-1 flex items-center">
                    <Typography variant="caption" className="whitespace-nowrap">
                      {DateTime.fromISO(suspension.createdAt).toFormat("dd/MM HH:mm")}
                    </Typography>
                    <Avatar
                      alt="avatar"
                      sx={{ width: 20, height: 20, margin: "2px" }}
                      src={generateImageUrl("avatars", generateAssetsId(selectedUser.avatarId, "2d"), {
                        width: "80",
                        height: "80",
                        format: "auto",
                      })}
                    />
                    <Typography variant="caption" fontWeight="bold" sx={{ marginLeft: "4px" }}>
                      {selectedUser.username}:&nbsp;
                    </Typography>
                    <Typography variant="caption">{getModActionStatus(suspension)}</Typography>
                  </div>
                ))}
                <h5 className="my-2">Suspensions with messages</h5>
              </>
            )}
            {(!isLoadingMessages || !!messages?.length) &&
              (messages?.length ? (
                <>
                  {messages.map((message) => (
                    <ChatMessage
                      key={message.messageId}
                      message={message}
                      selectedMessageId={selectedMessage?.messageId}
                      onClick={() => selectMessageClickHandler(message)}
                    />
                  ))}
                  <div ref={lastElementRef}></div>
                </>
              ) : (
                <Typography variant="subtitle1" textAlign="center">
                  No messages from this user
                </Typography>
              ))}
          </div>
        </>
      )}
    </Paper>
  );
};

export default UserChatHistory;
