import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { createTriggerPoll } from "../../../../api/triggers";
import type { CreateTriggerPollArgs } from "../../../../api/triggers";

import { createUploadLink, invalidateCache } from "../../../../api/assets";

import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

const useCreateTriggerPoll = () => {
  const { showFailureAlert, showSuccessAlert } = useAlert();

  return useMutation({
    mutationFn: async (data: CreateTriggerPollArgs) => {
      if (data.hasImages) {
        await Promise.all(
          data.options.map(async (option) => {
            if (!option.image) {
              return;
            }

            const data = await createUploadLink("triggers", option.id);

            // Upload image
            await axios.put(data.uploadUrl, option.image, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Length": option.image.size,
                "Content-Type": option.image.type,
              },
            });

            // Invalidate cache
            await invalidateCache("triggers", option.id);
          }),
        );
      }

      return createTriggerPoll({
        ...data,
        options: data.options.map(({ id, value, orderIndex, isCorrect }) => ({
          id,
          value,
          orderIndex,
          isCorrect,
        })),
      });
    },
    onSuccess() {
      showSuccessAlert("Poll created successfully");
    },
    onError(error) {
      if (error instanceof Error) {
        showFailureAlert(error.message);
      } else {
        showFailureAlert("Something went wrong while creating the poll");
      }
    },
  });
};

export default useCreateTriggerPoll;
