import { useQuery } from "@tanstack/react-query";
import { getCorrectAnswers } from "../../../api/pickems";

const useFetchCorrectAnswers = ({ pickemsId, enabled }: { pickemsId?: string; enabled?: boolean }) => {
  return useQuery(["correctAnswers", pickemsId], () => (pickemsId ? getCorrectAnswers(pickemsId) : undefined), {
    retry: true, //infinitely retry failing requests
    enabled,
  });
};

export default useFetchCorrectAnswers;
