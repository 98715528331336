import { RLRankingLeaderboard, RLRankingLeaderboardEntry } from "../../../../api/tournaments/rankings/schemas";
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";

interface RLRankingTableProps {
  data: RLRankingLeaderboard;
  category?: string;
  subCategory?: string;
}

export const RLRankingTable = ({ data, category, subCategory }: RLRankingTableProps) => {
  const columns: ColumnDef<RLRankingLeaderboardEntry>[] = [
    {
      accessorKey: "rank",
      header: "Rank",
    },
    {
      accessorKey: "teamName",
      header: "Team Name",
    },
    {
      accessorKey: "points",
      header: "Points",
    },
  ];
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="h-full overflow-hidden overflow-y-scroll rounded-lg border border-gray-200 p-4">
      <h2>{`${category || ""} ${subCategory ? ` - ${subCategory}` : ""}  Leaderboard`}</h2>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {!category ? "Select a category" : "No results."}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
