import { Modal } from "@blastorg/portal-pattern-library";
import { Event } from "@/api/metricsViewership/schemas/EventSchema";
import { Button } from "@/components/ui/button";
import { ContentCopy } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useCreateEvent } from "@/api/metricsViewership/hooks/useCreateEvent";
import { useNavigate } from "react-router-dom";

export const DuplicateEventModal = ({
  isOpen,
  onClose,
  event,
}: {
  isOpen: boolean;
  onClose: () => void;
  event: Event;
}) => {
  const { mutate: createEvent, isLoading: isSubmitting } = useCreateEvent();
  const newEventName = `Copy of ${event.name}`;
  const navigate = useNavigate();

  const onSubmit = () => {
    createEvent(
      {
        name: newEventName,
        gameName: event.gameName,
        twitchGameId: event.twitchGameId,
        startDate: event.startDate,
        endDate: event.endDate,
        keywords: event.keywords,
        channels: event.channels,
      },
      {
        onSuccess(newEvent) {
          navigate(`/metrics/viewership/events/${newEvent.id}`);
          onClose();
        },
      },
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="flex w-full max-w-lg flex-col items-start gap-8 bg-gray-800 p-8 text-left md:w-full"
    >
      <h1 className="text-xl">Duplicate event {event.name}?</h1>
      <p className="text-sm">
        All event details will be copied to a new event with the name mentioned bellow. Same channels will be linked to
        the new event.
      </p>
      <p>
        New event name: <b className="text-blue-100">{newEventName}</b>
      </p>
      <div className="flex w-full items-center justify-end gap-2">
        <Button onClick={onClose} variant="outline" disabled={isSubmitting}>
          Cancel
        </Button>
        <LoadingButton
          loading={isSubmitting}
          loadingPosition="start"
          onClick={onSubmit}
          variant="contained"
          startIcon={<ContentCopy />}
          color="primary"
        >
          Duplicate Event with channels
        </LoadingButton>
      </div>
    </Modal>
  );
};
