import styled from "@emotion/styled/macro";
import { colors, TableCell } from "@mui/material";

const Td = styled(TableCell)`
  padding: 0.5rem 0;
  padding-right: 4rem;
`;

export const HeaderTd = styled(Td)`
  padding-top: 1rem;
  font-weight: bold;
`;

export const Title = styled(Td)`
  color: ${colors.grey[600]};
  white-space: nowrap;
  width: fit-content;
`;
export const Value = styled(Td)`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;

  > * {
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StatusBall = styled.div<{ status: string }>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${({ status }) => (status === "active" ? colors.green[400] : colors.red[400])};
  margin-left: 0.5rem;
`;
