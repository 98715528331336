import { z } from "zod";
import { TeamSchema } from "./teams";

export const TournamentMetadataSchema = z.object({
  externalStreamUrl: z.string().nullish(),
  sponsors: z
    .object({
      header: z.string().nullish(),
      items: z.array(
        z.object({
          logoKey: z.string(), // assets image key
          url: z.string(),
        }),
      ),
    })
    .nullish()
    .catch(null),
  ticketing: z
    .object({
      items: z
        .object({
          title: z.string(),
          startDate: z.string(),
          endDate: z.string(),
          cta: z.object({
            url: z.string(),
          }),
        })
        .array(),
    })
    .nullish()
    .catch(null), // Pass null if schema is not correct
  headerCta: z.object({ text: z.string(), url: z.string() }).nullish().catch(null),
});

export type TournamentMetadata = z.infer<typeof TournamentMetadataSchema>;

export const TournamentSchema = z.object({
  id: z.string(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  circuitId: z.string(),
  name: z.string(),
  prizePool: z.string().nullish(),
  numberOfTeams: z.number().nullish(),
  location: z.string().nullish(),
  externalId: z.string().nullish(),
  region: z.string().nullish(),
  grouping: z.string().nullish(),
  description: z.string().nullable(),
  metadata: TournamentMetadataSchema.nullish().catch(null),
});

export const PostTournamentSchema = TournamentSchema.omit({
  id: true,
});

export const TournamentTeamMetadataSchema = z
  .object({
    bountyValue: z.number().nullish(),
    seed: z.number().nullish(),
  })
  .nullish();

export const TournamentTeamSlotSchema = z.object({
  team: TeamSchema.nullable(),
  index: z.number(),
  qualificationMethod: z.string().nullish(),
  metadata: TournamentTeamMetadataSchema.catch(null),
});

export type PostTournament = z.infer<typeof PostTournamentSchema>;
export type Tournament = z.infer<typeof TournamentSchema>;
export type TournamentTeamSlot = z.infer<typeof TournamentTeamSlotSchema>;
