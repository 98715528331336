import { Avatar, Box, Typography } from "@mui/material";
import { FC, MouseEvent, ReactNode, memo, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { DateTime } from "luxon";

import useCancelSuspension from "../../hooks/useCancelSuspension";
import useFetchAvatar from "../../hooks/useFetchAvatar";
import useRestoreMessage from "../../hooks/useRestoreMessage";

import { Highlight, Message } from "../../../../../types/Message";
import { Patron } from "../../../../../providers/PatronProvider/types";

import getModActionStatus from "../../helpers/getModActionStatus";

import { generateImageUrl } from "../../../../../helpers/images/generateImageUrl";
import { generateAssetsId } from "../../../../../helpers/images/generateImageUrl/generateAssetsId";
import { usePatron } from "../../../../../providers/PatronProvider/hooks/usePatron";

const highlightColors = {
  MOD_HIGHLIGHT: "rgba(116,0,0,.4)",
  AI_MOD_HIGHLIGHT: "rgba(222,100,0,.5)",
  TERM: "#414141",
  AI_DELETED: "#837878",
  MOD_ACTION: "rgba(0,195,31,.2)",
  MENTION: "#595959",
  default: "transparent",
};

type HighlightTypes = keyof typeof highlightColors;

const generateEmojiUrlFromId = ({ id, patron }: { id: string; patron?: Patron }) => {
  return generateImageUrl("emojis", id, {
    width: "80",
    format: "auto",
    patron,
  });
};

export interface ChatMessageProps {
  message: Message;
  selectedMessageId?: string;
  onClick?: () => void;
  showOnlyTime?: boolean;
}

const ChatMessage: FC<ChatMessageProps> = ({ message, selectedMessageId, onClick, showOnlyTime = false }) => {
  let highlightType = message.messageMeta.autoModHighlights?.[0]?.type as HighlightTypes;
  if (!highlightType) {
    if (message.isDeleted) {
      highlightType = message.messageMeta.isHighlightedByAiAutomod ? "AI_DELETED" : "MOD_ACTION";
    } else {
      highlightType = message.messageMeta.isHighlightedByAiAutomod ? "AI_MOD_HIGHLIGHT" : "default";
    }
  }
  const highlight = highlightColors[highlightType];
  const avatar = useFetchAvatar(message.messageMeta.user.avatarId);
  const { patron } = usePatron();

  const { mutate: restoreMessageAction } = useRestoreMessage(message.messageId);
  const { mutate: cancelSuspension } = useCancelSuspension();

  const [messageSelected, setMessageSelected] = useState(false);
  useEffect(() => {
    setMessageSelected(selectedMessageId === message.messageId);
  }, [selectedMessageId, message.messageId]);

  const parsedMessage = useMemo(() => {
    const getDeletedElement = (content: string, removed: boolean): ReactNode => {
      const key = Math.random().toString(32).split(".")[1];
      return (
        <span key={key} style={removed ? { textDecoration: "line-through" } : {}}>
          {content}
        </span>
      );
    };

    const removed = Boolean(message?.isDeleted);
    const highlightsMap = new Map<number, Highlight>();
    if (message.messageMeta.autoModHighlights) {
      message.messageMeta.autoModHighlights.forEach((highlight) => {
        highlightsMap.set(highlight.tagIndex, highlight);
      });
    }

    return Array.from(message.messageContent).map((item, index) => {
      switch (item.type) {
        default:
        case "text": {
          const content = item.content;

          if (highlightsMap.has(index)) {
            const { start, end } = (highlightsMap.get(index) as Highlight).highlightRange;
            const elements: Array<ReactNode> = [];

            if (content.slice(0, start).trim()) {
              elements.push(getDeletedElement(content.slice(0, start), removed));
            }

            elements.push(
              <span style={{ textDecoration: "underline" }} key={Math.random().toString(32).split(".")[1]}>
                {content.slice(start, end)}
              </span>,
            );

            if (content.slice(end).trim()) {
              elements.push(getDeletedElement(content.slice(end), removed));
            }

            return elements;
          }

          return getDeletedElement(content, removed);
        }
        case "emoji": {
          const id = item.content;
          const src = generateEmojiUrlFromId({ id, patron });

          return <img className="inline size-4 object-contain align-middle" key={id + index} src={src} />;
        }
      }
    });
  }, [message, patron]);

  const restoreMessage = (e: MouseEvent) => {
    e.stopPropagation();
    restoreMessageAction();
    if (message.suspensionId) {
      cancelSuspension(message.suspensionId);
    }
  };

  const isMessageRemoved = message.isDeleted;
  return (
    <div
      style={{
        backgroundColor: highlight,
      }}
      onClick={onClick}
      className={classNames("mb-1", {
        "opacity-70": highlight !== "transparent",
        "outline outline-white": messageSelected,
        "hover:cursor-pointer": onClick,
      })}
    >
      <div className="flex">
        <Typography variant="caption" className="whitespace-nowrap">
          {DateTime.fromISO(message.createdAt).toFormat(showOnlyTime ? "HH:mm" : "dd/MM HH:mm")}
        </Typography>
        <Avatar
          alt="profile picture"
          sx={{ width: 20, height: 20, margin: "2px" }}
          src={generateImageUrl("avatars", generateAssetsId(message.messageMeta.user.avatarId, "2d"), {
            width: "80",
            height: "80",
            format: "auto",
            patron,
          })}
        />
        <Typography variant="caption" className="break-words" style={{ wordBreak: "break-word", marginTop: "2px" }}>
          <Typography variant="caption" fontWeight="bold" sx={{ color: avatar?.color }}>
            {message.messageMeta.user.username}:&nbsp;
          </Typography>
          {parsedMessage}
          {isMessageRemoved && (
            <>
              &nbsp; &lt;{message.deletedBy || "Automod"}&gt;
              {getModActionStatus(message.suspension)}
            </>
          )}
        </Typography>
      </div>
      {isMessageRemoved && (
        <Box component="div" textAlign="right" lineHeight="1" width="100%">
          <Typography onClick={restoreMessage} color="yellow" variant="caption" fontWeight="bold" fontSize="0.6rem">
            REVERT
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default memo(ChatMessage);
