import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { LinkAccordion } from "./components/LinkAccordion";

interface DataUrlAccordionProps {
  matchId: string;
  matchType: string;
}

export const DataUrlAccordion = ({ matchId, matchType }: DataUrlAccordionProps) => {
  let mapsNumber: number = 0;
  switch (matchType) {
    case "BO1":
      mapsNumber = 1;
      break;
    case "BO3":
      mapsNumber = 3;
      break;
    case "BO5":
      mapsNumber = 5;
      break;
    case "BO7":
      mapsNumber = 7;
      break;
    default:
      break;
  }

  return (
    <Accordion type="single" collapsible className="mx-0 my-2 w-full rounded bg-zinc-900 px-2">
      <AccordionItem value="data-urls">
        <AccordionTrigger className="h-9">Data URLs (Timeline & Statistics)</AccordionTrigger>
        <AccordionContent>
          <LinkAccordion type="timeline" matchId={matchId} numberOfMaps={mapsNumber} />
          <LinkAccordion type="statistics" matchId={matchId} numberOfMaps={mapsNumber} />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
