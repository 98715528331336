import z from "zod";
import { DateStringSchema } from "./dateString";
import { DbTeamSchema } from "./teams";

export const DbTournamentSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: DateStringSchema,
  endDate: DateStringSchema.nullish(),
  circuitId: z.string(),
  prizePool: z.string().nullish(),
  location: z.string().nullish(),
  maxNumberOfTeams: z.number(),
  signupStartsAt: z.string().datetime(),
  signupEndsAt: z.string().datetime(),
  createdAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  updatedAt: z.string().datetime().nullable(),
});

export type DbTournament = z.infer<typeof DbTournamentSchema>;

export const TournamentEnrolledTeamSchema = DbTeamSchema.pick({
  id: true,
  name: true,
});

export type TournamentEnrolledTeam = z.infer<typeof TournamentEnrolledTeamSchema>;

export const DetailedTournamentSchema = DbTournamentSchema.extend({
  enrolledTeams: z.array(TournamentEnrolledTeamSchema),
});

export type DetailedTournament = z.infer<typeof DetailedTournamentSchema>;

export const TournamentCreateRequestSchema = DbTournamentSchema.pick({
  circuitId: true,
  startDate: true,
  endDate: true,
  name: true,
  prizePool: true,
  location: true,
  maxNumberOfTeams: true,
  signupStartsAt: true,
  signupEndsAt: true,
});

export type TournamentCreateRequest = z.infer<typeof TournamentCreateRequestSchema>;

export const TournamentPatchRequestSchema = DbTournamentSchema.pick({
  startDate: true,
  endDate: true,
  location: true,
  maxNumberOfTeams: true,
  prizePool: true,
  signupEndsAt: true,
  signupStartsAt: true,
}).partial();

export type TournamentPatchRequest = z.infer<typeof TournamentPatchRequestSchema>;
