import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Edit } from "@mui/icons-material";
import { MatchByTournamentIdResponse } from "../../../../../api/tournaments/schemas/matches";
import { Stage } from "../../../../../api/tournaments/schemas/stages";
import { TournamentMetadata } from "../../../../../api/tournaments/schemas/tournaments";
import { MatchesTable } from "./components/MatchesTable";

export const StageInfo = ({
  stage,
  matches,
  matchClick,
  editClick,
  tournamentMetadata,
  gameId,
}: {
  stage?: Stage;
  matches: MatchByTournamentIdResponse[];
  matchClick: (match: MatchByTournamentIdResponse) => void;
  editClick: (stage: Stage) => unknown;
  tournamentMetadata?: TournamentMetadata | null;
  gameId: string;
}) => {
  if (stage === undefined) {
    return <div>No stages</div>;
  }

  const matchMetadataType = (() => {
    switch (gameId) {
      case "dota":
        return "dota_match";
      case "cs":
        return "cs_match";
      default:
        return null;
    }
  })();

  const newMatch: MatchByTournamentIdResponse = {
    stageId: stage.id,
    stageName: stage.name,
    stageIndex: stage.index,
    matchId: "NEWMATCH",
    matchName: "",
    matchType: "BO3",
    matchIndex: matches.length,
    scheduledAt: new Date(),
    teams: [],
    metadata:
      tournamentMetadata?.externalStreamUrl && matchMetadataType
        ? {
            _t: matchMetadataType,
            externalStreamUrl: tournamentMetadata.externalStreamUrl,
          }
        : null,
  };

  return (
    <div className="mt-1 w-full">
      <Card className="grid grid-cols-4 gap-4 p-4">
        <div className="space-y-1">
          <p className="text-sm text-muted-foreground">Name</p>
          <p className="text-sm font-medium">{stage.name}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-muted-foreground">Index</p>
          <p className="text-sm font-medium">{stage.index}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-muted-foreground">Format</p>
          <p className="text-sm font-medium">{stage.format}</p>
        </div>
        <div>
          <Button variant="outline" onClick={() => editClick(stage)} className="gap-2">
            <Edit className="size-4" />
            Edit stage details
          </Button>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-muted-foreground">Date</p>
          <p className="text-sm font-medium">
            {`${stage.startDate.toISOString().split("T")[0]} - ${stage.endDate.toISOString().split("T")[0]}`}
          </p>
        </div>
        <div className="col-span-2 space-y-1">
          <p className="text-sm text-muted-foreground">Stage ID</p>
          <p className="text-sm font-medium">{stage.id}</p>
        </div>
      </Card>

      <div className="flex w-full items-center justify-between">
        <h3 className="mb-2 mt-4 text-lg font-medium">Matches</h3>
        <Button variant="secondary" onClick={() => matchClick(newMatch)}>
          Add Match
        </Button>
      </div>
      <div className="flex flex-col gap-3">
        {matches.length !== 0 && matches !== undefined ? (
          <MatchesTable matches={matches} matchClick={matchClick} gameId={gameId} tournamentId={stage.tournamentId} />
        ) : (
          <div>No matches for stage found</div>
        )}
      </div>
    </div>
  );
};
