import { RankingTransaction } from "@/api/tournaments/rankings/schemas";
import { DialogTitle } from "@/components/Dialog/DialogTitle";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTrigger } from "@/components/ui/dialog";
import { TrashIcon } from "lucide-react";
import useDeleteTransaction from "../../hooks/useDeleteTransaction";

interface ConfirmDeleteTransactionModalProps {
  transactionIdToDelete: string | undefined;
  triggerOpen: () => void;
  onClose: () => void;
  transaction: RankingTransaction;
}

export const ConfirmDeleteTransactionModal = ({
  transactionIdToDelete,
  triggerOpen,
  onClose,
  transaction,
}: ConfirmDeleteTransactionModalProps) => {
  const { mutate: deleteTransaction } = useDeleteTransaction({
    onSuccess: () => {
      onClose();
    },
    onError: () => {
      onClose();
    },
  });

  return (
    <Dialog
      open={transactionIdToDelete === transaction.id}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogTrigger>
        <Button variant="destructive" onClick={triggerOpen}>
          <TrashIcon />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are You Sure You Would Like to Delete this Transaction?</DialogTitle>
          <DialogDescription>
            {`${transaction.points} points, ${transaction.teamName}, ${transaction.category}:${transaction.subCategory}`}
          </DialogDescription>
        </DialogHeader>
        <Button variant="destructive" onClick={() => deleteTransaction({ transactionId: transaction.id })}>
          Delete
        </Button>
        <Button variant="default" onClick={onClose}>
          CANCEL
        </Button>
      </DialogContent>
    </Dialog>
  );
};
