import { useQuery } from "@tanstack/react-query";
import { getMatchesByTournamentId } from "../../../../api/community-tournaments";

export default function useFetchMatchesByTournamentId(tournamentId?: string) {
  return useQuery({
    queryKey: ["matches", tournamentId],
    queryFn: () => {
      return tournamentId ? getMatchesByTournamentId(tournamentId) : Promise.resolve([]);
    },
    enabled: true,
    retry: false,
  });
}
