import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { Download } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Button, Divider, IconButton, LinearProgress, MenuItem, Select, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { postUserAvatar, postUserEmoji } from "../../../../../../api/users";
import Avatar from "../../../../../../types/users/avatars";
import Emoji from "../../../../../../types/users/emojis";
import useFetchAvatars from "../../../../../UserProfile/hooks/useFetchAvatars";
import useFetchEmojis from "../../../../../UserProfile/hooks/useFetchEmojis";
import AvatarAutocomplete from "./AvatarAutocomplete";
import EmojiAutocomplete from "./EmojiAutocomplete";
import { useGetMultipleUsers } from "../../../../../../hooks/useGetMultipleUsers";

interface AssignRewardFormProps {
  userIds: string[];
  answersCount: number;
  isWaiting: boolean;
  setIsWaiting: (value: boolean) => void;
}
// Make an interface for the assignAvatar function
type AssetType = "avatar" | "emoji";

const AssignRewardForm = ({ userIds, answersCount, isWaiting, setIsWaiting }: AssignRewardFormProps) => {
  const [assetType, setAssetType] = useState<AssetType>("avatar");
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [successUserUids, setSuccessUserUids] = useState<string[]>([]);
  const [failedUserUids, setFailedUserUids] = useState<string[]>([]);
  const [chosenEmoji, setChosenEmoji] = useState<Emoji | null>(null);
  const [chosenAvatar, setChosenAvatar] = useState<Avatar | null>(null);
  const { data: emojis } = useFetchEmojis();
  const { data: avatars } = useFetchAvatars();
  const { data: users, isLoading: isUserDataLoading } = useGetMultipleUsers({
    ids: userIds,
  });

  const assignReward = useCallback(async () => {
    setIsWaiting(true);
    setProgress(0);
    const postAssignReward = async (userID: string) => {
      switch (assetType) {
        case "avatar":
          if (!chosenAvatar) {
            throw new Error("No avatar chosen");
          }
          await postUserAvatar(userID, chosenAvatar.id);
          break;

        case "emoji":
          if (!chosenEmoji) {
            throw new Error("No emoji chosen");
          }
          await postUserEmoji(userID, chosenEmoji.id);
          break;
        default:
          throw new Error("Invalid asset type");
      }
    };

    for (const uid of userIds) {
      try {
        await postAssignReward(uid);
        setSuccessUserUids((prev) => [...prev, uid]);
      } catch {
        setFailedUserUids((prev) => [...prev, uid]);
      }
      setProgress((prev) => (prev || 0) + 100 / userIds.length);
    }
    setIsWaiting(false);
  }, [setIsWaiting, userIds, assetType, chosenAvatar, chosenEmoji]);

  useEffect(() => {
    setChosenEmoji(null);
    setChosenAvatar(null);
  }, [assetType]);

  const downloadUsersData = () => {
    if (!users) {
      return;
    }

    let usersCsv = "User ID,Username,Email\n";

    // generate CSV file content based on user data.
    users.forEach((user) => {
      usersCsv += `${user.id},${user.username},${user.email}\n`;
    });
    usersCsv = usersCsv.trim();

    const blob = new Blob([usersCsv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "pickems-users.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredEmojis = emojis?.filter((emoji) => {
    return emoji.isUnlockOnly;
  });

  const filteredAvatars = avatars?.filter((avatar) => {
    return avatar.isUnlockOnly;
  });

  const isAssignmentDone = (progress ?? 0) >= 100;

  return (
    <>
      <Divider sx={{ mb: "2rem" }} />
      <Typography>
        There are
        <Typography component="span" sx={{ fontWeight: "bold", mx: "0.5rem" }}>
          {userIds.length}
        </Typography>
        users with at least
        <Typography component="span" color="success.light" sx={{ fontWeight: "bold", mx: "0.5rem" }}>
          {answersCount}
        </Typography>
        correct answers.
        {!!userIds.length && <Typography component="span"> Assign rewards to them:</Typography>}
      </Typography>
      {!!userIds.length && (
        <>
          <div className="my-8 flex items-center">
            <Typography sx={{ mr: "1rem" }}>Download users list: </Typography>
            <Button
              variant="contained"
              startIcon={<Download />}
              onClick={downloadUsersData}
              sx={{ ml: "1rem" }}
              disabled={isUserDataLoading}
            >
              Download
            </Button>
          </div>
          <Box display="flex" marginY="2rem" alignItems="center">
            <Typography sx={{ mr: "1rem" }}>Asset type: </Typography>
            <Select
              sx={{ width: "120px" }}
              size="small"
              value={assetType}
              disabled={isWaiting || isAssignmentDone}
              onChange={(event) => {
                if (event.target.value === "avatar") {
                  setAssetType(event.target.value);
                } else if (event.target.value === "emoji") {
                  setAssetType(event.target.value);
                }
              }}
            >
              <MenuItem value={"avatar"}>Avatar</MenuItem>
              <MenuItem value={"emoji"}>Emoji</MenuItem>
            </Select>
          </Box>
          <Box display="flex" marginY="2rem" alignItems="center">
            <Typography sx={{ mr: "1rem" }}> {assetType === "avatar" ? "Avatar" : "Emoji"} ID: </Typography>
            {/* TODO: Replace text field with select later */}

            {assetType === "emoji" ? (
              <EmojiAutocomplete
                emojis={filteredEmojis}
                disabled={isWaiting || isAssignmentDone}
                chosenEmoji={chosenEmoji}
                setChosenEmoji={setChosenEmoji}
              />
            ) : (
              <AvatarAutocomplete
                avatars={filteredAvatars}
                chosenAvatar={chosenAvatar}
                disabled={isWaiting || isAssignmentDone}
                setChosenAvatar={setChosenAvatar}
              />
            )}
            {assetType === "emoji" ? (
              <Button
                variant="contained"
                sx={{ ml: "1rem" }}
                startIcon={<AssignmentTurnedInIcon />}
                onClick={() => void assignReward()}
                disabled={!chosenEmoji || isWaiting || isAssignmentDone}
              >
                Assign reward
              </Button>
            ) : (
              <Button
                variant="contained"
                startIcon={<AssignmentTurnedInIcon />}
                onClick={() => void assignReward()}
                disabled={!chosenAvatar || isWaiting || isAssignmentDone}
                sx={{ ml: "1rem" }}
              >
                Assign reward
              </Button>
            )}
          </Box>
          {progress !== undefined && (
            <Box display="flex" marginY="2rem" alignItems="center">
              <LinearProgress variant="determinate" value={progress} sx={{ width: "100%" }} />
              <Box sx={{ minWidth: "fit-content", px: "0.5rem" }}>
                {progress < 100 ? (
                  <Typography variant="body2" color="text.secondary">
                    {successUserUids.length + failedUserUids.length} / {userIds.length}
                  </Typography>
                ) : (
                  <CheckIcon color="success" />
                )}
              </Box>
            </Box>
          )}
          {isAssignmentDone && (
            <>
              <Divider sx={{ mb: "2rem" }} />
              <Box display="flex" alignItems="center">
                <Typography>
                  Successfully assigned reward for
                  <Typography color="success.light" component="span" sx={{ mx: "0.5rem" }}>
                    {successUserUids.length}
                  </Typography>
                  users. Copy the list:
                </Typography>
                <IconButton
                  aria-label="copy"
                  sx={{ mx: "0.5rem" }}
                  onClick={() => {
                    void navigator.clipboard.writeText(JSON.stringify(successUserUids));
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
              {failedUserUids.length > 0 ? (
                <Box display="flex" alignItems="center">
                  <Typography>
                    Failed to assign reward for
                    <Typography color="error.light" component="span" sx={{ mx: "0.5rem" }}>
                      {failedUserUids.length}
                    </Typography>
                    users. Copy the list:
                  </Typography>
                  <IconButton
                    aria-label="copy"
                    sx={{ mx: "0.5rem" }}
                    onClick={() => {
                      void navigator.clipboard.writeText(JSON.stringify(failedUserUids));
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Box>
              ) : null}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AssignRewardForm;
