import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postReportGeneration, queryKeys } from "..";
import useAlert from "@/providers/AlertProvider/hooks/useAlert";

export const useRequestGenerateReport = () => {
  const alert = useAlert();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (eventId: string) => postReportGeneration(eventId),
    onError: () => {
      alert.showFailureAlert("Failed to generate report");
    },
    onSuccess: (_, eventId) => {
      void queryClient.invalidateQueries(queryKeys.event(eventId));
    },
  });
};
