import axios from "axios";
import { assetsURL, baseApiURL } from "../../config";

import { Avatar, AvatarSchema } from "../../types/Avatar";
import authInterceptor from "../authInterceptor";
import { AssetsUploadLink, AssetsUploadLinkSchema } from "./schemas";
import { Emoji, EmojiSchema } from "../../types/Emoji";
import { Patron } from "../../providers/PatronProvider/types";
import { getPatronPrefix } from "../../helpers/getPatronPrefix";

const assetsClient = axios.create({
  baseURL: assetsURL,
});

const apiClient = axios.create({
  baseURL: baseApiURL,
});
apiClient.interceptors.request.use(authInterceptor);

export const getAvatars = async (patron?: Patron) => {
  // Read from bucket index file: {baseAssetsURL}/avatars/index.json
  const { data } = await assetsClient.get<unknown>(`${getPatronPrefix(patron)}/avatars/index.json`);

  try {
    return AvatarSchema.array().parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // helpful for debugging zod errors
    throw error;
  }
};

export const getEmojis = async (patron?: Patron) => {
  // Read from bucket index file: {baseAssetsURL}/avatars/index.json
  const { data } = await assetsClient.get<unknown>(`${getPatronPrefix(patron)}/emojis/index.json`);

  try {
    return EmojiSchema.array().parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // helpful for debugging zod errors
    throw error;
  }
};

export const createUploadLink = async (
  folder: string,
  filename: string,
  patron?: Patron,
): Promise<AssetsUploadLink> => {
  const { data } = await apiClient.post<unknown>(`/v1${getPatronPrefix(patron)}/assets/upload-link`, {
    folder,
    filename,
  });

  const uploadLinkResponse = AssetsUploadLinkSchema.parse(data);
  return uploadLinkResponse;
};

export const invalidateCache = async (folder: string, filename: string, patron?: Patron): Promise<void> => {
  await apiClient.post(`/v1${getPatronPrefix(patron)}/assets/invalidate-cache`, {
    folder,
    filename,
  });
};

export const invalidateMetadataFile = async (folder: string, patron?: Patron): Promise<void> => {
  await apiClient.post(`/v1${getPatronPrefix(patron)}/assets/old/invalidate-metadata-cache`, {
    folder,
  });
};

export const createAvatar = async ({ avatarData, patron }: { avatarData: Avatar; patron?: Patron }) => {
  await apiClient.post(`/v1${getPatronPrefix(patron)}/assets/avatars/`, avatarData);
};

export const sendDeleteAvatarRequest = async ({ avatarId, patron }: { avatarId: string; patron?: Patron }) => {
  await apiClient.delete(`/v1${getPatronPrefix(patron)}/assets/avatars/${avatarId}`);
};

export const updateAvatar = async ({
  avatarId,
  avatarMetadata,
  patron,
}: {
  avatarId: string;
  avatarMetadata: Omit<Partial<Avatar>, "id">;
  patron?: Patron;
}): Promise<void> => {
  await apiClient.patch(`/v1${getPatronPrefix(patron)}/assets/avatars/${avatarId}`, avatarMetadata);
};

export const createEmoji = async ({ emojiData, patron }: { emojiData: Emoji; patron?: Patron }) => {
  await apiClient.post(`/v1${getPatronPrefix(patron)}/assets/emojis/`, emojiData);
};

export const sendDeleteEmojiRequest = async ({ emojiId, patron }: { emojiId: string; patron?: Patron }) => {
  await apiClient.delete(`/v1${getPatronPrefix(patron)}/assets/emojis/${emojiId}`);
};

export const updateEmoji = async ({
  emojiId,
  emojiMetadata,
  patron,
}: {
  emojiId: string;
  emojiMetadata: Omit<Partial<Emoji>, "id">;
  patron?: Patron;
}): Promise<void> => {
  await apiClient.patch(`/v1${getPatronPrefix(patron)}/assets/emojis/${emojiId}`, emojiMetadata);
};
