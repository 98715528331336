import { useMemo, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import { useDpcLeagues } from "./hooks/useDpcLeagues";
import { useMatches } from "./hooks/useMatches";
import { useTeams } from "./hooks/useTeams";
import { TransformedData, useDpcLeagueData } from "./hooks/useDpcLeagueData";
import { Disclosure } from "@headlessui/react";
import { TreeComponent } from "./components/Tree";
import { LeagueEventPhase, leagueNodeType, nodeGroupType } from "./helpers/enumMappers";

export const DotaTest = () => {
  const [leagueId, setLeagueId] = useState<string | undefined>(undefined);
  const [year, setYear] = useState<number | null>(null);
  const [tier, setTier] = useState<number | null>(null);

  const { data: matches } = useMatches(leagueId);
  const { data: dpcData } = useDpcLeagueData(leagueId);

  const { data: teams } = useTeams(leagueId);

  const kvTeams = useMemo(() => {
    // Ensure we can use kvTeams[teamId] to get the team
    if (!teams) {
      return {};
    }

    return teams.reduce(
      (acc, team) => {
        acc[team.team_id] = team;
        return acc;
      },
      {} as Record<number, (typeof teams)[0]>,
    );
  }, [teams]);

  const { data: leagues } = useDpcLeagues();

  const filteredLeagues = useMemo(() => {
    if (!leagues || !tier || !year) {
      return [];
    }

    return leagues.infos.filter((league) => {
      const startYear = new Date(league.start_timestamp * 1000).getFullYear();
      const endYear = new Date(league.end_timestamp * 1000).getFullYear();

      return league.tier >= tier && startYear === year && endYear === year;
    });
  }, [leagues, year, tier]);

  const selectedLeague = useMemo(() => {
    if (!filteredLeagues || !leagueId) {
      return null;
    }

    return filteredLeagues.find((league) => league.league_id === +leagueId);
  }, [filteredLeagues, leagueId]);

  const currencyFormatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const transformedData = useMemo(() => {
    if (!dpcData) return [];
    // DPC Data mapping:

    // NodeGroups top level is the stages, if there's only one it's the group stage.
    // Nodegroups within a given stage are the groups
    // NodeGroups within a group are the matches
    // NodeGroups within a match are the games

    const stages = dpcData.node_groups.map((stage, index) => {
      const groups = stage.node_groups?.map((group) => {
        return {
          ...group,
          name: group.name,
          groupId: group.node_group_id,
          standings: group.team_standings,
          matches: group.nodes,
          type: group.node_group_type,
        };
      });

      return {
        ...stage,
        name: stage.name + `${index == 0 ? "Group Stage" : "Playoffs"}`,
        stageId: stage.node_group_id,
        groups,
        type: stage.node_group_type,
      };
    });

    return stages;
  }, [dpcData]);

  return (
    <div>
      <h1>Dota Test</h1>
      <div>
        <Select
          value={year}
          onChange={(e) => {
            if (!e.target.value) return;
            setYear(e.target.value as number);
          }}
          defaultValue={2024}
          variant="filled"
          className="h-10 w-48"
        >
          {Array.from({ length: 10 }, (_, i) => 2024 - i).map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={tier}
          onChange={(e) => {
            if (!e.target.value) return;
            setTier(e.target.value as number);
          }}
          defaultValue={0}
          variant="filled"
          className="h-10 w-48"
        >
          <MenuItem value={(0).toString()}>Amateur</MenuItem>
          <MenuItem value={2}>Professional</MenuItem>
          <MenuItem value={3}>Premium</MenuItem>
        </Select>
        <Select
          value={leagueId}
          onChange={(e) => {
            if (!e.target.value) return;
            setLeagueId(e.target.value);
          }}
          variant="filled"
          className="h-10 w-48"
        >
          {filteredLeagues.map((league) => (
            <MenuItem key={league.league_id} value={league.league_id.toString()}>
              {league.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div>
        {selectedLeague && dpcData && (
          <>
            <div className="mt-4 bg-slate-800 p-4">
              <img
                src={`https://cdn.dota2.com/apps/dota2/images/leagues/${dpcData.info.league_id}/images/image_8.png`}
                alt=""
              />
              <p className="font-style-desktop-h3">
                {dpcData.info.name} ({dpcData.info.league_id})
              </p>
              <p>{dpcData.info.description}</p>
              <p>Total Prize pool: {currencyFormatted.format(dpcData.prize_pool.total_prize_pool)}</p>
              <p>Start date: {new Date(dpcData.info?.start_timestamp * 1000).toLocaleString()}</p>
              <p>End date: {new Date(dpcData.info?.end_timestamp * 1000).toLocaleString()}</p>
              {dpcData.prize_pool.prize_split_pct_x100.length > 0 && (
                <Disclosure>
                  <Disclosure.Button>
                    <h3 className="font-style-label-3">Prize pool</h3>
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <ul>
                      <li>
                        {dpcData.prize_pool.prize_split_pct_x100.map((split, index) => (
                          <li key={index}>
                            {index + 1} place: {currencyFormatted.format(split * 100)}
                          </li>
                        ))}
                      </li>
                    </ul>
                  </Disclosure.Panel>
                </Disclosure>
              )}
              <Disclosure>
                <Disclosure.Button>
                  <h3 className="font-style-label-3">Players</h3>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <div className="flex flex-wrap gap-4">
                    {dpcData.registered_players.map((player) => (
                      <div key={player.account_id} className="flex flex-col">
                        <p>{player.name}</p>
                        <img src={kvTeams[player.team_id]?.logo_url ?? ""} alt="" className="h-8" />
                        <p>{kvTeams[player.team_id]?.name}</p>
                      </div>
                    ))}
                  </div>
                </Disclosure.Panel>
              </Disclosure>
            </div>
            {transformedData.map((stage) => (
              <div key={stage.stageId} className="mt-4 bg-slate-800 p-4">
                <span className="font-style-desktop-h3">
                  {stage.name} ({LeagueEventPhase(stage.phase)})
                </span>
                <pre className="text-xs">
                  {JSON.stringify(
                    {
                      ...stage,
                      team_standings: undefined,
                      nodes: undefined,
                      node_groups: undefined,
                      standings: undefined,
                      matches: undefined,
                      groups: undefined,
                    },
                    null,
                    2,
                  )}
                </pre>
                <div className="flex flex-col gap-4">
                  {stage.groups?.map((group) => (
                    <div key={group.groupId} className="py-4">
                      <h2 className="pb-4 font-style-label-1">
                        {group.name} (ID: {group.node_group_id}){group.is_final_group && " (Final node group)"}
                      </h2>
                      <ul>
                        <li>Group type: {nodeGroupType(group.type)}</li>
                        <li>Advancing team count: {group.advancing_team_count}</li>
                      </ul>
                      <div className="grid grid-cols-5">
                        {group.standings.map((standing) => (
                          <div key={standing.team_id}>
                            <img src={kvTeams[standing.team_id]?.logo_url ?? ""} alt="" className="h-8" />
                            <p>{kvTeams[standing.team_id]?.name}</p>
                            {standing.wins > 0 || standing.losses > 0 ? (
                              <>
                                <p>Position: {standing.standing}</p>
                                <p>
                                  Wins: {standing.wins}
                                  <br />
                                  Losses: {standing.losses}
                                </p>
                              </>
                            ) : (
                              <p>Position: {standing.standing}</p>
                            )}
                          </div>
                        ))}
                      </div>
                      <Disclosure>
                        <Disclosure.Button>
                          <h3 className="pt-4 font-style-label-3">Matches</h3>
                        </Disclosure.Button>
                        <Disclosure.Panel>
                          <div className="flex flex-col gap-y-2">
                            {group.matches.map((match) => (
                              <div key={match.node_id}>
                                <p>
                                  {kvTeams[match.team_id_1]?.name ?? "TBD"} vs {kvTeams[match.team_id_2]?.name ?? "TBD"}
                                </p>
                                <p>
                                  Match type: {leagueNodeType(match.node_type)}
                                  {/* <pre className="text-xs">
                                    {JSON.stringify(match, null, 2)}
                                  </pre> */}
                                </p>
                                <p className="flex flex-col gap-y-2">
                                  {match.matches.map((game) => {
                                    const match = matches?.find((m) => m.match_id === parseInt(game.match_id));

                                    const loserId =
                                      game.winning_team_id === match?.radiant_team_id
                                        ? match?.dire_team_id
                                        : match?.radiant_team_id;

                                    return (
                                      <ul
                                        key={game.match_id}
                                        className="flex flex-col gap-y-2 rounded-md border-2 bg-slate-700 p-2"
                                      >
                                        <li>
                                          Start time: {new Date((match?.start_time ?? 0) * 1000).toLocaleString()}
                                        </li>
                                        <li>Winner: {kvTeams[game.winning_team_id]?.name}</li>
                                        {loserId && <li>Loser: {kvTeams[loserId]?.name}</li>}
                                        <li>Duration: {match?.duration} seconds</li>
                                      </ul>
                                    );
                                  })}
                                </p>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </Disclosure>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {transformedData && kvTeams && (
              <TreeComponent kvTeams={kvTeams} transformedData={transformedData as unknown as TransformedData} />
            )}

            {dpcData.series_infos.length > 0 && (
              <div>
                <h2>Series:</h2>
                <div className="flex flex-col p-4">
                  {dpcData.series_infos
                    .sort((a, b) => b.start_time - a.start_time)
                    .map((series) => (
                      <div key={series.series_id} className="rounded-md bg-slate-700 p-4">
                        <h3>{series.series_id}</h3>
                        <p>{new Date(series.start_time * 1000).toLocaleString()}</p>
                        <p>{leagueNodeType(series.series_type)}</p>
                        <p>Series id: {series.series_id}</p>
                        <p>games: {series.match_ids.length}</p>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
