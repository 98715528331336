import { Button, Card, ListItem, ListItemText } from "@mui/material";
import { DbStage } from "../../../../../api/community-tournaments/schemas/stages";
import {
  DbTournamentMatch,
  // MatchCreateRequest,
} from "../../../../../api/community-tournaments/schemas/matches";

export const StageInfo = ({
  stage,
  matches,
  circuit,
  tournament,
  matchClick,
  editClick,
}: {
  stage?: DbStage;
  matches: DbTournamentMatch[];
  circuit?: DbTournamentMatch["circuit"];
  tournament?: DbTournamentMatch["tournament"];
  matchClick: (match: DbTournamentMatch) => void;
  editClick: (stage: DbStage) => unknown;
}) => {
  if (!stage || !circuit || !tournament) {
    return <div>No stages</div>;
  }

  const newMatch: DbTournamentMatch = {
    id: "NEWMATCH",
    name: "",
    index: matches.length,
    stageId: stage.id,
    teams: [],
    type: "BO1",
    scheduledAt: null,
    circuit: circuit,
    tournament: tournament,
    stage: stage,
    maps: [],
    server: null,
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="my-4 flex-none font-style-desktop-h4">{stage.name}</div>
        <Button onClick={() => editClick(stage)}>Edit</Button>
      </div>
      <Card sx={{ mb: 2 }}>
        <div className="grid grid-cols-2">
          <ListItem>
            <ListItemText primary="Index" secondary={stage.index} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Date"
              secondary={`${new Date(stage.startDate).toLocaleDateString()} - ${
                stage?.endDate && new Date(stage?.endDate).toLocaleDateString()
              }`}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Format" secondary={stage.format} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Stage ID" secondary={stage.id} />
          </ListItem>
        </div>
      </Card>
      <div className="my-2 font-style-desktop-h4">Matches</div>
      <div className="flex flex-col gap-3">
        {matches.length !== 0 && matches !== undefined ? (
          matches.map((match) => (
            <Button
              key={match.id}
              color="primary"
              variant="contained"
              className="flex flex-col items-start gap-2"
              onClick={() => {
                matchClick({
                  ...match,
                  teams: match.teams.map((matchTeam) => ({
                    ...matchTeam,
                    prevTeamId: matchTeam.team.id,
                  })),
                });
              }}
            >
              <div>
                {match.name} - INDEX {match.index}
              </div>
              <div className="flex gap-1 font-style-b3-body-copy">
                {match.teams.map((team, index) => (
                  <div key={index}>
                    {team.team.name ? team.team.name : "TBD"}
                    {team.score !== null ? ` (${team.score})` : ""}
                    {index !== match.teams.length - 1 ? " :" : ""}
                  </div>
                ))}
              </div>
            </Button>
          ))
        ) : (
          <div>No matches for stage found</div>
        )}
      </div>
      <Button
        className="mt-4"
        onClick={() => {
          matchClick(newMatch);
        }}
      >
        Add Match
      </Button>
    </div>
  );
};
