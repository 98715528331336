import { useDeleteChannel } from "@/api/metricsViewership/hooks/useDeleteChannel";
import { Channel } from "@/api/metricsViewership/schemas/ChannelSchema";
import { ConfirmationModal } from "@/shared/components/ConfirmationModal";

export const DeleteChannelModal = ({
  isOpen,
  onClose,
  channel,
}: {
  isOpen: boolean;
  onClose: () => void;
  channel: Channel | null;
}) => {
  const { mutate: deleteChannel } = useDeleteChannel();

  const onSubmit = () => {
    if (!channel) {
      return;
    }

    deleteChannel(channel.id, {
      onSuccess() {
        onClose();
      },
    });
  };

  return (
    <ConfirmationModal
      title={`Delete Channel ${channel?.channelName}`}
      description={
        <div className="max-w-xl">
          Are you sure you want to delete this channel? If you delete this channel, you{" "}
          <b>will not get reports for this channel anymore</b>. If you need reports from this channel, please just keep
          it disabled.
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onSubmit}
    />
  );
};
