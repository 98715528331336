import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MatchMapStat } from "../../../api/statistics/schemas";
import { updateMatchMapStat } from "../../../api/statistics";
import useAlert from "../../../providers/AlertProvider/hooks/useAlert";

interface UseUpdateMatchMapStat {
  matchId: string;
  mapNumber: number;
}

const useUpdateMatchMapStat = ({ matchId, mapNumber }: UseUpdateMatchMapStat) => {
  const queryClient = useQueryClient();
  const { showFailureAlert } = useAlert();

  const result = useMutation({
    mutationFn: (stats: Omit<MatchMapStat, "matchUid" | "mapNumber">) =>
      updateMatchMapStat({ matchId, mapNumber, stats }),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["live stats match map"],
      });
    },
    onError() {
      showFailureAlert(`Something went wrong while updating the stats with matchId ${matchId}`);
    },
  });

  return result;
};

export default useUpdateMatchMapStat;
