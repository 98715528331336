import axios from "axios";
import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";
import { Circuit, CircuitSchema, PostCircuit } from "./schemas/circuits";
import { DateTime } from "luxon";
import {
  TournamentMetadata,
  PostTournament,
  Tournament,
  TournamentSchema,
  TournamentTeamSlot,
  TournamentTeamSlotSchema,
} from "./schemas/tournaments";
import {
  CsRoles,
  DetailedPlayer,
  DetailedPlayerSchema,
  DotaRoles,
  LineupV2Schema,
  Player,
  PlayerHistory,
  PlayerHistorySchema,
  PlayerHistoryWithMapCount,
  PlayerHistoryWithMapCountSchema,
  PlayerSchema,
} from "./schemas/players";
import { Team, TeamSchema } from "./schemas/teams";
import { PatchStage, PostStage, Stage, StageSchema } from "./schemas/stages";
import {
  CreateMatchRequest,
  DbMatchTeam,
  DbMatchTeamSchema,
  DetailedMatch,
  DetailedMatchSchema,
  Match,
  MatchByTournamentIdResponse,
  MatchByTournamentIdResponseSchema,
  MatchSchema,
  MatchWithMapsAndTournamentSchema,
} from "./schemas/matches";
import { MapSchema, Map, MapUpdateRequest, TeamMapSchema, MapNoTeamSchema } from "./schemas/maps";
import { Veto, VetoSchema } from "./schemas/vetoes";
import { AwardResponse, AwardResponseSchema, CreateUpdateAward, DbAward, DbAwardSchema } from "./schemas/awards";
import { PostRocketLeagueLeaderboardRequest, RankingCategoriesResponseSchema } from "./schemas/rankings";
import { DotaLeague, DotaLeagueSchema } from "./schemas/dotaLeagues";
import { Prize, PrizeSchema } from "./schemas/prizes";
import { z } from "zod";

export const newTournamentApi = axios.create({
  baseURL: `${baseApiURL}/v2`,
});

newTournamentApi.interceptors.request.use(authInterceptor);

export const getCircuits = async (game?: string): Promise<Circuit[]> => {
  let url = `/circuits`;
  url = game ? `${url}?game=${game}` : url;
  const { data } = await newTournamentApi.get<unknown>(`${url}`);
  const circuits = CircuitSchema.array().parse(data);
  return circuits;
};

export const postNewCircuit = async (circuit: PostCircuit): Promise<void> => {
  await newTournamentApi.post(`/circuits`, {
    ...circuit,
    startDate: DateTime.fromJSDate(circuit.startDate).toFormat("yyyy-MM-dd"),
    endDate: DateTime.fromJSDate(circuit.endDate).toFormat("yyyy-MM-dd"),
  });
};

export const patchCircuit = async (
  cicuitDates: { startDate: Date; endDate: Date },
  circuitId: string,
): Promise<void> => {
  await newTournamentApi.patch(`/circuits/${circuitId}`, {
    startDate: DateTime.fromJSDate(cicuitDates.startDate).toFormat("yyyy-MM-dd"),
    endDate: DateTime.fromJSDate(cicuitDates.endDate).toFormat("yyyy-MM-dd"),
  });
};

export const getCircuitById = async (circuitId: string): Promise<Circuit> => {
  const { data } = await newTournamentApi.get<unknown>(`/circuits/${circuitId}`);
  const circuit = CircuitSchema.parse(data);
  return circuit;
};

export const getDotaLeagues = async ({ year, tier }: { year?: string; tier?: number }): Promise<DotaLeague[]> => {
  const params = new URLSearchParams();
  if (year) {
    params.append("year", year);
  }
  if (tier) {
    params.append("tier", tier.toString());
  }
  const { data } = await newTournamentApi.get<unknown>(`/tournaments/gamedata/dota/leagues/?${params.toString()}`);
  const leagues = DotaLeagueSchema.array().parse(data);

  return leagues;
};

export const getTournaments = async (game?: string): Promise<Tournament[]> => {
  let url = "/tournaments";
  url = game ? `${url}?game=${game}` : url;
  const { data } = await newTournamentApi.get<unknown>(url);
  const tournaments = TournamentSchema.array().parse(data);
  return tournaments;
};
export const getTournamentsByCircuitId = async (circuitId: string): Promise<Tournament[]> => {
  const { data } = await newTournamentApi.get<unknown>(`/circuits/${circuitId}/tournaments`);
  const tournaments = TournamentSchema.array().parse(data);
  return tournaments;
};

export const postCreateUploadLink = async (folder: string, filename: string): Promise<string> => {
  const { data } = await newTournamentApi.post<{ uploadLink: string }>(`/players/config/upload-link`, {
    folder,
    filename,
  });
  return data.uploadLink;
};

export const postTournament = async (tournament: PostTournament) => {
  const response = await newTournamentApi.post(`/tournaments`, {
    ...tournament,
    startDate: DateTime.fromJSDate(tournament.startDate).toFormat("yyyy-MM-dd"),
    endDate: DateTime.fromJSDate(tournament.endDate).toFormat("yyyy-MM-dd"),
  });

  return z
    .object({
      id: z.string(),
    })
    .parse(response.data);
};

export const patchTournament = async (
  tournamentId: string,
  options: {
    numberOfTeams?: number;
    location?: string | null;
    externalId?: string | null;
    region?: string | null;
    grouping?: string | null;
    description?: string | null;
    metadata?: TournamentMetadata | null;
    prizePool?: string;
    startDate?: Date;
    endDate?: Date;
  },
): Promise<void> => {
  await newTournamentApi.patch(`/tournaments/${tournamentId}`, {
    startDate: options?.startDate ? DateTime.fromJSDate(options.startDate).toFormat("yyyy-MM-dd") : undefined,
    endDate: options?.endDate ? DateTime.fromJSDate(options.endDate).toFormat("yyyy-MM-dd") : undefined,
    numberOfTeams: options.numberOfTeams,
    location: options.location,
    externalId: options.externalId == "" ? null : options.externalId,
    region: options.region,
    grouping: options.grouping,
    description: options.description,
    metadata: options.metadata,
    prizePool: options.prizePool,
  });
};

export const getTournamentById = async (tournamentId: string): Promise<Tournament> => {
  const { data } = await newTournamentApi.get<unknown>(`/tournaments/${tournamentId}`);
  const tournament = TournamentSchema.parse(data);
  return tournament;
};

export const getTournamentTeams = async (tournamentId: string): Promise<TournamentTeamSlot[]> => {
  const { data } = await newTournamentApi.get<unknown>(`/tournaments/${tournamentId}/teams`);
  return TournamentTeamSlotSchema.array().parse(data);
};

export const createTournamentTeam = async ({
  tournamentId,
  teamId,
  qualificationMethod,
  index,
  metadata,
}: {
  tournamentId: string;
  teamId: string | null;
  qualificationMethod: string | undefined;
  index?: number;
  metadata?: TournamentTeamSlot["metadata"];
}): Promise<void> => {
  await newTournamentApi.post(`/tournaments/${tournamentId}/teams`, {
    teamId,
    qualificationMethod,
    index,
    metadata,
  });
};

export const deleteTournamentTeam = async ({
  tournamentId,
  index,
}: {
  tournamentId: string;
  index: number;
}): Promise<void> => {
  await newTournamentApi.delete(`/tournaments/${tournamentId}/teams/indexes/${index}`);
};

export const getPlayerByIngameId = async (ingameId: string): Promise<Player> => {
  const { data } = await newTournamentApi.get<unknown>(`/players/by-in-game-id/${ingameId}`);
  const player = PlayerSchema.parse(data);
  return player;
};

export const getPlayers = async (game?: string): Promise<DetailedPlayer[]> => {
  let url = `/players/detailed`;
  url = game ? `${url}?game=${game}` : url;
  const { data } = await newTournamentApi.get<unknown>(url);
  const players = DetailedPlayerSchema.array().parse(data);
  return players;
};

export const getTeams = async (game?: string): Promise<Team[]> => {
  let url = `/teams`;
  url = game ? `${url}?game=${game}` : url;
  const { data } = await newTournamentApi.get<Team[]>(url);

  try {
    return TeamSchema.array().parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // helpful for debugging zod errors
    throw error;
  }
};

export const getTeamPlayers = async (teamId: string): Promise<DetailedPlayer[]> => {
  const { data } = await newTournamentApi.get<DetailedPlayer[]>(`/teams/${teamId}/players`);

  try {
    return DetailedPlayerSchema.array().parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // helpful for debugging zod errors
    throw error;
  }
};

export const createTeam = async ({
  name,
  shortName,
  nationality,
  gameId,
}: {
  name: string;
  shortName: string;
  nationality: string;
  gameId: string;
}): Promise<Team> => {
  const { data } = await newTournamentApi.post<unknown>(`/teams`, {
    name,
    shortName,
    nationality,
    game: gameId,
  });

  try {
    return TeamSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // helpful for debugging zod errors
    throw error;
  }
};

export const updateTeam = async ({
  teamId,
  name,
  shortName,
  nationality,
  socialLinks,
  externalId,
}: {
  teamId: string;
  name?: string;
  shortName?: string;
  nationality?: string;
  socialLinks?: Team["socialLinks"];
  externalId?: string;
}) => {
  const { data } = await newTournamentApi.patch<unknown>(`/teams/${teamId}`, {
    name,
    shortName,
    nationality,
    socialLinks,
    externalId: externalId === "" ? null : externalId,
  });

  try {
    return TeamSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // helpful for debugging zod errors
    throw error;
  }
};

export const deleteTeam = async ({ teamId }: { teamId: string }) => {
  const { data } = await newTournamentApi.delete<unknown>(`/teams/${teamId}`);

  try {
    return TeamSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // helpful for debugging zod errors
    throw error;
  }
};

export const updatePlayer = async ({
  playerId,
  properties,
}: {
  playerId: string;
  properties: Partial<Player>;
}): Promise<Player> => {
  const payload = {
    ...properties,
    dateOfBirth: properties.dateOfBirth?.toISOString().split("T")[0],
  };

  const { data } = await newTournamentApi.patch<Player>(`/players/${playerId}`, payload);

  try {
    return PlayerSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // helpful for debugging zod errors
    throw error;
  }
};

export const putDotaMetadata = async ({
  playerId,
  metadata,
}: {
  playerId: string | number;
  metadata: {
    role?: DotaRoles;
    tiAppearances?: number;
  };
}) => {
  const url = `/players/${playerId}/dota-metadata`;

  await newTournamentApi.put(url, metadata);
};

export const putCsMetadata = async ({
  playerId,
  metadata,
}: {
  playerId: string | number;
  metadata: {
    role?: CsRoles;
    majorAppearances?: number;
  };
}) => {
  const url = `/players/${playerId}/cs-metadata`;

  await newTournamentApi.put(url, metadata);
};

export const updatePlayerRole = async ({
  playerId,
  fromDate,
  role,
  teamId,
}: {
  playerId: string;
  fromDate: Date;
  role: string | null;
  teamId: string | null;
}): Promise<Omit<PlayerHistory, "id">> => {
  const { data } = await newTournamentApi.post<Omit<PlayerHistory, "id">>(`/players/${playerId}/team`, {
    fromDate: DateTime.fromJSDate(fromDate).toFormat("yyyy-LL-dd"),
    role,
    teamId,
  });

  try {
    return PlayerHistorySchema.omit({ id: true }).parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // helpful for debugging zod errors
    throw error;
  }
};

export const getStageByTournamentId = async (tournamentId: string): Promise<Stage[]> => {
  const { data } = await newTournamentApi.get<Stage>(`/tournaments/${tournamentId}/stages`);
  return StageSchema.array().parse(data);
};

export const createStage = async (stage: PostStage): Promise<Stage> => {
  const { data } = await newTournamentApi.post<unknown>("/stages", {
    ...stage,
    startDate: DateTime.fromJSDate(stage.startDate).toFormat("yyyy-MM-dd"),
    endDate: DateTime.fromJSDate(stage.endDate).toFormat("yyyy-MM-dd"),
  });
  return StageSchema.parse(data);
};

export const patchStage = async (stageId: string, properties: PatchStage): Promise<Stage> => {
  const { data } = await newTournamentApi.patch<unknown>(`/stages/${stageId}`, {
    ...properties,
    numberOfTeams: properties.numberOfTeams ?? undefined,
    startDate:
      properties?.startDate !== undefined
        ? DateTime.fromJSDate(properties.startDate).toFormat("yyyy-MM-dd")
        : undefined,
    endDate:
      properties?.endDate !== undefined ? DateTime.fromJSDate(properties.endDate).toFormat("yyyy-MM-dd") : undefined,
  });
  return StageSchema.parse(data);
};

export const getMatchesByTournamentId = async (tournamentId: string): Promise<MatchByTournamentIdResponse[]> => {
  const { data } = await newTournamentApi.get<DetailedMatch[]>(`/tournaments/${tournamentId}/matches`);
  return MatchByTournamentIdResponseSchema.array().parse(data);
};

export const getDetailedMatchesByTournamentId = async (tournamentId: string): Promise<DetailedMatch[]> => {
  const { data } = await newTournamentApi.get<DetailedMatch[]>(`/games/cs/tournaments/${tournamentId}/matches`);
  return DetailedMatchSchema.array().parse(data);
};

export const createPlayer = async (
  playerProperties: Pick<
    Player,
    "nickname" | "firstName" | "lastName" | "nationality" | "dateOfBirth" | "ingameId" | "gameId"
  >,
): Promise<Player> => {
  const { data } = await newTournamentApi.post<unknown>(`/players`, {
    ...playerProperties,
    dateOfBirth: playerProperties.dateOfBirth
      ? DateTime.fromJSDate(playerProperties.dateOfBirth).toFormat("yyyy-LL-dd")
      : null,
  });

  try {
    return PlayerSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // helpful for debugging zod errors
    throw error;
  }
};

export const createMatch = async ({ name, type, index, scheduledAt, stageId }: CreateMatchRequest) => {
  const { data } = await newTournamentApi.post<unknown>(`/matches`, {
    name,
    type,
    index,
    scheduledAt,
    stageId,
  });
  try {
    return MatchSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

export const createMatchTeam = async ({ matchId, teamId, score, index }: DbMatchTeam): Promise<DbMatchTeam> => {
  const { data } = await newTournamentApi.post<unknown>(`/matches/${matchId}/teams`, {
    teamId,
    score,
    index,
  });
  try {
    return DbMatchTeamSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

export const deleteMatchTeam = async ({ matchId, teamId }: { matchId: string; teamId: string }) => {
  const { data } = await newTournamentApi.delete<unknown>(`/matches/${matchId}/teams/${teamId}`);
  try {
    return DbMatchTeamSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

export const updateMatch = async ({
  matchId,
  matchProperties,
}: {
  matchId: string;
  matchProperties: Pick<Match, "index" | "name" | "scheduledAt" | "type" | "externalId" | "metadata">;
}) => {
  const { data } = await newTournamentApi.patch<unknown>(`/matches/${matchId}`, matchProperties);
  try {
    return MatchSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

export const deleteMatch = async ({ matchId }: { matchId: string }) => {
  const { data } = await newTournamentApi.delete<unknown>(`/matches/${matchId}`);
  try {
    return MatchSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const updateMatchTeams = async ({
  matchId,
  teamId,
  score,
}: {
  matchId: string;
  teamId: string;
  score: number;
}) => {
  const { data } = await newTournamentApi.patch<unknown>(`/matches/${matchId}/teams/${teamId}`, { score: score });
  try {
    return DbMatchTeamSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

export const getPlayerHistory = async (id: string): Promise<PlayerHistoryWithMapCount[]> => {
  const { data } = await newTournamentApi.get<unknown>(`/players/${id}/history`);

  try {
    return PlayerHistoryWithMapCountSchema.array().parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // helpful for debugging zod errors
    throw error;
  }
};

export const getMatchMaps = async (id: string): Promise<Map[]> => {
  const { data } = await newTournamentApi.get<unknown>(`/matches/${id}/maps`);

  try {
    return MapSchema.array().parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error); // helpful for debugging zod errors
    throw error;
  }
};

export const getMatchVetoes = async (matchId: string): Promise<Veto[]> => {
  const { data } = await newTournamentApi.get<Veto[]>(`/matches/${matchId}/vetoes`);
  try {
    return VetoSchema.array().parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

export const getMatch = async (matchId: string): Promise<DetailedMatch> => {
  const { data } = await newTournamentApi.get<DetailedMatch>(`/matches/${matchId}/detailed`);

  try {
    return DetailedMatchSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

export const getTeamLineup = async ({ seriesId }: { seriesId?: string }) => {
  const { data } = await newTournamentApi.get<unknown>(`/games/cs/matches/${seriesId}/lineups`);
  const lineups = LineupV2Schema.parse(data);
  return {
    teamA: lineups.teamA,
    teamB: lineups.teamB,
  };
};

export const updateVeto = async (veto: Veto) => {
  const { id, index, map, matchId, teamId, type } = veto;
  const { data } = await newTournamentApi.patch<Veto>(`/vetoes/${id}`, {
    index,
    map,
    type,
    matchId,
    teamId: teamId === "Decider" ? null : teamId,
  });

  try {
    return VetoSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

export const createVeto = async (veto: Veto) => {
  const { index, map, matchId, teamId, type } = veto;
  const { data } = await newTournamentApi.post<unknown>(`/vetoes`, {
    index,
    map,
    type,
    matchId,
    teamId: teamId === "Decider" ? null : teamId,
  });
  try {
    return VetoSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

export const updateMap = async (map: MapUpdateRequest) => {
  const { id, name, scheduledAt, startedAt, endedAt, externalId } = map;
  const { data } = await newTournamentApi.patch<unknown>(`/maps/${id}`, {
    name,
    scheduledAt: scheduledAt || undefined,
    startedAt: startedAt || undefined,
    endedAt: endedAt || undefined,
    externalId: externalId === "" ? null : externalId || undefined,
  });
  try {
    return MapNoTeamSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const updateDotaMap = async (map: MapUpdateRequest) => {
  const { id, name, scheduledAt, startedAt, endedAt, externalId, teams } = map;
  const updatedTeamFormated = teams?.map((t) => ({ score: t.score, teamId: t.team.id }));
  const { data } = await newTournamentApi.patch<unknown>(`/games/dota/maps/${id}`, {
    name,
    scheduledAt: scheduledAt || undefined,
    startedAt: startedAt || undefined,
    endedAt: endedAt || undefined,
    externalId: externalId === "" ? null : externalId || undefined,
    scores: updatedTeamFormated || undefined,
  });
  try {
    return MapNoTeamSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const updateTeamMapScore = async (mapId: string, teamId: string, score: number) => {
  const { data } = await newTournamentApi.patch<unknown>(`/maps/${mapId}/teams/${teamId}`, {
    score,
  });
  try {
    return TeamMapSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const createTeamMap = async (mapId: string, teamId: string, score: number) => {
  const { data } = await newTournamentApi.patch<unknown>(`/maps/${mapId}/teams`, {
    teamId,
    score,
  });
  try {
    return TeamMapSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const createMap = async ({
  mapName,
  scheduledAt,
  matchId,
}: {
  mapName: string;
  scheduledAt: string;
  matchId: string;
}) => {
  const { data } = await newTournamentApi.post<unknown>(`/maps`, {
    name: mapName,
    scheduledAt: scheduledAt,
    matchId: matchId,
  });
  try {
    return MapNoTeamSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const deleteMap = async (mapId: string) => {
  const { data } = await newTournamentApi.delete<unknown>(`/maps/${mapId}`);
  try {
    return MapNoTeamSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const regenerateMapsData = async (matchId: string) => {
  await newTournamentApi.post(`/games/dota/matches/${matchId}/regenerate-maps-data`);
};

interface MatchAutoProgressOptions {
  gameId?: string;
  matchId: string;
}
export const matchAutoProgress = async ({ gameId, matchId }: MatchAutoProgressOptions) => {
  await newTournamentApi.get<unknown>(`/matches/${matchId}/autoprogress`, {
    params: {
      game: gameId,
    },
  });
};

export const getAwards = async (): Promise<AwardResponse[]> => {
  const { data } = await newTournamentApi.get<unknown>("/awards");
  try {
    return AwardResponseSchema.array().parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const createAward = async (award: CreateUpdateAward): Promise<DbAward> => {
  const { data } = await newTournamentApi.post<unknown>("/awards", award);
  try {
    return DbAwardSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const updateAward = async (awardId: string, award: CreateUpdateAward): Promise<DbAward> => {
  const { data } = await newTournamentApi.patch<unknown>(`/awards/${awardId}`, award);
  try {
    return DbAwardSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const deleteAward = async (awardId: string): Promise<DbAward> => {
  const { data } = await newTournamentApi.delete<unknown>(`/awards/${awardId}`);
  try {
    return DbAwardSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const listMatchesInPeriod = async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
  const { data } = await newTournamentApi.get<unknown>(`/matches`, {
    params: {
      startDate,
      endDate,
    },
  });
  try {
    return MatchWithMapsAndTournamentSchema.array().parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const updateTeamPlayer = async ({
  teamPlayerId,
  teamPlayerInfo,
}: {
  teamPlayerId: string;
  teamPlayerInfo: {
    role?: string | null;
    fromDate: Date | null;
    teamId?: string | null;
  };
}) => {
  const { data } = await newTournamentApi.patch<unknown>(`/players/teams/${teamPlayerId}`, {
    role: teamPlayerInfo.role,
    teamId: teamPlayerInfo.teamId,
    fromDate: teamPlayerInfo.fromDate ? DateTime.fromJSDate(teamPlayerInfo.fromDate).toFormat("yyyy-LL-dd") : null,
  });
  try {
    return PlayerHistorySchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const deleteTeamPlayer = async (teamPlayerId: string) => {
  const { data } = await newTournamentApi.delete<unknown>(`/players/teams/${teamPlayerId}`);
  try {
    return PlayerHistorySchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const getRankingCategories = async (game: string) => {
  const { data } = await newTournamentApi.get<unknown>(`/rankings/categories?game=${game}`);
  try {
    return RankingCategoriesResponseSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const postRLLeaderboard = async (request: PostRocketLeagueLeaderboardRequest) => {
  await newTournamentApi.post<unknown>("/games/rl/leaderboards", request);
};

export const getTournamentPrizes = async (tournamentId: string) => {
  const { data } = await newTournamentApi.get<Prize[]>(`/tournaments/prizes/${tournamentId}`);
  try {
    return PrizeSchema.array().parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export interface CreateTournamentPrizeOptions {
  tournamentId: string;
  teamId?: string;
  prize: string;
  subPrize?: string[];
  positionStart: number;
  positionEnd: number;
}
export const createTournamentPrize = async (data: CreateTournamentPrizeOptions) => {
  await newTournamentApi.post("/tournaments/prizes", {
    ...data,
    subPrize: data.subPrize ?? null,
    teamId: data.teamId ?? null,
  });
};

export const deleteTournamentPrize = async (prizeId: string) => {
  await newTournamentApi.delete(`/tournaments/prizes/${prizeId}`);
};
