import { useListTriggerPollsById } from "@/api/triggers/hooks/useListTriggerPollsById";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogTitle } from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { TriggerPoll } from "@/types/TriggerPolls";
import classNames from "classnames";
import { motion } from "motion/react";
import { ArrowDown, ArrowUp, CheckCircle, Hourglass, PauseCircle, PlayCircle } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import useStartTriggerPoll from "../hooks/useStartTriggerPoll";
import { Input } from "@/components/ui/input";

type PauseItem = {
  type: "pause";
  durationSeconds: number;
  id: string;
};

type PollItem = {
  type: "poll";
  poll: TriggerPoll;
};

type ListItem = PollItem | PauseItem;

type State = "editing" | "running" | "ended";

export const PollScheduleModal = ({
  pollIds,
  open,
  onClose,
}: {
  pollIds: string[];
  open: boolean;
  onClose: (args?: { isEnded?: boolean }) => void;
}) => {
  const { data: polls, isPending } = useListTriggerPollsById(pollIds);
  const [orderedItems, setOrderedItems] = useState<ListItem[]>([]);
  const [state, setState] = useState<State>("editing");
  const [shouldSequenceBePaused, setShouldSequenceBePaused] = useState(false);
  const [runningIndex, setRunningIndex] = useState(0);
  const initialPollItems = useMemo((): PollItem[] => {
    return polls.map((poll) => ({ type: "poll", poll }));
  }, [polls]);

  const { mutate: mutateStartPoll } = useStartTriggerPoll();

  useEffect(() => {
    const items: ListItem[] = [];
    for (const pollItem of initialPollItems) {
      if (items.length) items.push({ type: "pause", durationSeconds: 5, id: crypto.randomUUID() });
      items.push(pollItem);
    }

    setOrderedItems(items);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  const handleClose = (args: Parameters<typeof onClose>[0] = {}) => {
    if (state === "running") {
      return;
    }

    onClose(args);
  };

  const moveUp = (pollId: string) => {
    setOrderedItems((items) => {
      const index = items.findIndex((item) => item.type === "poll" && item.poll.id === pollId);
      if (index === 0) return items;

      const newItems = [...items];
      newItems[index] = items[index - 2];
      newItems[index - 2] = items[index];

      return newItems;
    });
  };

  const moveDown = (pollId: string) => {
    setOrderedItems((items) => {
      const index = items.findIndex((item) => item.type === "poll" && item.poll.id === pollId);
      if (index === items.length - 1) return items;

      const newItems = [...items];
      newItems[index] = items[index + 2];
      newItems[index + 2] = items[index];

      return newItems;
    });
  };

  const handlePauseSequence = () => {
    setShouldSequenceBePaused(true);
  };

  const handleResumeSequence = () => {
    setShouldSequenceBePaused(false);
  };

  const handleStartSequence = () => {
    setState("running");
    setRunningIndex(0);
  };

  const [isPendingStartPoll, setIsPendingStartPoll] = useState(false);

  const handleStartPoll = (pollId: string) => {
    setIsPendingStartPoll(true);

    return mutateStartPoll(pollId, {
      onSuccess: () => {
        setIsPendingStartPoll(false);
      },
    });
  };

  const onNextItem = () => {
    setRunningIndex((index) => {
      if (index === orderedItems.length - 1) {
        setState("ended");
        return index;
      }

      return index + 1;
    });
  };

  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (state === "ended") {
      clearInterval(timerRef.current);
      return;
    }

    if (state !== "running") return;

    const currentItem = orderedItems[runningIndex];
    if (currentItem.type === "poll") {
      handleStartPoll(currentItem.poll.id);
    }

    const timer = timerRef.current;

    return () => {
      clearInterval(timer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, runningIndex]);

  const onPauseChange = (id: string, durationSeconds: number) => {
    setOrderedItems((items) => {
      const index = items.findIndex((item) => item.type === "pause" && item.id === id);
      const newItems = [...items];
      const item = items[index];
      if (item.type === "pause") {
        item.durationSeconds = durationSeconds;
      }
      return newItems;
    });
  };

  const currentItem = useMemo(() => orderedItems[runningIndex], [runningIndex, orderedItems]);

  const isSequencePaused = useMemo(() => {
    if (!shouldSequenceBePaused) return false;

    if (currentItem.type === "pause") {
      return true;
    }

    return false;
  }, [shouldSequenceBePaused, currentItem]);

  const infoMessage = useMemo(() => {
    if (shouldSequenceBePaused && !isSequencePaused) {
      return `Sequence will be paused after current poll`;
    }

    return null;
  }, [shouldSequenceBePaused, isSequencePaused]);

  return (
    <Dialog open={open} onOpenChange={() => handleClose()}>
      <DialogContent className="w-full max-w-4xl">
        <DialogTitle>Start {pollIds.length} Polls</DialogTitle>
        <Table>
          <TableBody>
            {orderedItems.map((item, index) => (
              <MotionTableRow
                key={item.type === "pause" ? `pause-${index}` : `poll-${item.poll.id}-${index}`}
                initial={{ opacity: 0, y: 8 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -8 }}
                className={classNames("h-12 w-full", {})}
              >
                <TableCell
                  className={classNames("w-10 text-neutral-500", {
                    "animate-pulse text-white": runningIndex === index && state === "running",
                  })}
                >
                  {item.type === "poll" ? `${index / 2 + 1}.` : <Hourglass className="h-4 w-fit" />}
                </TableCell>
                {item.type === "pause" ? (
                  <PauseItemTableCells
                    {...item}
                    key={`pause-${index}`}
                    onChange={onPauseChange}
                    isDisabled={runningIndex >= index}
                  />
                ) : (
                  <PollItemTableCells poll={item.poll} />
                )}
                {(state === "running" || state === "ended") && (
                  <TimerCell
                    durationSeconds={item.type === "pause" ? item.durationSeconds : item.poll.durationSeconds}
                    isActive={runningIndex === index && state === "running" && !isPendingStartPoll}
                    onNextItem={onNextItem}
                    isPaused={isSequencePaused}
                  />
                )}
                {state === "editing" && (
                  <TableCell>
                    {item.type === "poll" && (
                      <div className="flex w-full gap-2">
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={() => moveUp(item.poll.id)}
                          className="ml-auto"
                          disabled={index === 0}
                        >
                          <ArrowUp />
                        </Button>
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={() => moveDown(item.poll.id)}
                          disabled={index === orderedItems.length - 1}
                        >
                          <ArrowDown />
                        </Button>
                      </div>
                    )}
                  </TableCell>
                )}
              </MotionTableRow>
            ))}
          </TableBody>
        </Table>
        <DialogFooter className="flex items-center gap-2">
          {!!infoMessage && <span className="text-xs text-neutral-300">{infoMessage}</span>}
          {state === "running" && !shouldSequenceBePaused && (
            <Button variant="default" onClick={handlePauseSequence}>
              <PauseCircle className="size-4" />
              Pause sequence
            </Button>
          )}
          {state === "running" && shouldSequenceBePaused && (
            <Button variant="default" onClick={handleResumeSequence}>
              <PlayCircle className="size-4" />
              Resume sequence
            </Button>
          )}
          {state === "editing" && (
            <Button variant="default" onClick={handleStartSequence}>
              Start the sequence
            </Button>
          )}
          {state === "ended" && (
            <Button variant="secondary" onClick={() => handleClose({ isEnded: true })}>
              <CheckCircle className="mr-1 size-4" /> Sequence ended, Close the modal
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const TimerCell = ({
  durationSeconds,
  isActive,
  onNextItem,
  isPaused,
}: {
  durationSeconds: number;
  isActive: boolean;
  onNextItem: () => void;
  isPaused: boolean;
}) => {
  const msTotal = durationSeconds * 1000;
  const [msRemainingRemaining, setMsRemainingRemaining] = useState(msTotal);
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (!isActive || isPaused) {
      clearInterval(timerRef.current);
      return;
    }

    timerRef.current = setInterval(() => {
      setMsRemainingRemaining((msRemainingRemaining) => msRemainingRemaining - 100);
    }, 100);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [isActive, isPaused]);

  useEffect(() => {
    if (msRemainingRemaining <= 0) {
      onNextItem();
      clearInterval(timerRef.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msRemainingRemaining]);

  useEffect(() => {
    // on time change
    setMsRemainingRemaining(msTotal);
  }, [msTotal]);

  return (
    <TableCell className="whitespace-nowrap">
      <div className="flex items-center gap-2">
        <Progress value={100 - (msRemainingRemaining / msTotal) * 100} />
        <span className="w-40 text-right text-xs text-muted-foreground">
          {(msRemainingRemaining / 1000).toFixed(1)} s
        </span>
      </div>
    </TableCell>
  );
};

const PollItemTableCells = ({ poll }: { poll: TriggerPoll }) => {
  return (
    <>
      <TableCell>
        <div className="flex flex-col gap-1 py-2">
          <strong className="whitespace-nowrap">{poll.title}</strong>
          <span className="text-2xs text-neutral-500">{poll.question}</span>
        </div>
      </TableCell>
    </>
  );
};

const PauseItemTableCells = ({
  id,
  durationSeconds,
  onChange,
  isDisabled,
}: {
  id: string;
  durationSeconds: number;
  onChange: (id: string, durationSeconds: number) => void;
  isDisabled: boolean;
}) => {
  return (
    <>
      <TableCell className="text-xs">
        <div className="flex items-center gap-2">
          <span className="whitespace-nowrap text-xs text-neutral-500">Pause</span>
          <Input
            type="number"
            value={durationSeconds}
            onChange={(e) => onChange(id, parseInt(e.target.value))}
            disabled={isDisabled}
            className="w-14"
          />
          <span className="text-xs text-neutral-500">seconds</span>
        </div>
      </TableCell>
    </>
  );
};

const MotionTableRow = motion.create(TableRow);
