import { Tournament } from "@/api/tournaments/schemas/tournaments";
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { uniqueId } from "lodash-es";

interface TournamentsTableProps {
  tournaments: Tournament[];
  onEdit: (tournamentId: string) => void;
  onNavigate: (tournamentId: string) => void;
}

export const TournamentsTable = ({ tournaments, onEdit, onNavigate }: TournamentsTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>Start Date</TableHead>
          <TableHead>End Date</TableHead>
          <TableHead>Teams</TableHead>
          <TableHead>Prize Pool</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {tournaments?.map((tournament) => (
          <TableRow key={tournament.name + uniqueId()}>
            <TableCell>{tournament.name}</TableCell>
            <TableCell>{tournament.startDate.toISOString().split("T")[0]}</TableCell>
            <TableCell>{tournament.endDate.toISOString().split("T")[0]}</TableCell>
            <TableCell>{tournament.numberOfTeams ?? "-"}</TableCell>
            <TableCell>{tournament.prizePool ?? "-"}</TableCell>
            <TableCell>
              <div className="flex gap-2">
                <Button variant="secondary" onClick={() => onNavigate(tournament.id)}>
                  Go to Tournament
                </Button>
                <Button variant="outline" onClick={() => onEdit(tournament.id)}>
                  Edit
                </Button>
              </div>
            </TableCell>
          </TableRow>
        ))}
        {tournaments.length === 0 && (
          <TableRow>
            <TableCell colSpan={6} className="text-sm">
              No tournaments found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
