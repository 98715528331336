import { Dialog, Divider, DialogTitle, DialogContent, Button, DialogActions, Grid } from "@mui/material";
import { useState } from "react";
import { Emoji } from "../../../../../types/Emoji";
import { ImageUploadForm } from "../../../shared/components/ImageUploadForm";
import { EmojiDetailsFields } from "../EmojiDetailsFields";
import { generateImageUrl } from "../../../../../helpers/images/generateImageUrl";
import { usePatron } from "../../../../../providers/PatronProvider/hooks/usePatron";

interface EditEmojiModalProps {
  open: boolean;
  isLoading: boolean;
  emoji: Emoji;
  onClose: () => void;
  onDelete: (emojiId: string) => void;
  onEdit: ({ changedEmoji, image }: { changedEmoji: Emoji; image: File | null }) => Promise<void>;
}

export const EditEmojiModal = ({ open, isLoading, emoji, onClose, onDelete, onEdit }: EditEmojiModalProps) => {
  const [image, setImage] = useState<File | null>(null);
  const [updatedEmoji, setUpdatedEmoji] = useState<Emoji | null>(null);
  const { patron } = usePatron();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Emoji</DialogTitle>
      <Divider />
      <DialogContent>
        <EmojiDetailsFields emoji={emoji} emojiId={emoji.id} updateEmoji={(emoji) => setUpdatedEmoji(emoji)} />
        <Divider />
        <Grid container spacing={4}>
          <Grid item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h3>Image:</h3>
              <img
                src={generateImageUrl("emojis", emoji.id, {
                  width: "40",
                  format: "auto",
                  patron,
                })}
                alt={emoji.name}
                height={40}
                width={40}
                style={{ marginLeft: "15px" }}
              />
            </div>

            <ImageUploadForm image={image} setImage={setImage} />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" style={{ marginRight: "auto" }} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="error" onClick={() => onDelete(emoji.id)} style={{ marginRight: "30px" }}>
          Delete Emoji
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={isLoading}
          onClick={() => {
            if (updatedEmoji === null) return;
            void onEdit({ changedEmoji: updatedEmoji, image });
          }}
        >
          Update Emoji
        </Button>
      </DialogActions>
    </Dialog>
  );
};
