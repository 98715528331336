import { useMutation, useQueryClient } from "@tanstack/react-query";
import { shuffleTeamsOntoTournamentMatches } from "../../../api/community-tournaments";

export default function usePostShuffleTeamsOnTournament({ tournamentId }: { tournamentId?: string }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["shuffle-teams", tournamentId],
    mutationFn: async () => {
      if (!tournamentId) {
        return;
      }
      await shuffleTeamsOntoTournamentMatches(tournamentId);
    },
    retry: false,
    onSuccess: async () => {
      //refetch matche data with updated data
      await queryClient.invalidateQueries(["matches", tournamentId]);
      await queryClient.refetchQueries(["matches", tournamentId]);
    },
  });
}
