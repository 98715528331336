import { Dialog, DialogContent, Divider, IconButton, Typography } from "@mui/material";
import { DialogTitle } from "../../../../components/Dialog/DialogTitle";
import CopyIcon from "@mui/icons-material/ContentCopy";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { Link } from "react-router-dom";

export const InfoCreateLocationDialog = ({ open, closeDialog }: { open: boolean; closeDialog: () => void }) => {
  const alert = useAlert();
  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
      <DialogTitle>Create New Location Tutorial</DialogTitle>
      <Divider />
      <DialogContent>
        <div className="m-2 flex flex-col rounded-small bg-gray-700 p-3">
          <Typography variant="h5">PRE-REQUISITES</Typography>
          <ul className="mt-2">
            <li>- Have CS2 game on Steam</li>
            <li>- Enable developer console</li>
            <li>- Recommend to have all quality options at the maximum</li>
          </ul>
        </div>
        <div className="m-2 flex flex-col rounded-small bg-gray-700 p-3">
          <Typography variant="h5">STEPS</Typography>
          <ul className="mt-2">
            <li>- Open CS2</li>
            <li>- Start a practice match in any of the maps you want to make screenshots of</li>
            <li>
              - Open console and run the next list of commands or execute the config file you can download{" "}
              <span className="text-yellow-500">here</span>
            </li>
            <li className="ml-4 inline-flex items-center rounded-small bg-gray-800 px-2 text-orange-400">
              sv_cheats 1; mp_roundtime_defuse 60; mp_freezetime 0; bot_kick; mp_restartgame 1; cl_drawhud 0;
              r_drawviewmodel 0;
              <IconButton
                onClick={() => {
                  alert.showInfoAlert("Text copied to clipboard");
                  void navigator.clipboard.writeText(
                    "sv_cheats 1; mp_roundtime_defuse 60; mp_freezetime 0; bot_kick; mp_restartgame 1; cl_drawhud 0; r_drawviewmodel 0;",
                  );
                }}
              >
                <CopyIcon />
              </IconButton>
            </li>
            <li>
              - Find a good spot to take the screenshot and press <b>F12</b> to save it in the steam folder
            </li>
            <li>
              - Move the closest you can to the spot {`(top of it)`} and in the console run{" "}
              <span className="inline-flex items-center rounded-small bg-gray-800 px-2 text-orange-400">getpos</span>{" "}
              command
            </li>
            <li>
              - You need to edit the screenshot to focust on the object/position you want also it needs to be a{" "}
              <b>1:1 square</b>
            </li>
            <li>
              - Fill the form with the image you just edited, select the map and copy the outpout of the getpos command
            </li>
          </ul>
        </div>
        <div className="m-2 flex flex-col rounded-small bg-gray-700 p-3">
          <Typography variant="h5">OTHER-INFO</Typography>
          <ul className="mt-2">
            <li>
              - You can know more about CS commands here{" "}
              <Link to={"https://totalcsgo.com/commands"} target="_blank" className="font-bold text-yellow-500">
                totalcsgo.com/commands
              </Link>
            </li>
            <li>
              - Steam Screenshot folder:{" "}
              <span className="font-bold text-yellow-500">
                {`Program Files/Steam/userdata/{steamId}/760/remote/730/screenshots `}
              </span>
              or you could also open Steam Desktop Client then in the top part click on
              <span className="font-bold text-yellow-500">{` View > Screenshots > Show on disk (icon)`}</span>
            </li>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
};
