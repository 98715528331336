import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { PostTournamentSchema } from "../../../../../api/tournaments/schemas/tournaments";
import { useMemo } from "react";
import { usePostPatchTO } from "../../../hooks/usePostPatchTO";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import { ZodError } from "zod";
import { tournamentBuilder } from "../..";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DbCircuit } from "../../../../../api/community-tournaments/schemas/circuits";
import { TournamentCreateRequestSchema } from "../../../../../api/community-tournaments/schemas/tournament";
import { patchTournament, postNewTournament } from "../../../../../api/community-tournaments";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";

interface CreateAndEditCircuitModalProps {
  tournamentModalStatus: "new" | "edit" | "closed";
  tournament: tournamentBuilder;
  handleChange: (circuit: tournamentBuilder) => void;
  handleClose: () => void;
  circuits?: DbCircuit[];
  circuitId?: string;
}

const CreateAndEditTournamentModal = ({
  tournamentModalStatus,
  handleClose,
  tournament,
  handleChange,
  circuits,
  // circuitId,
}: CreateAndEditCircuitModalProps) => {
  const readyToSubmit = useMemo(() => {
    return tournament.name !== "" &&
      tournament.name !== undefined &&
      tournament.startDate !== undefined &&
      tournament.endDate !== undefined &&
      tournament.endDate
      ? tournament.startDate <= tournament.endDate
      : !!tournament.signupStartsAt &&
          !!tournament.signupEndsAt &&
          !!tournament.circuitId &&
          !!tournament.maxNumberOfTeams &&
          !!tournament.location;
  }, [tournament]);
  const alert = useAlert();
  const submit = async () => {
    const data = TournamentCreateRequestSchema.safeParse({
      ...tournament,
      signupStartsAt: tournament.signupStartsAt ? new Date(tournament.signupStartsAt).toISOString() : undefined,
      signupEndsAt: tournament.signupEndsAt ? new Date(tournament.signupEndsAt).toISOString() : undefined,
    });
    if (!data.success) {
      const err = data.error as ZodError<typeof PostTournamentSchema>;
      alert.showFailureAlert(`${err.errors[0].path.join()} ${err.errors[0].message}`);
      throw new Error(data.error.message);
    }
    if (tournamentModalStatus === "edit" && tournament.id) {
      await patchTournament(
        {
          startDate: data.data.startDate,
          endDate: data.data.endDate,
          prizePool: data.data.prizePool ?? "0",
          location: data.data.location ?? null,
          signupStartsAt: data.data.signupStartsAt,
          signupEndsAt: data.data.signupEndsAt,
        },
        tournament.id,
      );
    } else {
      await postNewTournament(data.data);
    }
  };

  const mutatedSubmit = usePostPatchTO({
    onSuccess: () => {
      alert.showSuccessAlert(`Tournament was successfully ${tournament.id ? "edited" : "created"}}`);
      handleClose();
    },
    onError: () => {
      alert.showFailureAlert(`Tournament failed to ${tournament.id ? "edit" : "create"}`);
    },
    submit,
    type: "communityTournaments",
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Dialog open={tournamentModalStatus !== "closed"} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Tournament</DialogTitle>
        <Divider />
        <DialogContent>
          <div className="grid grid-cols-2 items-center gap-4">
            <TextField
              required
              label="Tournament Name"
              variant="outlined"
              onChange={(e) => {
                handleChange({ ...tournament, name: e.target.value });
              }}
              value={tournament.name}
              error={tournament?.name === "" || tournament === undefined}
              disabled={tournamentModalStatus === "edit"}
            />
            <FormControl>
              <InputLabel id="modal-circuit-select">Circuit</InputLabel>
              <Select
                labelId="modal-circuit-select"
                value={tournament.circuitId || "none"}
                label="Circuit"
                onChange={(e) => {
                  handleChange({ ...tournament, circuitId: e.target.value });
                }}
                disabled={tournamentModalStatus === "edit"}
                error={tournament.circuitId === "" || tournament.circuitId === undefined}
              >
                {circuits?.map((circuit) => (
                  <MenuItem value={circuit.id} key={circuit.id}>
                    {circuit.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider>
              <DesktopDatePicker
                label="Start Date"
                format="dd/LL/yyyy"
                onChange={(e) => {
                  handleChange({ ...tournament, startDate: e?.toISODate()! });
                }}
                value={tournament.startDate ? DateTime.fromJSDate(new Date(tournament.startDate)) : undefined}
              />
              <DesktopDatePicker
                label="End Date"
                format="dd/LL/yyyy"
                onChange={(e) => {
                  handleChange({ ...tournament, endDate: e?.toISODate() });
                }}
                value={tournament.endDate ? DateTime.fromJSDate(new Date(tournament.endDate)) : undefined}
                minDate={tournament.startDate ? DateTime.fromJSDate(new Date(tournament.startDate)) : undefined}
              />
              <DesktopDateTimePicker
                label="Signup Starts At"
                format="dd/LL/yyyy"
                onChange={(e) => {
                  handleChange({
                    ...tournament,
                    signupStartsAt: e?.toUTC().toISO()!,
                  });
                }}
                value={tournament.signupStartsAt ? DateTime.fromJSDate(new Date(tournament.signupStartsAt)) : undefined}
                minDate={undefined}
              />
              <DesktopDateTimePicker
                label="Signup ends closes at"
                format="dd/LL/yyyy"
                onChange={(e) => {
                  handleChange({
                    ...tournament,
                    signupEndsAt: e?.toUTC().toISO()!,
                  });
                }}
                value={tournament.signupEndsAt ? DateTime.fromJSDate(new Date(tournament.signupEndsAt)) : undefined}
                minDate={
                  tournament.signupStartsAt ? DateTime.fromJSDate(new Date(tournament.signupStartsAt)) : undefined
                }
              />
            </LocalizationProvider>
            <TextField
              required
              label="Max Number Of Teams"
              variant="outlined"
              type="number"
              onChange={(e) => {
                handleChange({
                  ...tournament,
                  maxNumberOfTeams: Number(e.target.value),
                });
              }}
              value={tournament.maxNumberOfTeams || 0}
              error={tournament?.maxNumberOfTeams === 0 || tournament === undefined}
            />
            <TextField
              label="Location"
              variant="outlined"
              onChange={(e) => {
                handleChange({ ...tournament, location: e.target.value });
              }}
              value={tournament.location || ""}
              error={tournament?.location === "" || tournament === undefined}
            />
          </div>
          {/* {tournamentModalStatus === "edit" &&
            tournament.id &&
            tournament.circuitId && (
              <TournamentTeamsModal
                tournamentId={tournament.id}
                numberTeams={tournament.numberOfTeams || 0}
                circuitId={tournament.circuitId}
              />
            )} */}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            disabled={!readyToSubmit}
            onClick={() => {
              mutatedSubmit.mutate();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default CreateAndEditTournamentModal;
