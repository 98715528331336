import { DateStringSchema } from "@/api/community-tournaments/schemas/dateString";
import { z } from "zod";

export const RLRankingLeaderboardEntrySchema = z.object({
  id: z.string(),
  gameId: z.string(),
  teamId: z.string(),
  teamName: z.string(),
  category: z.string(),
  subCategory: z.string(),
  points: z.number(),
  calculationDate: DateStringSchema,
  rank: z.number(),
});
export type RLRankingLeaderboardEntry = z.infer<typeof RLRankingLeaderboardEntrySchema>;

export const RLRankingLeaderboardSchema = RLRankingLeaderboardEntrySchema.array();
export type RLRankingLeaderboard = z.infer<typeof RLRankingLeaderboardSchema>;

export const RankingTransactionSchema = z.object({
  id: z.string(),
  gameId: z.string(),
  teamId: z.string().nullable(),
  teamName: z.string(),
  category: z.string(),
  subCategory: z.string(),
  points: z.number(),
  transactionDate: DateStringSchema,
  tournamentId: z.string().nullable(),
  description: z.string().nullable(),
  createdAt: z.string().datetime(),
});
export type RankingTransaction = z.infer<typeof RankingTransactionSchema>;

export const RankingTransactionListSchema = RankingTransactionSchema.array();
export type RankingTransactionList = z.infer<typeof RankingTransactionListSchema>;
