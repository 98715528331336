import { Chip } from "@mui/material";
import { useState } from "react";
import { Stack } from "@mui/system";

export interface Chips<T> {
  label: string;
  id: T;
}

export interface FilterChips<T> {
  label: string;
  chips: Chips<T>[];
  onClick: (filter: Chips<T>["id"]) => void;
  initialFilter: Chips<T>["id"];
}

function FilterChips<T extends string>({ label, chips, onClick, initialFilter }: FilterChips<T>) {
  const [filter, setFilter] = useState<T>(initialFilter);
  const handleFilterClick = (chipId: Chips<T>["id"]) => {
    if (filter === chipId) {
      return;
    } else {
      setFilter(chipId);
      onClick(chipId);
    }
  };

  return (
    <div className="flex w-full flex-col">
      <span className="pb-1 pl-1 font-style-label-3">{label} </span>
      <Stack direction="row" spacing={1}>
        {chips.map((chip) => (
          <Chip
            key={chip.id}
            label={chip.label}
            variant={filter === chip.id ? "filled" : "outlined"}
            onClick={() => handleFilterClick(chip.id)}
          />
        ))}
      </Stack>
    </div>
  );
}

export default FilterChips;
