import { useQuery } from "@tanstack/react-query";
import { getUsersByEmail } from "../../../api/users";

export default function useEmailSearch({ beginsWith, filterBy }: { beginsWith: string; filterBy: string }) {
  const enabled = !!filterBy && !!beginsWith && beginsWith.length > 1;

  return useQuery({
    queryKey: ["users", "search", beginsWith, filterBy],
    queryFn: () => (enabled ? getUsersByEmail({ filterBy, beginsWith }) : []),
    enabled: enabled,
  });
}
