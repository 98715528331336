import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createLocationMethod } from "../helpers/createLocationMethod";

interface CreateLocationProps {
  image: File;
  posX: number;
  posY: number;
  mapId: string;
}

export const useCreateLocation = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (props: CreateLocationProps) => {
      await createLocationMethod(props);
      await queryClient.invalidateQueries(["locations"]);
      await queryClient.refetchQueries(["locations"]);
      onSuccess?.();
    },
    {
      onSuccess,
      onError,
    },
  );
};
