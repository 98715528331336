import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import useCreateBroadcast, { CreateBroadcastPayload } from "./hooks/useCreateBroadcast";
import { usePatron } from "../../../providers/PatronProvider/hooks/usePatron";
import useAlert from "../../../providers/AlertProvider/hooks/useAlert";
import { Broadcast, BroadcastGameIdsSchema } from "../../../types/Broadcasts";

const BroadcastCreateForm = () => {
  const { patron } = usePatron();
  const { mutate, isLoading, data } = useCreateBroadcast({ patron });
  const navigate = useNavigate();

  const titleRef = useRef<HTMLInputElement>();
  const slugRef = useRef<HTMLInputElement>();
  const descriptionRef = useRef<HTMLInputElement>();
  const resolutionRef = useRef<HTMLSelectElement>();
  const providerRef = useRef<HTMLSelectElement>();
  const [gameIds, setGameIds] = useState<Broadcast["gameIds"]>([]);
  const [videoInputTier, setVideoInputTier] = useState<"HD" | "UHD">("HD");

  const handleGamesChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setGameIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const alert = useAlert();

  const create = () => {
    const [title, slug, description, resolution, provider] = [
      titleRef.current?.value.trim(),
      slugRef.current?.value.trim(),
      descriptionRef.current?.value.trim(),
      resolutionRef.current?.value,
      providerRef.current?.value as "aws" | "gcp" | undefined,
    ];

    if (!title || !slug || gameIds.length === 0) {
      alert.showFailureAlert("Please add title, slug and select 1 or more games");
      return;
    }

    mutate({
      patron,
      broadcast: {
        title,
        slug,
        description: description || undefined,
        videoHighestProfile: resolution as CreateBroadcastPayload["videoHighestProfile"],
        // @ts-expect-error adding type check to build, wont fix
        streamProvider: provider ?? "gcp",
        videoInputTier,
        gameIds,
      },
    });
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    navigate("/broadcasts", { replace: true });
  }, [data, navigate]);

  return (
    <>
      <div className="flex w-full flex-col p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <Link color="inherit" to="/broadcasts">
            Broadcasts
          </Link>
          <span>{patron}</span>
          <div> Create Broadcast </div>
        </Breadcrumbs>
        <h1 className="my-8">Create New Broadcast</h1>
        <table className="w-full border-separate border-spacing-4">
          <tbody>
            <tr>
              <td>Title *</td>
              <td>
                <TextField
                  inputRef={titleRef}
                  placeholder="Enter broadcast title"
                  variant="outlined"
                  required
                  className="w-full max-w-96"
                />
              </td>
              <td />
            </tr>
            <tr>
              <td>Slug *</td>
              <td>
                <TextField
                  inputRef={slugRef}
                  label="Slug"
                  placeholder="Enter broadcast slug (URL fragment that should be used to identify stream)"
                  variant="outlined"
                  required
                  className="w-full max-w-96"
                />
              </td>
              <td />
            </tr>
            <tr>
              <td>Description</td>
              <td>
                <TextField
                  inputRef={descriptionRef}
                  label="Description"
                  placeholder="Enter broadcast description"
                  variant="outlined"
                  className="w-full max-w-96"
                />
              </td>
              <td />
            </tr>
            <tr>
              <td>Resolution</td>
              <td>
                <FormControl fullWidth>
                  <Select
                    defaultValue="1080p"
                    inputRef={resolutionRef}
                    required
                    className="w-full max-w-96"
                    onChange={(e: SelectChangeEvent<string>) => {
                      if (e.target.value === "2160p") {
                        setVideoInputTier("UHD");
                      } else {
                        setVideoInputTier("HD");
                      }
                    }}
                  >
                    <MenuItem value={"1080p"}>1080p</MenuItem>
                    <MenuItem value={"1440p"}>1440p (Upscaling)</MenuItem>
                    <MenuItem value={"2160p"}>2160p (4K)</MenuItem>
                  </Select>
                  <FormHelperText>Select higher resolution only if it&apos;s going to be used</FormHelperText>
                </FormControl>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Input Tier</td>
              <td>
                <Select
                  required
                  className="w-full max-w-96"
                  value={videoInputTier}
                  onChange={(e) => setVideoInputTier(e.target.value as "HD" | "UHD")}
                >
                  <MenuItem value={"HD"}>HD</MenuItem>
                  <MenuItem value={"UHD"}>UHD</MenuItem>
                </Select>
              </td>
              <td />
            </tr>
            <tr>
              <td>Provider</td>
              <td>
                <FormControl fullWidth>
                  <Select defaultValue="gcp" inputRef={providerRef} required className="w-full max-w-96">
                    <MenuItem value={"gcp"}>GCP</MenuItem>
                    <MenuItem value={"aws"}>AWS</MenuItem>
                  </Select>
                  <FormHelperText>
                    Select the stream cloud provider <b>(AWS does not support UHD 60fps streams)</b>
                  </FormHelperText>
                </FormControl>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Games *</td>
              <td>
                <FormControl fullWidth>
                  <InputLabel>Games</InputLabel>
                  <Select
                    multiple
                    className="w-full max-w-96"
                    value={gameIds}
                    onChange={handleGamesChange}
                    input={<OutlinedInput label="Games" />}
                    renderValue={(selected) => {
                      return (
                        <div className="flex flex-row items-center gap-2">
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </div>
                      );
                    }}
                  >
                    {BroadcastGameIdsSchema.options.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={gameIds.includes(name)} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Select the games that you want this broadcast to be visible on.</FormHelperText>
                </FormControl>
              </td>
            </tr>
          </tbody>
        </table>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={create}
          disabled={isLoading}
          className="mt-16 max-w-48"
        >
          Create
        </Button>
      </div>
    </>
  );
};

export default BroadcastCreateForm;
