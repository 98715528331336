import { Button } from "@mui/material";
import { Fire, FireButtonContainer, Particle } from "./styled";
import { useMemo, useState } from "react";

type AcceptedColors = "primary" | "error" | "success" | "warning" | "info";

export default function FireButton({
  color,
  onClick,
  label,
}: {
  color: AcceptedColors;
  onClick: () => void;
  label: string;
}) {
  const numberOfParticles = 50;
  const [mouseOver, setMouseOver] = useState(false);

  const showFire = useMemo(() => {
    return mouseOver && color === "error";
  }, [mouseOver, color]);

  return (
    <FireButtonContainer onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
      {showFire && (
        <Fire>
          {Array(numberOfParticles)
            .fill(0)
            .map((_, index) => (
              <Particle key={index} randomNumber={Math.random() + index}></Particle>
            ))}
        </Fire>
      )}
      <Button variant="outlined" sx={{ marginLeft: 2, height: "1.5rem" }} color={color} onClick={() => onClick()}>
        {label}
      </Button>
    </FireButtonContainer>
  );
}
