import { useMutation, useQueryClient } from "@tanstack/react-query";
import { sendNotification } from "../../../api/notifications";

const useSendNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (...rules: Parameters<typeof sendNotification>) => sendNotification(...rules),
    onSuccess() {
      void queryClient.invalidateQueries(["scheduledNotifications", "deliveredNotifications"]);
    },
  });
};

export default useSendNotification;
