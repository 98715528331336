import { useQuery } from "@tanstack/react-query";
import { getTournamentTeams } from "..";
import { queryKeys } from "../shared/queryKeys";

export const useGetTournamentTeams = (tournamentId: string) => {
  return useQuery({
    queryKey: queryKeys.tournamentTeams(tournamentId),
    queryFn: () => getTournamentTeams(tournamentId),
  });
};
