import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { InfoIcon, PlayCircle, StopCircle } from "lucide-react";
import { DateTime } from "luxon";
import { memo, useCallback, useMemo, useState } from "react";

import { Broadcast } from "../../../types/Broadcasts";

import useFetchBroadcasts from "../../../hooks/useFetchBroadcasts";
import { usePatron } from "../../../providers/PatronProvider/hooks/usePatron";
import useFetchTriggerPolls from "./hooks/useFetchTriggerPolls";
import useStartTriggerPoll from "./hooks/useStartTriggerPoll";

import secondsToMessage from "../../../helpers/secondsToMessage";

import ConfirmModal from "@/components/ConfirmModal";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import PageContainer from "../../../components/Layout/PageContainer";
import PollCreateModal from "./components/PollCreateModal";
import PollDetails from "./components/PollDetails";
import { PatronSelect } from "@/components/PatronSelect";
import { useStopTriggerPoll } from "./hooks/useStopTriggerPoll";
import { isTriggerPollActive } from "./helpers/hasTimePassed";
import { Checkbox } from "@/components/ui/checkbox";
import { PollScheduleModal } from "./components/PollScheduleModal";
import { Label } from "@/components/ui/label";

const ROWS_PER_PAGE = 10;

const TriggerPolls = () => {
  const [selectedPolls, setSelectedPolls] = useState<string[]>([]);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const { patron } = usePatron();
  const { data: broadcasts } = useFetchBroadcasts({ patron });
  const {
    data: polls,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useFetchTriggerPolls({
    patron,
    limit: ROWS_PER_PAGE,
  });
  const { mutate: mutateStartPoll, isLoading: isPollStarting } = useStartTriggerPoll();

  const handleStartPoll = () => {
    if (!selectedToStartPollId) {
      return;
    }

    return mutateStartPoll(selectedToStartPollId, {
      onSuccess: () => {
        setSelectedToStartPollId(null);
        void refetch();
      },
    });
  };

  const { mutate: mutateStopPoll, isLoading: isPollStopping } = useStopTriggerPoll();

  const handleStopPoll = () => {
    if (!selectedToStopPollId) {
      return;
    }

    return mutateStopPoll(selectedToStopPollId, {
      onSuccess: () => {
        setSelectedToStopPollId(null);
        void refetch();
      },
    });
  };

  const [page, setPage] = useState(0);
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
  const [selectedPollId, setSelectedPollId] = useState<null | string>(null);
  const [selectedToStartPollId, setSelectedToStartPollId] = useState<null | string>(null);
  const [selectedToStopPollId, setSelectedToStopPollId] = useState<null | string>(null);

  const closeCreateModal = useCallback(() => {
    setIsCreateModalOpened(false);
  }, []);

  const closePollDetails = useCallback(() => {
    setSelectedPollId(null);
  }, []);

  const broadcastsMap = useMemo<Map<string, Broadcast>>(() => {
    if (!broadcasts?.length) {
      return new Map();
    }

    return new Map(broadcasts.map((broadcast) => [broadcast.id, broadcast]));
  }, [broadcasts]);

  return (
    <PageContainer>
      <div className="flex w-full items-center gap-4">
        <div className="mr-auto flex items-center gap-4">
          <h2 className="text-heading">Polls</h2>
          <PatronSelect />
        </div>
        <Button variant="secondary" disabled={!selectedPolls.length} onClick={() => setIsScheduleModalOpen(true)}>
          <PlayCircle className="size-4" />
          Start {!selectedPolls.length ? "Multiple" : selectedPolls.length} Polls
        </Button>
        <Button onClick={() => setIsCreateModalOpened(true)}>Create Poll</Button>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead />
              <TableHead>Title</TableHead>
              <TableHead>Broadcast</TableHead>
              <TableHead>Question</TableHead>
              <TableHead>Duration</TableHead>
              <TableHead>Started at</TableHead>
              <TableHead>Action</TableHead>
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {polls?.pages[page]?.polls.map((poll) => (
              <TableRow key={poll.id}>
                <TableCell>
                  {!poll.startedAt && (
                    <Label className="cursor-pointer p-2">
                      <Checkbox
                        checked={selectedPolls.includes(poll.id)}
                        onCheckedChange={(checked) => {
                          setSelectedPolls((prev) => {
                            if (checked) {
                              return [...prev, poll.id];
                            }

                            return prev.filter((id) => id !== poll.id);
                          });
                        }}
                      />
                    </Label>
                  )}
                </TableCell>
                <TableCell>{poll.title}</TableCell>
                <TableCell>{poll.broadcastId && broadcastsMap.get(poll.broadcastId)?.title}</TableCell>
                <TableCell>{poll.question}</TableCell>
                <TableCell>{secondsToMessage(poll.durationSeconds)}</TableCell>
                <TableCell>
                  {!!poll.startedAt && DateTime.fromISO(poll.startedAt).toFormat("dd-MM-yyyy HH:mm")}
                </TableCell>
                <TableCell>
                  {!poll.startedAt && (
                    <Button variant="secondary" onClick={() => setSelectedToStartPollId(poll.id)}>
                      <PlayCircle className="size-4" /> Start
                    </Button>
                  )}
                  {poll.startedAt &&
                    isTriggerPollActive({ startedAt: poll.startedAt, duration: poll.durationSeconds }) && (
                      <Button variant="destructive" onClick={() => setSelectedToStopPollId(poll.id)}>
                        <StopCircle className="size-4" /> Stop
                      </Button>
                    )}
                </TableCell>
                <TableCell>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant="ghost" size="icon" onClick={() => setSelectedPollId(poll.id)}>
                        <InfoIcon className="size-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>Poll details</TooltipContent>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="flex w-full justify-center py-3">
          <div className="w-fit">
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious isDisabled={page <= 0} onClick={() => setPage(Math.max(0, page - 1))} />
                </PaginationItem>

                {polls?.pages.map((_, index) => (
                  <PaginationItem key={index}>
                    <PaginationLink onClick={() => setPage(index)} isActive={page === index}>
                      {index + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem>
                  <PaginationNext
                    isDisabled={!hasNextPage && page >= (polls?.pages.length ?? 0) - 1}
                    onClick={() => {
                      if (!hasNextPage && page >= (polls?.pages.length ?? 0) - 1) {
                        return;
                      }

                      if (hasNextPage && polls?.pages && page >= polls.pages.length - 1) {
                        void fetchNextPage();
                      }
                      setPage(page + 1);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        </div>
      </div>
      {isCreateModalOpened && (
        <PollCreateModal
          onClose={closeCreateModal}
          refetch={refetch}
          open={isCreateModalOpened}
          broadcasts={broadcasts ?? []}
        />
      )}
      {selectedPollId && (
        <PollDetails
          onClose={closePollDetails}
          refetch={refetch}
          pollId={selectedPollId}
          broadcastsMap={broadcastsMap}
        />
      )}
      <ConfirmModal
        open={!!selectedToStartPollId}
        onCancel={() => setSelectedToStartPollId(null)}
        onOpenChange={() => setSelectedToStartPollId(null)}
        disabled={isPollStarting}
        onSubmit={handleStartPoll}
        submitText="Start"
      >
        <h3 className="mb-2 text-xl">Start the poll?</h3>
        <p className="text-sm">This will stop the currently running polls.</p>
      </ConfirmModal>
      <ConfirmModal
        open={!!selectedToStopPollId}
        onCancel={() => setSelectedToStopPollId(null)}
        onOpenChange={() => setSelectedToStopPollId(null)}
        disabled={isPollStopping}
        onSubmit={handleStopPoll}
        submitVariant="destructive"
        submitText="Stop"
      >
        <h3 className="mb-2 text-xl">Stop the poll?</h3>
        <p className="text-sm">The duration will be updated to up to now.</p>
      </ConfirmModal>
      {isScheduleModalOpen && (
        <PollScheduleModal
          pollIds={selectedPolls}
          open={isScheduleModalOpen}
          onClose={(args) => {
            if (args?.isEnded) {
              // Refetch and reset selected polls
              void refetch();
              setSelectedPolls([]);
            }
            setIsScheduleModalOpen(false);
          }}
        />
      )}
    </PageContainer>
  );
};

export default memo(TriggerPolls);
