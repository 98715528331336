import { Dialog, Link } from "@mui/material";

export const HowToAddTemplateModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <div className="p-8">
        <h1 className="mb-8 font-style-desktop-h3">How to add a new template</h1>
        <p className="font-style-b1-body-copy">
          Templates data is stored in the{" "}
          <Link
            href="https://s3.console.aws.amazon.com/s3/buckets/emulation-data-dev?region=eu-central-1&bucketType=general&tab=objects"
            target="_blank"
          >
            emulation-data-dev
          </Link>{" "}
          S3 bucket. In order to add a new template:
        </p>
        <ul className="my-4 flex list-disc flex-col gap-6 pl-4">
          <li>
            <div>
              <p className="font-style-b1-body-copy">
                Download the video file. If this is a previous match, ran on prod, you can download the rewatch video
                using:
                <br />
              </p>
              <code className="mt-2 block w-fit bg-black/30 p-1 text-sm">
                {"ffmpeg -i {fullHdManifestUrl} -c copy -bsf:a aac_adtstoasc video.mp4"}
              </code>
            </div>
          </li>
          <li>
            <p className="font-style-b1-body-copy">
              Replace
              <code className="mx-2 w-fit bg-black/30 p-1 text-sm">{"{fullHdManifestUrl}"}</code>
              with the full HD manifest URL. You can check the main manifest file when opening the rewatch video in the
              browser.
              <br />
              For example, if main manifest url is:
              <code className="my-1 block w-fit bg-black/30 p-1 text-sm">
                https://rewatch-a.blast.tv/match-1/manifest.m3u8
              </code>
              And in the main manifest we have:
              <code className="my-1 block w-fit bg-black/30 p-1 text-sm">
                {`#EXT-X-STREAM-INF:BANDWIDTH=14757761,AVERAGE-BANDWIDTH=7842191,RESOLUTION=1920x1080,FRAME-RATE=59.940,CODECS="avc1.64002A,mp4a.40.2"
                manifest_5.m3u8`}
              </code>
              Then the full HD manifest URL is:
              <code className="my-1 block w-fit bg-black/30 p-1 text-sm">
                https://rewatch-a.blast.tv/match-1/manifest_5.m3u8
              </code>
            </p>
          </li>
          <li>
            <p className="font-style-b1-body-copy">
              <code className="mr-2 w-fit bg-black/30 p-1 text-sm">POST</code>
              the template data using the api endpoint using the following template body:
              <code className="mx-2 w-fit bg-black/30 p-1 text-sm">
                https://api.blast-dev.tv/v1/emulation/templates
              </code>
              <pre className="my-2 bg-black/30 p-2 text-sm">
                {`{
    "id": "2024-spring-showdown-gamerlegion-vs-big",
    "logOffsetSeconds": 0, // Can be adjusted after the first time you run the emulation, to sync video with stats.
    "gamestateOffsetSeconds": 0, // If the game state is available, this is the offset to sync the game state with the video.
    "matchType": "BO3",
    "teams": [
        "gamerlegion",
        "big"
    ],
    "videoPath": "2024-spring-showdown-gamerlegion-vs-big/video.mp4",
    "maps": [
        {
            "name": "de_vertigo", // Get from log file or the match fetched from tournaments api
            "logPath": "2024-spring-showdown-gamerlegion-vs-big/map-1.txt",
            "orderIndex": 0
            // If the map has game state, add "gamestatePath": "2024-spring-showdown-gamerlegion-vs-big/map-1-gamestate.json"
        },
        {
            "name": "de_overpass", // Get from log file or the match fetched from tournaments api
            "logPath": "2024-spring-showdown-gamerlegion-vs-big/map-2.txt",
            "orderIndex": 1
            // If the map has game state, add "gamestatePath": "2024-spring-showdown-gamerlegion-vs-big/map-2-gamestate.json"
        },
        {
            "name": "de_ancient", // Get from log file or the match fetched from tournaments api
            "logPath": "2024-spring-showdown-gamerlegion-vs-big/map-3.txt",
            "orderIndex": 2
            // If the map has game state, add "gamestatePath": "2024-spring-showdown-gamerlegion-vs-big/map-3-gamestate.json"
        }
    ]
}`}
              </pre>
            </p>
          </li>
          <li>
            <p className="font-style-b1-body-copy">
              Download the match log files using{" "}
              <Link href="https://github.com/blastorg/statistics-tooling/blob/main/src/downloadMatchLogFile/index.ts">
                this statistics-tooling script
              </Link>
            </p>
          </li>
          <li>
            <p className="font-style-b1-body-copy">
              Upload the map log files and the video file under the same route, mentioned in the template body, in the
              S3 bucket.
            </p>
          </li>
          <li>
            <p className="font-style-b1-body-copy">
              Upload the game state files (if available) in the mentioned routes in the template. You can use the{" "}
              <code className="w-fit bg-black/30 p-1 text-sm">blast-tv-first-cs2-test</code> for an example template
              with game state.
            </p>
          </li>
        </ul>
      </div>
    </Dialog>
  );
};
