import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { DotlePlayer } from "../../../api/data/dotle/getDotlePlayers/schemas";
import { useMemo, useState, useRef } from "react";
import { CheckCircle, FindInPage, OpenInNew } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useGetDotleAnswerPlayer } from "../../../api/data/dotle/getDotleAnswerPlayer/useGetDotleAnswerPlayer";
import { LoadingButton } from "@mui/lab";

export const DotlePlayers = ({
  players,
  isLoading,
  isError,
}: {
  players: DotlePlayer[];
  isLoading?: boolean;
  isError?: boolean;
}) => {
  const { data: answerPlayer, isLoading: isAnswerPlayerLoading } = useGetDotleAnswerPlayer();
  const [searchQuery, setSearchQuery] = useState("");
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);

  const scrollToAnswerPlayer = () => {
    if (answerPlayer) {
      const index = filteredPlayers.findIndex((player) => player.id === answerPlayer.id);
      if (index !== -1 && rowRefs.current[index]) {
        rowRefs.current[index]?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const sortedPlayers = useMemo(() => players.sort((a, b) => a.nickname.localeCompare(b.nickname)), [players]);
  const filteredPlayers = useMemo(() => {
    return sortedPlayers.filter(
      (player) =>
        player.nickname.match(new RegExp(searchQuery, "i")) ||
        `${player.firstName} ${player.lastName}`.match(new RegExp(searchQuery, "i")) ||
        player.id.toString().match(new RegExp(searchQuery, "i")),
    );
  }, [sortedPlayers, searchQuery]);

  return (
    <div className="flex flex-col gap-4 pt-8">
      <div className="text-sm">Active players currently available to be selected as Dotle answers.</div>
      <div className="flex items-center gap-2">
        <TextField
          label="Filter by nickname, name or ID"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-96"
          size="small"
        />
        <LoadingButton
          variant="text"
          onClick={scrollToAnswerPlayer}
          loading={isAnswerPlayerLoading}
          loadingPosition="start"
          startIcon={<FindInPage />}
        >
          {"Find today's answer"}
        </LoadingButton>
      </div>
      <Table size="small" className="w-fit max-w-full" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Nickname</TableCell>
            <TableCell>Full Name</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>{"Today's Answer"}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {isError && !isLoading && (
            <TableRow>
              <TableCell colSpan={5}>Something went wrong when fetching the players</TableCell>
            </TableRow>
          )}
          {!isError && !isLoading && players?.length === 0 && (
            <TableRow>
              <TableCell colSpan={5}>No players available</TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow key="loading">
              {Array.from({ length: 5 }, (_, i) => (
                <TableCell key={i}>
                  <div
                    className="my-2 min-h-6 w-36 animate-pulse rounded-full bg-white/20"
                    style={{ animationDelay: `${i * 50}ms` }}
                  />
                </TableCell>
              ))}
            </TableRow>
          )}
          {filteredPlayers?.map((player, i) => (
            <TableRow key={i} ref={(el) => (rowRefs.current[i] = el)}>
              <TableCell>{player.nickname}</TableCell>
              <TableCell>
                {player.firstName} {player.lastName}
              </TableCell>
              <TableCell>{player.id}</TableCell>
              <TableCell className="text-center">
                {answerPlayer && player.id === answerPlayer.id && <CheckCircle color="success" />}
              </TableCell>
              <TableCell>
                <Link to={`/to/players/dotle?search=${player.nickname}`} target="_blank">
                  <Button size="small" variant="text">
                    <OpenInNew />
                  </Button>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
