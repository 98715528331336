import useFetchMatchVetoes from "../../../../../hooks/matches/useFetchMatchVetoes";
import mapPool from "../../../../../shared/components/mapPool";
import { EditMatch, EditTeamMatch } from "../../../../../../../api/tournaments/schemas/matches";
import { useEffect, useMemo, useState } from "react";
import { Veto } from "../../../../../../../api/tournaments/schemas/vetoes";
import useUpdateMatchVetoes from "../../../../../hooks/matches/useUpdateMatchVetoes";
import useAlert from "../../../../../../../providers/AlertProvider/hooks/useAlert";

import useCreateMatchVetoes from "../../../../../hooks/matches/useCreateMatchVetoes";
import usePostMap from "../../../../../hooks/maps/usePostMap";
import { DateTime } from "luxon";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { DialogTitle } from "@/components/Dialog/DialogTitle";

interface VetoesProps {
  match: EditMatch;
  matchHasMaps: boolean;
  teamA?: EditTeamMatch;
  teamB?: EditTeamMatch;
}

export const VetoesDialog = ({ match, matchHasMaps, teamA, teamB }: VetoesProps) => {
  const alert = useAlert();
  const [vetoesWithTeamName, setVetoesWithTeamName] = useState<(Veto & { teamName: string })[]>([]);
  const [isNewVetos, setIsNewVetos] = useState<boolean>(false);
  const [generateMapsOnSave, setGenerateMapsOnSave] = useState<boolean>(false);

  const newVetoes: Veto[] = useMemo(
    () => [
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: "ban",
        index: 0,
        map: "de_ancient",
        teamId: teamA?.team?.id || null,
      },
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: "ban",
        index: 1,
        map: "de_anubis",
        teamId: teamB?.team?.id || null,
      },
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: match.matchType.toLocaleUpperCase() === "BO1" ? "ban" : "pick",
        index: 2,
        map: "de_inferno",
        teamId: teamA?.team?.id || null,
      },
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: match.matchType.toLocaleUpperCase() === "BO1" ? "ban" : "pick",
        index: 3,
        map: "de_mirage",
        teamId: teamB?.team?.id || null,
      },
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: match.matchType.toLocaleUpperCase() === "BO5" ? "pick" : "ban",
        index: 4,
        map: "de_nuke",
        teamId: teamA?.team?.id || null,
      },
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: match.matchType.toLocaleUpperCase() === "BO5" ? "pick" : "ban",
        index: 5,
        map: "de_dust2",
        teamId: teamB?.team?.id || null,
      },
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: "pick",
        index: 6,
        map: "de_vertigo",
        teamId: null,
      },
    ],
    [match.matchId, match.matchType, teamA?.team?.id, teamB?.team?.id],
  );

  const { data: vetoes } = useFetchMatchVetoes(match.matchId);
  const { mutate: updateVeto } = useUpdateMatchVetoes();
  const { mutate: createVeto } = useCreateMatchVetoes();
  const { mutate: createMap } = usePostMap();

  useEffect(() => {
    if (!vetoes) return;

    if (vetoes.length > 0) {
      const tempVetoesWithTeamName = vetoes.map((veto) => {
        if (veto.teamId === teamA?.team?.id) {
          return { ...veto, teamName: teamA?.team?.name };
        } else if (veto.teamId === teamB?.team?.id) {
          return { ...veto, teamName: teamB?.team?.name };
        } else if (veto.teamId === undefined || veto.teamId === null) {
          return { ...veto, teamId: "Decider", teamName: "Decider" };
        } else return { ...veto, teamName: "unknown" };
      });
      setVetoesWithTeamName(tempVetoesWithTeamName);
      setIsNewVetos(false);
    } else {
      const tempVetoesWithTeamName = newVetoes.map((veto) => {
        if (veto.teamId === teamA?.team?.id) {
          return { ...veto, teamName: teamA?.team?.name };
        } else if (veto.teamId === teamB?.team?.id) {
          return { ...veto, teamName: teamB?.team?.name };
        } else if (veto.teamId === undefined || veto.teamId === null) {
          return { ...veto, teamId: "Decider", teamName: "Decider" };
        } else return { ...veto, teamName: "unknown" };
      });
      setVetoesWithTeamName(tempVetoesWithTeamName);
      setIsNewVetos(true);
    }
  }, [vetoes, newVetoes, teamA, teamB]);

  const swapTeamVetoes = () => {
    const vetoes = [...vetoesWithTeamName];

    for (let i = 0; i < vetoes.length - 1; i++) {
      vetoes[i]["teamId"] = vetoes[(i % 5) + 1]["teamId"];
      vetoes[i]["teamName"] = vetoes[(i % 5) + 1]["teamName"];
    }

    setVetoesWithTeamName(vetoes);
  };

  const changeVetoProperty = (vetoId: string, propertyName: string, propertyValue: string) => {
    const vetoes = [...vetoesWithTeamName];
    const needsVetoUpdate = vetoes.find((veto) => veto.id === vetoId);
    if (!needsVetoUpdate) return;
    const vetoIndex = needsVetoUpdate.index;
    if (propertyName === "team") {
      const [teamId, teamName] = propertyValue.split("+");
      vetoes[vetoIndex]["teamId"] = teamId;
      vetoes[vetoIndex]["teamName"] = teamName;
    }

    if (propertyName === "map") {
      vetoes[vetoIndex]["map"] = propertyValue;
    }

    if (propertyName === "type") {
      vetoes[vetoIndex]["type"] = propertyValue === "pick" ? "pick" : "ban";
    }
    setVetoesWithTeamName(vetoes);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" className="w-[150px] self-center">
          Vetos
        </Button>
      </DialogTrigger>
      <DialogContent aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>Map Vetoes</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center">
          <div className="flex items-center gap-4">
            <div className="flex space-x-2">
              <Checkbox
                id="generateMaps"
                checked={generateMapsOnSave}
                disabled={matchHasMaps}
                onClick={() => setGenerateMapsOnSave((prev) => !prev)}
              />
              <label
                htmlFor="generateMaps"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Generate Maps
              </label>
            </div>
            <Button onClick={() => swapTeamVetoes()}>Swap Teams</Button>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[10%]">Index</TableHead>
                <TableHead className="w-[30%]">Team</TableHead>
                <TableHead className="w-[30%]">Map</TableHead>
                <TableHead className="w-[30%]">Type</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="w-full">
              {vetoesWithTeamName.map((veto) => {
                return (
                  <TableRow key={veto.id} className="w-full">
                    <TableCell>{veto.index}</TableCell>
                    <TableCell>
                      <Select
                        value={`${veto.teamId}+${veto.teamName}`}
                        onValueChange={(e) => changeVetoProperty(veto.id, "team", e)}
                      >
                        <SelectTrigger className="w-[150px]">
                          <SelectValue placeholder="Team" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={`${teamA?.team?.id}+${teamA?.team?.name}`}>{teamA?.team?.name}</SelectItem>
                          <SelectItem value={`${teamB?.team?.id}+${teamB?.team?.name}`}>{teamB?.team?.name}</SelectItem>
                          <SelectItem value={`Decider+Decider`}>Decider</SelectItem>
                        </SelectContent>
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Select value={veto.map} onValueChange={(e) => changeVetoProperty(veto.id, "map", e)}>
                        <SelectTrigger className="w-[150px]">
                          <SelectValue placeholder="Map" />
                        </SelectTrigger>
                        <SelectContent>
                          {mapPool["cs"].map((map) => (
                            <SelectItem key={map} value={map}>
                              {map}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Select value={veto.type} onValueChange={(e) => changeVetoProperty(veto.id, "type", e)}>
                        <SelectTrigger className="w-[150px]">
                          <SelectValue placeholder="Type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="pick">pick</SelectItem>
                          <SelectItem value="ban">ban</SelectItem>
                        </SelectContent>
                      </Select>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <DialogFooter>
          <Button
            onClick={() => {
              vetoesWithTeamName.forEach((veto) => {
                if (isNewVetos) {
                  createVeto(veto, {
                    onSuccess: () => {
                      alert.showSuccessAlert("Created vetoes for match");
                    },
                  });
                } else {
                  updateVeto(veto, {
                    onSuccess: () => {
                      alert.showSuccessAlert("Updated vetoes for match");
                    },
                  });
                }
              });
              //create maps from vetos
              if (generateMapsOnSave) {
                vetoesWithTeamName
                  .filter((veto) => veto.type === "pick")
                  .forEach((veto, index) => {
                    const matchTime = DateTime.fromJSDate(match.scheduledAt).plus({
                      hours: index,
                    });
                    createMap({
                      mapName: veto.map,
                      matchId: match.matchId,
                      scheduledAt: matchTime.toJSDate(),
                    });
                  });
              }
            }}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
