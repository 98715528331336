import { blastApiClient } from "../../clients/blastApiClient";
import { z } from "zod";
export const RocketLeagueTeamSchema = z.object({
  id: z.string(),
  teamNames: z.array(z.string()),
  teamDisplayName: z.string(),
  assignedTeamId: z.string().nullish(),
});

export type RocketLeagueStatsTeam = z.infer<typeof RocketLeagueTeamSchema>;

export const getRocketLeagueStatTeams = async (): Promise<RocketLeagueStatsTeam[]> => {
  const { data } = await blastApiClient.get<unknown>("/v1/rlcs-statistics/teams");

  const teams = RocketLeagueTeamSchema.array().parse(data);
  return teams;
};
