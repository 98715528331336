import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter } from "@/components/ui/dialog";
import useFetchTeams from "@/routes/Tournaments/hooks/teams/useFetchTeams";
import { Loader } from "@/shared/components/Loader";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { MatchByTournamentIdResponse } from "../../../../../api/tournaments/schemas/matches";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import useFetchMatchMaps from "../../../hooks/maps/useFetchMatchMaps";
import usePostMap from "../../../hooks/maps/usePostMap";
import { useRegenerateMapsData } from "../../../hooks/maps/useRegenerateMapsData";
import useCreateMatch from "../../../hooks/matches/useCreateMatch";
import useUpdateMatch from "../../../hooks/matches/useUpdateMatch";
import mapPool from "../../../shared/components/mapPool";
import { DataUrlAccordion } from "./components/DataUrlsAccordion";
import { DeleteConfirmModal } from "./components/DeleteConfirmModal";
import { GeneralInfo } from "./components/GeneralInfo";
import { Maps } from "./components/Maps";
import { TeamInfo } from "./components/TeamInfo";
import { VetoesDialog } from "./components/Vetoes";

interface MatchModalProps {
  match: MatchByTournamentIdResponse;
  handleClose: () => void;
  updateMatch: (updatedMatch: MatchByTournamentIdResponse) => void;
  gameId?: string;
}

export const MatchModal = ({ match, handleClose, gameId = "cs" }: MatchModalProps) => {
  const [editMatch, setEditMatch] = useState<MatchByTournamentIdResponse>(match);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const { data: teams } = useFetchTeams(gameId);

  const allowMapSave: "yes" | "saveMatch" | "no" = useMemo(() => {
    if (
      editMatch.teams.length != 2 ||
      editMatch.matchId === "NEWMATCH" ||
      editMatch.teams[0].team === undefined ||
      editMatch.teams[1].team === undefined
    ) {
      return "no";
    } else if (
      editMatch.teams.length == 2 &&
      editMatch.teams?.[0]?.team?.id === match.teams?.[0]?.team?.id &&
      editMatch.teams?.[1]?.team?.id === match.teams?.[1]?.team?.id
    ) {
      return "yes";
    } else {
      return "saveMatch";
    }
  }, [editMatch.matchId, editMatch.teams, match.teams]);

  const alert = useAlert();
  const { tournamentId } = useParams();

  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
  useEffect(() => {
    setReadyToSubmit(
      editMatch != undefined &&
        editMatch.matchIndex >= 0 &&
        editMatch.matchName !== "" &&
        editMatch.matchName !== undefined &&
        editMatch.scheduledAt !== undefined &&
        editMatch.teams
          .map((team) => team?.score)
          .every((score) => score !== null && score !== undefined && !isNaN(score) && score >= 0),
    );
  }, [editMatch]);

  const teamA = editMatch.teams.find((team) => team?.teamIndex === 10);
  const teamB = editMatch.teams.find((team) => team?.teamIndex === 20);

  const { mutate: submitUpdate } = useUpdateMatch({
    tournamentId: tournamentId,
    onSuccess: () => {
      alert.showSuccessAlert("Successfully updated match");
    },
    onError: () => {
      handleClose();
      alert.showFailureAlert("Error while updating match data");
    },
  });

  const { mutate: submitCreate } = useCreateMatch({
    tournamentId: tournamentId,
    onSuccess: () => {
      alert.showSuccessAlert("Successfully Created match");
    },
    onError: () => {
      handleClose();
      alert.showFailureAlert("Error while creating match data");
    },
  });

  const { mutate: postMap } = usePostMap();
  const { mutate: regenerateMapsData, isLoading: isRegenerateMapsDataLoading } = useRegenerateMapsData();

  const { data: matchMaps, isLoading: isMatchMapsLoading } = useFetchMatchMaps(editMatch.matchId);

  const mapComponents = useMemo(() => {
    if (matchMaps && matchMaps.length > 0) {
      return matchMaps
        .sort(
          (a, b) =>
            (a.scheduledAt?.getTime() || new Date(a.createdAt).getTime()) -
            (b.scheduledAt?.getTime() || new Date(b.createdAt).getTime()),
        )
        .map((matchMap, i) => (
          <Maps
            key={matchMap.id}
            matchMap={matchMap}
            mapNumber={i + 1}
            teamA={teamA}
            teamB={teamB}
            allowedToSave={allowMapSave}
            gameId={gameId}
          />
        ));
    } else {
      return <div className="text-sm">No Maps found</div>;
    }
  }, [allowMapSave, matchMaps, teamA, teamB, gameId]);

  return (
    <>
      <DeleteConfirmModal
        isOpen={deleteConfirm}
        close={() => setDeleteConfirm(false)}
        matchId={editMatch.matchId}
        tournamentId={tournamentId}
        closeMatchModal={handleClose}
      />
      <Dialog
        open={editMatch !== undefined}
        onOpenChange={(open) => {
          if (!open) handleClose();
        }}
        modal
      >
        <DialogContent className="max-h-[90vh] w-full max-w-[1000px] overflow-y-auto p-6">
          <div className="flex flex-col gap-10">
            <div className="flex justify-center gap-20">
              <TeamInfo team={teamA} teamLabel="Team A" teams={teams} updateMatch={setEditMatch} match={editMatch} />
              <div className="flex w-[340px] flex-col">
                <GeneralInfo match={editMatch} gameId={gameId} updateMatch={setEditMatch} />
              </div>
              <TeamInfo team={teamB} teamLabel="Team B" teams={teams} updateMatch={setEditMatch} match={editMatch} />
            </div>

            {allowMapSave == "yes" && gameId === "cs" && (
              <VetoesDialog
                match={match}
                matchHasMaps={(matchMaps && matchMaps.length > 0) || false}
                teamA={teamA}
                teamB={teamB}
              />
            )}

            <DataUrlAccordion matchId={editMatch.matchId} matchType={editMatch.matchType} />

            <div className="flex flex-col">
              <div className="mb-5 flex items-center justify-between">
                <div className="flex gap-2">
                  <h6 className="text-lg font-semibold">Maps</h6>
                  {gameId === "dota" && (
                    <Button
                      disabled={!matchMaps?.length}
                      onClick={() => regenerateMapsData(editMatch.matchId)}
                      variant="outline"
                    >
                      {isRegenerateMapsDataLoading && <Loader className="mr-2 size-4" />}
                      regenerate maps data
                    </Button>
                  )}
                </div>

                <div className="relative flex flex-col items-end justify-end">
                  <Button
                    disabled={allowMapSave !== "yes"}
                    onClick={() => {
                      const teamIds: string[] = [];
                      if (teamA?.team) teamIds.push(teamA.team.id);
                      if (teamB?.team) teamIds.push(teamB.team.id);
                      if (teamIds.length > 0) {
                        postMap({
                          mapName: mapPool[gameId][0],
                          matchId: editMatch.matchId,
                        });
                      }
                    }}
                  >
                    Create Map
                  </Button>
                  {allowMapSave === "saveMatch" && (
                    <p className="absolute -bottom-4 text-xs text-orange-400">Teams Changed, Please Save Match</p>
                  )}
                  {editMatch.matchId === "NEWMATCH" && (
                    <p className="absolute -bottom-4 text-xs text-orange-400">Please Save Match</p>
                  )}
                </div>
              </div>
              {isMatchMapsLoading && <Loader className="mx-auto w-40" />}
              {mapComponents}
            </div>
          </div>

          <DialogFooter>
            {editMatch.matchId !== "NEWMATCH" && (
              <Button variant="destructive" onClick={() => setDeleteConfirm(true)}>
                Delete match
              </Button>
            )}
            <div className="grow" />
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (editMatch.matchId === "NEWMATCH") {
                  submitCreate({ gameId, match: editMatch });
                } else {
                  submitUpdate({ gameId, match: editMatch, originalMatch: match });
                }
                handleClose();
              }}
              disabled={!readyToSubmit}
            >
              Save Match
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
