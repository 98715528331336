import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { EditCommunityMatch } from "../../../../../../../api/community-tournaments/schemas/matches";
import { MatchTypeSchema } from "../../../../../../../api/emulations/schemas/MatchTypeSchema";

export const GeneralInfo = ({
  match,
  updateMatch,
}: {
  match: EditCommunityMatch | undefined;
  updateMatch: (match: EditCommunityMatch) => void;
}) => {
  return (
    <div className="flex flex-col gap-4">
      <span>GENERAL INFO</span>
      <TextField type="text" label="Match Id" defaultValue={match?.id} disabled={true} />
      <TextField
        type="number"
        label="Match Index"
        defaultValue={match?.index}
        InputProps={{
          inputProps: { min: 0 },
        }}
        onChange={(e) => {
          const matchIndex = parseInt(e.target.value);
          if (match) updateMatch({ ...match, index: matchIndex });
        }}
        error={match?.index === undefined || isNaN(match?.index) || match?.index < 0}
      />
      <TextField
        label="Match Name"
        defaultValue={match?.name}
        onChange={(e) => {
          const matchName = e.target.value;
          if (match) updateMatch({ ...match, name: matchName });
        }}
        error={match?.name === undefined || match?.name === ""}
      />
      <FormControl>
        <InputLabel id="match-types-select"> Type of Match</InputLabel>
        <Select
          labelId="match-types-select"
          value={match?.type || "BO3"}
          label="Type of Match"
          onChange={(e) => {
            const matchType = e.target.value;
            const parsedType = MatchTypeSchema.parse(matchType);
            if (match) updateMatch({ ...match, type: parsedType });
          }}
        >
          <MenuItem value="BO1">BO1</MenuItem>
          <MenuItem value="BO2">BO2</MenuItem>
          <MenuItem value="BO3">BO3</MenuItem>
          <MenuItem value="BO5">BO5</MenuItem>
          <MenuItem value="BO7">BO7</MenuItem>
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DateTimePicker
          label="Scheduled Start"
          defaultValue={
            match?.scheduledAt ? DateTime.fromJSDate(new Date(match?.scheduledAt)) : DateTime.fromJSDate(new Date())
          }
          format="dd/LL/yyyy HH:mm"
          ampm={false}
          onChange={(date) => {
            const scheduledAt = date?.toJSDate();
            if (match && scheduledAt) updateMatch({ ...match, scheduledAt: scheduledAt.toISOString() });
          }}
        />
      </LocalizationProvider>
    </div>
  );
};
