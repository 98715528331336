import axios from "axios";
import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";
import { Channel, ChannelSchema, ChannelSource } from "./schemas/ChannelSchema";
import { Event, EventSchema, UpdateEventParameters } from "./schemas/EventSchema";

export const queryKeys = {
  all: ["metricsViewership"] as const,
  channelList: () => [...queryKeys.all, "channelList"] as const,
  eventsList: () => [...queryKeys.all, "eventList"] as const,
  event: (id?: string) => [...queryKeys.all, "event", id] as const,
  twitchGames: () => [...queryKeys.all, "twitchGames"] as const,
};

const metricsViewershipApi = axios.create({
  baseURL: `${baseApiURL}/v1/metrics/viewership`,
});
metricsViewershipApi.interceptors.request.use(authInterceptor);

export async function getChannelList() {
  const { data } = await metricsViewershipApi.get<unknown>("/channels");
  return ChannelSchema.array().parse(data);
}

export async function createChannel(channel: Omit<Channel, "id" | "disabled">) {
  const { data } = await metricsViewershipApi.post<unknown>("/channels", channel);
  return ChannelSchema.parse(data);
}

export async function deleteChannel(id: Channel["id"]) {
  const { data } = await metricsViewershipApi.delete<unknown>(
    `/channels/${
      // may contain special characters, so we need to encode it
      encodeURIComponent(id)
    }`,
  );
  return ChannelSchema.parse(data);
}

export async function getEventsList() {
  const { data } = await metricsViewershipApi.get<unknown>("/events");
  return EventSchema.array()
    .parse(data)
    .sort((a, b) => b.startDate.localeCompare(a.startDate));
}

export const getEventById = async (id: Event["id"]) => {
  const { data } = await metricsViewershipApi.get<unknown>(`/events/${id}`);
  return EventSchema.parse(data);
};

export async function createEvent(
  event: Omit<Event, "id" | "airtimeMinutesByDate" | "totalAirtimeMinutes" | "airtimeIntervalsByDate">,
) {
  const { data } = await metricsViewershipApi.post<unknown>("/events", event);
  return EventSchema.parse(data);
}

export async function updateEvent(id: string, parameters: UpdateEventParameters) {
  const { data } = await metricsViewershipApi.patch<unknown>(`/events/${id}`, parameters);
  return EventSchema.parse(data);
}

export const addChannelToEvent = async (
  id: string,
  channel: { id: string; channelSource: ChannelSource; channelName: string },
) => {
  const { data } = await metricsViewershipApi.put<unknown>(`/events/${id}/channel`, channel);
  return EventSchema.parse(data);
};

export const removeChannelFromEvent = async (id: string, channelId: string) => {
  const { data } = await metricsViewershipApi.delete<unknown>(`/events/${id}/channel/${channelId}`);
  return EventSchema.parse(data);
};

export const toggleChannelExcludedStatus = async (id: string, channelId: string) => {
  const { data } = await metricsViewershipApi.post<unknown>(`/events/${id}/channel/${channelId}/exclude`);
  return EventSchema.parse(data);
};

export const toggleChannelPrimaryStatus = async (id: string, channelId: string) => {
  const { data } = await metricsViewershipApi.post<unknown>(`/events/${id}/channel/${channelId}/primary`);
  return EventSchema.parse(data);
};

export async function deleteEvent(id: Event["id"]) {
  const { data } = await metricsViewershipApi.delete<unknown>(`/events/${id}`);
  return EventSchema.parse(data);
}

export async function getEventReport(eventId: Event["id"]) {
  const { data } = await metricsViewershipApi.get<Blob>(`/events/${eventId}/report`, {
    responseType: "blob",
    headers: {
      Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  });

  return data;
}

export async function postReportGeneration(eventId: Event["id"]) {
  const { data, status } = await metricsViewershipApi.post<unknown>(`/events/report/generate`, {
    id: eventId,
  });
  return { data, status };
}
