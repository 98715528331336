import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import useFetchStreams from "../../../../Streams/hooks/useFetchStreams";
import { useParams } from "react-router-dom";
import { addAdditionalVideo, updateAdditionalVideo } from "@/api/broadcasts";
import useFetchBroadcastById from "../../../../../hooks/useFetchBroadcastById";
import { AddVideoModal, AddVideoModalResult } from "./AddVideoModal";
import { useMemo, useState } from "react";
import { AdditionalVideoRow } from "./AdditionalVideoRow";
import { usePatron } from "@/providers/PatronProvider/hooks/usePatron";

export function AdditionalVideos() {
  const { id: broadcastId } = useParams();
  const { patron } = usePatron();
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const broadcastQuery = useFetchBroadcastById({
    patron,
    id: broadcastId as string,
  });

  const streamsQuery = useFetchStreams();
  const additionalVideos = useMemo(() => {
    return (broadcastQuery.data?.additionalVideos ?? []).map((video) => ({
      ...video,
      isLive: streamsQuery.data?.find((s) => s.id === video.videoId)?.state === "RUNNING",
    }));
  }, [broadcastQuery, streamsQuery]);

  if (!broadcastId || broadcastQuery.isLoading || !broadcastQuery.data) {
    return null;
  }

  if (streamsQuery.isLoading || !streamsQuery.data) {
    return null;
  }

  const reload = () => {
    void broadcastQuery.refetch();
    void streamsQuery.refetch();
  };

  const addVideo = (result: AddVideoModalResult) => {
    return addAdditionalVideo({
      patron,
      broadcastId: broadcastId,
      name: result.name,
      offset: result.offset,
    }).finally(() => reload());
  };

  const updateVideo = (result: AddVideoModalResult) => {
    // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
    if (!selectedVideo) return Promise.reject("No selected video.");

    return updateAdditionalVideo({
      patron,
      broadcastId: broadcastId,
      videoId: selectedVideo,
      payload: {
        name: result.name,
        offset: result.offset,
      },
    }).finally(() => reload());
  };

  return (
    <>
      {(addModalOpen || selectedVideo) && (
        <AddVideoModal
          additionalVideo={additionalVideos.find((video) => video.videoId === selectedVideo)}
          setSelectedVideo={setSelectedVideo}
          onSubmit={(result) => {
            if (selectedVideo) {
              void updateVideo(result).then(() => {
                setSelectedVideo(null);
                setAddModalOpen(false);
              });
            } else {
              void addVideo(result).then(() => setAddModalOpen(false));
            }
          }}
          closeModal={() => setAddModalOpen(false)}
        />
      )}
      <div className="w-full rounded-small border border-solid border-neutral-50 p-4 pt-2">
        <div className="flex">
          <div className="flex-1">
            <span className="mr-2 font-norms text-sm">Additional Video Streams</span>

            <IconButton onClick={() => setAddModalOpen(true)}>
              <AddIcon />
            </IconButton>
          </div>
          <div className="flex-none">
            <IconButton onClick={() => reload()}>
              <RefreshIcon />
            </IconButton>
          </div>
        </div>
        {additionalVideos.length === 0 ? (
          <div className="w-full rounded-small border border-solid border-neutral-50 px-4 py-2">
            <span className="font-norms text">This broadcast has no additional video streams</span>
          </div>
        ) : (
          <div className="space-y-2">
            {additionalVideos.map((video) => {
              const stream = streamsQuery.data.find((x) => x.id === video.videoId);
              if (!stream) return null;
              return (
                <AdditionalVideoRow
                  broadcastId={broadcastId}
                  name={video.name}
                  reload={reload}
                  stream={stream}
                  offset={video.offset}
                  setSelectedVideo={setSelectedVideo}
                  videoId={video.videoId}
                  key={video.videoId}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
