import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useMemo, useState } from "react";
import { useGetEventById } from "@/api/metricsViewership/hooks/useGetEventById";
import { DateTime } from "luxon";
import { CircleHelp } from "lucide-react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";

export const AirtimeModal = ({ eventId }: { eventId: string }) => {
  const [open, setOpen] = useState(false);

  const { data: event } = useGetEventById(eventId);

  const chartData = useMemo(() => {
    const data = [];
    for (const date of Object.keys(event?.airtimeIntervalsByDate ?? {})) {
      const interval = event!.airtimeIntervalsByDate[date];
      const start = DateTime.fromISO(interval.start);
      const end = DateTime.fromISO(interval.end);

      data.push({
        date: DateTime.fromISO(date).toLocaleString(DateTime.DATE_HUGE),
        interval: `${start.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET)} - ${end.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET)}`,
        duration: end.diff(start).shiftTo("hours", "minutes").toHuman({
          unitDisplay: "short",
          maximumFractionDigits: 0,
        }),
      });
    }
    return data;
  }, [event]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" title="More information">
          <CircleHelp />
        </Button>
      </DialogTrigger>

      <DialogContent className="w-full max-w-4xl">
        <DialogHeader>
          <DialogTitle>Airtime</DialogTitle>
          <DialogDescription>Time intervals when the primary channel(s) were live by day.</DialogDescription>
        </DialogHeader>
        {event && (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Date</TableHead>
                <TableHead>Airtime Interval</TableHead>
                <TableHead className="text-right">Duration</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {chartData.map((data) => (
                <TableRow key={data.date}>
                  <TableCell>{data.date}</TableCell>
                  <TableCell>{data.interval}</TableCell>
                  <TableCell className="text-right">{data.duration}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
