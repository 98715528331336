import { createContext } from "react";
import { ModalState } from "./types/modalState";
import { Collection } from "../../../../api/points/types/Collection";

export interface PointsCollectionsContextProps {
  collection: Partial<Collection>;
  handleUpdateCollection: (newValues: Partial<Collection>) => void;

  // Modal
  setModal: (isOpen: true, modalState: ModalState, collection?: Collection) => void;
  modalOpen: boolean;
  modalState: ModalState;
  closeModal: () => void;
}

const StateContext = createContext<PointsCollectionsContextProps | undefined>(undefined);

export default StateContext;
