import { Button, Dialog, DialogActions, DialogTitle, Divider } from "@mui/material";

interface ConfirmDeliverModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  productUrl?: string;
  productTitle?: string;
  username?: string;
  email?: string;
}

export const ConfirmDeliveryActionModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  productUrl,
  productTitle,
  username,
  email,
}: ConfirmDeliverModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="w-full">
      <DialogTitle>{title}</DialogTitle>
      <div className="flex flex-col place-content-center items-center gap-4 pb-8">
        <img src={productUrl} alt="icon" className="mx-auto w-24" />
        <span className="text-sm">{productTitle}</span>
        <div className="flex flex-col gap-0.5 text-center">
          <span className="text-sm">
            To: <span className="text-green-500">{username}</span>
          </span>
          <span className="text-sm">
            mail: <span className="text-green-500">{email}</span>
          </span>
        </div>
      </div>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
