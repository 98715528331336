interface VideoFileAttributes {
  series: string;
  episode?: string;
  isTrailer: boolean;
}

export const formatFileName = ({ series, episode, isTrailer }: VideoFileAttributes): string => {
  const seriesCorrectFormat = series.replace("_", "-").trim();
  const episodeCorrectFormat = episode?.replace("_", "-").trim();

  if (isTrailer) {
    if (episode) {
      return `${seriesCorrectFormat}-${episodeCorrectFormat}-trailer.mp4`;
    } else {
      return `${seriesCorrectFormat}-trailer.mp4`;
    }
  } else {
    return `${seriesCorrectFormat}-${episodeCorrectFormat}.mp4`;
  }
};
