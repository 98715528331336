import { useMutation } from "@tanstack/react-query";
import { createStream, CreateStreamPayload } from "../../../../api/streams";

const useCreateStream = ({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) =>
  useMutation({
    mutationKey: ["streams"],
    onSuccess,
    onError,
    mutationFn: (stream: CreateStreamPayload) => createStream(stream),
  });

export default useCreateStream;
