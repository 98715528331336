import { Breadcrumbs, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import useFetchPickemsUids from "./hooks/useFetchPickemsUids";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../components/Layout/PageContainer";

export interface PostCorrectAnswerCallbackArgs {
  questionId: string;
  choiceUid: string;
}

export default function PickemsPage() {
  const { data: pickemsUids } = useFetchPickemsUids();
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" fontWeight="bold">
          Pickems
        </Typography>
      </Breadcrumbs>
      <h4>Select Pickems UID:</h4>
      <Select
        labelId="pickems-select-label"
        id="pickems-select"
        onChange={(event: SelectChangeEvent) => navigate(`/pickems/${event.target.value}`)}
        sx={{ width: 360 }}
      >
        {pickemsUids?.map((pickems, index) => (
          <MenuItem value={pickems.uid} key={index}>
            {pickems.uid}
          </MenuItem>
        ))}
      </Select>
    </PageContainer>
  );
}
