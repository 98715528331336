import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postRlcsStatTeam } from "..";
import { rlcsStatQueryKeys } from "../../rlcsStatQueryKeys";
import { toast } from "@/hooks/use-toast";

export function usePostRlcsStatTeam() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postRlcsStatTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: rlcsStatQueryKeys.teams,
      });
      toast({
        title: "Team updated successfully",
        description: "The team has been updated successfully",
        variant: "default",
      });
    },
    onError: () => {
      toast({
        title: "Failed to update team",
        description: "The team could not be updated",
        variant: "destructive",
      });
    },
  });
}
