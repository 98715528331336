/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios from "axios";
import authInterceptor from "../authInterceptor";
import { Notification, NotificationSchema } from "./schemas";
import { baseApiURL } from "../../config";

import { DateTime } from "luxon";

const apiClient = axios.create({
  baseURL: baseApiURL,
});
apiClient.interceptors.request.use(authInterceptor);

export const getScheduledNotifications = async (): Promise<Notification[]> => {
  const { data } = await apiClient.get<unknown>("/v1/notifications/mobile/scheduled");
  const scheduledNotifications = NotificationSchema.array().parse(data);
  return scheduledNotifications;
};

export const getDeliveredNotifications = async (): Promise<Notification[]> => {
  const { data } = await apiClient.get<unknown>("/v1/notifications/mobile");
  const deliveredNotifications = NotificationSchema.array().parse(data);
  return deliveredNotifications;
};

export const sendNotification = async (notification: Omit<Notification, "id" | "status" | "date">): Promise<void> => {
  const id = crypto.randomUUID();
  const date = DateTime.now().toUTC().toISO();
  const { type, title, body, metadata } = notification;

  await apiClient.post("/v1/notifications/mobile", {
    type,
    id,
    title,
    body,
    status: "SCHEDULED",
    metadata,
    date,
    scheduled: false,
  });
};

export const scheduleNotification = async (notification: Omit<Notification, "id" | "status">): Promise<void> => {
  const id = crypto.randomUUID();
  const { type, title, body, date } = notification;

  await apiClient.post("/v1/notifications/mobile", {
    type,
    id,
    title,
    body,
    status: "SCHEDULED",
    date,
    scheduled: true,
  });
};

export const deleteScheduledNotification = async (notification: Pick<Notification, "type" | "id">): Promise<void> => {
  const { type, id } = notification;
  const notificationId = `${type}_${id}`;

  await apiClient.delete(`/v1/notifications/mobile/${notificationId}`);
};
