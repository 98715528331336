import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { usePatchTournament } from "../../../../../api/tournaments/hooks/usePatchTournament";
import { usePostTournament } from "../../../../../api/tournaments/hooks/usePostTournament";
import { Tournament } from "../../../../../api/tournaments/schemas/tournaments";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import useFetchCircuits from "../../../hooks/useFetchCircuits";
import useFetchTournamentById from "../../../hooks/useFetchTournamentById";
import { DotaTournamentsAutocomplete } from "./components/DotaTournamentsAutocomplete";
import { TournamentCustomModulesAccordion } from "./components/TournamentCustomModulesAccordion";
import { TournamentSponsorsAccordion } from "./components/TournamentSponsorsAccordion";
import { TournamentTeamsAccordion } from "./components/TournamentTeamsAccordion";
import { TournamentTicketingAccordion } from "./components/TournamentTicketingAccordion";
import { DatePicker } from "@/components/DatePicker";
import { Loader } from "@/shared/components/Loader";
import { ImageUpload } from "./components/ImageUpload";
import { useUploadTournamentImage } from "@/api/assets/uploadImage/useUploadTournamentImage";
import { generateImageUrl } from "@/helpers/images/generateImageUrl";
import { Textarea } from "@/components/ui/textarea";

export function CreateAndEditTournamentModal({
  isOpen,
  onClose,
  editingTournamentId,
  selectedCircuitId,
}: {
  isOpen: boolean;
  onClose: () => void;
  editingTournamentId?: string;
  selectedCircuitId?: string;
}) {
  const { data: editingTournament } = useFetchTournamentById(editingTournamentId);
  const alert = useAlert();

  // Tournament details:
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [circuitId, setCircuitId] = useState<string>(selectedCircuitId ?? "");
  const [numberOfTeams, setNumberOfTeams] = useState<number>(0);
  const [location, setLocation] = useState("");
  const [externalId, setExternalId] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [grouping, setGrouping] = useState<string>("");
  const [image, setImage] = useState<File | undefined>(undefined);
  const [description, setDescription] = useState<string>("");

  // DOTA tournament specific details
  const [externalStreamUrl, setExternalStreamUrl] = useState("");
  const [sponsorsHeader, setSponsorsHeader] = useState(editingTournament?.metadata?.sponsors?.header ?? "");
  const [headerCtaText, setHeaderCtaText] = useState("");
  const [headerCtaUrl, setHeaderCtaUrl] = useState("");

  // Hooks
  const { mutate: patchTournament, isLoading: isPatchingTournament } = usePatchTournament(editingTournament?.id);
  const { mutate: postTournament, isLoading: isPostingTournament } = usePostTournament();
  const { data: circuits } = useFetchCircuits();

  // Memos
  const gameId = useMemo(() => {
    return circuits?.find((c) => c.id === circuitId)?.gameId;
  }, [circuits, circuitId]);

  const isValid = useMemo(() => {
    return !!name && !!startDate && !!endDate && startDate <= endDate && !!circuitId && !!numberOfTeams;
  }, [name, startDate, endDate, circuitId, numberOfTeams]);

  // Effects
  useEffect(() => {
    if (editingTournament) {
      // Tournament details:
      setName(editingTournament.name);
      setStartDate(editingTournament.startDate);
      setEndDate(editingTournament.endDate);
      setCircuitId(editingTournament.circuitId);
      setNumberOfTeams(editingTournament.numberOfTeams ?? 0);
      setLocation(editingTournament.location ?? "");
      setExternalId(editingTournament.externalId ?? "");
      setRegion(editingTournament.region ?? "");
      setGrouping(editingTournament.grouping ?? "");
      setDescription(editingTournament.description ?? "");

      // External stream URL
      setExternalStreamUrl(editingTournament.metadata?.externalStreamUrl ?? "");

      // Sponsors
      setSponsorsHeader(editingTournament.metadata?.sponsors?.header ?? "");

      // Custom CTA
      setHeaderCtaText(editingTournament.metadata?.headerCta?.text ?? "");
      setHeaderCtaUrl(editingTournament.metadata?.headerCta?.url ?? "");

      // Images
      setImage(undefined);
    }
  }, [editingTournament]);

  useEffect(() => {
    // Reset modal on open
    setName("");
    setStartDate(new Date());
    setEndDate(new Date());
    setCircuitId("");
    setNumberOfTeams(0);
    setLocation("");
    setExternalId("");
    setRegion("");
    setGrouping("");
    setExternalStreamUrl("");
    setSponsorsHeader("");
    setHeaderCtaText("");
    setDescription("");
    setHeaderCtaUrl("");
    setImage(undefined);
  }, [isOpen]);
  const { mutate: uploadTournamentImage, isLoading: isUploadingTournamentImage } = useUploadTournamentImage();

  const handleSubmit = () => {
    if (!name || !startDate || !endDate || startDate > endDate || !circuitId || !numberOfTeams) {
      return;
    }

    // Create tournament metadata
    const metadata: Tournament["metadata"] = {
      externalStreamUrl: externalStreamUrl ? externalStreamUrl : undefined,
      headerCta: headerCtaText && headerCtaUrl ? { text: headerCtaText, url: headerCtaUrl } : undefined,
      sponsors:
        sponsorsHeader || editingTournament?.metadata?.sponsors?.items.length
          ? {
              header: sponsorsHeader,
              items: editingTournament?.metadata?.sponsors?.items ?? [],
            }
          : undefined,
      ticketing: editingTournament?.metadata?.ticketing?.items.length
        ? {
            items: editingTournament?.metadata?.ticketing?.items ?? [],
          }
        : undefined,
    };

    if (editingTournament) {
      if (image) {
        uploadTournamentImage({
          tournamentId: editingTournament.id,
          image: image,
        });
      }

      void patchTournament(
        {
          startDate,
          endDate,
          numberOfTeams,
          location: location ?? null,
          externalId: externalId ?? null,
          region: region ?? null,
          grouping: grouping ?? null,
          description: description ?? null,
          metadata,
        },
        {
          onError: () => {
            alert.showFailureAlert("Failed to edit tournament");
          },
          onSuccess: () => {
            alert.showSuccessAlert("Tournament edited successfully");
            onClose();
          },
        },
      );
    } else {
      void postTournament(
        {
          name,
          startDate,

          endDate,
          circuitId,
          numberOfTeams,
          location: location ?? null,
          externalId: externalId ?? null,
          region: region ?? null,
          grouping: grouping ?? null,
          description: description ?? null,
          metadata,
        },
        {
          onError: () => {
            alert.showFailureAlert("Failed to create tournament");
          },
          onSuccess: (data) => {
            alert.showSuccessAlert("Tournament created successfully");
            if (!image) {
              onClose();
              return;
            }
            if (image) {
              uploadTournamentImage(
                {
                  tournamentId: data.id,
                  image: image,
                },
                {
                  onSuccess: () => {
                    onClose();
                  },
                },
              );
            }
          },
        },
      );
    }
  };

  const isMutating = isUploadingTournamentImage || isPatchingTournament || isPostingTournament;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-h-[90vh] w-full max-w-5xl overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Tournament</DialogTitle>
        </DialogHeader>
        <Separator />

        <div className="grid grid-cols-2 items-center gap-4">
          <div className="space-y-2">
            <label htmlFor="name">Tournament Name</label>
            <Input
              id="name"
              required
              onChange={(e) => setName(e.target.value)}
              value={name}
              disabled={!!editingTournament}
            />
          </div>

          <div className="space-y-2">
            <label>Circuit</label>
            <Select value={circuitId} onValueChange={(value) => setCircuitId(value)} disabled={!!editingTournament}>
              <SelectTrigger>
                <SelectValue placeholder="Select circuit" />
              </SelectTrigger>
              <SelectContent>
                {circuits?.map((circuit) => (
                  <SelectItem key={circuit.id} value={circuit.id}>
                    {circuit.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <label>Start Date</label>
            <DatePicker value={startDate} onChange={(date) => date && setStartDate(date)} />
          </div>

          <div className="space-y-2">
            <label>End Date</label>
            <DatePicker value={endDate} onChange={(date) => date && setEndDate(date)} />
          </div>

          <div className="space-y-2">
            <label>Number Of Teams</label>
            <Input
              type="number"
              onChange={(e) => {
                setNumberOfTeams(Number(e.target.value));
              }}
              value={numberOfTeams}
            />
          </div>

          <div className="space-y-2">
            <label>Region</label>
            <Input
              onChange={(e) => {
                setRegion(e.target.value);
              }}
              value={region || ""}
            />
          </div>

          <div className="space-y-2">
            <label>Grouping</label>
            <Input
              onChange={(e) => {
                setGrouping(e.target.value);
              }}
              value={grouping || ""}
            />
          </div>

          <div className="space-y-2">
            <label>Location</label>
            <Input
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              value={location}
            />
          </div>

          {gameId === "dota" ? (
            <div className="space-y-2">
              <DotaTournamentsAutocomplete
                selectedTournament={externalId}
                setSelectedTournament={(value: string | null) => {
                  setExternalId(value ?? "");
                }}
              />
            </div>
          ) : (
            <div className="space-y-2">
              <label>External ID</label>
              <Input
                onChange={(e) => {
                  setExternalId(e.target.value);
                }}
                value={externalId || ""}
              />
            </div>
          )}

          <div className="space-y-2">
            <label>External Stream URL</label>
            <Input
              onChange={(e) => {
                setExternalStreamUrl(e.target.value);
              }}
              value={externalStreamUrl}
            />
          </div>
          <div className="space-y-2">
            <label>Image</label>
            <ImageUpload
              onImageSet={(image) => setImage(image)}
              imageUrl={editingTournament?.id ? generateImageUrl("tournament", editingTournament?.id) : undefined}
              file={image}
            />
          </div>
        </div>
        <div className="space-y-2">
          <label>Description</label>
          <Textarea
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
          />
        </div>

        {editingTournament && (
          <>
            <TournamentTeamsAccordion tournamentId={editingTournament.id} circuitId={editingTournament.circuitId} />
            <TournamentSponsorsAccordion
              tournamentId={editingTournament.id}
              sponsorsHeader={sponsorsHeader}
              setSponsorsHeader={setSponsorsHeader}
            />
            <TournamentTicketingAccordion tournamentId={editingTournament.id} />
            <TournamentCustomModulesAccordion
              headerCtaText={headerCtaText}
              headerCtaUrl={headerCtaUrl}
              setHeaderCtaText={setHeaderCtaText}
              setHeaderCtaUrl={setHeaderCtaUrl}
            />
          </>
        )}

        <Separator />
        <DialogFooter className="flex items-center justify-between">
          {editingTournament && (
            <Button variant="ghost" asChild className="mr-auto">
              <Link to={`/to/tournaments/${editingTournament.id}`}>Go To Tournament</Link>
            </Button>
          )}
          <div className="flex gap-2">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={!isValid || isMutating} onClick={handleSubmit} className={isMutating ? "opacity-50" : ""}>
              {isMutating ? (
                <>
                  {" "}
                  <Loader className="mx-auto w-40" />
                  Saving...
                </>
              ) : (
                <>Save Tournament Details</>
              )}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
