import { useEffect } from "react";

import {
  pubSubClient,
  Unsubscribe,
  NewAdminMessagesListener,
  DeletedMessageListener,
  RestoredMessageListener,
} from "@/common/PubSubClient";

interface DDSEventsOptions {
  roomId?: string;
  newAdminMessagesListener?: NewAdminMessagesListener;
  deletedMessageListener?: DeletedMessageListener;
  restoredMessageListener?: RestoredMessageListener;
  disabled?: boolean;
}

const useDDSEvents = ({
  roomId,
  newAdminMessagesListener,
  deletedMessageListener,
  restoredMessageListener,
  disabled,
}: DDSEventsOptions) => {
  useEffect(() => {
    let sessionStateChangeListener: null | Unsubscribe = null;
    let eventsListener: null | Unsubscribe = null;

    if (disabled) {
      return;
    }

    const subscribeToEvents = () => {
      if (!roomId) {
        return;
      }

      eventsListener = pubSubClient.subscribe({
        roomId,
        newAdminMessagesListener,
        deletedMessageListener,
        restoredMessageListener,
      });
    };

    if (!pubSubClient.sessionOpened) {
      sessionStateChangeListener = pubSubClient.onSessionStateChange(() => {
        sessionStateChangeListener?.unsubscribe();
        sessionStateChangeListener = null;
        subscribeToEvents();
      });
    } else {
      subscribeToEvents();
    }

    return () => {
      sessionStateChangeListener?.unsubscribe();
      eventsListener?.unsubscribe();
    };
  }, [roomId, newAdminMessagesListener, deletedMessageListener, restoredMessageListener, disabled]);
};

export default useDDSEvents;
