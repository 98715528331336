import { useQuery } from "@tanstack/react-query";
import { listMatchTimelineEvents } from "..";

interface UseListMatchTimelineEventsOptions {
  matchId?: string;
}

export const useListMatchTimelineEvents = ({ matchId }: UseListMatchTimelineEventsOptions) => {
  return useQuery({
    queryFn: () => listMatchTimelineEvents({ matchId }),
    queryKey: ["timeline events", matchId],
    enabled: matchId !== undefined,
  });
};
