import { getRLRankingLeaderboard } from "@/api/tournaments/rankings";
import { useQuery } from "@tanstack/react-query";


export default function useFetchLeaderboard(category: string, subCategory: string) {
  return useQuery({
    queryKey: ["rankings", "leaderboard", category, subCategory],
    queryFn: () => {
      return getRLRankingLeaderboard(category, subCategory);
    },
    retry: false,
  });
}