import { useMemo } from "react";
import { generateImageUrl } from "../../../../../../../../../helpers/images/generateImageUrl";
import { generateAssetsId } from "../../../../../../../../../helpers/images/generateImageUrl/generateAssetsId";
import { Avatar } from "../../../../../../../../../types/Avatar";
import useFetchAvatars from "../../../../../../../../UserProfile/hooks/useFetchAvatars";
import { SelectEmpty } from "./components/selectEmpty";
import SelectWithImage, { SelectWithImageOption } from "./components/selectWithImage";

type CaseRarity = "silver" | "gold" | "diamond";

const convertRarity = (rarity: CaseRarity) => {
  const rarities = {
    silver: "silverRarity",
    diamond: "diamondRarity",
    gold: "goldRarity",
  };

  return rarities[rarity] as "silverRarity" | "diamondRarity" | "goldRarity";
};

interface AvatarCaseProps {
  rarity: CaseRarity;
  pickedAvatars: string[];
  hiddenAvatars: string[];
  onClick: (
    rarity: "diamondRarity" | "silverRarity" | "goldRarity",
    oldValue: string | null,
    newValue: string | null,
  ) => void;
}

const convertAvatarsToOptions = (avatars?: Avatar[]): SelectWithImageOption[] => {
  return (
    avatars?.map((avatar) => ({
      id: avatar.id,
      name: avatar.name,
      image: generateImageUrl("avatars", generateAssetsId(avatar.id, "3d"), {
        width: "80",
        height: "80",
        format: "auto",
      }),
    })) ?? []
  );
};

export const AvatarCase = ({ rarity, pickedAvatars, hiddenAvatars, onClick }: AvatarCaseProps) => {
  const { data: avatars } = useFetchAvatars();

  const avatarsToUse = useMemo(() => {
    const converted = convertAvatarsToOptions(
      avatars?.filter((value) => value.isUnlockOnly && value.rarity === rarity),
    );

    return converted.filter((value) => !hiddenAvatars.includes(value.id));
  }, [avatars, hiddenAvatars, rarity]);

  const pickedAvatarsToUse = useMemo(() => {
    const converted = convertAvatarsToOptions(avatars);

    return converted
      .filter((value) => pickedAvatars.includes(value.id))
      .sort((a, b) => {
        return pickedAvatars.indexOf(a.id) - pickedAvatars.indexOf(b.id);
      });
  }, [avatars, pickedAvatars]);

  return (
    <>
      <div className="w-full gap-3">
        <span className="pb-3 pl-3 capitalize font-style-label-3">{rarity}</span>
        <div className="grid grid-cols-3 gap-3 pt-3">
          {pickedAvatarsToUse.map((value) => {
            return (
              <SelectWithImage
                key={value.id}
                values={avatarsToUse}
                selected={value}
                onChange={(newValue) => onClick(convertRarity(rarity), value.id, newValue.id)}
                label={rarity}
              />
            );
          })}
          <SelectWithImage
            values={avatarsToUse}
            onChange={(value) => onClick(convertRarity(rarity), null, value.id)}
            label={"+"}
          />
          <SelectEmpty
            onRemove={() => {
              const lastEntry = pickedAvatarsToUse[pickedAvatarsToUse.length - 1];

              if (!lastEntry) return;

              onClick(convertRarity(rarity), lastEntry.id, null);
            }}
            showRemove={pickedAvatarsToUse.length > 0}
          />
        </div>
      </div>
    </>
  );
};
