import { Grid, TextField, FormControlLabel, Switch } from "@mui/material";
import { Emoji } from "../../../../../types/Emoji";
import { useEffect, useState } from "react";

interface EmojiDetailsFieldsProps {
  emojiId: string;
  emoji: Emoji | undefined;
  updateEmoji: (emoji: Emoji) => void;
}

export const EmojiDetailsFields = ({ emoji, emojiId, updateEmoji }: EmojiDetailsFieldsProps) => {
  const [name, setName] = useState<string>(emoji?.name ?? "");
  const [orderIndex, setOrderIndex] = useState<number>(emoji?.orderIndex ?? 0);
  const [code, setCode] = useState<string>(emoji?.code ?? "");
  const [score, setScore] = useState<number>(emoji?.score ?? 0);
  const [hidden, setHidden] = useState<boolean>(emoji?.hidden ?? false);
  const [isUnlockOnly, setIsUnlockOnly] = useState<boolean>(emoji?.isUnlockOnly ?? false);
  const [isClaimable, setIsClaimable] = useState<boolean>(emoji?.isClaimable ?? false);

  useEffect(() => {
    updateEmoji({
      id: emojiId,
      name,
      orderIndex,
      code,
      score,
      hidden,
      isUnlockOnly,
      isClaimable,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emojiId, name, orderIndex, code, score, hidden, isUnlockOnly, isClaimable]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item>
          <TextField
            id="outlined"
            label="Id"
            sx={{ width: 375 }}
            value={emojiId}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </Grid>
        <Grid item>
          <TextField
            id="outlined"
            label="Name"
            sx={{ width: 375 }}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={4}>
        <Grid item>
          <TextField
            id="outlined"
            label="Order Index"
            sx={{ width: 375 }}
            value={orderIndex}
            onChange={(e) => {
              setOrderIndex(parseInt(e.target.value));
            }}
            type="number"
          />
        </Grid>
        <Grid item>
          <TextField
            id="outlined"
            label="Code"
            sx={{ width: 375 }}
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            type="text"
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={4}>
        <Grid item>
          <TextField
            id="outlined"
            label="Frequently Used Score (0 for N/A)"
            sx={{ width: 375 }}
            value={score}
            onChange={(e) => {
              setScore(parseInt(e.target.value));
            }}
            type="number"
          />
        </Grid>
      </Grid>
      <br />
      <FormControlLabel
        control={<Switch />}
        checked={hidden}
        onChange={(_, checked) => {
          setHidden(checked);
        }}
        label="Hidden"
      />
      <br />
      <FormControlLabel
        control={<Switch />}
        checked={isUnlockOnly}
        onChange={(_, checked) => {
          setIsUnlockOnly(checked);
        }}
        label="Is Unlock Only"
      />
      <br />
      <FormControlLabel
        control={<Switch />}
        checked={isClaimable}
        onChange={(_, checked) => {
          setIsClaimable(checked);
        }}
        label="Is Claimable"
      />
    </>
  );
};
