import { useMemo } from "react";
import { useGetLineupBySeriesId } from "../hooks/useGetLineupBySeriesId";
import { CsEvent } from "../api/video/schemas";
import useFetchMatch from "../routes/Broadcasts/BroadcastDetails/hooks/useFetchMatch";
import { getPlayerIdFromEvent } from "../helpers/getPlayerIdFromEvent";

export const useGetEventRelatedData = (event?: CsEvent) => {
  const matchQueryResults = useFetchMatch([event?.matchId ?? ""]);

  const match = useMemo(() => {
    if (!matchQueryResults) {
      return null;
    }

    return matchQueryResults.at(0);
  }, [matchQueryResults]);

  const { data: lineups, isLoading: isLineupsLoading } = useGetLineupBySeriesId({
    seriesId: event?.matchId,
    enabled: !!event,
  });

  const playerId = useMemo(() => (event ? getPlayerIdFromEvent(event) : null), [event]);
  const { team, player } = useMemo(() => {
    if (!lineups || !event) {
      return {
        team: undefined,
        player: undefined,
      };
    }

    const teamKey = (["teamA", "teamB"] as const).find((teamKey) => {
      const team = lineups[teamKey];
      return team && team.players.find((player) => player.ingameId === playerId);
    });

    return teamKey
      ? {
          team: lineups[teamKey],
          player: lineups[teamKey].players.find((player) => player.ingameId === playerId),
        }
      : {
          team: undefined,
          player: undefined,
        };
  }, [event, lineups, playerId]);

  return {
    isLoading: !match || isLineupsLoading,
    data: {
      match,
      team,
      player,
    },
  };
};
