import { RankingTransactionList, RankingTransaction } from "../../../../api/tournaments/rankings/schemas";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../../components/ui/table";
import { useGetTournaments } from "@/api/tournaments/getTournaments/useGetTournaments";
import { Button } from "@/components/ui/button";
import { ArrowUpDown } from "lucide-react";
import { useState } from "react";
import { ConfirmDeleteTransactionModal } from "./ConfirmDeleteTransactionModal";

interface RLTransacationTableProps {
  data: RankingTransactionList;
}

export const RLTransactionTable = ({ data }: RLTransacationTableProps) => {
  const { data: tournaments } = useGetTournaments({ game: "rl" });
  const [toDeleteTransactionId, setToDeleteTransactionId] = useState<string | undefined>(undefined);

  const columns: ColumnDef<RankingTransaction>[] = [
    {
      accessorKey: "teamName",
      header: "Team Name",
    },
    {
      accessorKey: "category",
      header: "Category",
    },
    {
      header: "Sub Category",
      accessorKey: "subCategory",
    },
    {
      accessorKey: "points",
      header: "Value",
    },
    {
      accessorKey: "transactionDate",
      header: ({ column }) => {
        return (
          <Button
            variant={column.getIsSorted() ? "secondary" : "ghost"}
            onClick={() => column.toggleSorting(column.getIsSorted() === "desc")}
          >
            Transaction Date
            <ArrowUpDown />
          </Button>
        );
      },
    },
    {
      accessorKey: "tournamentId",
      header: "Tournament",
      cell: (cell) => {
        const tournament = tournaments?.find((tournament) => tournament.id === cell.getValue());
        return tournament ? tournament.name : "";
      },
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "createdAt",
      header: ({ column }) => {
        return (
          <Button
            variant={column.getIsSorted() ? "secondary" : "ghost"}
            onClick={() => column.toggleSorting(column.getIsSorted() === "desc")}
          >
            Created At
            <ArrowUpDown />
          </Button>
        );
      },
    },
    {
      id: "delete-button",
    },
  ];
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <div className="mt-10 h-full overflow-hidden overflow-y-scroll rounded-lg border border-gray-200 p-4">
      <h2>Transactions</h2>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
                <TableCell>
                  <ConfirmDeleteTransactionModal
                    transaction={row.original}
                    transactionIdToDelete={toDeleteTransactionId}
                    triggerOpen={() => setToDeleteTransactionId(row.original.id)}
                    onClose={() => setToDeleteTransactionId(undefined)}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
