import { z } from "zod";
import { DbMapSchema } from "./map";
import { ServerResponseSchema } from "./servers";
import { DbTeamSchema } from "./teams";
import { DbVetoSchema } from "./vetoes";
import { DbCircuitSchema } from "./circuits";
import { DbTournamentSchema } from "./tournament";
import { DbStageSchema } from "./stages";

export const MatchTypeSchema = z.enum(["BO1", "BO2", "BO3", "BO5", "BO7"]);

export type MatchType = z.infer<typeof MatchTypeSchema>;

export const DbTeamMatchSchema = z.object({
  matchId: z.string(),
  teamId: z.string(),
  score: z.coerce.number().int().nullable(),
  index: z.number().int(),
});

export type DbTeamMatch = z.infer<typeof DbTeamMatchSchema>;

export const DbMatchSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: MatchTypeSchema,
  index: z.coerce.number().int(),
  scheduledAt: z.string().datetime().nullable(),
  stageId: z.string(),
  createdAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  updatedAt: z.string().datetime().nullable(),
});

export const MatchSchema = DbMatchSchema.omit({
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
});

export type DbMatch = z.infer<typeof DbMatchSchema>;
export type Match = z.infer<typeof MatchSchema>;

export const CreateMatchOptionsSchema = DbMatchSchema.pick({
  stageId: true,
  name: true,
  type: true,
  index: true,
  scheduledAt: true,
});

export const MatchCreateRequestSchema = CreateMatchOptionsSchema;

export type MatchCreateRequest = z.infer<typeof MatchCreateRequestSchema>;

export const UpdateMatchRequestSchema = DbMatchSchema.pick({
  name: true,
  type: true,
  scheduledAt: true,
}).partial();

export type UpdateMatchRequest = z.infer<typeof UpdateMatchRequestSchema>;

export const CreateTeamMatchRequestSchema = z.object({
  teamId: z.string(),
  score: z.number().int().nullable(),
  index: z.number().int(),
});

export const DbMatchDetailsSchema = DbMatchSchema.omit({
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
}).extend({
  server: ServerResponseSchema.pick({
    ip: true,
    status: true,
  }).nullable(),
  circuit: DbCircuitSchema.pick({
    id: true,
    name: true,
  }),
  tournament: DbTournamentSchema.pick({
    id: true,
    name: true,
    startDate: true,
    endDate: true,
  }),
  stage: DbStageSchema.pick({
    id: true,
    name: true,
    startDate: true,
    endDate: true,
    format: true,
    index: true,
  }),
  maps: z.array(
    DbMapSchema.pick({
      id: true,
      name: true,
      scheduledAt: true,
      startedAt: true,
      endedAt: true,
    }),
  ),
});

export type DbMatchDetails = z.infer<typeof DbMatchDetailsSchema>;

export const DbTeamMatchDetailsSchema = z.object({
  id: z.string(),
  name: z.string(),
  shortName: z.string(),
  index: z.number().int(),
  score: z.number().int().nullable(),
  matchId: z.string(),
  maps: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      score: z.number().int().nullable(),
    }),
  ),
});

export type DbTeamMatchDetails = z.infer<typeof DbTeamMatchDetailsSchema>;

export const DbTournamentMatchSchema = DbMatchDetailsSchema.extend({
  teams: z.array(
    z.object({
      score: z.number().nullable(),
      teamIndex: z.number().int(),
      team: DbTeamSchema.pick({
        id: true,
        name: true,
      }),
    }),
  ),
});

export type DbTournamentMatch = z.infer<typeof DbTournamentMatchSchema>;

export const EditCommunityMatchSchema = DbTournamentMatchSchema.extend({
  teams: z.array(
    z.object({
      prevTeamId: z.string().optional(),
      score: z.number().nullable(),
      teamIndex: z.number().int(),
      team: DbTeamSchema.pick({
        id: true,
        name: true,
      }).optional(),
    }),
  ),
});

export type EditCommunityMatch = z.infer<typeof EditCommunityMatchSchema>;

export const UpdateMatchScoreRequestSchema = z.object({
  scores: z
    .object({
      teamId: z.string(),
      score: z.number().int().min(0),
    })
    .array()
    .min(1),
});

export const VetoCreateRequestSchema = z
  .object({
    matchId: z.string().optional(),
    teamId: z.string().nullable().describe("The team making the veto, or null if it's the decider map."),
    type: z.enum(["pick", "ban"]),
    map: z.string(),
    index: z.number().int(),
  })
  .refine((obj) => obj.teamId !== null || obj.type === "pick", "If team is null the type must be 'pick'.");

export const VetoResponseSchema = DbVetoSchema.omit({
  deletedAt: true,
});

export const UpdateVetoRequestSchema = z
  .object({
    index: z.number().int(),
    map: z.string(),
    type: z.enum(["pick", "ban"]),
    matchId: z.string(),
    teamId: z.string().nullable(),
  })
  .partial();
