import { Dialog, Divider, DialogTitle, DialogContent, Button, DialogActions, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ImageUploadForm } from "../../../shared/components/ImageUploadForm";
import { createAvatar, invalidateMetadataFile } from "../../../../../api/assets";

import { uploadAvatarImage } from "../../helpers/uploadAvatarImage";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import { Avatar } from "../../../../../types/Avatar";
import { AvatarDetailsFields } from "../AvatarDetailsFields";
import { usePatron } from "../../../../../providers/PatronProvider/hooks/usePatron";

interface CreateAvatarModalProps {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
}

export const CreateAvatarModal = ({ open, isLoading, onClose }: CreateAvatarModalProps) => {
  const alert = useAlert();
  const { patron } = usePatron();

  const [smallImage, setSmallImage] = useState<File | null>(null);
  const [largeImage, setLargeImage] = useState<File | null>(null);
  const [avatarId, setAvatarId] = useState("");
  const [createdAvatar, setCreatedAvatar] = useState<Avatar | null>(null);

  useEffect(() => {
    setAvatarId(crypto.randomUUID());
  }, []);

  const onAvatarCreate = async () => {
    if (createdAvatar === null) {
      alert.showFailureAlert("You need to fill out the information.");
      return;
    }

    if (smallImage === null || largeImage === null) {
      alert.showFailureAlert("You need to attach a small and large image.");
      return;
    }

    await createAvatar({
      avatarData: createdAvatar,
      patron,
    });

    await uploadAvatarImage({
      avatarId,
      image: smallImage,
      type: "2d",
      patron,
      sendSuccessMessage: (message) => {
        alert.showSuccessAlert(message);
      },
      sendErrorMessage: (message) => {
        alert.showFailureAlert(message);
      },
    });

    await uploadAvatarImage({
      avatarId,
      image: largeImage,
      type: "3d",
      patron,
      sendSuccessMessage: (message) => {
        alert.showSuccessAlert(message);
      },
      sendErrorMessage: (message) => {
        alert.showFailureAlert(message);
      },
    });

    await invalidateMetadataFile("avatars", patron);
    alert.showSuccessAlert("Successfully created avatar");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create Avatar</DialogTitle>
      <Divider />
      <DialogContent>
        <AvatarDetailsFields
          avatarId={avatarId}
          avatar={undefined}
          updateAvatar={(avatar) => setCreatedAvatar(avatar)}
        />
        <Divider />
        <Grid container spacing={4}>
          <Grid item>
            <h3>2D Avatar:</h3>
            <ImageUploadForm image={smallImage} setImage={setSmallImage} />
          </Grid>
          <Grid item>
            <h3>3D Avatar:</h3>
            <ImageUploadForm image={largeImage} setImage={setLargeImage} />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled={isLoading} onClick={() => void onAvatarCreate()}>
          Create Avatar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
