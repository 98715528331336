import { invalidateCache, invalidateMetadataFile, sendDeleteEmojiRequest } from "../../../../api/assets";
import { Patron } from "../../../../providers/PatronProvider/types";

interface DeleteEmojiProps {
  emojiId: string;
  sendSuccessMessage: (message: string) => void;
  sendErrorMessage: (message: string) => void;
  patron?: Patron;
}

export const deleteEmoji = async ({ emojiId, sendSuccessMessage, sendErrorMessage, patron }: DeleteEmojiProps) => {
  try {
    await sendDeleteEmojiRequest({ emojiId, patron });
  } catch (error) {
    sendErrorMessage("Couldn't delete emoji");
    throw error;
  }
  try {
    await invalidateCache("emojis", emojiId, patron);
  } catch (error) {
    sendErrorMessage("Couldn't invalidate cache for images");
    throw error;
  }
  try {
    await invalidateMetadataFile("emojis", patron);
  } catch (error) {
    sendErrorMessage("Couldn't invalidate cache for metadata");
    throw error;
  }

  sendSuccessMessage("Successfully delete emoji");
};
