import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import useDeleteMatch from "../../../../../hooks/matches/useDeleteMatch";
import useAlert from "../../../../../../../providers/AlertProvider/hooks/useAlert";

interface DeleteConfirmModalProps {
  isOpen: boolean;
  close: () => void;
  matchId: string;
  tournamentId?: string;
  closeMatchModal: () => void;
}

export const DeleteConfirmModal = ({
  isOpen,
  close,
  matchId,
  tournamentId,
  closeMatchModal,
}: DeleteConfirmModalProps) => {
  const alert = useAlert();
  const { mutate: deleteMatch } = useDeleteMatch({
    tournamentId: tournamentId,
    onSuccess: () => alert.showSuccessAlert("Delete Match Success"),
    onError: () => alert.showFailureAlert("Delete Match Failed"),
  });

  return (
    <Dialog open={isOpen} onOpenChange={close}>
      <DialogContent className="max-w-[600px] p-6">
        <DialogHeader>
          <DialogTitle>Delete Match</DialogTitle>
          <DialogDescription>Are you sure you want to delete this match?</DialogDescription>
        </DialogHeader>
        <DialogFooter className="mt-4">
          <Button variant="outline" onClick={close}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              deleteMatch({ matchId });
              close();
              closeMatchModal();
            }}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
