import { useQueries } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";
import { getTriggerPollById } from "..";
import notEmpty from "@/shared/helpers/notEmpty";
import { useMemo } from "react";

export const useListTriggerPollsById = (ids: string[]) => {
  const queryResults = useQueries({
    queries: ids.map((id) => ({
      queryKey: queryKeys.pollById(id),
      queryFn: () => getTriggerPollById(id),
    })),
  });

  const results = useMemo(() => {
    return {
      data: queryResults.map((result) => result.data).filter(notEmpty),
      isPending: queryResults.some((result) => result.isLoading),
      isError: queryResults.some((result) => result.isError),
    };
  }, [queryResults]);

  return results;
};
