import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Fragment, useEffect } from "react";
import { Label } from "@/components/ui/label";
import { Broadcast } from "../../../../../types/Broadcasts";
import getLetterByIndex from "../../../../../helpers/getLetterByIndex";
import { generateImageUrl } from "../../../../../helpers/images/generateImageUrl";
import useDeleteTriggerPoll from "../../hooks/useDeleteTriggerPoll";
import useFetchTriggerPollById from "../../hooks/useFetchTriggerPollById";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CheckCircle } from "lucide-react";

function PollDetails({
  onClose,
  refetch,
  pollId,
  broadcastsMap,
}: {
  onClose: () => void;
  refetch: () => Promise<unknown>;
  pollId: string;
  broadcastsMap: Map<string, Broadcast>;
}) {
  const { data: poll, isLoading } = useFetchTriggerPollById(pollId);
  const { mutate: deleteStream, isLoading: isDeleting, isSuccess } = useDeleteTriggerPoll(pollId);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    void refetch();
    onClose();
  }, [isSuccess, onClose, refetch]);

  if (isLoading || !poll) {
    return (
      <Dialog open={true} onOpenChange={() => onClose()}>
        <DialogContent className="sm:max-w-[800px]">
          <DialogHeader>
            <DialogTitle>Fetching - details</DialogTitle>
          </DialogHeader>
          <div className="flex items-center justify-center py-8">
            <div className="size-8 animate-spin rounded-full border-b-2 border-gray-900" />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={true} onOpenChange={() => onClose()}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogTitle>{poll.title}</DialogTitle>

        <hr />

        <ScrollArea className="max-h-[80vh]">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-8">
              <div className="flex flex-col gap-2">
                <Label>Question</Label>
                <span className="text-sm">{poll.question}</span>
              </div>
              <div className="flex flex-col gap-2">
                <Label>ID</Label>
                <span className="text-sm">{poll.id}</span>
              </div>
              <div className="flex flex-col gap-2">
                <Label>Broadcast ID</Label>
                <span className="text-sm">{poll.broadcastId || ""}</span>
              </div>
              <div className="flex flex-col gap-2">
                <Label>Broadcast Title</Label>
                <span className="text-sm">{poll.broadcastId ? broadcastsMap.get(poll.broadcastId)?.title : ""}</span>
              </div>
              <div className="flex flex-col gap-2">
                <Label>Total votes</Label>
                <span className="text-sm">{poll.votes}</span>
              </div>
            </div>

            {poll.options.map((option, index) => (
              <Fragment key={option.id}>
                <hr />

                <div className="flex gap-8">
                  <div className="flex flex-1 flex-col gap-2">
                    <Label className="flex items-center gap-2">
                      <span>Answer {getLetterByIndex(index)}</span>
                      {option.isCorrect && (
                        <div className="flex items-center gap-1 text-xs text-green-500">
                          <CheckCircle className="inline-block size-5" />
                          <span>Correct Answer</span>
                        </div>
                      )}
                    </Label>
                    <div className="flex items-center gap-2">
                      <span>{option.value}</span>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <Label>Votes</Label>
                    <div>{option.votes}</div>
                  </div>

                  {poll.hasImages && (
                    <div className="flex flex-col gap-2">
                      <Label>Image</Label>
                      <img
                        src={generateImageUrl("triggers", option.id, {
                          width: "150",
                          format: "auto",
                        })}
                        className="size-20 object-contain"
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </Fragment>
            ))}
          </div>
        </ScrollArea>

        <DialogFooter className="gap-2">
          {!poll.startedAt && (
            <Button variant="destructive" onClick={() => deleteStream()} disabled={isDeleting}>
              Delete
            </Button>
          )}
          <Button variant="outline" onClick={onClose} disabled={isDeleting}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default PollDetails;
