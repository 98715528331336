import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { Chip } from "@mui/material";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useGetEventById } from "../../../../api/metricsViewership/hooks/useGetEventById";
import { EventChannelsTable } from "./components/EventChannelsTable";
import { LinkChannelModal } from "./components/LinkChannelModal";
import { useGetEventReport } from "../../../../api/metricsViewership/hooks/useGetEventReport";
import { Event } from "../../../../api/metricsViewership/schemas/EventSchema";
import { DuplicateEventModal } from "./components/DuplicateEventModal";
import PageContainer from "@/components/Layout/PageContainer";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useRequestGenerateReport } from "@/api/metricsViewership/hooks/useRequestGenerateReport";
import { AddEditEventModal } from "../shared/components/AddEditEventModal";
import { getEventStatus } from "../shared/helpers/getEventStatus";
import { LightsaberFightLoader } from "@/shared/components/LightsaberFightLoader";
import { formatDateTimeInterval, formatMinutesToHuman } from "../shared/helpers/time";
import { AirtimeModal } from "./components/AirtimeModal";
import { Alert, AlertDescription } from "@/components/ui/alert";

export const MetricsViewershipEventDetailsPage = () => {
  const { id: eventId } = useParams<{ id: string }>();

  const { data: event, isError, refetch: refetchEvent } = useGetEventById(eventId);
  const { mutate: getReportFile, isLoading: isDownloadingReport, data: xlsxFile, reset } = useGetEventReport();
  const { mutate: generateReport, isLoading: isRequestingReport } = useRequestGenerateReport();

  const [reportName, setReportName] = useState<string>("");
  const [duplicatingEvent, setDuplicatingEvent] = useState<Event | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [lastReportGenerationRequested, setLastReportGenerationRequested] = useState(() => {
    try {
      const persisted = window.localStorage.getItem("lastReportGenerationRequested");

      if (!persisted) {
        return null;
      }

      const { requestedAt, eventId: id } = JSON.parse(persisted) as {
        requestedAt: string;
        eventId: string;
      };

      const lastReportGenerationRequested = DateTime.fromISO(requestedAt);

      if (!lastReportGenerationRequested.isValid || eventId !== id) {
        return null;
      }

      return lastReportGenerationRequested;
    } catch {
      return null;
    }
  });

  const primaryChannels = event?.channels.filter((c) => c.isPrimary) || [];

  const isGeneratingReport = useMemo(() => {
    if (!lastReportGenerationRequested || !event) {
      return false;
    }

    if (!event.lastGeneratedReport) {
      return true;
    }

    return lastReportGenerationRequested.diff(DateTime.fromISO(event.lastGeneratedReport)).milliseconds > 0;
  }, [event, lastReportGenerationRequested]);

  useEffect(() => {
    if (!xlsxFile) {
      return;
    }

    const url = window.URL.createObjectURL(xlsxFile);
    const link = document.createElement("a");
    link.href = url;
    const reportFileName = `Viewership Stats (${reportName}).xlsx`;
    link.setAttribute("download", reportFileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
    reset();
  }, [xlsxFile, reset, reportName]);

  useEffect(() => {
    if (!isGeneratingReport) {
      return;
    }

    const interval = setInterval(() => {
      void refetchEvent();
    }, 10_000);

    return () => clearInterval(interval);
  }, [isGeneratingReport, refetchEvent]);

  const eventStatus = event ? getEventStatus(event) : undefined;

  if (isError) {
    return (
      <div className="w-full">
        <PageContainer>
          <p>Something went wrong when fetching the event</p>
        </PageContainer>
      </div>
    );
  }
  if (!event) {
    return (
      <div className="w-full">
        <PageContainer>
          <div>Event not found.</div>
        </PageContainer>
      </div>
    );
  }

  const onGenerateReport = (eventId: string) => {
    const now = DateTime.now();
    setLastReportGenerationRequested(now);
    window.localStorage.setItem(
      "lastReportGenerationRequested",
      JSON.stringify({
        eventId,
        requestedAt: now.toISO(),
      }),
    );

    generateReport(eventId);
  };

  return (
    <div className="w-full">
      <PageContainer>
        {!primaryChannels.length && (
          <Alert>
            <AlertDescription>
              Primary channel(s) must be added to start storing and reporting on airtime intervals.
            </AlertDescription>
          </Alert>
        )}

        <div className="flex w-full items-center gap-4">
          <div className="flex w-full flex-col gap-1">
            <header className="flex items-center gap-4">
              {eventStatus && (
                <span
                  title={eventStatus.title}
                  className={classNames("rounded p-2 uppercase", eventStatus.className, eventStatus.bgClassName)}
                >
                  {eventStatus.status}
                </span>
              )}
              <h1 className="text-heading">{event.name}</h1>
              <div className="flex gap-1">
                <AddEditEventModal editingEvent={event} />
                <Button
                  onClick={() => {
                    setDuplicatingEvent(event);
                  }}
                >
                  Duplicate
                </Button>
              </div>
            </header>

            <Table className="mt-4">
              <TableBody>
                <TableRow>
                  <TableCell className="w-40 border-none">Date:</TableCell>
                  <TableCell className="border-none">
                    {formatDateTimeInterval({ start: event.startDate, end: event.endDate })}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="border-none">Channels</TableCell>
                  <TableCell className="border-none">
                    Total {event.channels.length} channels - {primaryChannels.length} Primary
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="border-none">Airtime</TableCell>
                  <TableCell className="border-none">
                    {formatMinutesToHuman(event.totalAirtimeMinutes)} <AirtimeModal eventId={event.id} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="flex w-56 shrink-0 flex-col gap-2">
            <Button
              onClick={() => {
                setReportName(event.name);
                getReportFile(event.id);
              }}
              disabled={isDownloadingReport}
            >
              Download Report
            </Button>
            <Button
              variant="default"
              onClick={() => {
                onGenerateReport(event.id);
              }}
              disabled={isRequestingReport}
            >
              Generate Report
            </Button>

            {event.lastGeneratedReport && (
              <span>
                Last generated: <br />
                {DateTime.fromISO(event.lastGeneratedReport).toLocaleString(DateTime.DATETIME_FULL)}
              </span>
            )}

            {isGeneratingReport && (
              <div className="flex w-56 flex-col items-center justify-center gap-1">
                <LightsaberFightLoader />
                <span className="text-center">
                  Watch this beautiful fight while we&apos;re generating new file for you
                </span>
              </div>
            )}
          </div>
        </div>

        {/* Scout */}
        <header>
          <h3 className="text-xl font-bold">Scout Settings</h3>
          <p>
            The Scout service auto-links channels to events based on keywords, game ID (Twitch only) and tournament
            data.
          </p>
        </header>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="w-40 border-none">Keywords</TableCell>
              <TableCell className="flex flex-wrap items-center gap-2 border-none">
                {event.keywords.map((keyword, i) => (
                  <Chip key={i} label={keyword} size="small" className="" />
                ))}
                {event.keywords.length === 0 && <span>Not assigned</span>}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="border-none">Twitch Game Id</TableCell>
              <TableCell className="border-none">{!event.twitchGameId ? "Not assigned" : event.gameName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="border-none">Circuit</TableCell>
              <TableCell className="border-none">{!event.circuitId ? "Not assigned" : event.circuitId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="border-none">Tournament</TableCell>
              <TableCell className="border-none">{!event.tournamentId ? "Not assigned" : event.tournamentId}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className="flex gap-4">
          <h2 className="text-heading">Channels</h2>
          {<LinkChannelModal event={event} />}
        </div>
        <div className="flex w-full items-center gap-4">
          <Label htmlFor="filter">Filter</Label>
          <Input id="filter" className="w-96" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
        </div>
        {<EventChannelsTable eventId={event.id} searchQuery={searchQuery} />}
      </PageContainer>

      {duplicatingEvent && (
        <DuplicateEventModal
          isOpen={!!duplicatingEvent}
          onClose={() => setDuplicatingEvent(null)}
          event={duplicatingEvent}
        />
      )}
    </div>
  );
};
