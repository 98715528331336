import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { createStage, patchStage } from "../../../../../api/tournaments";
import { PostTournamentSchema } from "../../../../../api/tournaments/schemas/tournaments";
import { useEffect, useState } from "react";
import { usePostPatchTO } from "../../../hooks/usePostPatchTO";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import { ZodError } from "zod";
import { StageBuilder } from "../..";
import { PatchStageSchema, PostStageSchema } from "../../../../../api/tournaments/schemas/stages";
import { DatePicker } from "@/components/DatePicker";

interface CreateEditStageModalProps {
  tournamentId: string;
  stageModalStatus: "new" | "edit" | "closed";
  stage: StageBuilder;
  onChange: (stage: StageBuilder) => void;
  onClose: () => void;
  onSubmit: () => unknown;
}

const stageFormats = [
  "double-elim-4-gauntlet",
  "gsl-4-playoff",
  "gsl-8",
  "double-elim-6",
  "double-elim-8",
  "double-elim-4-skewed",
  "double-elim-8-skewed",
  "double-elim-8-groups",
  "double-elim-10-skewed",
  "double-elim-12-skewed",
  "double-elim-16-skewed",
  "gauntlet-4",
  "gauntlet-9",
  "gauntlet-10",
  "single-elim-8",
  "single-elim-6",
  "single-elim-4",
  "single-elim-8-bounty",
  "single-elim-32-bounty",
  "swiss-8",
  "swiss-16",
  "swiss-16-6-qualified",
  "afl-final-eight",
  "showmatch",
  "custom",
  "round-robin",
  "tie-breaker",
];

const CreateEditStageModal = ({
  stageModalStatus,
  onClose,
  stage,
  onChange,
  tournamentId,
  onSubmit,
}: CreateEditStageModalProps) => {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);

  useEffect(() => {
    setReadyToSubmit(
      stage.name !== "" &&
        stage.name !== undefined &&
        stage.startDate !== undefined &&
        stage.endDate !== undefined &&
        stage.startDate <= stage.endDate &&
        stage.format !== undefined &&
        (stage.format === "round-robin"
          ? stage.numberOfTeams !== undefined && stage.numberOfTeams !== null && stage.numberOfTeams > 0
            ? true
            : false
          : true) &&
        stage.index !== undefined,
    );
  }, [stage]);

  const alert = useAlert();

  const submit = async () => {
    setIsSubmitButtonDisabled(true);
    if (stageModalStatus === "new") {
      await submitCreate();
    } else if (stageModalStatus === "edit") {
      await submitEdit();
    }
    setIsSubmitButtonDisabled(false);
  };

  const submitCreate = async () => {
    if (stageModalStatus !== "new") {
      throw new Error("Incorrect modal state");
    }
    const data = PostStageSchema.safeParse({ ...stage, tournamentId });
    if (!data.success) {
      const err = data.error as ZodError<typeof PostTournamentSchema>;
      alert.showFailureAlert(`${err.errors[0].path.join()} ${err.errors[0].message}`);
      throw new Error(data.error.message);
    }
    await createStage({
      ...data.data,
    });
    onSubmit();
  };

  const submitEdit = async () => {
    if (stageModalStatus !== "edit") {
      throw new Error("Incorrect modal state");
    }
    if (stage.id === undefined) {
      throw new Error("Stage ID was unexpectedly undefined");
    }

    const data = PatchStageSchema.safeParse(stage);
    if (!data.success) {
      const err = data.error as ZodError<typeof PostTournamentSchema>;
      alert.showFailureAlert(`${err.errors[0].path.join()} ${err.errors[0].message}`);
      throw new Error(data.error.message);
    }
    await patchStage(stage.id, {
      ...data.data,
    });
    onSubmit();
  };

  const mutatedSubmit = usePostPatchTO({
    onSuccess: () => {
      alert.showSuccessAlert(`Stage was successfully ${stage.id ? "edited" : "created"}`);
      onClose();
    },
    onError: () => {
      alert.showFailureAlert(`Stage failed to ${stage.id ? "edit" : "create"}`);
    },
    submit,
    type: "stage",
  });

  return (
    <Dialog open={stageModalStatus !== "closed"} onOpenChange={() => onClose()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{stageModalStatus === "new" ? "Create Stage" : "Edit Stage"}</DialogTitle>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <div className="col-span-3">
              <Label htmlFor="name">Stage Name</Label>
              <Input
                id="name"
                required
                value={stage.name}
                onChange={(e) => onChange({ ...stage, name: e.target.value })}
                className={stage?.name === "" || stage === undefined ? "border-red-500" : ""}
              />
            </div>

            <div>
              <Label htmlFor="index">Index</Label>
              <Input
                id="index"
                type="number"
                required
                value={stage.index}
                onChange={(e) => onChange({ ...stage, index: parseInt(e.target.value) })}
                className={stage?.index === undefined ? "border-red-500" : ""}
              />
            </div>

            <div className="col-span-4 flex w-full flex-col items-start gap-2">
              <Label>Start Date</Label>
              <DatePicker value={stage.startDate} onChange={(date) => onChange({ ...stage, startDate: date })} />
            </div>

            <div className="col-span-4 flex flex-col items-start gap-2">
              <Label>End Date</Label>
              <DatePicker value={stage.endDate} onChange={(date) => onChange({ ...stage, endDate: date })} />
            </div>

            <div className="col-span-4">
              <Label>Format</Label>
              <Select value={stage.format || "none"} onValueChange={(value) => onChange({ ...stage, format: value })}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select format" />
                </SelectTrigger>
                <SelectContent className="w-full">
                  {stageFormats?.map((format) => (
                    <SelectItem key={format} value={format}>
                      {format}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            {(stage.format === "round-robin" || stage.format === "tie-breaker") && (
              <>
                <div>
                  <Label>Number of teams</Label>
                  <Input
                    type="number"
                    value={stage.numberOfTeams ?? ""}
                    onChange={(e) =>
                      onChange({
                        ...stage,
                        numberOfTeams: parseInt(e.target.value),
                      })
                    }
                    className={
                      ((stage?.format === "round-robin" || stage?.format === "tie-breaker") &&
                        stage?.numberOfTeams === undefined) ||
                      stage?.numberOfTeams === 0
                        ? "border-red-500"
                        : ""
                    }
                  />
                </div>

                {stage.format === "round-robin" && (
                  <div>
                    <Label>Teams advancing</Label>
                    <Input
                      type="number"
                      placeholder="3"
                      value={stage.metadata?.numberOfTeamsAdvances || 0}
                      onChange={(e) =>
                        onChange({
                          ...stage,
                          metadata: {
                            numberOfTeamsAdvances: parseInt(e.target.value),
                          },
                        })
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={!readyToSubmit || isSubmitButtonDisabled} onClick={() => mutatedSubmit.mutate()}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateEditStageModal;
