import { ElementType } from "react";

import { Knife } from "../../../shared/assets/game-icons/Knife";
import { Tazed } from "../../../shared/assets/game-icons/Tazed";
import { Pog } from "../../../shared/assets/game-icons/Pog";
import { Rapid } from "../../../shared/assets/game-icons/Rapid";
import { Kekw } from "../../../shared/assets/game-icons/Kekw";
import { NinjaDefuse } from "../../../shared/assets/game-icons/NinjaDefuse";
import { Ace } from "../../../shared/assets/game-icons/Ace";
import { AgainstTheOdds } from "../../../shared/assets/game-icons/AgainstTheOdds";
import { QuickRound } from "../../../shared/assets/game-icons/QuickRound";
import { Iq } from "../../../shared/assets/game-icons/Iq";
import { Eco } from "../../../shared/assets/game-icons/Eco";
import { CsEvent } from "../../../api/video/schemas";

const icons: Partial<Record<CsEvent["eventType"], ElementType>> = {
  ACE: Ace,
  AGAINST_THE_ODDS: AgainstTheOdds,
  ECO: Eco,
  IQ: Iq,
  KEKW: Kekw,
  KNIFE: Knife,
  TAZED: Tazed,
  NINJA_DEFUSE: NinjaDefuse,
  POG: Pog,
  QUICK_ROUND: QuickRound,
  RAPID: Rapid,
};

export const GameIcon = ({ eventType }: { eventType: CsEvent["eventType"] }) => {
  const Icon = icons[eventType];
  return Icon ? <Icon /> : null;
};
