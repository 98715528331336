import { useMutation, useQueryClient } from "@tanstack/react-query";
import { RankingTransaction, RankingTransactionSchema } from "../../../../api/tournaments/rankings/schemas";
import { createRankingTransaction } from "@/api/tournaments/rankings";
import useAlert from "@/providers/AlertProvider/hooks/useAlert";
import { AddTransaction } from "../components/AddTransactionModal/schemas";
import { ZodError } from "zod";

export default function useCreateTransaction({
  category,
  subCategory,
  onSuccess,
  onError,
}: {
  category: string;
  subCategory: string;
  onSuccess: () => void;
  onError: () => void;
}) {
  const queryClient = useQueryClient();
  const alert = useAlert();
  return useMutation({
    mutationKey: ["transaction"],
    mutationFn: async ({ transaction }: { transaction: AddTransaction }): Promise<RankingTransaction[] | undefined> => {
      const transactionResponses: RankingTransaction[] = [];
      if (transaction && transaction.entries) {
        try {
          for (const entry of transaction.entries) {
            const builtTransaction = RankingTransactionSchema.omit({ id: true, createdAt: true }).parse({
              gameId: transaction.gameId,
              category: transaction.category,
              subCategory: transaction.subCategory,
              transactionDate: transaction.transactionDate,
              tournamentId: transaction.tournamentId === "non-tournament-transaction" ? null : transaction.tournamentId,
              teamId: entry.teamId,
              teamName: entry.teamName,
              points: entry.points,
              description: entry.description,
            });
            const transactionResponse = await createRankingTransaction(builtTransaction);
            transactionResponses.push(transactionResponse);
          }
        } catch (error) {
          if (error instanceof ZodError) {
            alert.showFailureAlert("Invalid transaction data, check console for more info");
          }
          alert.showFailureAlert("Error while creating transaction data");
          throw error;
        }
      }
      return transactionResponses;
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["rankings", "leaderboard", category, subCategory] }); //refetch leaderboard
      await queryClient.refetchQueries({ queryKey: ["rankings", "transactions", "rl"] }); // refetch transactions
      alert.showSuccessAlert("Successfully created transactions");
      onSuccess();
    },
    onError: () => {
      onError();
    },
  });
}
