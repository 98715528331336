"use client";

import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";

import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import classNames from "classnames";

export function Combobox({
  options,
  value,
  onChange,
  className,
  placeholder,
}: {
  options: { value: string; label: string; image?: string }[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
}) {
  const [open, setOpen] = React.useState(false);

  const currentOption = options.find((option) => option.value === value);
  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={classNames("min-w-[150px] justify-between", className)}
        >
          <span className="line-clamp-1 flex items-center gap-2 text-xs">
            {currentOption?.image && <img src={currentOption.image} className="h-4" />}
            {value ? currentOption?.label : placeholder}
          </span>
          <ChevronsUpDown className="opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[--radix-popover-trigger-width] p-0">
        <Command>
          <CommandInput placeholder={placeholder} className="h-9" />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  keywords={[option.label]}
                  onSelect={(currentValue) => {
                    onChange(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                  className="line-clamp-1"
                >
                  <span className="line-clamp-1 flex items-center gap-2 text-xs">
                    {option.image && <img src={option.image} className="w-5" />}
                    {option.label}
                    <Check className={cn("ml-auto", value === option.value ? "opacity-100" : "opacity-0")} />
                  </span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
