import { z } from "zod";
import { MatchTypeSchema } from "./MatchTypeSchema";
import { MapNameSchema } from "./MapNameSchema";
export const TaskSchema = z.object({
  id: z.string(),
  taskName: z.string(),
  taskRunnerArn: z.string(),
  broadcastId: z.string(),
  startedAt: z.string().datetime(),
  startedAtDate: z.string(),
  ingestUrl: z.string(), // the ingest for the live stream server
  streamProvider: z.enum(["gcp", "aws"]),
  matches: z.array(
    z.object({
      matchId: z.string(),
      matchType: MatchTypeSchema,
      orderIndex: z.number(),
      videoPath: z.string(), // the path to the video file for this match
      maps: z.array(
        z.object({
          name: MapNameSchema,
          logPath: z.string(),
          orderIndex: z.number(),
        }),
      ),
      logOffsetSeconds: z.number(),
      templateId: z.string(),
    }),
  ),
  status: z.string(),
});
