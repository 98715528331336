import { useMutation } from "@tanstack/react-query";
import { updateRolePermissions } from "../../../../api/auth";

import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { Role } from "../../../../api/auth/schemas";

const useUpdateRole = () => {
  const { showFailureAlert } = useAlert();

  return useMutation({
    mutationFn: ({ role, permissions }: { role: Role; permissions: Array<string> }) =>
      updateRolePermissions(role.name, permissions),
    onError() {
      showFailureAlert("Something went wrong while updating role");
    },
  });
};

export default useUpdateRole;
