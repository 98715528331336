import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createBroadcastAd } from "../../../api/broadcast-ads";
import { BroadcastAd } from "../../../api/broadcast-ads/types";

export const useCreateBroadcastAd = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void }) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (props: BroadcastAd) => {
      await createBroadcastAd(props);
      await queryClient.invalidateQueries(["broadcast-ads"]);
      await queryClient.refetchQueries(["broadcast-ads"]);
      onSuccess?.();
    },
    {
      onSuccess,
      onError,
    },
  );
};
