import { useMemo } from "react";
import useFetchCorrectAnswers from "./useFetchCorrectAnswers";
import useFetchPickemsQuestions from "./useFetchPickemsQuestions";
import usePostCorrectAnswer from "./usePostCorrectAnswer";
import usePostIncorrectAnswer from "./usePostIncorrectAnswer";
import { getFinalsQuestions, getGroupsQuestions, getShowdownQuestions } from "../../../sanity/Pickems/helpers";

const usePickems = (pickemsId?: string) => {
  const { data: pickems, isLoading: questionsAreLoading } = useFetchPickemsQuestions(pickemsId);

  const pickemsQuestions = useMemo(() => {
    try {
      switch (pickems?.pickemsFormat) {
        case "groups":
          return getGroupsQuestions(pickems);
        case "finals":
          return getFinalsQuestions(pickems);
        case "showdown":
          return getShowdownQuestions(pickems);
        default:
          return [];
      }
    } catch {
      // old pickems documents
      return [];
    }
  }, [pickems]);

  const { data: correctAnswers, isLoading: answersAreLoading } = useFetchCorrectAnswers({
    pickemsId,
    enabled: !!pickemsId && !questionsAreLoading && !!pickems,
  });
  const { mutate: sendCorrectAnswer, isLoading: sendingCorrectAnswer } = usePostCorrectAnswer();
  const { mutate: sendIncorrectAnswer, isLoading: sendingIncorrectAnswer } = usePostIncorrectAnswer();

  return {
    pickems,
    pickemsQuestions,
    correctAnswers: correctAnswers ?? [],
    isLoading: questionsAreLoading || answersAreLoading,
    isWaiting: sendingCorrectAnswer || sendingIncorrectAnswer,
    sendCorrectAnswer,
    sendIncorrectAnswer,
  };
};

export default usePickems;
