import { Check, Close, Edit } from "@mui/icons-material";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Emoji } from "../../../../../types/Emoji";
import { usePatron } from "../../../../../providers/PatronProvider/hooks/usePatron";
import { generateImageUrl } from "../../../../../helpers/images/generateImageUrl";

interface EmojisTableProps {
  emojis: Emoji[] | undefined;
  setEditingEmoji: (emojiId: string) => void;
}

export const EmojisTable = ({ emojis, setEditingEmoji }: EmojisTableProps) => {
  const [sortedEmojis, setSortedEmojis] = useState<Emoji[]>([]);
  const { patron } = usePatron();

  useEffect(() => {
    if (!emojis) return;

    const sortedEmojis = emojis.sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1));
    setSortedEmojis(sortedEmojis);
  }, [emojis]);

  return (
    <TableContainer style={{ height: "80vh" }}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Icon</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Order index</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>
              Frequently Used
              <br />
              Score
            </TableCell>
            <TableCell align="center">Hidden</TableCell>
            <TableCell align="center">Unlock Only?</TableCell>
            <TableCell align="center">Claimable</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedEmojis?.map((emoji) => (
            <TableRow key={emoji.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                <img
                  src={generateImageUrl("emojis", emoji.id, {
                    width: "40",
                    format: "auto",
                    patron,
                  })}
                  alt={emoji.name}
                  height={40}
                  width={40}
                  style={{ marginRight: "5px" }}
                />
              </TableCell>
              <TableCell>{emoji.name}</TableCell>
              <TableCell>{emoji.orderIndex}</TableCell>
              <TableCell>{emoji.code}</TableCell>
              <TableCell>{emoji.score === 0 ? "N/A" : emoji.score}</TableCell>
              <TableCell align="center">{emoji.hidden ? <Check color="success" /> : <Close color="error" />}</TableCell>
              <TableCell align="center">
                {emoji.isUnlockOnly ? <Check color="success" /> : <Close color="error" />}
              </TableCell>
              <TableCell align="center">
                {emoji.isClaimable ? <Check color="success" /> : <Close color="error" />}
              </TableCell>
              <TableCell>
                <Button onClick={() => setEditingEmoji(emoji.id)}>
                  <Edit />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
