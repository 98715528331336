import { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { useCreateEvent } from "@/api/metricsViewership/hooks/useCreateEvent";
import { Event } from "@/api/metricsViewership/schemas/EventSchema";
import { useUpdateEvent } from "@/api/metricsViewership/hooks/useUpdateEvent";
import { useGetTwitchGamesMap } from "@/api/data/viewership/getTwitchGamesMap/useGetTwitchGamesMap";
import useFetchCircuits from "@/routes/Tournaments/hooks/useFetchCircuits";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogTrigger,
  DialogDescription,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { MultiSelect } from "@/components/multi-select";
import useFetchTournamentsByCircuitId from "@/routes/Tournaments/hooks/useFetchTournamentsByCircuitId";
import { CircleX } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { DateTimePicker24h } from "@/components/DateTimePicker24h";

const notAssignedValue = "<Not Assigned>";

const getInitialStartDate = (date?: string) => {
  const parsed = date && DateTime.fromISO(date);
  return parsed && parsed.isValid ? parsed : DateTime.now().startOf("day");
};

const getInitialEndDate = (date?: string) => {
  const parsed = date && DateTime.fromISO(date);
  return parsed && parsed.isValid ? parsed : DateTime.now().startOf("day").plus({ days: 1 });
};

export const AddEditEventModal = ({ editingEvent }: { editingEvent?: Event }) => {
  const { data: twitchGamesMap, isLoading: twitchGamesMapLoading } = useGetTwitchGamesMap();
  const { mutate: createEvent } = useCreateEvent();
  const { mutate: updateEvent } = useUpdateEvent(editingEvent?.id);

  const { data: circuits } = useFetchCircuits();
  const [circuitId, setCircuitId] = useState<string>();

  const { data: tournaments } = useFetchTournamentsByCircuitId(circuitId);
  const [tournamentId, setTournamentId] = useState<string>();

  const [eventName, setEventName] = useState<string>("");
  const [game, setGame] = useState<{ id: string; label: string } | null>(null);
  const [fromDate, setFromDate] = useState(getInitialStartDate());
  const [toDate, setToDate] = useState(getInitialEndDate());
  const [keywords, setKeywords] = useState<string[]>([]);

  const gameOptions = useMemo(() => {
    if (twitchGamesMapLoading) {
      return [];
    }

    return [
      { id: notAssignedValue, label: notAssignedValue },
      ...Object.values(twitchGamesMap ?? {}).map((game) => ({
        id: game.id,
        label: game.name,
      })),
    ].sort((a, b) => a.label.localeCompare(b.label));
  }, [twitchGamesMap, twitchGamesMapLoading]);

  const formIsValid = !!eventName && !!fromDate && !!toDate;
  const saveAndClose = () => {
    if (!formIsValid) {
      return;
    }

    if (editingEvent) {
      updateEvent(
        {
          name: eventName,
          twitchGameId: game?.id === notAssignedValue ? null : game?.id,
          gameName: game?.label === notAssignedValue ? null : game?.label,
          circuitId: circuitId || null,
          tournamentId: tournamentId || null,
          startDate: fromDate.toISO(),
          endDate: toDate.toISO(),
          keywords,
        },
        {
          onSuccess: () => {
            setOpen(false);
          },
        },
      );
    } else {
      createEvent(
        {
          name: eventName,
          twitchGameId: game?.id === notAssignedValue ? undefined : game?.id,
          gameName: game?.label === notAssignedValue ? undefined : game?.label,
          circuitId: circuitId ? circuitId : undefined,
          tournamentId: tournamentId ? tournamentId : undefined,
          startDate: fromDate.toISO(),
          endDate: toDate.toISO(),
          channels: [],
          keywords,
        },
        {
          onSuccess: () => {
            setOpen(false);
          },
        },
      );
    }
  };

  useEffect(() => {
    if (editingEvent) {
      const game = gameOptions.find((g) => g.id === editingEvent.twitchGameId);
      setEventName(editingEvent.name);
      setGame({
        id: game?.id ?? notAssignedValue,
        label: game?.label ?? notAssignedValue,
      });
      setFromDate(getInitialStartDate(editingEvent.startDate));
      setToDate(getInitialEndDate(editingEvent.endDate));
      setKeywords(editingEvent.keywords);

      if (editingEvent.circuitId) setCircuitId(editingEvent.circuitId);
      if (editingEvent.tournamentId) setTournamentId(editingEvent.tournamentId);
    }
  }, [editingEvent, gameOptions]);

  const [open, setOpen] = useState(false);

  const circuit = circuits?.find((circuit) => circuit.id === circuitId);
  const tournament = tournaments?.find((tournament) => tournament.id === tournamentId);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button>{editingEvent ? "Edit" : "Add Event"}</Button>
      </DialogTrigger>

      <DialogContent className="w-full max-w-4xl">
        <DialogHeader>
          <DialogTitle>{editingEvent ? `Editing Event "${editingEvent.name}"` : "New Event"}</DialogTitle>
          <DialogDescription>
            Events are used to track viewership data for a specific set of channels.
          </DialogDescription>
        </DialogHeader>

        <form className="flex w-full flex-col gap-6">
          <div className="space-y-2">
            <Label htmlFor="name">
              Name <span className="text-red-500">*</span>
            </Label>
            <Input
              placeholder="Name*"
              id="name"
              value={eventName}
              required
              type="string"
              onChange={(e) => setEventName(e.target.value)}
            />
            <div className="ml-3 text-xs text-muted-foreground">
              Used for back-office purposes. (Not used for keyword searching)
            </div>
          </div>
          <div className="space-y-2">
            <Label>Twitch Game</Label>
            <Select
              value={game?.id}
              onValueChange={(value) => {
                setGame(gameOptions.find((option) => option.id === value) ?? null);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Twitch Game" />
              </SelectTrigger>
              <SelectContent>
                {gameOptions.map((option) => (
                  <SelectItem key={option.id} value={option.id}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <div className="ml-3 text-xs text-muted-foreground">
              Game is used for ordering and finding relevant streams on Twitch
            </div>
          </div>
          <div className="space-y-2">
            <MultiSelect
              value={keywords}
              options={[]}
              maxCount={Infinity}
              placeholder="Scout Keywords*"
              onValueChange={(value) => setKeywords(value)}
              freeSolo
            />

            <div className="ml-3 text-xs text-muted-foreground">
              Keywords are used to find relevant live streams. Any stream with these any of the listed keywords in the
              title will be added to the list of streams for this event.
              <br />
              Keyword searches are <b>not</b> case sensitive.
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="circuit">Event Start Date</Label>
            <DateTimePicker24h
              value={fromDate.toJSDate()}
              onChange={(date) => {
                if (!date) return;
                const newValue = DateTime.fromJSDate(date);
                if (!newValue.isValid) return;

                setFromDate(newValue);
              }}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="circuit">Event End Date</Label>
            <DateTimePicker24h
              value={toDate.toJSDate()}
              onChange={(date) => {
                if (!date) return;
                const newValue = DateTime.fromJSDate(date);
                if (!newValue.isValid) return;

                setToDate(newValue);
              }}
            />
            <div className="ml-3 text-xs text-muted-foreground">
              Streams will be scanned within this time period. At least one of the <b>Primary</b> channels attached to
              the event should be live, for data to be collected.
            </div>
          </div>
        </form>

        <div className="flex items-end gap-4">
          <div className="grid w-full grid-cols-2 gap-4">
            <div className="w-full space-y-2">
              <Label htmlFor="circuit">Circuit</Label>
              <Select
                name="circuit"
                onValueChange={(value) => {
                  setCircuitId(value);
                }}
                value={circuitId}
              >
                <SelectTrigger>
                  <SelectValue>{circuit ? circuit.name : "Select a Circuit"}</SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {circuits
                      ?.sort((a, b) => (a.startDate < b.startDate ? 1 : -1))
                      .map((circuit) => (
                        <SelectItem key={circuit.id} value={circuit.id}>
                          {circuit.name}
                        </SelectItem>
                      ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <div className="w-full space-y-2">
              <Label htmlFor="tournament">Tournament</Label>
              <Select
                name="tournament"
                onValueChange={(value) => setTournamentId(value)}
                value={tournamentId || undefined}
                disabled={!circuitId || !tournaments?.length}
              >
                <SelectTrigger>
                  <SelectValue>{tournament ? tournament.name : "Select a Tournament"}</SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {tournaments
                      ?.sort((a, b) => (a.startDate < b.startDate ? 1 : -1))
                      .map((tournament) => (
                        <SelectItem key={tournament.id} value={tournament.id}>
                          {tournament.name}
                        </SelectItem>
                      ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>

          <Button
            variant="destructive"
            size="icon"
            onClick={() => {
              setTournamentId(undefined);
              setCircuitId(undefined);
            }}
            title="Clear Tournament"
          >
            <CircleX />
          </Button>
        </div>

        <Separator className="my-4" />

        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={saveAndClose} disabled={!formIsValid}>
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
