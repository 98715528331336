/* eslint-disable @typescript-eslint/unbound-method */
import {
  Listener,
  SubscribeArgs,
  Unsubscribe,
  DdsEdgeMessage,
  ChatRoom,
  ReconnectListener,
  SessionStateListener,
  AppSyncMessageSchema,
  Namespaces,
  AdminChatRoom,
} from "./types";
import * as cookies from "es-cookie";
import { environment } from "@/config";

export * from "./types";

export class PubSubClient {
  private readonly subscriptions = new Map<Namespaces, string>();
  private _sessionOpened = false;
  private readonly host =
    environment === "production"
      ? "ti4rqv5fjbeqnmsfng5ugmfpwi.appsync-api.eu-central-1.amazonaws.com"
      : "du5ibvvopfco3ouaqt26432b6y.appsync-api.eu-central-1.amazonaws.com";

  public get sessionOpened() {
    return this._sessionOpened;
  }

  private set sessionOpened(value: boolean) {
    this._sessionOpened = value;
    this.triggerSessionStateChange();
  }

  private wsInstance: null | WebSocket = null;
  private listeners: Array<Listener> = [];
  private reconnectListeners: Array<ReconnectListener> = [];
  private sessionStateListeners: Array<SessionStateListener> = [];
  private closingTimer: null | number = null;
  private connectionTimeout: null | number = null;
  private connectionTimeoutMs: null | number = null;

  constructor() {
    this.onMessage = this.onMessage.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  public open() {
    return this.openConnection();
  }

  public close() {
    this.closeConnection();
  }

  public subscribe({
    roomId,
    newAdminMessagesListener,
    deletedMessageListener,
    restoredMessageListener,
  }: SubscribeArgs): Unsubscribe {
    const subscriptionId: string = Math.random().toString(32).split(".")[1];

    if ((deletedMessageListener || restoredMessageListener) && roomId && !this.roomExists(roomId)) {
      this.subscribeToRoom(roomId);
    }

    if (newAdminMessagesListener && roomId && !this.adminRoomExists(roomId)) {
      this.subscribeToAdminRoom(roomId);
    }

    this.listeners.push({
      id: subscriptionId,
      roomId,
      newAdminMessagesListener,
      deletedMessageListener,
      restoredMessageListener,
    });

    return {
      unsubscribe: (): boolean => {
        try {
          const messageListenerIndex: number = this.listeners.findIndex(
            (messageListener) => messageListener.id === subscriptionId,
          );

          if (messageListenerIndex > -1) {
            const removedListener = this.listeners.splice(messageListenerIndex, 1)[0];

            if (
              (removedListener.deletedMessageListener || removedListener.restoredMessageListener) &&
              roomId &&
              !this.roomExists(roomId)
            ) {
              this.unsubscribeFromRoom(roomId);
            }

            if (removedListener.newAdminMessagesListener && roomId && !this.adminRoomExists(roomId)) {
              this.unsubscribeFromAdminRoom(roomId);
            }
          }

          return true;
        } catch {
          return false;
        }
      },
    };
  }

  public reconnect(): void {
    this.closeConnection();
    void this.openConnection().then(() => {
      this.triggerReconnect();
      const sessionStateChangeSub = this.onSessionStateChange((sessionOpened) => {
        if (!sessionOpened) {
          return;
        }
        sessionStateChangeSub.unsubscribe();

        const rooms: Set<string> = new Set(
          this.listeners
            .filter(
              (listener) =>
                typeof listener.restoredMessageListener === "function" ||
                typeof listener.deletedMessageListener === "function",
            )
            .map((messageListener) => messageListener.roomId) as Array<string>,
        );
        const adminRooms: Set<string> = new Set(
          this.listeners
            .filter((listener) => typeof listener.newAdminMessagesListener === "function")
            .map((messageListener) => messageListener.roomId) as Array<string>,
        );

        rooms.forEach((roomId: string) => {
          this.subscribeToRoom(roomId);
        });
        adminRooms.forEach((roomId: string) => {
          this.subscribeToAdminRoom(roomId);
        });
      });
    });
  }

  public onReconnect(callback: () => void): Unsubscribe {
    const reconnectId: string = Math.random().toString(32).split(".")[1];

    this.reconnectListeners.push({
      id: reconnectId,
      callback,
    });

    return {
      unsubscribe: (): boolean => {
        try {
          const listenerIndex: number = this.reconnectListeners.findIndex((listener) => listener.id === reconnectId);

          if (listenerIndex > -1) {
            this.reconnectListeners.splice(listenerIndex, 1);
          }

          return true;
        } catch {
          return false;
        }
      },
    };
  }

  public onSessionStateChange(callback: (value: boolean) => void): Unsubscribe {
    const sessionStateId: string = Math.random().toString(32).split(".")[1];

    this.sessionStateListeners.push({
      id: sessionStateId,
      callback,
    });

    return {
      unsubscribe: (): boolean => {
        try {
          const listenerIndex: number = this.sessionStateListeners.findIndex(
            (listener) => listener.id === sessionStateId,
          );

          if (listenerIndex > -1) {
            this.sessionStateListeners.splice(listenerIndex, 1);
          }

          return true;
        } catch {
          return false;
        }
      },
    };
  }

  private closeConnection(): void {
    this.stopStateWatcher();

    if (this.wsInstance === null) {
      return;
    }

    this.wsInstance.removeEventListener("message", this.onMessage);
    this.wsInstance.removeEventListener("error", this.onClose);
    this.wsInstance.close();
    this.wsInstance = null;

    if (this.closingTimer !== null) {
      window.clearTimeout(this.closingTimer);
    }
    this.sessionOpened = false;
  }

  private getWsProtocols() {
    const getHeader = () => {
      const auth = {
        host: this.host,
        // authorization is not needed to connect to the websocket,
        // but the value is required to be present
        Authorization: "n/a",
      };

      return btoa(JSON.stringify(auth))
        .replace(/\+/g, "-") // Convert '+' to '-'
        .replace(/\//g, "_") // Convert '/' to '_'
        .replace(/=+$/, ""); // Remove padding `=`
    };

    return [`header-${getHeader()}`, "aws-appsync-event-ws"];
  }

  private openConnection(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.wsInstance = new WebSocket(
        `//${this.host.replace("appsync-api", "appsync-realtime-api")}/event/realtime`,
        this.getWsProtocols(),
      );
      this.wsInstance.addEventListener("message", this.onMessage);
      this.wsInstance.addEventListener("error", this.onClose);
      this.wsInstance.addEventListener("open", () => {
        this.wsInstance?.send(JSON.stringify({ type: "connection_init" }));
        resolve();
      });
    });
  }

  private onClose(): void {
    this.closingTimer = window.setTimeout(() => {
      this.closingTimer = null;
      this.reconnect();
    }, 1000);
  }

  private stopStateWatcher(): void {
    if (this.connectionTimeout !== null) {
      window.clearTimeout(this.connectionTimeout);
      this.connectionTimeout = null;
    }
  }

  private watchConnectionState(): void {
    this.stopStateWatcher();
    if (this.connectionTimeoutMs === null) {
      return;
    }

    this.connectionTimeout = window.setTimeout(() => {
      this.reconnect();
    }, this.connectionTimeoutMs);
  }

  private triggerReconnect() {
    this.reconnectListeners.forEach((listener) => {
      setTimeout(() => {
        listener.callback();
      }, 1);
    });
  }

  private triggerSessionStateChange() {
    this.sessionStateListeners.forEach((listener) => {
      setTimeout(() => {
        listener.callback(this.sessionOpened);
      }, 1);
    });
  }

  private onMessage(event: MessageEvent): void {
    const data = JSON.parse(event.data as string) as { type: string; connectionTimeoutMs: number };

    switch (data.type) {
      case "connection_ack": {
        this.sessionOpened = true;
        // Connection timeout is the period of time after which the client should reconnect
        // if he's not receiving "ka" messages from the server.
        this.connectionTimeoutMs = data.connectionTimeoutMs;
        this.watchConnectionState();
        break;
      }
      case "ka": {
        this.watchConnectionState();
        break;
      }
      case "data": {
        this.onEvent(AppSyncMessageSchema.parse(data).event);
        break;
      }
    }
  }

  private onEvent(data: DdsEdgeMessage): void {
    try {
      switch (data.type) {
        case "chat_room": {
          this.onChatRoom(data.chat_room);
          break;
        }
        case "admin_chat_room": {
          this.onAdminChatRoom(data.admin_chat_room);
          break;
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }

  private onChatRoom(chatRoom: ChatRoom) {
    switch (chatRoom.type) {
      case "message_delete": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.deletedMessageListener === "function"
          ) {
            setTimeout(() => {
              messageListener.deletedMessageListener?.(chatRoom.message_delete);
            }, 1);
          }
        });
        break;
      }
      case "message_restore": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.restoredMessageListener === "function"
          ) {
            setTimeout(() => {
              messageListener.restoredMessageListener?.(chatRoom.message_restore);
            }, 1);
          }
        });
        break;
      }
    }
  }

  private onAdminChatRoom(adminChatRoom: AdminChatRoom) {
    switch (adminChatRoom.type) {
      case "message": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === adminChatRoom.room_uuid &&
            typeof messageListener.newAdminMessagesListener === "function"
          ) {
            setTimeout(() => {
              messageListener.newAdminMessagesListener?.(adminChatRoom.message);
            }, 1);
          }
        });
        break;
      }
    }
  }

  private subscribeToRoom(roomId: string) {
    this.subscribeTo(`/chat-room/${roomId}`);
  }

  private unsubscribeFromRoom(roomId: string): void {
    this.unsubscribeFrom(`/chat-room/${roomId}`);
  }

  private subscribeToAdminRoom(roomId: string): void {
    this.subscribeTo(`/admin-chat-room/${roomId}`);
  }

  private unsubscribeFromAdminRoom(roomId: string): void {
    this.unsubscribeFrom(`/admin-chat-room/${roomId}`);
  }

  private subscribeTo(channel: Namespaces): void {
    if (!this.sessionOpened || !this.wsInstance || this.subscriptions.has(channel)) {
      return;
    }

    const subscriptionId = window.crypto.randomUUID();
    this.subscriptions.set(channel, subscriptionId);

    this.wsInstance.send(
      JSON.stringify({
        type: "subscribe",
        id: subscriptionId,
        channel: channel.replace(/[^a-z\d-/]/gi, "-"), // replace all not allowed characters with "-",
        authorization: {
          Authorization: cookies.get("access_token") ?? "n/a",
        },
      }),
    );
  }

  private unsubscribeFrom(channel: Namespaces): void {
    const subscriptionId = this.subscriptions.get(channel);

    if (!this.sessionOpened || !this.wsInstance || !subscriptionId) {
      return;
    }

    this.subscriptions.delete(channel);
    this.wsInstance.send(
      JSON.stringify({
        type: "unsubscribe",
        id: subscriptionId,
      }),
    );
  }

  private roomExists(roomId: string): boolean {
    return this.listeners.some((listener) => listener.roomId === roomId);
  }

  private adminRoomExists(roomId: string): boolean {
    return this.listeners.some(
      (listener) => listener.roomId === roomId && typeof listener.newAdminMessagesListener === "function",
    );
  }
}

export const pubSubClient = new PubSubClient();
