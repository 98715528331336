import axios from "axios";
import { createUploadLink } from "../../assets";
import { usePatchTournament } from "../hooks/usePatchTournament";
import { getTournamentById } from "..";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";
import { Tournament } from "../schemas/tournaments";

export const useAddTournamentSponsor = ({ tournamentId }: { tournamentId: string }) => {
  const { mutateAsync: patchTournament } = usePatchTournament(tournamentId);

  const mutationFn = async (params: { imageFile: File; url: string }) => {
    // Get tournament, to make sure we don't overwrite existing metadata
    const tournament = await getTournamentById(tournamentId);

    const id = crypto.randomUUID();

    //  Get upload URL
    const folder = `tournaments/${tournamentId}/sponsor-logos`;
    const data = await createUploadLink(folder, id);

    await axios.put(data.uploadUrl, params.imageFile, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": params.imageFile.type,
      },
    });

    const image = `${folder}/${id}`;

    const sponsors: NonNullable<Tournament["metadata"]>["sponsors"] = {
      ...tournament.metadata?.sponsors,
      items: [...(tournament.metadata?.sponsors?.items ?? []), { logoKey: image, url: params.url }],
    };

    // Already has metadata, so we just update the sponsors
    const metadata: Tournament["metadata"] = {
      ...(tournament.metadata ?? {}),
      sponsors,
    };

    await patchTournament({
      metadata,
    });
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.tournamentById(tournamentId) });
    },
  });
};
