import { DateTime, Duration } from "luxon";

export function formatMinutesToHuman(minutes: number) {
  return Duration.fromObject({
    minutes,
  })
    .rescale()
    .toHuman({
      unitDisplay: "short",
    });
}

export function formatDateInterval({ start, end }: { start: string; end: string }) {
  return `${DateTime.fromISO(start).toLocaleString(DateTime.DATE_MED)} - ${DateTime.fromISO(end).toLocaleString(DateTime.DATE_MED)}`;
}
export function formatDateTimeInterval({ start, end }: { start: string; end: string }) {
  return `${DateTime.fromISO(start).toLocaleString(DateTime.DATETIME_FULL)} - ${DateTime.fromISO(end).toLocaleString(DateTime.DATETIME_FULL)}`;
}
