import { createContext, useMemo, useState } from "react";
import { Patron } from "./types";

interface PatronProviderValues {
  patron: Patron;
  setPatron: (patron: Patron) => void;
}

export const PatronContext = createContext<PatronProviderValues | null>(null);

export default function PatronProvider({ children }: { children: React.ReactNode }) {
  const [patron, setPatron] = useState<Patron>("blasttv");

  const providerValue = useMemo(
    () => ({
      patron,
      setPatron: (patron: Patron) => {
        setPatron(patron);
      },
    }),
    [patron, setPatron],
  );

  return <PatronContext.Provider value={providerValue}>{children}</PatronContext.Provider>;
}
