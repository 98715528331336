import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createCollectionMethod } from "./helpers/createCollectionMethod";
import { Collection } from "../../../../../../../../api/points/types/Collection";

interface useCreateCollectionProps {
  onSuccess?: () => void;
  onError?: (e: unknown) => void;
}

export const useCreateEditCollection = ({ onSuccess, onError }: useCreateCollectionProps) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ collection, action }: { collection: Collection; action: "create" | "edit" }) => {
      await createCollectionMethod(collection, action === "create" ? true : false);
      await queryClient.invalidateQueries(["collections"]);
      await queryClient.refetchQueries(["collections"]);
    },
    {
      onSuccess,
      onError,
    },
  );
};
