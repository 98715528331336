import { z } from "zod";

import { Message } from "../../types/Message";

export type DDSMessageType = "chat_room" | "ping" | "connection_metadata" | "admin_chat_room";

export type DDSChatRoomType = "message_delete" | "message_restore";

export type DDSAdminChatRoomType = "message";

export interface Ping {
  token: string;
}

export interface ConnectionMetadata {
  connection_id: string;
}

export interface ChatRoom {
  type: DDSChatRoomType;
  room_uuid: string;
  message_delete: Message;
  message_restore: Message;
}

export interface AdminChatRoom {
  type: DDSAdminChatRoomType;
  room_uuid: string;
  message: Message;
}

export interface DdsEdgeMessage {
  type: DDSMessageType;
  ping: Ping;
  connection_metadata: ConnectionMetadata;
  chat_room: ChatRoom;
  admin_chat_room: AdminChatRoom;
  notification: Notification;
}

export type NewAdminMessagesListener = (message: Message) => unknown;
export type DeletedMessageListener = (message: Message) => unknown;
export type RestoredMessageListener = (message: Message) => unknown;

export interface Listener {
  id: string;
  roomId?: string;
  newAdminMessagesListener?: NewAdminMessagesListener;
  deletedMessageListener?: DeletedMessageListener;
  restoredMessageListener?: RestoredMessageListener;
}

export interface ReconnectListener {
  id: string;
  callback: () => void;
}

export interface SessionStateListener {
  id: string;
  callback: (value: boolean) => void;
}

export interface Unsubscribe {
  unsubscribe: () => boolean;
}

export interface SubscribeArgs {
  roomId: string;
  newAdminMessagesListener?: NewAdminMessagesListener;
  deletedMessageListener?: DeletedMessageListener;
  restoredMessageListener?: RestoredMessageListener;
}

export type Namespaces = `/chat-room/${string}` | `/admin-chat-room/${string}`;

export const AppSyncMessageSchema = z.object({
  event: z.string().transform((value) => JSON.parse(value) as DdsEdgeMessage),
});
