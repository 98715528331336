import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateStream } from "../../../../api/streams";
import { Stream } from "../../../../types/Streams";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

const useUpdateStream = (streamId: string) => {
  const queryClient = useQueryClient();
  const { showFailureAlert } = useAlert();

  const result = useMutation({
    mutationKey: ["streams", streamId],
    mutationFn: (payload: Stream) => updateStream(streamId, payload),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["streams"] });
    },
    onError() {
      showFailureAlert(`Something went wrong while updating the stream with id ${streamId}`);
    },
  });

  return result;
};

export default useUpdateStream;
