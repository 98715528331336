import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import useFetchTeams from "@/routes/Tournaments/hooks/teams/useFetchTeams";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useMemo, useState } from "react";
import { useGetTournaments } from "@/api/tournaments/getTournaments/useGetTournaments";
import { AddTransaction } from "./schemas";
import { Input } from "@/components/ui/input";
import useFetchCategories from "../../hooks/useFetchCategories";
import { Button } from "@/components/ui/button";
import { DateTime } from "luxon";
import { DatePicker } from "@/components/DatePicker";
import useCreateTransaction from "../../hooks/useCreateTransaction";
import { CircleMinus } from "lucide-react";
import { Combobox } from "@/components/Combobox";
import { generateImageUrl } from "@/helpers/images/generateImageUrl";

interface AddTransactionModalProps {
  open: boolean;
  onClose: () => void;
  selectedCategory?: string;
  selectedSubCategory?: string;
}

const emptyTransactionEntry = {
  teamId: null,
  teamName: undefined,
  points: 0,
  description: null,
};

export const AddTransactionModal = ({
  open,
  onClose,
  selectedCategory,
  selectedSubCategory,
}: AddTransactionModalProps) => {
  const baseTransaction: AddTransaction = {
    gameId: "rl",
    category: selectedCategory,
    subCategory: selectedSubCategory,
    tournamentId: "non-tournament-transaction", // string placeholder for unknown tournamentid for selector
    transactionDate: DateTime.now().toFormat("yyyy-MM-dd"),
    entries: [emptyTransactionEntry],
  };
  const [teamsToAdd, setTeamsToAdd] = useState(1);
  const [transaction, setTransaction] = useState<AddTransaction>(baseTransaction);
  const { data: teams } = useFetchTeams("rl");
  const { data: tournaments } = useGetTournaments({ game: "rl" });

  const { data: categoryRecords } = useFetchCategories("rl");
  const availableSubCategories = useMemo(() => {
    return (
      categoryRecords?.find((cat: { category: string }) => cat.category === transaction.category)?.subCategories ?? []
    );
  }, [categoryRecords, transaction.category]);

  const { mutate: createTransaction } = useCreateTransaction({
    category: transaction.category || "",
    subCategory: transaction.subCategory || "",
    onSuccess: () => {
      onClose();
    },
    onError: () => {
      onClose();
    },
  });

  const teamOptions = [...(teams ?? [])]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((team) => ({
      value: team.id,
      label: team.name,
      image: generateImageUrl("teams", team.id),
    }));

  const readyToSubmit = useMemo(() => {
    return (
      transaction.category &&
      transaction.subCategory &&
      transaction.transactionDate &&
      transaction.entries.length > 0 &&
      transaction.entries.every((entry) => entry.points && entry.teamId)
    );
  }, [transaction.category, transaction.entries, transaction.subCategory, transaction.transactionDate]);

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
        setTransaction(baseTransaction);
      }}
    >
      <DialogContent aria-describedby="add-transaction">
        <DialogTitle>Add Transaction</DialogTitle>
        <div className="grid grid-cols-2 gap-4">
          {/* category */}
          <Select value={transaction.category} onValueChange={(e) => setTransaction({ ...transaction, category: e })}>
            <SelectTrigger>
              <SelectValue placeholder="Category" />
            </SelectTrigger>
            <SelectContent>
              {categoryRecords?.map((category) => (
                <SelectItem key={category.category} value={category.category}>
                  {category.category}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {/* Sub Category */}
          <Select
            value={transaction.subCategory}
            onValueChange={(e) => setTransaction({ ...transaction, subCategory: e })}
            disabled={!transaction.category}
          >
            <SelectTrigger>
              <SelectValue placeholder="Sub-Category" />
            </SelectTrigger>
            <SelectContent>
              {availableSubCategories.map((subCategory) => (
                <SelectItem key={subCategory} value={subCategory}>
                  {subCategory}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {/* tournament */}
          <Select
            value={undefined}
            onValueChange={(e) => {
              setTransaction({
                ...transaction,
                tournamentId: e,
                transactionDate: DateTime.fromJSDate(
                  tournaments?.find((t) => t.id === e)?.endDate || new Date(),
                ).toFormat("yyyy-MM-dd"),
              });
            }}
            disabled={!tournaments?.length}
          >
            <SelectTrigger>
              <SelectValue placeholder="Tournament" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={"non-tournament-transaction"}>Non-Tournament Transaction</SelectItem>
              {tournaments?.map((tournament) => (
                <SelectItem key={tournament.id} value={tournament.id}>
                  {tournament.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {/* Date */}
          <DatePicker
            value={DateTime.fromFormat(transaction.transactionDate, "yyyy-MM-dd").toJSDate()}
            onChange={(e) => {
              if (e) {
                setTransaction({ ...transaction, transactionDate: DateTime.fromJSDate(e).toFormat("yyyy-MM-dd") });
              }
            }}
          />
        </div>
        <div className="flex max-h-96 flex-col gap-2 overflow-y-auto">
          {transaction.entries.map((entry, index) => (
            <div key={index} className="flex flex-row items-center justify-center gap-2">
              <div className="font-style-label-2">{index + 1}</div>
              <Combobox
                className="w-96"
                value={entry.teamId || ""}
                options={teamOptions}
                onChange={(value) =>
                  setTransaction({
                    ...transaction,
                    entries: transaction.entries.map((t, i) =>
                      i === index ? { ...t, teamId: value, teamName: teams?.find((t) => t.id === value)?.name } : t,
                    ),
                  })
                }
              />
              <Input
                className="w-20"
                type="number"
                placeholder="Points"
                onChange={(e) =>
                  setTransaction({
                    ...transaction,
                    entries: transaction.entries.map((t, i) =>
                      i === index ? { ...t, points: parseInt(e.target.value) } : t,
                    ),
                  })
                }
              />
              <Input
                placeholder="Description"
                onChange={(e) =>
                  setTransaction({
                    ...transaction,
                    entries: transaction.entries.map((t, i) =>
                      i === index ? { ...t, description: e.target.value } : t,
                    ),
                  })
                }
              />
              <Button
                variant="ghost"
                className="w-4"
                onClick={() =>
                  setTransaction({ ...transaction, entries: transaction.entries.filter((_, i) => i !== index) })
                }
              >
                <CircleMinus />
              </Button>
            </div>
          ))}
        </div>
        <div className="mx-2 h-px w-full bg-gray-300" />
        <div className="flex flex-row gap-2 justify-self-center">
          <Input
            type="number"
            className="w-20"
            defaultValue={teamsToAdd}
            onChange={(e) => setTeamsToAdd(parseInt(e.target.value))}
          />
          <Button
            variant="outline"
            onClick={() => {
              setTransaction({
                ...transaction,
                entries: transaction.entries.concat(...Array.from({ length: teamsToAdd }, () => emptyTransactionEntry)),
              });
              setTeamsToAdd(1);
            }}
            className="w-40"
          >
            Add Multiple Teams
          </Button>
          <div className="mx-2 h-full w-px bg-gray-300" />
          <Button
            variant="outline"
            onClick={() => setTransaction({ ...transaction, entries: [...transaction.entries, emptyTransactionEntry] })}
            className="w-32"
          >
            Add 1 Team
          </Button>
        </div>
        <Button onClick={() => createTransaction({ transaction })} disabled={!readyToSubmit}>
          Submit
        </Button>
      </DialogContent>
    </Dialog>
  );
};
