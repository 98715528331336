import { Button, Typography } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import useUpdatePickems from "../../hooks/useUpdatePickems";
import usePickems from "../../hooks/usePickems";
import { useCallback, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";

interface SyncEndTimeButtonProps {
  pickemsId: string;
}

type State = "idle" | "loading" | "success" | "error";

const SyncEndTimeButton = ({ pickemsId }: SyncEndTimeButtonProps) => {
  const [state, setState] = useState<State>("idle");
  const { pickems } = usePickems(pickemsId);

  const onSuccess = useCallback(() => setState("success"), []);

  const onError = useCallback(() => setState("error"), []);

  const { mutate: mutateUpdatePickems } = useUpdatePickems({
    pickemsId,
    onSuccess,
    onError,
  });

  const syncEndTime = useCallback(() => {
    if (!pickems?.endTime) {
      return;
    }

    setState("loading");

    mutateUpdatePickems({
      pickemsId,
      endTime: pickems?.endTime,
    });
  }, [mutateUpdatePickems, pickems?.endTime, pickemsId]);

  if (state === "success") {
    return (
      <>
        <DoneIcon color="success" sx={{ mx: "0.5rem" }} />
        <Typography variant="body2" fontWeight="bold" color="success.main" component="span">
          Synced with backend!
        </Typography>
      </>
    );
  }

  if (state === "error") {
    return (
      <>
        <ErrorIcon color="error" sx={{ mx: "0.5rem" }} />
        <Typography variant="body2" fontWeight="bold" color="error.main" component="span">
          Error syncing. Check console.
        </Typography>
      </>
    );
  }

  return (
    <Button startIcon={<SyncIcon />} onClick={syncEndTime} disabled={state === "loading"} color="primary">
      Sync end time with backend
    </Button>
  );
};

export default SyncEndTimeButton;
