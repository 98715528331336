import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import z from "zod";

// OpenDota league/teams
const TeamResponseSchema = z.array(
  z.object({
    team_id: z.number(),
    rating: z.number(),
    wins: z.number(),
    losses: z.number(),
    last_match_time: z.number(),
    name: z.string(),
    tag: z.string(),
    logo_url: z.string().nullable().optional(),
  }),
);

export type Team = z.infer<typeof TeamResponseSchema>;

export const useTeams = (leagueId?: string) => {
  return useQuery(
    ["teams", leagueId],
    async () => {
      const response = await axios.get(`https://api.opendota.com/api/leagues/${leagueId}/teams`);

      const data = TeamResponseSchema.parse(response.data);

      return data;
    },
    {
      enabled: !!leagueId,
    },
  );
};
