import { invalidateCache } from "../../../../api/assets";
import { uploadTeamLogo } from "./uploadTeamLogo";

export const updateTeamLogo = async ({
  image,
  teamId,
  onSuccess,
  onError,
}: {
  image: File;
  teamId: string;
  onSuccess: () => void;
  onError: (message: string) => void;
}) => {
  if (!image || !teamId) return;
  try {
    await uploadTeamLogo({ image, teamId });
  } catch {
    onError("Failed to upload team logo");
    return;
  }

  try {
    await invalidateCache("teams", teamId);
  } catch {
    onError("Failed to invalidate cache");
    return;
  }

  onSuccess();
};
