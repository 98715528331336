import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Stop as StopIcon,
  AssistWalker as AssistWalkerIcon,
  Circle as CircleIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import PlayIcon from "@mui/icons-material/PlayArrow";
import { removeAdditionalVideo } from "../../../../../../api/broadcasts";
import { startStream, stopStream } from "../../../../../../api/streams";
import { Stream } from "../../../../../../types/Streams";
import { isAxiosError } from "axios";
import { usePatron } from "../../../../../../providers/PatronProvider/hooks/usePatron";

export function AdditionalVideoRow({
  broadcastId,
  name,
  videoId,
  stream,
  offset,
  setSelectedVideo,
  reload,
}: {
  broadcastId: string;
  name: string;
  videoId: string;
  stream: Stream;
  offset: number;
  setSelectedVideo: (videoId: string) => void;
  reload: () => unknown;
}) {
  const { patron } = usePatron();
  const startVideoStream = () => {
    void startStream(videoId).finally(() => reload());
  };

  const stopVideoStream = () => {
    void stopStream(videoId).finally(() => reload());
  };

  const editVideoStream = () => {
    setSelectedVideo(videoId);
  };

  const removeVideo = () => {
    removeAdditionalVideo({ patron, broadcastId, videoId })
      .catch((error) => {
        if (
          isAxiosError<{ code: string }>(error) &&
          error.response?.data &&
          "code" in error.response.data &&
          error.response?.data.code === "remove-video-not-idle"
        ) {
          // eslint-disable-next-line no-console
          console.error("Unable to remove video, it needs to be stopped first!");
        }
      })
      .finally(() => {
        reload();
      });
  };

  return (
    <div className="flex">
      <div className="flex-1 rounded-small border border-solid border-neutral-50 p-4">
        <div className="flex items-center">
          <div className="flex-1">
            <span>
              {name} ({stream.state}) <br />
              Offset: {offset}
            </span>
          </div>
          <div className="flex-none">
            <span className="mx-4 text-neutral-50">{videoId}</span>
          </div>
          {stream.state === "RUNNING" ? (
            <CircleIcon htmlColor="red" className="flex-none animate-pulse" />
          ) : (
            <CircleIcon htmlColor="gray" className="flex-none" />
          )}
        </div>
      </div>
      <div className="flex flex-none">
        {stream.state === "RUNNING" ? (
          <IconButton onClick={stopVideoStream}>
            <StopIcon />
          </IconButton>
        ) : stream.state === "STOPPED" ? (
          <IconButton onClick={startVideoStream}>
            <PlayIcon />
          </IconButton>
        ) : (
          <IconButton disabled={true}>
            <AssistWalkerIcon />
          </IconButton>
        )}
      </div>
      <div className="flex flex-none">
        <IconButton onClick={editVideoStream}>
          <EditIcon />
        </IconButton>
        {stream.state === "STOPPED" && (
          <IconButton onClick={removeVideo}>
            <DeleteIcon />
          </IconButton>
        )}
        {stream.state !== "STOPPED" && (
          <Tooltip title="Stream must be stopped before removing">
            <span className="inline-flex">
              <IconButton disabled={true}>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
