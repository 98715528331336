import { invalidateCache, invalidateMetadataFile, sendDeleteAvatarRequest } from "../../../../api/assets";
import { Patron } from "../../../../providers/PatronProvider/types";

interface DeleteAvatarProps {
  avatarId: string;
  sendSuccessMessage: (message: string) => void;
  sendErrorMessage: (message: string) => void;
  patron?: Patron;
}

export const deleteAvatar = async ({ avatarId, sendSuccessMessage, sendErrorMessage, patron }: DeleteAvatarProps) => {
  try {
    await sendDeleteAvatarRequest({ avatarId, patron });
  } catch (error) {
    sendErrorMessage("Couldn't delete avatar");
    throw error;
  }
  try {
    await invalidateCache("avatars", `${avatarId}_2d`, patron);
    await invalidateCache("avatars", `${avatarId}_3d`, patron);
  } catch (error) {
    sendErrorMessage("Couldn't invalidate cache for images");
    throw error;
  }
  try {
    await invalidateMetadataFile("avatars", patron);
  } catch (error) {
    sendErrorMessage("Couldn't invalidate cache for metadata");
    throw error;
  }

  sendSuccessMessage("Successfully delete avatar");
};
