import axios from "axios";
import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";
import { TemplateSchema } from "./schemas/TemplateSchema";
import { z } from "zod";
import { TaskSchema } from "./schemas/TaskSchema";

export const queryKeys = {
  all: ["emulations"] as const,
  templates: () => [...queryKeys.all, "templates"] as const,
  tasks: () => [...queryKeys.all, "tasks"] as const,
};

const emulationsApi = axios.create({
  baseURL: `${baseApiURL}/v1/emulation/`,
});
emulationsApi.interceptors.request.use(authInterceptor);

export async function getEmulationTemplates() {
  const { data } = await emulationsApi.get<unknown>("/templates");
  return TemplateSchema.array().parse(data);
}

export async function getEmulationTasks() {
  const { data } = await emulationsApi.get<unknown>("/tasks/today");

  const tasks = TaskSchema.array().parse(data);

  const sortedByDateDescending = tasks.sort((a, b) => {
    return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
  });

  return sortedByDateDescending;
}

interface StartEmulationTask {
  taskName: string;
  templateIds: string[];
  streamProvider?: string;
}

export async function startEmulationTask({ taskName, templateIds, streamProvider = "gcp" }: StartEmulationTask) {
  const { data } = await emulationsApi.post<unknown>("/tasks", {
    taskName,
    templateIds,
    streamProvider,
  });
  return z
    .object({
      taskId: z.string(),
    })
    .parse(data);
}

export async function stopEmulationTask(taskId: string) {
  await emulationsApi.post(`/tasks/${taskId}/stop`);
}
