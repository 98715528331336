import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Stack, Typography } from "@mui/material";
import useGetSchedules from "../../hooks/useGetSchedules";
import classNames from "classnames";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { useEffect, useState } from "react";
import { Location, Schedule, ScheduleRequest } from "../../../../api/csguessr/types";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import AddIcon from "@mui/icons-material/Add";
import { assetsURL } from "../../../../config";
import { CreateScheduleLocationDialog } from "../CreateScheduleLocationDialog";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ConfirmationModal } from "../../../../shared/components/ConfirmationModal";
import useDeleteSchedule from "../../hooks/useDeleteSchedule";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { DateTime } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

export const ScheduleLocation = ({ locations }: { locations?: Location[] }) => {
  const { data: schedulesData, isLoading, refetch: refetchSchedules } = useGetSchedules();
  const [selectedDate, setSelectedDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [selectedLocations, setSelectedLocations] = useState<Schedule[]>([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [isDeleteScheduleConfirmationOpen, setIsDeleteScheduleConfirmationOpen] = useState(false);
  const [deleteScheduleRequest, setDeleteScheduleRequest] = useState<ScheduleRequest>();
  const alert = useAlert();

  const { mutate: deleteSchedule } = useDeleteSchedule({
    schedule: deleteScheduleRequest,
    onSuccess: () => {
      void refetchSchedules();
      alert.showSuccessAlert("Schedule deleted successfully");
    },
    onError: () => {
      alert.showFailureAlert("Failed to delete schedule");
    },
  });

  useEffect(() => {
    if (!selectedDate || !schedulesData) return;
    setSelectedLocations(schedulesData?.filter((schedule) => schedule.date === selectedDate) ?? []);
  }, [selectedDate, schedulesData]);

  function customDay(props: PickersDayProps<DateTime>) {
    const { day, ...other } = props;
    const currentDay = DateTime.now().toFormat("yyyy-LL-dd");
    const schedules: Schedule[] = schedulesData
      ? schedulesData?.filter((schedule) => schedule.date === day.toFormat("yyyy-LL-dd"))
      : [];

    return (
      <PickersDay
        {...other}
        day={day}
        className={classNames("bg-opacity-50", {
          "border-2 border-solid border-purple-400": currentDay === selectedDate,
          "bg-red-300": schedules?.length === 0,
          "bg-green-300": schedules?.length === 5,
          "bg-yellow-300": schedules?.length && (schedules?.length < 5 || schedules?.length > 5),
        })}
      />
    );
  }

  return (
    <Stack flex="true" direction="row" justifyContent="start" className="mt-2" gap={5}>
      <Stack>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Stack>
            <DateCalendar
              onChange={(newDate) =>
                // eslint-disable-next-line
                setSelectedDate(newDate?.toFormat("yyyy-LL-dd") ?? "")
              }
              className="rounded border"
              renderLoading={() => <DayCalendarSkeleton />}
              loading={isLoading}
              slots={{
                day: customDay,
              }}
            />
            <Stack direction="column" justifyContent="center" gap={1} marginTop={1}>
              <div className="flex items-center">
                <span className="mr-1 block size-2 rounded-full bg-red-300"></span>
                <Typography variant="caption">No Locations assigned!</Typography>
              </div>
              <div className="flex items-center">
                <span className="mr-1 block size-2 rounded-full bg-yellow-300"></span>
                <Typography variant="caption">Has less or more than 5 locations!</Typography>
              </div>
              <div className="flex items-center">
                <span className="mr-1 block size-2 rounded-full bg-green-300"></span>
                <Typography variant="caption">Has 5 locations.</Typography>
              </div>
            </Stack>
          </Stack>
        </LocalizationProvider>
      </Stack>
      <Stack>
        <div>
          <Typography variant="h5" className="mb-2">
            {selectedDate} / SCHEDULED LOCATIONS
          </Typography>
          <h1></h1>
          <div className="flex max-w-lg flex-row flex-wrap gap-4">
            {selectedLocations?.map((schedule) => (
              <div key={schedule.id}>
                <div className="relative size-36">
                  <img src={`${assetsURL}/images/${schedule.image}`} alt="Image" />
                  <TrashIcon
                    className="absolute right-4 top-4 size-5 cursor-pointer hover:text-red-400"
                    onClick={() => {
                      setDeleteScheduleRequest({
                        locationId: schedule.id,
                        date: schedule.date,
                      });
                      setIsDeleteScheduleConfirmationOpen(true);
                    }}
                  />
                </div>
              </div>
            ))}
            {selectedLocations?.length < 5 && (
              <div
                className="flex size-36 cursor-pointer items-center justify-center border border-gray-500"
                onClick={() => setCreateDialogOpen(true)}
              >
                <AddIcon />
              </div>
            )}
          </div>
        </div>
      </Stack>
      {createDialogOpen && (
        <CreateScheduleLocationDialog
          open={createDialogOpen}
          closeDialog={() => setCreateDialogOpen(false)}
          locations={locations}
          date={selectedDate}
          schedules={schedulesData}
        />
      )}
      {isDeleteScheduleConfirmationOpen && (
        <ConfirmationModal
          isOpen={isDeleteScheduleConfirmationOpen}
          onClose={() => {
            setIsDeleteScheduleConfirmationOpen(false);
            setDeleteScheduleRequest(undefined);
          }}
          title="Delete Schedule?"
          description="Are you sure that you want to delete this schedule?"
          onConfirm={() => {
            setIsDeleteScheduleConfirmationOpen(false);
            deleteSchedule();
          }}
        />
      )}
    </Stack>
  );
};
