import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { ZodError } from "zod";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import { DetailsFields } from "./components/DetailsField";
import { useCreateEditCollection } from "./components/DetailsField/hooks/useCreateEditCollection";
import { useMemo } from "react";
import usePointsCollections from "../../usePointsCollections/usePointsCollections";
import { CollectionSchema } from "../../../../../api/points/types/Collection";

export const CreateEditCollectionModal = () => {
  const { modalOpen, closeModal, modalState, collection } = usePointsCollections();

  const alert = useAlert();

  const { mutate: createEditCollection } = useCreateEditCollection({
    onSuccess: () => {
      alert.showSuccessAlert("Collection created successfully");
      closeModal();
    },
    onError: () => {
      alert.showFailureAlert("Collection failed to create");
    },
  });

  const submit = () => {
    const data = CollectionSchema.safeParse(collection);

    if (!data.success) {
      const err = data.error as ZodError<typeof CollectionSchema>;
      alert.showFailureAlert(`${err.errors[0].path.join()} ${err.errors[0].message}`);

      return;
    }

    createEditCollection({
      collection: data.data,
      action: modalState,
    });
    return;
  };

  const isDisabled = useMemo(() => {
    const res = CollectionSchema.safeParse(collection);

    if (!res.success) return true;
  }, [collection]);

  return (
    <Dialog open={modalOpen} onClose={closeModal} fullWidth maxWidth="md">
      <DialogTitle>{modalState === "edit" ? "Edit" : "Create"} Collection</DialogTitle>
      <Divider />
      <DialogContent>
        <DetailsFields />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={submit} disabled={isDisabled}>
          Submit
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
