import classNames from "classnames";
import { Trash2 } from "lucide-react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

export function ImageUpload({
  onImageSet,
  imageUrl,
  file,
}: {
  onImageSet: (image: File | undefined) => void;
  imageUrl?: string;
  file?: File;
}) {
  const [imageLoadingError, setImageLoadingError] = useState(false);
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".svg", ".png"],
    },
    maxSize: 10 * 1024 * 1024, // 10MB
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (!acceptedFiles.length) {
        return;
      }
      onImageSet(acceptedFiles[0]);
    },
  });

  const image = (file && URL.createObjectURL(file)) || imageUrl || "";

  return (
    <div className="flex flex-col gap-4">
      <div
        {...getRootProps()}
        className={classNames(
          "flex h-32 cursor-pointer place-content-center items-center rounded-lg border border-dashed text-center text-xs transition-colors",
          {
            "border-blue-500 bg-blue-50": isDragActive,
            "border-zinc-300/30 hover:border-gray-400": !isDragActive,
          },
        )}
      >
        <input {...getInputProps()} />
        <div className="space-y-2">
          <div className="flex flex-col items-center gap-2 text-gray-600">
            <div className="">
              <img
                src={image}
                alt="Tournament"
                className={classNames("mx-auto h-20 w-auto rounded-lg object-cover", {
                  hidden: imageLoadingError,
                })}
                onError={() => setImageLoadingError(true)}
                onLoad={() => setImageLoadingError(false)}
              />
            </div>
            <div className="flex items-center gap-2 pb-2">
              <span>Click to upload or drag and drop </span>
              {file && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onImageSet(undefined);
                  }}
                >
                  <Trash2 className="size-4" />
                </button>
              )}
            </div>
          </div>

          {fileRejections.length > 0 && (
            <div className="mt-2 text-red-500">
              {fileRejections.map(({ file, errors }) => (
                <div key={file.name}>
                  {errors.map((error) => (
                    <span key={error.code}>{error.message}</span>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
