import { PickemsFinals, PickemsGroups, PickemsShowdown } from "../../../types/pickems/Pickems";
import { PickemsQuestion } from "../../../types/pickems/PickemsQuestion";

export const getShowdownQuestions = (pickems: PickemsShowdown): PickemsQuestion[] => {
  return [
    pickems.pickemsShowdownQuestionsModule.aFinalistsQuestion.question,
    pickems.pickemsShowdownQuestionsModule.bFinalistsQuestion.question,
    pickems.pickemsShowdownQuestionsModule.aChampionQuestion.question,
    pickems.pickemsShowdownQuestionsModule.bChampionQuestion.question,
  ];
};

export const getFinalsQuestions = (pickems: PickemsFinals): PickemsQuestion[] => {
  return [
    pickems.pickemsFinalsQuestionsModule.groupA20Question.question,
    pickems.pickemsFinalsQuestionsModule.groupA02Question.question,
    pickems.pickemsFinalsQuestionsModule.groupB20Question.question,
    pickems.pickemsFinalsQuestionsModule.groupB02Question.question,
    pickems.pickemsFinalsQuestionsModule.finalistsQuestion.question,
    pickems.pickemsFinalsQuestionsModule.championQuestion.question,
  ];
};

export const getGroupsQuestions = (pickems: PickemsGroups): PickemsQuestion[] => {
  return [
    pickems.pickemsGroupsQuestionsModule.groupAFinalistsQuestion.question,
    pickems.pickemsGroupsQuestionsModule.groupBFinalistsQuestion.question,
    pickems.pickemsGroupsQuestionsModule.groupCFinalistsQuestion.question,
    pickems.pickemsGroupsQuestionsModule.groupDFinalistsQuestion.question,
    pickems.pickemsGroupsQuestionsModule.finalistsQuestion.question,
  ];
};
