import classNames from "classnames";

interface SpinnerProps {
  className?: string;
}

export function Loader({ className }: SpinnerProps) {
  return (
    <div className={classNames("my-25 w-50 relative h-[3px] overflow-hidden rounded-full bg-zinc-600", className)}>
      <div className="absolute -left-1/2 top-0 h-full w-2/5 rounded-full bg-zinc-900 animate-loaderLine" />
    </div>
  );
}
