import { Message } from "../../../types/Message";
import { Patron } from "../../../providers/PatronProvider/types";

export const truncateUsernamePatronPrefix = (message: Message, patron?: Patron) => {
  if (!patron || patron === "blasttv") {
    return;
  }

  message.messageMeta.user.username = message.messageMeta.user.username.replace(new RegExp(`^${patron}_`, "i"), "");
};
