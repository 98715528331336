import { VisibilityOff } from "@mui/icons-material";
import { Autocomplete, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, TextField } from "@mui/material";
import Avatar from "../../../../../../types/users/avatars";
import { AvatarImage } from "../../../../../UserProfile/components/EditAvatarForm/styled";

export default function AvatarAutocomplete({
  avatars,
  chosenAvatar,
  setChosenAvatar,
  disabled,
}: {
  avatars?: Avatar[];
  chosenAvatar: Avatar | null;
  setChosenAvatar: (avatar: Avatar | null) => void;
  disabled?: boolean;
}) {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={avatars ?? []}
      sx={{ flexGrow: 4 }}
      value={chosenAvatar}
      disabled={disabled}
      onChange={(_, newValue) => {
        setChosenAvatar(newValue);
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => {
        return (
          <>
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,

                startAdornment: chosenAvatar?.url ? (
                  <AvatarImage src={chosenAvatar?.url} />
                ) : (
                  params.InputProps.startAdornment
                ),
              }}
              label="Pick an avatar"
            />
          </>
        );
      }}
      renderOption={(_, option) => {
        if (option?.url) {
          return (
            <>
              <MenuItem onClick={() => setChosenAvatar(option)} key={option.id}>
                <ListItemAvatar>
                  <AvatarImage src={option.url} />
                </ListItemAvatar>
                <ListItemText primary={option.name} />
                <ListItemIcon>
                  {option.hidden ? (
                    <div title="Hidden">
                      <VisibilityOff />{" "}
                    </div>
                  ) : null}
                </ListItemIcon>
              </MenuItem>
            </>
          );
        }
        return null;
      }}
    />
  );
}
