import {
  GridColDef,
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridRowModesModel,
  GridRowModel,
  GridRowId,
  GridRowEditStopReasons,
  GridEventListener,
} from "@mui/x-data-grid";
import { Cancel, Edit, Save } from "@mui/icons-material";
import { PlayerStat } from "../../../../api/statistics/schemas";
import { useMemo, useState } from "react";
import { Player } from "../../../../api/tournaments/schemas/players";

interface RoundStatsTableProps {
  stats: PlayerStat[];
  detailedPlayers: (Player | undefined)[];
  updateRow: (row: PlayerStat) => void;
}

export const RoundStatsTable = ({ stats, detailedPlayers, updateRow }: RoundStatsTableProps) => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState<
    (PlayerStat & {
      id: string;
      playerId: string;
      playerNickname: string;
    })[]
  >(
    stats.map((s, index) => ({
      ...s,
      id: `round-${index + 1}-steam-${s.playerSteamId}`,
      playerId: "",
      playerNickname: "",
    })),
  );

  const players = useMemo<
    (PlayerStat & {
      id: string;
      playerId: string;
      playerNickname: string;
    })[]
  >(() => {
    const players: (PlayerStat & {
      id: string;
      playerId: string;
      playerNickname: string;
    })[] = [];

    stats.forEach((s) => {
      const player = detailedPlayers.find((p) => p && p.ingameId === s.playerSteamId);
      if (!player) return;

      players.push({
        id: s.playerSteamId,
        playerId: player.id,
        playerNickname: player.nickname,
        ...s,
      });
    });

    return players;
  }, [stats, detailedPlayers]);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (
    newRow: GridRowModel<
      PlayerStat & {
        id: string;
        playerId: string;
        playerNickname: string;
      }
    >,
  ) => {
    const updatedRow = { ...newRow };
    updateRow(updatedRow);
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "playerId",
      headerName: "Id",
      minWidth: 300,
      editable: false,
    },
    {
      field: "playerNickname",
      headerName: "Nickname",
      flex: 1,
      editable: false,
    },
    {
      field: "playerSteamId",
      headerName: "Steam id",
      flex: 1,
      editable: false,
    },
    {
      field: "kills",
      type: "number",
      headerName: "Kills",
      editable: true,
      flex: 1,
    },
    {
      field: "assists",
      type: "number",
      headerName: "Assists",
      editable: true,
      flex: 1,
    },
    {
      field: "deaths",
      type: "number",
      headerName: "Deaths",
      editable: true,
      flex: 1,
    },
    {
      field: "firstKills",
      type: "number",
      headerName: "First kills",
      editable: true,
      flex: 1,
    },
    {
      field: "headshots",
      type: "number",
      headerName: "Headshots",
      editable: true,
      flex: 1,
    },
    {
      field: "suicides",
      type: "number",
      headerName: "Suicides",
      editable: true,
      flex: 1,
    },
    {
      field: "effectiveDamageDealt",
      type: "number",
      headerName: "Damage dealt",
      editable: true,
      flex: 1,
    },
    {
      field: "effectiveDamageTaken",
      type: "number",
      headerName: "Damage taken",
      editable: true,
      flex: 1,
    },
    {
      field: "utilityDamage",
      type: "number",
      headerName: "Utility damage",
      editable: true,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      width: 100,
      cellClassName: "edit",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={"actionSave"}
              icon={<Save />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={"actionCancel"}
              icon={<Cancel />}
              label="Cancel"
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={"actionEdit"}
            icon={<Edit />}
            label="Edit"
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div style={{ width: "90%" }}>
      <DataGrid
        rows={players}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slotProps={{
          toolbar: {
            // @ts-expect-error adding type check to build, wont fix
            setRows,
            setRowModesModel,
          },
        }}
      />
    </div>
  );
};
