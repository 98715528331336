import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import useDeleteMap from "../../../../../../../hooks/maps/useDeleteMap";

interface DeleteMapConfirmModalProps {
  isOpen: boolean;
  close: () => void;
  mapId: string;
  matchId?: string;
}

export const DeleteMapConfirmModal = ({ isOpen, close, mapId, matchId }: DeleteMapConfirmModalProps) => {
  const { mutate: deleteMap } = useDeleteMap({
    matchId,
  });

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && close()}>
      <DialogContent className="max-w-[600px] p-6">
        <DialogHeader>
          <DialogTitle>Delete Map</DialogTitle>
        </DialogHeader>

        <p className="text-sm text-muted-foreground">Are you sure you want to delete this Map?</p>

        <DialogFooter className="mt-4">
          <Button variant="outline" onClick={close}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              deleteMap({ mapId });
              close();
            }}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
