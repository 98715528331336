import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { PlusCircle, Trash2, ExternalLink, Save } from "lucide-react";
import { Button } from "@/components/ui/button";
import { DateTime } from "luxon";
import { useState } from "react";
import { useAddTournamentTicketing } from "@/api/tournaments/addTournamentTicketing/useAddTournamentTicketing";
import { usePatchTournament } from "@/api/tournaments/hooks/usePatchTournament";
import { DateInput } from "@/components/DateInput";
import useAlert from "../../../../../../providers/AlertProvider/hooks/useAlert";
import useFetchTournamentById from "../../../../hooks/useFetchTournamentById";
import { Loader } from "@/shared/components/Loader";
import { Label } from "@/components/ui/label";

// Ticketing items have been limited to 1 for now
const ticketingItemsLimit = 1;

export const TournamentTicketingAccordion = ({ tournamentId }: { tournamentId: string }) => {
  const { data: tournament } = useFetchTournamentById(tournamentId);
  const [isAddTicketingModalOpen, setIsAddTicketingModalOpen] = useState(false);
  const [deletingTicketing, setDeletingTicketing] = useState<
    | {
        title: string;
        startDate: string;
        endDate: string;
        cta: {
          url: string;
        };
      }
    | undefined
  >(undefined);

  return (
    <Accordion type="single" collapsible>
      <AccordionItem value="ticketing">
        <AccordionTrigger>
          <div className="flex items-center gap-4">
            <span className="font-bold">Ticketing</span>
            <span className="text-sm text-muted-foreground">
              {tournament?.metadata?.ticketing?.items.length ? "Assigned" : "Not assigned"}
            </span>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Title</TableHead>
                <TableHead>Start Date</TableHead>
                <TableHead>End Date</TableHead>
                <TableHead>CTA Link</TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {tournament?.metadata?.ticketing?.items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{DateTime.fromISO(item.startDate).toLocaleString(DateTime.DATE_MED)}</TableCell>
                  <TableCell>{DateTime.fromISO(item.endDate).toLocaleString(DateTime.DATE_MED)}</TableCell>
                  <TableCell>
                    <a
                      href={item.cta.url}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center hover:underline"
                    >
                      {item.cta.url}
                      <ExternalLink className="ml-2 size-4" />
                    </a>
                  </TableCell>
                  <TableCell>
                    <Button variant="destructive" size="icon" onClick={() => setDeletingTicketing(item)}>
                      <Trash2 className="size-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {(tournament?.metadata?.ticketing?.items.length ?? 0) < ticketingItemsLimit && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Button variant="outline" size="sm" onClick={() => setIsAddTicketingModalOpen(true)}>
                      <PlusCircle className="mr-2 size-4" />
                      Add a ticketing module
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </AccordionContent>
        {isAddTicketingModalOpen && (
          <AddTicketingModal
            isOpen={isAddTicketingModalOpen}
            onClose={() => setIsAddTicketingModalOpen(false)}
            tournamentId={tournamentId}
          />
        )}
        {deletingTicketing && (
          <DeleteTicketingModal
            isOpen={!!deletingTicketing}
            onClose={() => setDeletingTicketing(undefined)}
            ticketing={deletingTicketing}
            tournamentId={tournamentId}
          />
        )}
      </AccordionItem>
    </Accordion>
  );
};

const DeleteTicketingModal = ({
  isOpen,
  onClose,
  ticketing,
  tournamentId,
}: {
  isOpen: boolean;
  onClose: () => void;
  ticketing: { title: string; startDate: string; endDate: string; cta: { url: string } };
  tournamentId: string;
}) => {
  const alert = useAlert();
  const { data: tournament } = useFetchTournamentById(tournamentId);
  const { mutate: patchTournament, isLoading: isPatchingTournament } = usePatchTournament(tournamentId);

  const handleDeleteTicketing = () => {
    if (!tournament?.metadata?.ticketing?.items) return; // Nothing to delete

    patchTournament(
      {
        metadata: {
          ...tournament.metadata,
          ticketing: {
            items: tournament?.metadata?.ticketing?.items.filter((item) => item.title !== ticketing.title),
          },
        },
      },
      {
        onError: () => {
          alert.showFailureAlert("Failed to delete ticketing module");
        },
        onSuccess: () => {
          alert.showSuccessAlert("Ticketing module deleted successfully");
          onClose();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete Ticketing Module?</DialogTitle>
          <DialogDescription>
            <p>Title: {ticketing.title}</p>
            <p>Start Date: {ticketing.startDate}</p>
            <p>End Date: {ticketing.endDate}</p>
            <p>CTA Link: {ticketing.cta.url}</p>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={handleDeleteTicketing} disabled={isPatchingTournament}>
            {isPatchingTournament ? (
              <>
                <Loader className="w-40" />
                Deleting...
              </>
            ) : (
              <>
                <Trash2 className="mr-2 size-4" />
                Delete
              </>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const AddTicketingModal = ({
  isOpen,
  onClose,
  tournamentId,
}: {
  isOpen: boolean;
  onClose: () => void;
  tournamentId: string;
}) => {
  const { mutate: addTournamentTicketing, isLoading: isAddingTicketing } = useAddTournamentTicketing({
    tournamentId,
  });
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(() => DateTime.now().plus({ days: 1 }).startOf("day"));
  const [endDate, setEndDate] = useState(() => DateTime.now().plus({ days: 7 }).startOf("day"));
  const [ctaLink, setCtaLink] = useState("");

  const alert = useAlert();

  const handleAddTicketing = () => {
    if (!title || !startDate || !endDate || !ctaLink) return;

    addTournamentTicketing(
      { title, startDate: startDate.toUTC().toISO(), endDate: endDate.toUTC().toISO(), cta: { url: ctaLink } },
      {
        onError: () => {
          alert.showFailureAlert("Failed to add ticketing module");
        },
        onSuccess: () => {
          alert.showSuccessAlert("Ticketing module added successfully");
          onClose();
        },
      },
    );
  };

  const isFormValid = title && startDate && endDate && ctaLink;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add a Ticketing Module</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Label>Title</Label>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} required />
          <Label>CTA Link</Label>
          <Input value={ctaLink} onChange={(e) => setCtaLink(e.target.value)} required />
          <div className="grid grid-cols-2 gap-4">
            <DateInput
              label="Start Date"
              value={startDate}
              onChange={(e) => {
                if (!e) return;
                setStartDate(e);
              }}
            />
            <DateInput
              label="End Date"
              value={endDate}
              onChange={(e) => {
                if (!e) return;
                setEndDate(e);
              }}
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleAddTicketing} disabled={!isFormValid || isAddingTicketing}>
            {isAddingTicketing ? (
              <>
                <Loader className="w-40" />
                Adding...
              </>
            ) : (
              <>
                <Save className="mr-2 size-4" />
                Add
              </>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
