import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { usePatron } from "../hooks/usePatron";
import { Patron, PatronTypes } from "../types";
import { useOnClickOutside } from "usehooks-ts";

export function PatronBreadcrumbItem() {
  const ref = useRef<HTMLAnchorElement>(null);
  const { patron, setPatron } = usePatron();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded((prevValue) => !prevValue);
  };

  const changePatron = (patron: Patron) => {
    setPatron(patron);
  };

  useOnClickOutside(ref, () => {
    setIsExpanded(false);
  });

  return (
    <Link to="#" className="text-black dark:text-white" onClick={toggleExpanded} ref={ref}>
      <span className="underline">{patron}</span>
      <ArrowDropDownIcon />
      {isExpanded && (
        <div className="absolute">
          <ul>
            {PatronTypes.map((patron) => (
              <li key={patron} onClick={() => changePatron(patron)}>
                {patron}
              </li>
            ))}
          </ul>
        </div>
      )}
    </Link>
  );
}
