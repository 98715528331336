import { z } from "zod";
import { ChannelSourceSchema } from "./ChannelSchema";

export const EventSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  isActive: z.boolean().optional(),
  twitchGameId: z.string().optional(),
  gameName: z.string().optional(),
  lastGeneratedReport: z.string().optional(),
  keywords: z.string().array().default([]),
  tournamentId: z.string().optional(),
  circuitId: z.string().optional(),
  channels: z
    .object({
      id: z.string(),
      channelSource: ChannelSourceSchema.optional(),
      channelName: z.string().optional(),
      isPrimary: z.boolean().optional(),
      scoutedAt: z.string().optional(),
      excluded: z.boolean().optional(),
    })
    .array(),
  airtimeMinutesByDate: z.record(z.number()).default({}),
  totalAirtimeMinutes: z.number().default(0),
  airtimeIntervalsByDate: z
    .record(
      z.object({
        start: z.string(),
        end: z.string(),
      }),
    )
    .default({}),
});

export type Event = z.infer<typeof EventSchema>;

export const UpdateEventParametersSchema = EventSchema.omit({ id: true }).partial().extend({
  gameName: z.string().nullish(), // "null" to remove
  twitchGameId: z.string().nullish(), // "null" to remove
  circuitId: z.string().nullish(), // "null" to remove
  tournamentId: z.string().nullish(), // "null" to remove
});

export type UpdateEventParameters = z.infer<typeof UpdateEventParametersSchema>;
