import { MultiSelect } from "@/components/multi-select";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { generateImageUrl } from "@/helpers/images/generateImageUrl";
import { X } from "lucide-react";
import { useMemo, useState } from "react";
import { Prize } from "../../../../../../api/tournaments/schemas/prizes";
import useAlert from "../../../../../../providers/AlertProvider/hooks/useAlert";
import useFetchTeams from "../../../../hooks/teams/useFetchTeams";
import useCreateTournamentPrizes from "../hooks/useCreateTournamentPrize";
import useDeleteTournamentPrize from "../hooks/useDeleteTournamentPrize";
import { Loader } from "@/shared/components/Loader";

export const AddEditPrizeModal = ({
  editingPrizes,
  isOpen,
  onClose,
  gameId,
  tournamentId,
}: {
  editingPrizes?: Prize[]; // Grouped prizes for multiple teams having the same prize
  isOpen: boolean;
  onClose: () => void;
  gameId: string | undefined;
  tournamentId: string;
}) => {
  const sampleEditingPrize = editingPrizes?.[0]; // All fields are the same except for the team for grouped prizes
  const { data: teams } = useFetchTeams(gameId);
  const [prize, setPrize] = useState<string>(sampleEditingPrize?.prize ?? "");
  const [subPrize, setSubPrize] = useState<string[]>(sampleEditingPrize?.subPrize ?? []);
  const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>(
    editingPrizes?.filter((p) => !!p.teamId).map((p) => p.teamId ?? "") ?? [],
  );
  const [singleSubPrize, setSingleSubPrize] = useState<string>("");
  const [positionStart, setPositionStart] = useState<number>(sampleEditingPrize?.positionStart ?? 0);
  const [positionEnd, setPositionEnd] = useState<number>(sampleEditingPrize?.positionEnd ?? 0);

  const { mutateAsync: createPrize, isLoading: isCreatingPrize } = useCreateTournamentPrizes();
  const { mutateAsync: deletePrize, isLoading: isDeletingPrize } = useDeleteTournamentPrize();
  const alert = useAlert();

  const onSubmit = async () => {
    let hasAnyError = false;

    const subPrizes = subPrize;
    if (singleSubPrize.length > 0) {
      subPrizes.push(singleSubPrize);
    }

    if (editingPrizes?.length) {
      // Delete all editing prizes and create them with new values
      for (const prize of editingPrizes) {
        await deletePrize(prize.id, {
          onError: () => {
            hasAnyError = true;

            void alert.showFailureAlert("Failed to delete existing prizes before creating new ones");
          },
        });
      }
    }

    if (selectedTeamIds.length === 0) {
      await createPrize(
        {
          tournamentId: tournamentId,
          teamId: undefined,
          prize,
          subPrize: subPrizes,
          positionStart,
          positionEnd,
        },
        {
          onError: () => {
            hasAnyError = true;

            void alert.showFailureAlert("Failed to create prize");
          },
        },
      );
    } else {
      for (const teamId of selectedTeamIds) {
        await createPrize(
          {
            tournamentId: tournamentId,
            teamId,
            prize,
            subPrize,
            positionStart,
            positionEnd,
          },
          {
            onError: () => {
              hasAnyError = true;

              void alert.showFailureAlert("Failed to create prize");
            },
          },
        );
      }
    }

    if (!hasAnyError) {
      onClose();
    }
  };

  const teamsOptions = useMemo(() => {
    return (
      teams
        ?.map((team) => ({
          value: team.id,
          label: team.name,
          image: generateImageUrl("teams", team.id),
        }))
        .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)) ?? []
    );
  }, [teams]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{editingPrizes?.length ? "Edit Prize" : "Add Prize"}</DialogTitle>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <div className="grid gap-2">
            <Label htmlFor="prize">Prize</Label>
            <Input id="prize" placeholder="$50,000" value={prize} onChange={(e) => setPrize(e.target.value)} />
          </div>

          <div className="grid gap-2">
            <Label>Teams</Label>
            <MultiSelect options={teamsOptions} value={selectedTeamIds} onValueChange={setSelectedTeamIds} />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="grid gap-2">
              <Label htmlFor="positionStart">Start Position</Label>
              <Input
                id="positionStart"
                type="number"
                placeholder="1"
                value={positionStart}
                onChange={(e) => setPositionStart(parseInt(e.target.value))}
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="positionEnd">End Position</Label>
              <Input
                id="positionEnd"
                type="number"
                placeholder="3"
                value={positionEnd}
                onChange={(e) => setPositionEnd(parseInt(e.target.value))}
              />
            </div>
          </div>

          <div className="grid gap-2">
            <Label>Sub Prizes</Label>
            <Input
              placeholder="Add sub prize and press enter..."
              value={singleSubPrize}
              onChange={(e) => setSingleSubPrize(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSubPrize([...subPrize, e.currentTarget.value]);
                  e.currentTarget.value = "";
                }
              }}
            />
            <div className="flex flex-wrap gap-1">
              {subPrize.map((prize, index) => (
                <Badge key={index} variant="secondary" className="flex items-center gap-1">
                  {prize}
                  <X
                    className="size-3 cursor-pointer"
                    onClick={() => {
                      setSubPrize(subPrize.filter((_, i) => i !== index));
                    }}
                  />
                </Badge>
              ))}
            </div>
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => void onSubmit()} disabled={isCreatingPrize || isDeletingPrize}>
            {isCreatingPrize || isDeletingPrize ? <Loader className="mx-auto w-40" /> : "Save"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
