import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { Map, MapNoTeam, MapUpdateRequestSchema } from "../../../../api/tournaments/schemas/maps";
import { updateMap, updateTeamMapScore, updateDotaMap } from "../../../../api/tournaments";

export default function usePatchMap(gameId: string) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["map", gameId],
    mutationFn: async ({ map }: { map: Map }) => {
      let updatedMap: MapNoTeam | undefined = undefined;
      if (gameId === "dota") {
        try {
          updatedMap = await updateDotaMap(MapUpdateRequestSchema.parse(map));
        } catch (error) {
          alert.showFailureAlert("Error While updating dota map");
          throw error;
        }
      } else {
        try {
          updatedMap = await updateMap(MapUpdateRequestSchema.parse(map));
        } catch (error) {
          alert.showFailureAlert("Error While updating map");
          throw error;
        }

        if (updatedMap && map.teams) {
          for (const team of map.teams) {
            try {
              await updateTeamMapScore(map.id, team.team.id, team.score);
            } catch {
              alert.showFailureAlert(`Error updating scores for team ${team.team.name}`);
            }
          }
        }
      }
      alert.showSuccessAlert("Successfully updated map");

      await queryClient.refetchQueries(["matchMaps", map.matchId]);
    },
  });
}
