import { useInfiniteQuery } from "@tanstack/react-query";
import type { QueryFunctionContext, QueryKey } from "@tanstack/react-query";
import { GetChatMessagesOptions } from "../../../../../api/chat/options";
import { getChatMessages } from "../../../../../api/chat";

export interface ChatMessagesOptions extends GetChatMessagesOptions {
  enabled?: boolean;
  keepPreviousData?: boolean;
}

const useFetchChatMessages = ({ enabled = true, keepPreviousData = true, ...options }: ChatMessagesOptions) => {
  return useInfiniteQuery({
    queryKey: ["messages", options],
    queryFn: ({ pageParam }: QueryFunctionContext<QueryKey, string>) =>
      getChatMessages({
        ...options,
        pageToken: pageParam,
      }),
    getNextPageParam: (response) => {
      return response.nextPageToken;
    },
    enabled,
    keepPreviousData,
  });
};

export default useFetchChatMessages;
