import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateBroadcastPriority } from "../../../../../api/broadcasts";
import { Patron } from "../../../../../providers/PatronProvider/types";
import { Broadcast } from "../../../../../types/Broadcasts";

const useUpdateBroadcastPriority = () => {
  const queryClient = useQueryClient();
  const result = useMutation({
    mutationKey: ["updatePriority"],
    mutationFn: ({ patron, payload }: { patron: Patron; payload: Broadcast[] }) =>
      updateBroadcastPriority({ patron, payload }),
    onError: () => {
      void queryClient.invalidateQueries(["broadcasts"]);
    },
  });

  return result;
};
export default useUpdateBroadcastPriority;
