import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  // SelectChangeEvent,
  TextField,
} from "@mui/material";
import { EditCommunityMatch } from "../../../../../../../api/community-tournaments/schemas/matches";
import { TournamentEnrolledTeam } from "../../../../../../../api/community-tournaments/schemas/tournament";

export const TeamInfo = ({
  team,
  tournamentTeams,
  teamLabel,
  match,
  updateMatch,
}: {
  team?: EditCommunityMatch["teams"][number];
  tournamentTeams?: TournamentEnrolledTeam[];
  teamLabel: string;
  match: EditCommunityMatch | undefined;
  updateMatch: (match: EditCommunityMatch) => void;
}) => {
  const teamIndex = teamLabel === "Team A" ? 10 : 20;
  const teamOptions = [...(tournamentTeams ?? [])].sort((a, b) => a.name.localeCompare(b.name));

  const handleTeamChange = (e: SelectChangeEvent<string>) => {
    const newTeam = tournamentTeams?.find((team) => team.id === e.target.value);
    if (match) {
      if (team) {
        //team is attached to match
        updateMatch({
          ...match,
          teams: match.teams.map((team) => {
            if (team?.teamIndex === teamIndex) {
              return {
                ...team,
                score: team.score,
                team: newTeam,
              };
            } else {
              return team;
            }
          }),
        });
      } else {
        //has to add team to match
        updateMatch({
          ...match,
          teams: match.teams.concat([
            {
              teamIndex,
              team: newTeam,
              score: 0,
              prevTeamId: undefined,
            },
          ]),
        });
      }
    }
  };

  return (
    <>
      {match && (
        <div className="flex flex-col gap-5">
          <FormControl>
            <InputLabel id={`${teamLabel}-select-label`}>{teamLabel}</InputLabel>
            <Select
              labelId={`${teamLabel}-select-label`}
              id={`${teamLabel}-select`}
              label={teamLabel}
              className="w-60"
              value={team?.team?.id}
              onChange={(e) => {
                handleTeamChange(e);
              }}
            >
              <MenuItem value={undefined}>No Team</MenuItem>
              {teamOptions.map((team) => (
                <MenuItem value={team.id} key={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type="number"
            label={`${teamLabel} Score`}
            defaultValue={team?.score}
            InputProps={{
              inputProps: { min: 0 },
            }}
            disabled={team?.team?.id === undefined}
            onChange={(e) => {
              const score = parseInt(e.target.value);
              if (score >= 0) {
                updateMatch({
                  ...match,
                  teams: match.teams.map((team) => {
                    if (team?.teamIndex === teamIndex) {
                      return {
                        ...team,
                        score,
                      };
                    } else {
                      return team;
                    }
                  }),
                });
              }
            }}
          />
        </div>
      )}
    </>
  );
};
