import { useCallback, useEffect, useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { useGetPointsProducts } from "../../../../../hooks/useGetPointsProducts";
import { ProductAction } from "../../../../../api/points/schemas";
import {
  getPurchases,
  approvePurchase as approvePurchaseApi,
  rejectPurchase as rejectPurchaseApi,
} from "../../../../../api/points";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";

// Note: This hook assumes that we have only one exemplar of that product action.
// If we're going to have more, hook should be recreated
const useReviewPendingProduct = ({
  actionToFollow,
  startDate,
}: {
  actionToFollow: ProductAction;
  startDate: string;
}) => {
  const { showFailureAlert } = useAlert();
  const { data: productsResponse } = useGetPointsProducts();

  const { mutate: approvePurchaseAction, isLoading: isApproving } = useMutation({
    mutationFn: (id: string) => approvePurchaseApi(id),
    onError(error: AxiosError<{ message: string }>) {
      if (error.response?.data?.message) {
        showFailureAlert(error.response?.data?.message);
      } else {
        showFailureAlert("Error occurred while reviewing");
      }
    },
  });
  const { mutate: rejectPurchaseAction, isLoading: isRejecting } = useMutation({
    mutationFn: ({ id, refund }: { id: string; refund?: boolean }) => rejectPurchaseApi(id, { refund }),
    onError(error: AxiosError<{ message: string }>) {
      if (error.response?.data?.message) {
        showFailureAlert(error.response?.data?.message);
      } else {
        showFailureAlert("Error occurred while reviewing");
      }
    },
  });

  const product = useMemo(() => {
    if (!productsResponse) {
      return;
    }

    return productsResponse.products.find((product) => product.action === actionToFollow);
  }, [actionToFollow, productsResponse]);

  const { data: pendingPurchasesResponse, refetch: refetchPurchases } = useQuery({
    queryKey: ["purchases", actionToFollow, "pending"],
    queryFn: () =>
      getPurchases({
        productId: product?.id as string,
        state: "pending",
        startDate,
      }),
    enabled: !!product,
  });

  const pendingPurchase = pendingPurchasesResponse?.purchases[0];
  const isSendingReviewing = isApproving || isRejecting;

  const approvePurchase = useCallback(() => {
    if (!pendingPurchase) {
      return;
    }

    approvePurchaseAction(pendingPurchase.id);
  }, [approvePurchaseAction, pendingPurchase]);

  const rejectPurchase = useCallback(
    ({ refund }: { refund?: boolean }) => {
      if (!pendingPurchase) {
        return;
      }

      rejectPurchaseAction({
        id: pendingPurchase.id,
        refund,
      });
    },
    [pendingPurchase, rejectPurchaseAction],
  );

  useEffect(() => {
    if (!product) {
      return;
    }

    const interval = setInterval(() => void refetchPurchases(), 15_000);

    return () => {
      clearInterval(interval);
    };
  }, [product, refetchPurchases]);

  useEffect(() => {
    if (isSendingReviewing) {
      return;
    }

    void refetchPurchases();
  }, [isSendingReviewing, refetchPurchases]);

  return {
    pendingPurchase,
    approvePurchase,
    rejectPurchase,
    isSendingReviewing,
  };
};

export default useReviewPendingProduct;
