import { useMutation } from "@tanstack/react-query";
import { uploadImage } from "./uploadImage";
import { toast } from "@/hooks/use-toast";
import { invalidateCache } from "..";

export function useUploadTournamentImage() {
  return useMutation({
    mutationFn: async ({ tournamentId, image }: { tournamentId: string; image: File }) => {
      const success = await uploadImage({
        image,
        folder: "tournament",
        filename: tournamentId,
      });
      await invalidateCache("tournament", tournamentId);

      return success;
    },
    onSuccess: () => {
      toast({
        title: "Tournament image uploaded successfully",
      });
    },
    onError: () => {
      toast({
        title: "Failed to upload tournament image",
      });
    },
  });
}
