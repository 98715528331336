import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ExternalLink } from "lucide-react";

export const TournamentCustomModulesAccordion = ({
  headerCtaText,
  headerCtaUrl,
  setHeaderCtaText,
  setHeaderCtaUrl,
}: {
  headerCtaText: string;
  headerCtaUrl: string;
  setHeaderCtaText: (text: string) => void;
  setHeaderCtaUrl: (url: string) => void;
}) => {
  return (
    <Accordion type="single" collapsible>
      <AccordionItem value="custom-cta">
        <AccordionTrigger>
          <span className="font-bold">Custom CTA</span>
        </AccordionTrigger>
        <AccordionContent className="flex flex-col gap-4">
          <div className="space-y-2">
            <Label htmlFor="cta-text">CTA Text</Label>
            <Input
              id="cta-text"
              value={headerCtaText}
              onChange={(e) => setHeaderCtaText(e.target.value)}
              required
              className="max-w-md"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="cta-url">CTA URL</Label>
            <div className="flex items-center gap-2">
              <Input
                id="cta-url"
                value={headerCtaUrl}
                onChange={(e) => setHeaderCtaUrl(e.target.value)}
                required
                className="max-w-md"
                type="url"
              />
              <Button asChild variant="outline" disabled={!headerCtaUrl}>
                <a href={headerCtaUrl} target="_blank" rel="noopener noreferrer">
                  <ExternalLink className="mr-2 size-4" />
                  Preview link
                </a>
              </Button>
            </div>
          </div>

          <p className="text-sm text-muted-foreground">
            This CTA will be displayed in the header of the tournament page. It will be displayed as a button that links
            to the URL you provide.
            <br />
            Remove the values and save in order to remove the CTA.
          </p>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
