import { AddCircle, ExpandMore, RemoveCircle } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { ClipCuratorEvent, ClipCuratorEventWithMatchDetails } from "@/api/video/schemas";
import { assetsURL } from "@/config";
import { DetailedPlayer } from "@/api/tournaments/schemas/players";
import { useMemo, useState } from "react";
import { Tournament } from "@/api/tournaments/schemas/tournaments";
import useFetchMatch from "../../Broadcasts/BroadcastDetails/hooks/useFetchMatch";
import { events } from "../../Broadcasts/TimelineEvents/data/events";

const ConditionalRenderAccordian = ({ videoClipSrc }: { videoClipSrc: string }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <Accordion onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
        className="flex-row-reverse"
      >
        Preview
      </AccordionSummary>
      <AccordionDetails>{expanded && <video controls src={videoClipSrc} />}</AccordionDetails>
    </Accordion>
  );
};

interface ClipCardProps {
  addClip: (clip: ClipCuratorEventWithMatchDetails) => void;
  removeClip: (clip: ClipCuratorEventWithMatchDetails) => void;
  selectedClips: ClipCuratorEventWithMatchDetails[];
  videoClip: ClipCuratorEvent;
  players: DetailedPlayer[];
  tournaments: Tournament[];
}

const ClipCard = ({ videoClip, tournaments, selectedClips, removeClip, addClip, players }: ClipCardProps) => {
  const matchData = useFetchMatch([videoClip.matchId])[0].data;
  const sortedMaps = matchData?.maps.sort((a, b) =>
    a.scheduledAt! < b.scheduledAt! ? -1 : a.scheduledAt! > b.scheduledAt! ? 1 : 0,
  );
  const clipWithMatchDetials = useMemo(() => {
    return {
      ...videoClip,
      mapName: sortedMaps?.[videoClip.mapNumber - 1].name,
      tournamentName: tournaments?.find((t) => t.id === videoClip.tournamentId)?.name,
      matchName: matchData?.name,
      playerName: players.find((p) => p.id === videoClip.playerIds[0])?.nickname,
    };
  }, [videoClip, sortedMaps, tournaments, matchData?.name, players]);

  return (
    <Card variant="elevation" className="w-full p-2">
      <div className="mb-4 flex w-full flex-row items-center justify-between gap-2">
        <div className="flex flex-row gap-1">
          <img height={30} width={30} src={`${assetsURL}/images/teams/${videoClip.teamIds[0]}`} />
          <Typography>vs</Typography>
          <img height={30} width={30} src={`${assetsURL}/images/teams/${videoClip.teamIds[1]}`} />
        </div>
        <TextField
          label="Tournament"
          disabled
          variant="standard"
          value={tournaments?.find((t) => t.id === videoClip.tournamentId)?.name}
        />
        {selectedClips.map((selectedClip) => selectedClip.id).includes(videoClip.id) ? (
          <IconButton className="size-10 p-0" onClick={() => removeClip(clipWithMatchDetials)}>
            <RemoveCircle />
          </IconButton>
        ) : (
          <IconButton className="size-10" onClick={() => addClip(clipWithMatchDetials)}>
            <AddCircle />
          </IconButton>
        )}
      </div>
      <div className="mb-4 flex flex-row justify-start gap-2">
        <TextField
          label="Game Event"
          className=""
          disabled
          variant="standard"
          value={events.find((e) => e.id === videoClip.eventType)?.name}
        />
        <TextField label="Match Name" className="" disabled variant="standard" value={matchData?.name || "unknown"} />
      </div>
      <div className="flex flex-row justify-start gap-2">
        <TextField
          label="Map"
          className="w-40"
          disabled
          variant="standard"
          value={`(${videoClip.mapNumber}) ${matchData?.maps?.[videoClip.mapNumber - 1]?.name || "unknown"}`}
        />
        <TextField label="Round" className="w-20" disabled variant="standard" value={videoClip.roundNumber} />
        <div className="flex flex-col">
          {videoClip.playerIds[0] && (
            <TextField
              label="Player"
              disabled
              variant="standard"
              value={players?.find((player) => player.id === videoClip.playerIds[0])?.nickname}
            />
          )}
        </div>
      </div>
      <div className="flex flex-row">
        <ConditionalRenderAccordian videoClipSrc={videoClip.url} />
      </div>
    </Card>
  );
};

interface ClipGridProps {
  addClip: (clip: ClipCuratorEventWithMatchDetails) => void;
  removeClip: (clip: ClipCuratorEventWithMatchDetails) => void;
  selectedClips: ClipCuratorEventWithMatchDetails[];
  videoClips: ClipCuratorEvent[];
  players: DetailedPlayer[];
  tournaments: Tournament[];
}

export const ClipGrid = ({ addClip, removeClip, selectedClips, videoClips, players, tournaments }: ClipGridProps) => {
  const dedupedVideoClips = videoClips.filter(
    (videoClip, index, self) => index === self.findIndex((t) => t.id === videoClip.id),
  );

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-2">
        <Button
          disabled={dedupedVideoClips.length === 0}
          onClick={() => {
            dedupedVideoClips.forEach((videoClip) => {
              const clipWithMatchDetials = {
                ...videoClip,
                mapName: "unknown",
                tournamentName: tournaments?.find((t) => t.id === videoClip.tournamentId)?.name,
                matchName: "unknown",
                playerName: players.find((p) => p.id === videoClip.playerIds[0])?.nickname,
              };
              if (!selectedClips.map((selectedClip) => selectedClip.id).includes(videoClip.id)) {
                addClip(clipWithMatchDetials);
              }
            });
          }}
        >
          Add filtered
        </Button>
        <Button
          disabled={dedupedVideoClips.length === 0}
          onClick={() => {
            dedupedVideoClips.forEach((videoClip) => {
              const clipWithMatchDetials = {
                ...videoClip,
                mapName: "unknown",
                tournamentName: tournaments?.find((t) => t.id === videoClip.tournamentId)?.name,
                matchName: "unknown",
                playerName: players.find((p) => p.id === videoClip.playerIds[0])?.nickname,
              };
              if (selectedClips.map((selectedClip) => selectedClip.id).includes(videoClip.id)) {
                removeClip(clipWithMatchDetials);
              }
            });
          }}
        >
          Deselect filtered
        </Button>
      </div>
      <Grid container spacing={2}>
        {dedupedVideoClips.map((videoClip, index) => (
          <Grid key={`timeline-event-${index}`} item xs={5}>
            <div className="flex w-full flex-row items-center gap-1">
              <ClipCard
                addClip={addClip}
                removeClip={removeClip}
                selectedClips={selectedClips}
                players={players}
                tournaments={tournaments}
                videoClip={videoClip}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
