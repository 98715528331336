import { Combobox } from "@/components/Combobox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { generateImageUrl } from "@/helpers/images/generateImageUrl";
import classNames from "classnames";
import { useState } from "react";
import { EditTeamMatch, MatchByTournamentIdResponse } from "../../../../../../../api/tournaments/schemas/matches";
import { Team } from "../../../../../../../api/tournaments/schemas/teams";
import { assetsURL } from "../../../../../../../config";

export const TeamInfo = ({
  team,
  teams,
  teamLabel,
  match,
  updateMatch,
}: {
  team?: EditTeamMatch;
  teams?: Team[];
  teamLabel: string;
  match: MatchByTournamentIdResponse | undefined;
  updateMatch: (match: MatchByTournamentIdResponse) => void;
}) => {
  const [isImageError, setIsImageError] = useState(false);

  const teamIndex = teamLabel === "Team A" ? 10 : 20;
  const teamOptions = [...(teams ?? [])]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((team) => ({
      value: team.id,
      label: team.name,
      image: generateImageUrl("teams", team.id),
    }));

  const handleTeamChange = (teamId: string) => {
    const newTeam = teams?.find((team) => team.id === teamId);
    if (match) {
      if (!newTeam && teamId === undefined) {
        const previousTeam = match.teams.find((item) => item.team?.id === team?.team?.id);

        updateMatch({
          ...match,
          teams: [...match.teams.filter((item) => item.team?.id !== previousTeam?.team?.id)],
        });

        return;
      }
      if (!newTeam) return;

      if (team) {
        //team is attached to match
        updateMatch({
          ...match,
          teams: match.teams.map((team) => {
            if (team.teamIndex === teamIndex) {
              return {
                ...team,
                team: newTeam,
              };
            } else {
              return team;
            }
          }),
        });
        return;
      }

      //has to add team to match
      updateMatch({
        ...match,
        teams: match.teams.concat([
          {
            teamIndex,
            team: newTeam,
            score: 0,
          },
        ]),
      });
    }
  };

  return (
    <>
      {match && (
        <div className="flex flex-col gap-5">
          <img
            className={classNames("h-52 w-52", {
              hidden: isImageError,
            })}
            onError={() => setIsImageError(true)}
            onLoad={() => setIsImageError(false)}
            src={
              team?.team?.id
                ? `${assetsURL}/images/teams/${
                    team?.team.id
                  }?height=234&width=234&format=auto&cache_busting=${Date.now()}`
                : ""
            }
          />
          {isImageError && (
            <div className="flex size-52 items-center justify-center rounded-md border border-zinc-800/60 bg-zinc-800/30"></div>
          )}
          <Combobox value={team?.team?.id ?? ""} options={teamOptions} onChange={(value) => handleTeamChange(value)} />

          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor={`${teamLabel}-score`}>{`${teamLabel} Score`}</Label>
            <Input
              id={`${teamLabel}-score`}
              type="number"
              defaultValue={team?.score ?? ""}
              min={0}
              disabled={team?.team?.id === undefined}
              onChange={(e) => {
                const score = parseInt(e.target.value);
                if (score >= 0) {
                  updateMatch({
                    ...match,
                    teams: match.teams.map((team) => {
                      if (team.teamIndex === teamIndex) {
                        return {
                          ...team,
                          score,
                        };
                      } else {
                        return team;
                      }
                    }),
                  });
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
