import { useEffect } from "react";
import { Button } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import { DateTime } from "luxon";

import PageContainer from "../../../components/Layout/PageContainer";
import { ChatSettings, LiveChatContent } from "./components";

import useReviewPendingProduct from "./hooks/useReviewPendingProduct";
import useFetchTriggerPollById from "../../ChatTriggers/TriggerPolls/hooks/useFetchTriggerPollById";

import useFetchBroadcastById from "../../../hooks/useFetchBroadcastById";
import { usePatron } from "../../../providers/PatronProvider/hooks/usePatron";

import { pubSubClient } from "@/common/PubSubClient";

function ChatModeration() {
  const { patron } = usePatron();
  const { id } = useParams();
  const { data: broadcast } = useFetchBroadcastById({
    id: id as string,
    patron,
  });
  const { chatId } = broadcast || {};
  const { pendingPurchase, approvePurchase, rejectPurchase, isSendingReviewing } = useReviewPendingProduct({
    actionToFollow: "GRANT_HEAD_TO_HEAD_POLL",
    startDate: DateTime.now().minus({ day: 1 }).startOf("day").toUTC().toISO(),
  });
  const { data: poll } = useFetchTriggerPollById(
    pendingPurchase?.details?.pollId as string,
    !!pendingPurchase?.details?.pollId,
  );

  useEffect(() => {
    void pubSubClient.open();

    return () => {
      pubSubClient.close();
    };
  }, []);

  return (
    <PageContainer>
      <ChatSettings chatId={chatId} />
      <div className="relative flex h-[calc(100vh-10rem)] min-h-0 grow gap-2 overflow-hidden">
        {pendingPurchase && poll && (
          <div className="absolute left-1.5 top-10 z-10 flex w-96 flex-col gap-4 bg-neutral-800 p-2">
            <div className="text-neutral-50 font-style-b4-body-copy">
              <span className="font-bold text-white">{poll.customPollDetails?.redeemedByUsername}</span>
              &nbsp;has redeemed:&nbsp;
              <span className="font-bold text-white">{pendingPurchase.productName}</span>
            </div>
            <div className="font-style-b4-body-copy">
              Would be shown in <Link to={`/broadcasts/${patron}/${poll.broadcastId}`}>broadcast</Link>
            </div>
            <div className="font-style-b4-body-copy">
              Message: {poll.options[0].value} vs {poll.options[1].value}
            </div>
            <div className="flex items-center gap-8">
              <Button
                className="font-style-b4-body-copy"
                color="success"
                variant="text"
                disabled={isSendingReviewing}
                onClick={approvePurchase}
              >
                Approve
              </Button>
              <Button
                className="font-style-b4-body-copy"
                color="error"
                variant="text"
                disabled={isSendingReviewing}
                onClick={() => rejectPurchase({ refund: false })}
              >
                Deny
              </Button>
              <Button
                className="font-style-b4-body-copy"
                color="warning"
                variant="text"
                disabled={isSendingReviewing}
                onClick={() => rejectPurchase({ refund: true })}
              >
                Deny + Refund
              </Button>
            </div>
          </div>
        )}
        {chatId && <LiveChatContent chatId={chatId} />}
      </div>
    </PageContainer>
  );
}

export default ChatModeration;
