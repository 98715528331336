import authInterceptor from "@/api/authInterceptor";
import { baseApiURL } from "@/config";
import axios from "axios";
import {
  RankingTransaction,
  RankingTransactionList,
  RankingTransactionListSchema,
  RankingTransactionSchema,
  RLRankingLeaderboard,
  RLRankingLeaderboardSchema,
} from "./schemas";

export const tournamentsApi = axios.create({
  baseURL: `${baseApiURL}/v2`,
});

tournamentsApi.interceptors.request.use(authInterceptor);

export const getRLRankingLeaderboard = async (
  category: string,
  subCategory?: string,
): Promise<RLRankingLeaderboard> => {
  const response = await tournamentsApi.get<RLRankingLeaderboard>(`/games/rl/leaderboards/${category}/${subCategory}`);
  const { data } = response;
  const leaderboard = RLRankingLeaderboardSchema.parse(data);
  return leaderboard;
};

export const getRankingTransactions = async (gameId: string): Promise<RankingTransactionList> => {
  const response = await tournamentsApi.get<RankingTransactionList>(`/games/${gameId}/ranking/transactions`);
  const { data } = response;
  const transactions = RankingTransactionListSchema.parse(data);
  return transactions;
};

export const createRankingTransaction = async (
  transaction: Omit<RankingTransaction, "createdAt" | "id">,
): Promise<RankingTransaction> => {
  const { data } = await tournamentsApi.post<RankingTransaction>("/games/rl/ranking/transaction", transaction);
  const transactionResponse = RankingTransactionSchema.parse(data);
  return transactionResponse;
};

export const deleteRankingTransaction = async (transactionId: string): Promise<RankingTransaction> => {
  const { data } = await tournamentsApi.delete<RankingTransaction>(`/games/rl/ranking/transaction/${transactionId}`);
  const transactionResponse = RankingTransactionSchema.parse(data);
  return transactionResponse;
};
