import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Map } from "../../../../../../../api/tournaments/schemas/maps";
import { EditTeamMatch } from "../../../../../../../api/tournaments/schemas/matches";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useState } from "react";
import mapPool from "../../../../../shared/components/mapPool";
import usePatchMap from "../../../../../hooks/maps/usePatchMap";
import { DeleteMapConfirmModal } from "./components/DeleteMapConfirmModal";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

interface MatchMapProps {
  matchMap: Map;
  mapNumber: number;
  teamA?: EditTeamMatch;
  teamB?: EditTeamMatch;
  allowedToSave: "yes" | "saveMatch" | "no";
  gameId: string;
}
export const Maps = ({ matchMap, mapNumber, teamA, teamB, allowedToSave, gameId }: MatchMapProps) => {
  const [editMap, setEditMap] = useState<Map>(matchMap);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

  const teamAMap = matchMap.teams?.find((team) => team.team.id === teamA?.team?.id);
  const teamBMap = matchMap.teams?.find((team) => team.team.id === teamB?.team?.id);

  const { mutate: updateMap } = usePatchMap(gameId);

  return (
    <>
      <DeleteMapConfirmModal
        isOpen={deleteConfirm}
        close={() => setDeleteConfirm(false)}
        mapId={editMap.id}
        matchId={editMap.matchId}
      />
      <Accordion type="single" collapsible className="mx-0 my-2 w-full max-w-[1500px] rounded bg-zinc-900">
        <AccordionItem value={matchMap.id}>
          <AccordionTrigger className="px-4">
            <div className="flex w-full justify-start gap-3 no-underline">
              <div className="flex">
                <span className="rounded bg-zinc-800 px-2">
                  {matchMap.name}
                  {matchMap.externalId && ` (${matchMap.externalId})`}
                </span>
              </div>
              <span className="rounded bg-zinc-800 px-2">
                {teamAMap?.score} {teamAMap?.team.name} vs {teamBMap?.team.name} {teamBMap?.score}
              </span>
              <span className="rounded bg-zinc-800 px-2">
                Start: {matchMap.scheduledAt && new Date(matchMap.scheduledAt).toLocaleString()}
              </span>
              <span className="rounded bg-zinc-800 px-2">
                End: {matchMap.endedAt && new Date(matchMap.endedAt).toLocaleString()}
              </span>
            </div>
          </AccordionTrigger>
          <AccordionContent className="border-t-2 border-solid px-4 pt-8">
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-2">
                <Select
                  value={editMap.name}
                  onValueChange={(value) => {
                    setEditMap({ ...editMap, name: value });
                  }}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Select map" />
                  </SelectTrigger>
                  <SelectContent>
                    {mapPool[gameId].map((map) => (
                      <SelectItem key={map} value={map}>
                        {map}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DateTimePicker
                    label="Scheduled Start"
                    ampm={false}
                    format="dd/LL/yyyy HH:mm"
                    defaultValue={
                      editMap.scheduledAt ? DateTime.fromJSDate(editMap.scheduledAt) : DateTime.fromJSDate(new Date())
                    }
                    onChange={(date) => {
                      const scheduledAt = date?.toJSDate();
                      if (scheduledAt) setEditMap({ ...editMap, scheduledAt: scheduledAt });
                    }}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DateTimePicker
                    label="Actual Start"
                    ampm={false}
                    format="dd/LL/yyyy HH:mm"
                    defaultValue={
                      editMap.startedAt ? DateTime.fromJSDate(editMap.startedAt) : DateTime.fromJSDate(new Date())
                    }
                    onChange={(date) => {
                      const startedAt = date?.toJSDate();
                      if (startedAt) setEditMap({ ...editMap, startedAt: startedAt });
                    }}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DateTimePicker
                    label="End Time"
                    ampm={false}
                    format="dd/LL/yyyy HH:mm"
                    defaultValue={
                      editMap.endedAt ? DateTime.fromJSDate(editMap.endedAt) : DateTime.fromJSDate(new Date())
                    }
                    onChange={(date) => {
                      const endedAt = date?.toJSDate();
                      if (endedAt) setEditMap({ ...editMap, endedAt: endedAt });
                    }}
                  />
                </LocalizationProvider>
              </div>

              <div className="flex flex-row gap-2">
                <Input
                  type="number"
                  placeholder={teamAMap ? `Team A (${teamAMap.team.name}) Score` : `Team A Score`}
                  value={editMap.teams?.find((t) => t.team?.id === teamA?.team?.id)?.score || "0"}
                  onChange={(e) => {
                    const score = parseInt(e.target.value);

                    const newTeams =
                      editMap.teams && score >= 0
                        ? editMap.teams.map((team) => {
                            if (team.team.id === teamAMap?.team?.id) {
                              return {
                                ...team,
                                score,
                              };
                            } else return team;
                          })
                        : editMap.teams;
                    setEditMap({
                      ...editMap,
                      teams: newTeams,
                    });
                  }}
                />
                <Input
                  type="number"
                  placeholder={teamBMap ? `Team B (${teamBMap.team.name}) Score` : `Team B Score`}
                  value={editMap.teams?.find((t) => t.team?.id === teamB?.team?.id)?.score || "0"}
                  onChange={(e) => {
                    const score = parseInt(e.target.value);

                    const newTeams =
                      editMap.teams && score >= 0
                        ? editMap.teams.map((team) => {
                            if (team.team.id === teamBMap?.team?.id) {
                              return {
                                ...team,
                                score,
                              };
                            } else return team;
                          })
                        : editMap.teams;
                    setEditMap({
                      ...editMap,
                      teams: newTeams,
                    });
                  }}
                />
                <Input
                  placeholder="External Id"
                  value={editMap.externalId ?? ""}
                  onChange={(e) => {
                    setEditMap({
                      ...editMap,
                      externalId: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="flex justify-end gap-2">
                {gameId === "cs" && (
                  <Button variant="outline" asChild>
                    <Link to={`/livestats/match/${matchMap.matchId}/maps/${mapNumber}`}>View Stats</Link>
                  </Button>
                )}
                <Button variant="outline" onClick={() => setDeleteConfirm(true)}>
                  Delete Map
                </Button>
                <Button
                  variant="default"
                  disabled={allowedToSave !== "yes"}
                  onClick={() => updateMap({ map: editMap })}
                >
                  Save Map
                </Button>
                {allowedToSave === "saveMatch" && (
                  <p className="max-w-[150px] text-center text-orange-400">Teams Changed Save Match</p>
                )}
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
};
