import { useMemo, useState } from "react";

import { useGetRlcsStatTeams } from "@/api/rocketLeagueStatistics/getRLCSStatTeams/hooks/useGetRlcsStatTeams";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Badge } from "@/components/ui/badge";
import { UpsertTeamModal } from "./components/UpsertTeamModal";
import { usePostRlcsStatTeam } from "@/api/rocketLeagueStatistics/postRlcsStatTeam/hooks/usePostRlcsStatTeam";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { PackagePlus, EditIcon, Trash2Icon } from "lucide-react";
import { ConfirmationModal } from "@/shared/components/ConfirmationModal";
import { RocketLeagueStatsTeam } from "@/api/rocketLeagueStatistics/getRLCSStatTeams";
import useFetchTeams from "@/routes/Tournaments/hooks/teams/useFetchTeams";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Team } from "@/api/tournaments/schemas/teams";

export function Groups({ teams, tournamentTeams }: { teams: RocketLeagueStatsTeam[]; tournamentTeams: Team[] }) {
  const [search, setSearch] = useState("");
  const [removeTeamGroupCandidate, setRemoveTeamGroupCandidate] = useState<RocketLeagueStatsTeam | null>(null);
  const [removeTeamCandidate, setRemoveTeamCandidate] = useState<string | null>(null);
  const [selectedEditTeam, setSelectedEditTeam] = useState<RocketLeagueStatsTeam | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredGroups = useMemo(() => {
    return teams.filter((team) => team.teamDisplayName.toLowerCase().includes(search.toLowerCase()));
  }, [teams, search]);

  const { mutate: postRlcsStatTeam } = usePostRlcsStatTeam();

  const totalPages = Math.ceil(filteredGroups.length / itemsPerPage);
  const paginatedGroups = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredGroups.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredGroups, currentPage, itemsPerPage]);

  return (
    <div className="flex w-1/2 flex-col gap-3 rounded border border-gray-300/10 bg-zinc-900/70 p-2">
      {selectedEditTeam && (
        <UpsertTeamModal
          open={!!selectedEditTeam}
          team={selectedEditTeam}
          tournamentTeams={tournamentTeams || []}
          handleClose={() => setSelectedEditTeam(null)}
          handleSubmit={(updatedTeam) => {
            postRlcsStatTeam(updatedTeam);
            setSelectedEditTeam(null);
          }}
        />
      )}
      <div className="flex scroll-m-20 items-center gap-3 text-sm font-semibold tracking-tight">
        <div className="w-full">Assigned Teams</div>
        <Input placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
      <div className="">
        <Accordion type="multiple" className="rounded">
          {paginatedGroups.map((team) => (
            <AccordionItem value={team.id} key={team.id}>
              <AccordionTrigger className="relative rounded bg-zinc-900 px-1 py-3">
                <div className="flex w-full items-center justify-between gap-2 px-4">
                  <div>{team.teamDisplayName}</div>
                  <Button size="icon" className="p-0" onClick={() => setSelectedEditTeam(team)}>
                    <EditIcon className="size-2" />
                  </Button>
                </div>
              </AccordionTrigger>
              <AccordionContent className="flex flex-col gap-2 p-2">
                <div className="flex flex-wrap gap-2 border-t border-gray-300/10 pt-2">
                  {team.teamNames.map((variation) => (
                    <Badge className="flex w-fit gap-2" key={variation}>
                      {variation}{" "}
                      <Trash2Icon
                        onClick={() => {
                          setRemoveTeamCandidate(variation);
                          setRemoveTeamGroupCandidate(team);
                        }}
                        className="size-3 cursor-pointer"
                      />
                    </Badge>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
          {filteredGroups.length === 0 && <div className="h-full text-center text-xs">No groups found</div>}
        </Accordion>
        <Pagination className="mt-4 gap-2">
          <PaginationPrevious
            onClick={() => setCurrentPage(currentPage - 1)}
            className="cursor-pointer"
            isDisabled={currentPage === 1}
          />
          <PaginationContent>
            <PaginationItem>
              {currentPage} out of {totalPages}
            </PaginationItem>
          </PaginationContent>
          <PaginationNext
            onClick={() => setCurrentPage(currentPage + 1)}
            className="cursor-pointer"
            isDisabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
      <ConfirmationModal
        isOpen={!!removeTeamCandidate}
        description="Are you sure you want to remove this team from the group?"
        title="Remove team"
        onClose={() => {
          setRemoveTeamCandidate(null);
        }}
        onConfirm={() => {
          if (!removeTeamCandidate || !removeTeamGroupCandidate) return;
          const updatedTeam = { ...removeTeamGroupCandidate };
          updatedTeam.teamNames = removeTeamGroupCandidate.teamNames.filter(
            (teamName) => teamName !== removeTeamCandidate,
          );
          postRlcsStatTeam(updatedTeam);
          setRemoveTeamCandidate(null);
          setRemoveTeamGroupCandidate(null);
        }}
      />
    </div>
  );
}

export function TeamsChanger() {
  const { data: teams } = useGetRlcsStatTeams();
  const { data: tournamentSystemTeams } = useFetchTeams("rl");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedTeam, setSelectedTeam] = useState<RocketLeagueStatsTeam | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const { mutate: postRlcsStatTeam } = usePostRlcsStatTeam();

  const notAssignedTeams = useMemo(() => {
    return teams?.filter((team: RocketLeagueStatsTeam) => !team.assignedTeamId) || [];
  }, [teams]);

  const assignedTeams = useMemo(() => {
    return teams?.filter((team: RocketLeagueStatsTeam) => team.assignedTeamId) || [];
  }, [teams]);

  const filteredNotAssignedTeams = useMemo(() => {
    setCurrentPage(1);
    return notAssignedTeams
      .filter((team) => team.teamDisplayName.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => a.teamDisplayName.localeCompare(b.teamDisplayName));
  }, [notAssignedTeams, searchTerm]);

  const totalPages = Math.ceil(filteredNotAssignedTeams.length / itemsPerPage);
  const paginatedTeams = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredNotAssignedTeams.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredNotAssignedTeams, currentPage, itemsPerPage]);

  return (
    <div className="flex gap-3">
      <div className="flex w-1/2 rounded border border-gray-300/10 bg-zinc-900/70 p-2.5">
        {selectedTeam && (
          <UpsertTeamModal
            open={!!selectedTeam}
            team={selectedTeam}
            tournamentTeams={tournamentSystemTeams || []}
            handleClose={() => setSelectedTeam(null)}
            handleSubmit={(updatedTeam) => {
              postRlcsStatTeam(updatedTeam);
              setSelectedTeam(null);
            }}
            nameDisabled={true}
          />
        )}
        <div className="flex w-full flex-col gap-3">
          <div className="scroll-m-20 text-sm font-semibold tracking-tight">
            <div>Unassigned Teams</div>
          </div>
          <Input
            placeholder="Search..."
            className="w-96"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="grid grid-cols-2 gap-2">
            {paginatedTeams.length > 0 ? (
              paginatedTeams.map((team: RocketLeagueStatsTeam) => (
                <div
                  key={team.id}
                  className="flex items-center justify-between rounded border border-gray-300/10 p-2 text-xs"
                >
                  <span className="truncate">{team.teamDisplayName}</span>
                  <Button variant="outline" size="icon" onClick={() => setSelectedTeam(team)}>
                    <PackagePlus className="size-4" />
                  </Button>
                </div>
              ))
            ) : (
              <div className="col-span-2 py-4 text-center text-sm text-gray-500">No teams found</div>
            )}
          </div>

          <Pagination className="gap-2">
            <PaginationPrevious
              onClick={() => setCurrentPage(currentPage - 1)}
              className="cursor-pointer"
              isDisabled={currentPage === 1}
            />
            <PaginationContent>
              <PaginationItem>
                {currentPage} out of {totalPages}
              </PaginationItem>
            </PaginationContent>
            <PaginationNext
              onClick={() => setCurrentPage(currentPage + 1)}
              className="cursor-pointer"
              isDisabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      </div>
      <Groups teams={assignedTeams} tournamentTeams={tournamentSystemTeams || []} />
    </div>
  );
}
