import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CS_GUESSR_MAPS } from "../../constants";
import { assetsURL } from "../../../../config";
import { Location } from "../../../../api/csguessr/types";
import FireButton from "../../../UserProfile/components/FireButton";
import useDeleteLocation from "../../hooks/useDeleteLocation";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { ConfirmationModal } from "../../../../shared/components/ConfirmationModal";

export const ShowLocations = ({
  locations,
  isLoading,
  refetchLocations,
}: {
  locations: Location[];
  isLoading: boolean;
  refetchLocations: () => void;
}) => {
  const [mapLocations, setMapLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [selectedMap, setSelectedMap] = useState<string | null>(null);
  const [mapSrc, setMapSrc] = useState<string>();
  const [isDeleteLocationConfirmationOpen, setIsDeleteLocationConfirmationOpen] = useState(false);
  const alert = useAlert();

  const { mutate: deleteLocation } = useDeleteLocation({
    locationId: selectedLocation?.id,
    onSuccess: () => {
      refetchLocations();
      alert.showSuccessAlert("Location deleted successfully");
      setSelectedLocation(undefined);
    },
    onError: () => {
      alert.showFailureAlert("Failed to delete location");
    },
  });

  const maps = CS_GUESSR_MAPS;

  useEffect(() => {
    if (!selectedMap || !locations) return;
    setMapSrc(`${assetsURL}/images/cs-guessr/maps/${selectedMap}`);
    setMapLocations(locations?.filter((location) => location.mapId === selectedMap) ?? []);
  }, [selectedMap, locations]);

  return (
    <Stack direction="row" gap={5}>
      <div className="relative flex size-[512px] items-center justify-center bg-black">
        {mapLocations.length > 0 &&
          mapLocations.map((location) => (
            <div
              key={location.id}
              onClick={() => setSelectedLocation(location)}
              className="absolute size-[10px] -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-full border border-black bg-yellow-300 transition-all hover:scale-[2]"
              style={{
                left: `${location.position.x}%`,
                top: `${location.position.y}%`,
              }}
            ></div>
          ))}
        {mapSrc && <img src={mapSrc} alt="Map" />}
        {!mapSrc && <Typography variant="body1">Select a map to see locations</Typography>}
      </div>
      <Stack direction="column" gap={5}>
        <Stack>
          <Typography variant="body1">Select a Map</Typography>
          <Select
            disabled={isLoading}
            label="Select Map"
            placeholder="Select Map"
            className="h-10 w-48"
            onChange={(e) => {
              if (!e.target.value) return;
              setSelectedMap(e.target.value as string);
            }}
          >
            {maps.map((map) => (
              <MenuItem key={map.id} value={map.id} className="flex justify-between">
                {map.name}
                {locations && (
                  <span className="pl-2 font-bold">({locations.filter((l) => l.mapId === map.id).length})</span>
                )}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack>
          <Typography variant="h5" className="mb-2">
            Location Details
          </Typography>
          {selectedLocation ? (
            <>
              <Stack direction="row">
                <label className="mr-2 opacity-50">ID:</label>
                <label>{selectedLocation.id}</label>
              </Stack>
              <Stack direction="row">
                <label className="mr-2 opacity-50">Position:</label>
                <label>{JSON.stringify(selectedLocation.position)}</label>
              </Stack>
              <Stack direction="row" className="mt-2">
                <FireButton color="error" onClick={() => setIsDeleteLocationConfirmationOpen(true)} label="Delete" />
              </Stack>
              <img
                className="mt-2 size-64"
                src={`${assetsURL}/images/${selectedLocation.image}`}
                alt="Location Image"
              />
            </>
          ) : (
            <Typography variant="body1">Select a location to see details</Typography>
          )}
        </Stack>
        {isDeleteLocationConfirmationOpen && (
          <ConfirmationModal
            isOpen={isDeleteLocationConfirmationOpen}
            onClose={() => {
              setIsDeleteLocationConfirmationOpen(false);
            }}
            title="Delete Schedule?"
            description="Are you sure that you want to delete this schedule?"
            onConfirm={() => {
              setIsDeleteLocationConfirmationOpen(false);
              deleteLocation();
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};
