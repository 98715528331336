import { z } from "zod";
import { TeamSchema } from "./teams";
import { CircuitSchema } from "./circuits";
import { TournamentSchema } from "./tournaments";
import { StageSchema } from "./stages";
import { MapSchema } from "./maps";
export const DotaMatchMetadataSchema = z.object({
  _t: z.literal("dota_match"),
  externalStreamUrl: z.string().nullish(),
});

export const BountyMatchMetadataSchema = z.object({
  _t: z.literal("bounty_match"),
  wager: z.number().nullish(),
});

export const CsMatchMetadataSchema = z.object({
  _t: z.literal("cs_match"),
  externalStreamUrl: z.string().nullish(),
});
export const RlMatchMetadataSchema = z.object({
  _t: z.literal("rl_match"),
  externalStreamUrl: z.string().nullish(),
});

export const DbMatchMetadataSchema = z.discriminatedUnion("_t", [
  DotaMatchMetadataSchema,
  BountyMatchMetadataSchema,
  CsMatchMetadataSchema,
  RlMatchMetadataSchema,
]);

export type DbMatchMetadata = z.infer<typeof DbMatchMetadataSchema>;

export const MatchSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
  index: z.number(),
  scheduledAt: z.string(),
  stageId: z.string(),
  externalId: z.string().nullish(),
  metadata: DbMatchMetadataSchema.nullish(),
});

export const CreateMatchRequestSchema = MatchSchema.omit({ id: true });

export const MatchTeamSchema = z.object({
  score: z.number().nullable(),
  teamIndex: z.number().int(),
  team: TeamSchema.omit({
    gameId: true,
  }).extend({
    nationality: z.string().nullable(),
  }),
});

export const EditMatchTeamSchema = MatchTeamSchema.extend({
  prevTeamId: z.string().optional(),
  team: TeamSchema.omit({
    gameId: true,
  })
    .extend({
      nationality: z.string().nullable(),
    })
    .optional(),
});

export const MatchByTournamentIdResponseSchema = z.object({
  stageId: z.string(),
  stageName: z.string(),
  stageIndex: z.number().int(),
  matchId: z.string(),
  matchName: z.string(),
  matchType: z.string(),
  matchIndex: z.number().int(),
  scheduledAt: z.coerce.date(),
  metadata: DbMatchMetadataSchema.nullish(),
  teams: MatchTeamSchema.array(),
  externalId: z.string().nullish(),
});

export const DbMatchTeamSchema = z.object({
  matchId: z.string(),
  teamId: z.string(),
  score: z.number(),
  index: z.number(),
});

export const EditMatchSchema = MatchByTournamentIdResponseSchema.extend({
  teams: EditMatchTeamSchema.array(),
});

export const DetailedMatchSchema = MatchSchema.omit({ stageId: true }).extend({
  circuit: CircuitSchema.pick({ id: true, name: true, gameId: true }),
  tournament: TournamentSchema.omit({ circuitId: true, description: true }),
  stage: StageSchema.omit({ tournamentId: true, index: true }),
  teamA: TeamSchema.omit({ gameId: true }).nullable(),
  teamB: TeamSchema.omit({ gameId: true }).nullable(),
  teamAScore: z.number(),
  teamBScore: z.number(),
  maps: MapSchema.omit({
    createdAt: true,
    updatedAt: true,
    matchId: true,
    teams: true,
  })
    .extend({
      teamAScore: z.number(),
      teamBScore: z.number(),
    })
    .array(),
});

export const MatchWithMapsAndTournamentSchema = MatchSchema.extend({
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime().nullable().optional(),
  maps: MapSchema.omit({ createdAt: true, updatedAt: true }).array(),
  tournament: TournamentSchema,
});

export type EditMatch = z.infer<typeof EditMatchSchema>;
export type EditTeamMatch = z.infer<typeof EditMatchTeamSchema>;
export type DbMatchTeam = z.infer<typeof DbMatchTeamSchema>;
export type MatchTeam = z.infer<typeof MatchTeamSchema>;
export type MatchByTournamentIdResponse = z.infer<typeof MatchByTournamentIdResponseSchema>;
export type Match = z.infer<typeof MatchSchema>;
export type DetailedMatch = z.infer<typeof DetailedMatchSchema>;
export type CreateMatchRequest = z.infer<typeof CreateMatchRequestSchema>;
export type MatchWithMapsAndTournament = z.infer<typeof MatchWithMapsAndTournamentSchema>;
