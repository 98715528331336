import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useGetCollections } from "../../../../../../../Collections/hooks/useGetCollections";
import usePointsContext from "../../../../../../../context/PointsConsumer";

export function SelectCollectionDropdown() {
  const { productMethods, productData } = usePointsContext();
  const { data: collections } = useGetCollections();

  if (!collections) {
    return null;
  }

  return (
    <FormControl className="w-full">
      <InputLabel className="">Collection: </InputLabel>
      <Select
        label="Action"
        className="pointer-events-none"
        value={productData.collectionId}
        onChange={(e) => {
          productMethods.setCollectionId(e.target.value);
        }}
      >
        {collections.collections?.map((collection) => (
          <MenuItem key={collection.collectionId} value={collection.collectionId}>
            {collection.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
