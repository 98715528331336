import { CircularProgress, Dialog, DialogActions, DialogContent, Divider } from "@mui/material";
import { CopyBlock, dracula } from "react-code-blocks";

import { DialogRow } from "../../../../components/Dialog/DialogRow";
import { DialogTitle } from "../../../../components/Dialog/DialogTitle";
import { DialogContentText } from "../../../../components/Dialog/DIalogContentText";

import { AuditLogRecord } from "../../../../types/Auditlogs";

import useFetchUserProfile from "../../../../hooks/useFetchUserProfile";
import { DateTime } from "luxon";

export function AuditLogDetails({ onClose, log }: { onClose: () => void; log: AuditLogRecord }) {
  const { data: userProfile, isLoading } = useFetchUserProfile(log.userId);

  if (isLoading || !userProfile) {
    return (
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Fetching - details</DialogTitle>
        <Divider />
        <div className="flex justify-center">
          <CircularProgress />
        </div>
        <Divider />
        <DialogActions />
      </Dialog>
    );
  }

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Log details</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogRow>
          <DialogContentText
            title="Occured At"
            contentTextValue={DateTime.fromISO(log.occurredAt).toFormat("dd-MM-yyyy HH:mm:ss")}
            className="flex-1"
          />
          <DialogContentText title="Author id" contentTextValue={log.userId} className="flex-1" />
        </DialogRow>
        <Divider />
        <DialogRow>
          <DialogContentText title="Author Username" contentTextValue={userProfile.username} className="flex-1" />
          <DialogContentText title="Author email" contentTextValue={userProfile.email} className="flex-1" />
        </DialogRow>
        <Divider />
        <DialogRow>
          <DialogContentText title="Action" contentTextValue={log.action} className="flex-1" />
          <DialogContentText title="Service" contentTextValue={log.service} className="flex-1" />
        </DialogRow>
        <Divider />
        {log.metadata && (
          <DialogContentText
            title="Metadata"
            contentTextValue={
              <CopyBlock
                language="javascript"
                text={JSON.stringify(log.metadata, null, 2)}
                theme={dracula}
                wrapLongLines
              />
            }
            className="flex-1"
          />
        )}
      </DialogContent>
      <Divider />
    </Dialog>
  );
}
