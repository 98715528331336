import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AssignRewardForm from "./components/AssignRewardForm";
import { getUsersWithCorrectAnswers } from "../../../../api/pickems";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 720,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface RewardsModalProps {
  pickemsId: string;
  open: boolean;
  handleClose: () => void;
}

const RewardsModal = ({ pickemsId, open, handleClose }: RewardsModalProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [userIds, setUserUids] = useState<string[]>([]);
  const [searchingValue, setSearchingValue] = useState<number | undefined>(undefined);

  const search = useCallback(() => {
    setSearchingValue(undefined);
    const correctAnswers = !!inputValue && parseInt(inputValue, 10);
    if (correctAnswers) {
      setIsWaiting(true);

      getUsersWithCorrectAnswers({ pickemsId, correctAnswers })
        .then(({ userIds }) => {
          setUserUids(userIds);
          setSearchingValue(correctAnswers);
          setIsWaiting(false);
        })
        .catch((err) => {
          // TODO: show snackbar alert
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }, [pickemsId, inputValue]);

  return (
    <Modal
      open={open}
      onClose={isWaiting ? undefined : handleClose}
      aria-labelledby="rewards-modal-title"
      aria-describedby="rewards-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6">Check rewards for {pickemsId}</Typography>
        <Box display="flex" marginY="2rem" alignItems="center">
          <Typography>Min number of correct answers: </Typography>
          <TextField
            ref={textFieldRef}
            id="outlined-basic"
            variant="outlined"
            sx={{ width: 120, mx: "1rem" }}
            size="small"
            type="number"
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            disabled={isWaiting}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                search();
              }
            }}
          />
          <Button variant="contained" startIcon={<SearchIcon />} onClick={search} disabled={!inputValue || isWaiting}>
            Search
          </Button>
        </Box>
        {searchingValue !== undefined && (
          <AssignRewardForm
            key={searchingValue}
            userIds={userIds}
            answersCount={searchingValue}
            isWaiting={isWaiting}
            setIsWaiting={setIsWaiting}
          />
        )}
        <Box display="flex" justifyContent="flex-end" marginTop="2rem">
          <Button onClick={handleClose} variant="outlined" disabled={isWaiting}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RewardsModal;
