import { usePatchTournament } from "../hooks/usePatchTournament";
import { getTournamentById } from "..";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";
import { Tournament } from "../schemas/tournaments";

export const useAddTournamentTicketing = ({ tournamentId }: { tournamentId: string }) => {
  const { mutateAsync: patchTournament } = usePatchTournament(tournamentId);

  const mutationFn = async (newItem: { title: string; startDate: string; endDate: string; cta: { url: string } }) => {
    // Get tournament, to make sure we don't overwrite existing metadata
    const tournament = await getTournamentById(tournamentId);

    const ticketing: NonNullable<Tournament["metadata"]>["ticketing"] = {
      ...tournament.metadata?.ticketing,
      items: [...(tournament.metadata?.ticketing?.items ?? []), newItem],
    };

    // Already has metadata, so we just update the ticketing
    const metadata: Tournament["metadata"] = {
      ...(tournament.metadata ?? {}),
      ticketing,
    };

    await patchTournament({
      metadata,
    });
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.tournamentById(tournamentId) });
    },
  });
};
