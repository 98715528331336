import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Edit } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { banUser, unbanUser } from "@/api/users";
import PageContainer from "../../components/Layout/PageContainer";
import useFetchUserProfile from "../../hooks/useFetchUserProfile";
import useAlert from "../../providers/AlertProvider/hooks/useAlert";
import EditChatForm from "./components/EditChatForm";
import EditUserAvatars from "./components/EditUserAvatar";
import EditUserEmojis from "./components/EditUserEmoji";
import { EditUsernameForm } from "./components/EditUsernameForm";
import FireButton from "./components/FireButton";
import { StatusBall, StatusContainer } from "./styled";

export default function UserProfilePage() {
  const alert = useAlert();
  const { id } = useParams();
  const { data: userProfile, refetch: refetchUser } = useFetchUserProfile(id);
  const [isEditChatRoleModalOpen, setIsEditChatRoleModalOpen] = useState(false);
  const [isEditUsernameModalOpen, setIsEditUsernameModalOpen] = useState(false);

  if (!userProfile) {
    return null;
  }

  const executeBan = () => {
    banUser(userProfile.id)
      .then(() => {
        alert.showSuccessAlert("User banned successfully");
        void refetchUser();
      })
      .catch(() => {
        alert.showFailureAlert("Failed to ban user");
      });
  };

  const executeUnban = () => {
    unbanUser(userProfile.id)
      .then(() => {
        alert.showSuccessAlert("User unbanned successfully");
        void refetchUser();
      })
      .catch(() => {
        alert.showFailureAlert("Failed to unban user");
      });
  };

  return (
    <PageContainer>
      <Table className="w-1/2">
        <TableHeader>
          <TableRow>
            <TableHead>Details</TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody className="divide-y divide-zinc-800 border-y">
          <TableRow>
            <TableCell className="font-medium">Username</TableCell>
            <TableCell className="flex items-center border-l border-zinc-800 pl-3">
              {userProfile.username}
              <Button
                className="text-emerald-500"
                variant="ghost"
                size="icon"
                onClick={() => setIsEditUsernameModalOpen(true)}
              >
                <Edit />
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium">Email</TableCell>
            <TableCell className="border-l border-zinc-800 pl-3">{userProfile.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium">Country</TableCell>
            <TableCell className="border-l border-zinc-800 pl-3">{userProfile.country}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium">Gender</TableCell>
            <TableCell className="border-l border-zinc-800 pl-3">{userProfile.gender}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium">Birth date</TableCell>
            <TableCell className="border-l border-zinc-800 pl-3">{userProfile.birthDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium">Status</TableCell>
            <TableCell className="border-l border-zinc-800 pl-3">
              <StatusContainer>
                <label>{userProfile.status}</label>
                <StatusBall status={userProfile.status} />
                <FireButton
                  color={userProfile.status === "active" ? "error" : "primary"}
                  onClick={() => {
                    if (userProfile.status === "active") {
                      executeBan();
                    } else {
                      executeUnban();
                    }
                  }}
                  label={userProfile.status === "active" ? "Ban" : "Unban"}
                />
              </StatusContainer>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table className="w-1/2">
        <TableHeader>
          <TableRow>
            <TableHead>Chat</TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody className="divide-y divide-zinc-800 border-y">
          <TableRow>
            <TableCell className="font-medium">Chat Role</TableCell>
            <TableCell className="flex items-center border-l border-zinc-800 pl-3 capitalize">
              {userProfile.chatRole ?? "-"}
              <Button
                variant="ghost"
                size="icon"
                className="text-emerald-500"
                onClick={() => setIsEditChatRoleModalOpen(true)}
              >
                <Edit />
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {isEditChatRoleModalOpen && (
        <EditChatForm
          open={isEditChatRoleModalOpen}
          handleClose={() => {
            setIsEditChatRoleModalOpen(false);
          }}
          data={userProfile}
        />
      )}

      {isEditUsernameModalOpen && (
        <EditUsernameForm
          open={isEditUsernameModalOpen}
          handleClose={() => {
            setIsEditUsernameModalOpen(false);
          }}
          data={userProfile}
        />
      )}

      <EditUserAvatars />
      <EditUserEmojis />
    </PageContainer>
  );
}
