import { VisibilityOff } from "@mui/icons-material";
import { Autocomplete, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, TextField } from "@mui/material";
import Emoji from "../../../../../../types/users/emojis";
import { AvatarImage } from "../../../../../UserProfile/components/EditAvatarForm/styled";

export default function EmojiAutocomplete({
  emojis,
  chosenEmoji,
  setChosenEmoji,
  disabled,
}: {
  emojis?: Emoji[];
  chosenEmoji: Emoji | null;
  setChosenEmoji: (emoji: Emoji | null) => void;
  disabled?: boolean;
}) {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={emojis ?? []}
      sx={{ flexGrow: 4 }}
      disabled={disabled}
      value={chosenEmoji}
      onChange={(_, newValue) => {
        setChosenEmoji(newValue);
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => {
        return (
          <>
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,

                startAdornment: chosenEmoji?.url ? (
                  <AvatarImage src={chosenEmoji?.url} />
                ) : (
                  params.InputProps.startAdornment
                ),
              }}
              label="Pick an emoji"
            />
          </>
        );
      }}
      renderOption={(_, option) => {
        if (option?.url) {
          return (
            <>
              <MenuItem onClick={() => setChosenEmoji(option)} key={option.id}>
                <ListItemAvatar>
                  <AvatarImage src={option.url} />
                </ListItemAvatar>
                <ListItemText primary={option.name} />
                <ListItemIcon>
                  {option.hidden ? (
                    <div title="Hidden">
                      <VisibilityOff />{" "}
                    </div>
                  ) : null}
                </ListItemIcon>
              </MenuItem>
            </>
          );
        }
        return null;
      }}
    />
  );
}
