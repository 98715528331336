import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { DateTime } from "luxon";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useGetEventsList } from "@/api/metricsViewership/hooks/useGetEventsList";
import { getEventStatus } from "../../shared/helpers/getEventStatus";
import { formatDateInterval, formatMinutesToHuman } from "../../shared/helpers/time";

export const EventsTable = () => {
  const { data: events, isError } = useGetEventsList();

  const navigate = useNavigate();

  return (
    <Table>
      <TableHeader>
        <TableRow className="text-xs">
          <TableHead className="w-36">Status</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Game</TableHead>
          <TableHead>Airtime</TableHead>
          <TableHead>Event Date</TableHead>
          <TableHead>Tracking Channels</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isError && (
          <TableRow>
            <TableCell colSpan={6}>Something went wrong when fetching data.</TableCell>
          </TableRow>
        )}

        {events
          ?.sort((a, b) => DateTime.fromISO(b.endDate).toMillis() - DateTime.fromISO(a.endDate).toMillis())
          .map((event, i) => {
            const eventStatus = getEventStatus(event);
            return (
              <TableRow
                key={i}
                className={classNames(eventStatus.bgClassName, {
                  "cursor-pointer transition-all duration-150 ease-in-out hover:bg-white/10": !isError,
                })}
                onClick={() => navigate(`/metrics/viewership/events/${event.id}`)}
              >
                <TableCell title={eventStatus.title} className={classNames("uppercase", eventStatus.className)}>
                  {eventStatus.status}
                </TableCell>
                <TableCell>{event.name}</TableCell>
                <TableCell>{event.gameName}</TableCell>
                <TableCell>{formatMinutesToHuman(event.totalAirtimeMinutes)}</TableCell>
                <TableCell>{formatDateInterval({ start: event.startDate, end: event.endDate })}</TableCell>
                <TableCell>{event.channels.length} Channels</TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};
