import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../providers/AuthProvider/hooks/useAuth";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Loader2 } from "lucide-react";
import { z } from "zod";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [isWaiting, setIsWaiting] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const isEmailValid = useMemo(() => {
    return z.string().email().safeParse(email).success;
  }, [email]);

  useEffect(() => {
    if (auth.userId && !auth.hasAccess) {
      setErrorMessage(
        "You do not have the required permissions to enter. Please log in with an administrator account.",
      );
    }
  }, [auth.hasAccess, auth.userId]);

  useEffect(() => {
    if (!auth.hasAccess) {
      return;
    }

    const urlParams = new URLSearchParams(location.search);
    const redirectPath = urlParams.has("redirect_path")
      ? decodeURIComponent(urlParams.get("redirect_path") as string)
      : "/";
    navigate(redirectPath, { replace: true });
  }, [auth, navigate, location]);

  const login = async () => {
    setIsWaiting(true);

    await auth
      .login({
        login: email,
        password: password,
      })
      .then(() => {
        setErrorMessage(null);
        setIsWaiting(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setIsWaiting(false);
        setErrorMessage("Login failed, please check the console for details.");
      });
  };

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-black px-4">
      <Card className="mx-auto max-w-sm">
        <CardHeader>
          <div className="flex items-center justify-center gap-2">
            <img src={`/BLAST-logo-light.svg`} alt="Blast Logo" className="h-5" />
            <CardTitle className="text-2xl">Login</CardTitle>
          </div>
          <CardDescription>Enter your email below to login to your account</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="m@example.com"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="grid gap-2">
              <div className="flex items-center">
                <Label htmlFor="password">Password</Label>
              </div>
              <Input
                id="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button type="submit" className="w-full" disabled={!isEmailValid || !password} onClick={() => void login()}>
              <span className="flex w-10 place-content-center items-center text-center">
                {isWaiting ? <Loader2 className="w-4 animate-spin" /> : "Login"}
              </span>
            </Button>
            {errorMessage && <p className="text-xs text-red-500">{errorMessage}</p>}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;
