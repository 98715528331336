import { Dialog, Divider, DialogTitle, DialogContent, Button, DialogActions, Grid } from "@mui/material";
import { useEffect, useState } from "react";

import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import { uploadEmojiImage } from "../../helpers/uploadEmojiImage";
import { createEmoji, invalidateMetadataFile } from "../../../../../api/assets";
import { ImageUploadForm } from "../../../shared/components/ImageUploadForm";
import { EmojiDetailsFields } from "../EmojiDetailsFields";
import { Emoji } from "../../../../../types/Emoji";
import { usePatron } from "../../../../../providers/PatronProvider/hooks/usePatron";

interface CreateEmojiModalProps {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
}

export const CreateEmojiModal = ({ open, isLoading, onClose }: CreateEmojiModalProps) => {
  const alert = useAlert();
  const { patron } = usePatron();

  const [image, setImage] = useState<File | null>(null);
  const [emojiId, setEmojiId] = useState<string>("");
  const [createdEmoji, setCreatedEmoji] = useState<Emoji | null>(null);

  useEffect(() => {
    setEmojiId(crypto.randomUUID());
  }, []);

  const onEmojiCreate = async () => {
    if (createdEmoji === null) {
      alert.showFailureAlert("You need to fill out the information.");
      return;
    }

    if (image === null) {
      alert.showFailureAlert("You need to attach an image.");
      return;
    }

    await createEmoji({
      emojiData: createdEmoji,
      patron,
    });

    await uploadEmojiImage({
      emojiId,
      image: image,
      patron,
      sendSuccessMessage: (message) => {
        alert.showSuccessAlert(message);
      },
      sendErrorMessage: (message) => {
        alert.showFailureAlert(message);
      },
    });

    await invalidateMetadataFile("emojis", patron);
    alert.showSuccessAlert("Successfully created emoji");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create Emoji</DialogTitle>
      <Divider />
      <DialogContent>
        <EmojiDetailsFields emojiId={emojiId} emoji={undefined} updateEmoji={(emoji) => setCreatedEmoji(emoji)} />
        <Divider />
        <Grid container spacing={4}>
          <Grid item>
            <h3>Image:</h3>
            <ImageUploadForm image={image} setImage={setImage} />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled={isLoading} onClick={() => void onEmojiCreate()}>
          Create Emoji
        </Button>
      </DialogActions>
    </Dialog>
  );
};
