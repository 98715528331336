import { Button, Chip, Dialog, DialogActions, DialogContent, Divider, Stack, TextField } from "@mui/material";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { DialogTitle } from "../../../../components/Dialog/DialogTitle";
import { Location, Schedule } from "../../../../api/csguessr/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { assetsURL } from "../../../../config";
import { useCreateSchedule } from "../../hooks/useCreateSchedule";

interface LocationWithLastSchedule extends Location {
  lastScheduledDate: string | undefined;
}
export const CreateScheduleLocationDialog = ({
  open,
  closeDialog,
  locations,
  date,
  schedules,
}: {
  open: boolean;
  closeDialog: (success: boolean) => void;
  locations?: Location[];
  date: string;
  schedules?: Schedule[];
}) => {
  const [locationsWithLastSchedule, setLocationsWithLastSchedule] = useState<LocationWithLastSchedule[]>([]);
  const [selectedLocationId, setSelectedLocationId] = useState<string>();

  const selectedLocation = useMemo(
    () => locationsWithLastSchedule?.find((location) => location.id === selectedLocationId),
    [locationsWithLastSchedule, selectedLocationId],
  );

  const alert = useAlert();

  const { mutate: createLocation, isLoading: creatingSchedule } = useCreateSchedule({
    onSuccess: () => {
      alert.showSuccessAlert("Schedule created successfully");
      closeDialog(true);
    },
    onError: () => {
      alert.showFailureAlert("Failed to create Schedule");
    },
  });

  const calculateLastSchedule = useCallback(
    (location: Location) => {
      const lastSchedule = schedules?.find((schedule) => schedule.id === location.id);

      return lastSchedule?.date ?? undefined;
    },
    [schedules],
  );

  const calculateDayDifference = useCallback((date: string | undefined) => {
    if (!date) return <Chip label="Never" />;
    const today = new Date();
    const scheduleDate = new Date(date);

    const diffTime = Math.abs(scheduleDate.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const label = diffDays === 1 ? "Today" : `${diffDays} days ago`;

    return (
      <Chip
        label={label}
        className={classNames("bg-opacity-50", {
          "bg-red-300": diffDays < 30,
          "bg-yellow-300": diffDays >= 30 && diffDays < 60,
          "bg-green-300": diffDays >= 60,
        })}
      />
    );
  }, []);

  useEffect(() => {
    if (!locations) return;
    const locationsUpdated: LocationWithLastSchedule[] = locations?.map((location) => ({
      ...location,
      lastScheduledDate: calculateLastSchedule(location),
    }));

    locationsUpdated.sort((a, b) => {
      if (!a.lastScheduledDate) return -1;
      if (!b.lastScheduledDate) return 1;
      return new Date(a.lastScheduledDate).getTime() - new Date(b.lastScheduledDate).getTime();
    });

    setLocationsWithLastSchedule(locationsUpdated);
  }, [calculateLastSchedule, locations, schedules]);

  function saveSchedule() {
    if (!selectedLocationId) return;

    createLocation({
      locationId: selectedLocationId,
      date: date,
    });
  }

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
      <DialogTitle>Create Schedule Location</DialogTitle>
      <div className="mx-auto p-4">
        <Stack direction="row" gap={2}>
          <Stack direction="row" alignItems="center" gap={2}>
            <label>Selected Date</label>
            <TextField disabled value={date} variant="outlined" />
          </Stack>
          <Stack direction="row" alignItems="center" gap={2}>
            <label>Selected Location</label>
            <div className="flex size-16 items-center justify-center overflow-hidden rounded border border-gray-400">
              {selectedLocationId ? <img src={`${assetsURL}/images/${selectedLocation?.image}`} /> : "?"}
            </div>
          </Stack>
        </Stack>
      </div>
      <Divider />
      <DialogContent className="relative">
        <div className="overflow-auto">
          {locationsWithLastSchedule?.map((location) => (
            <div
              key={location.id}
              className={classNames(
                "mt-2 flex cursor-pointer items-center justify-between gap-2 rounded-small px-2 py-2 transition-all",
                {
                  "bg-gray-700": selectedLocationId === location.id,
                  "hover:bg-gray-600": selectedLocationId !== location.id,
                },
              )}
              onClick={() => setSelectedLocationId(location.id)}
            >
              <div className="flex items-center gap-4">
                <img className="size-14 rounded-small" src={`${assetsURL}/images/${location.image}`} alt="Location" />
                <label className="font-bold uppercase">{location.mapId.split("_")[1]}</label>
                <label className="opacity-40">{location.id}</label>
              </div>
              <div className="mr-3">{calculateDayDifference(location.lastScheduledDate)}</div>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog(false)}>Cancel</Button>
        <Button onClick={saveSchedule} variant="contained" disabled={!date || !selectedLocationId || creatingSchedule}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
