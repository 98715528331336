import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { MatchByTournamentIdResponse } from "../../../../../../../api/tournaments/schemas/matches";

export const GeneralInfo = ({
  match,
  gameId,
  updateMatch,
}: {
  match: MatchByTournamentIdResponse | undefined;
  gameId: string;
  updateMatch: (match: MatchByTournamentIdResponse) => void;
}) => {
  const { tournamentId } = useParams();

  const isExternalStreamUrlFieldVisible = useMemo(
    () => gameId === "cs" || gameId === "dota" || gameId === "rl",
    [gameId],
  );
  const isExternalIdFieldVisible = useMemo(() => gameId === "dota", [gameId]);

  const onExternalStreamUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!match) return;

    const type = gameId === "rl" ? "rl_match" : gameId === "cs" ? "cs_match" : "dota_match";

    updateMatch({
      ...match,
      metadata: { _t: type, externalStreamUrl: e.target.value },
    });
  };

  const externalStreamUrl = useMemo(() => {
    if (!match) return "";
    if (gameId === "cs") return match.metadata?._t === "cs_match" ? match.metadata?.externalStreamUrl : "";
    if (gameId === "dota") return match.metadata?._t === "dota_match" ? match.metadata?.externalStreamUrl : "";
    if (gameId === "rl") return match.metadata?._t === "rl_match" ? match.metadata?.externalStreamUrl : "";
    return "";
  }, [match, gameId]);

  return (
    <div className="flex flex-col gap-4">
      <h3 className="font-semibold">GENERAL INFO</h3>

      <div className="grid gap-2">
        <Label htmlFor="matchId">Match Id</Label>
        <Input id="matchId" defaultValue={match?.matchId} disabled />
      </div>

      <div className="grid gap-2">
        <Label htmlFor="matchIndex">Match Index</Label>
        <Input
          id="matchIndex"
          type="number"
          min={0}
          defaultValue={match?.matchIndex}
          onChange={(e) => {
            const matchIndex = parseInt(e.target.value);
            if (match) updateMatch({ ...match, matchIndex });
          }}
          className={
            match?.matchIndex === undefined || isNaN(match?.matchIndex) || match?.matchIndex < 0
              ? "border-destructive"
              : ""
          }
        />
      </div>

      <div className="grid gap-2">
        <Label htmlFor="matchName">Match Name</Label>
        <Input
          id="matchName"
          defaultValue={match?.matchName}
          onChange={(e) => {
            const matchName = e.target.value;
            if (match) updateMatch({ ...match, matchName });
          }}
          className={match?.matchName === undefined || match?.matchName === "" ? "border-destructive" : ""}
        />
      </div>

      <div className="grid gap-2">
        <Label htmlFor="matchType">Type of Match</Label>
        <Select
          value={match?.matchType || "BO3"}
          onValueChange={(value) => {
            if (match) updateMatch({ ...match, matchType: value });
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select match type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="BO1">BO1</SelectItem>
            <SelectItem value="BO2">BO2</SelectItem>
            <SelectItem value="BO3">BO3</SelectItem>
            <SelectItem value="BO5">BO5</SelectItem>
            <SelectItem value="BO7">BO7</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DateTimePicker
          label="Scheduled Start"
          defaultValue={match?.scheduledAt ? DateTime.fromJSDate(match?.scheduledAt) : DateTime.fromJSDate(new Date())}
          format="dd/LL/yyyy HH:mm"
          ampm={false}
          onChange={(date) => {
            const scheduledAt = date?.toJSDate();
            if (match && scheduledAt) updateMatch({ ...match, scheduledAt });
          }}
          sx={{ width: "100%" }}
        />
      </LocalizationProvider>

      {tournamentId?.includes("bounty") && (
        <div className="grid gap-2">
          <Label htmlFor="wager">Wager</Label>
          <Input
            id="wager"
            onChange={(e) => {
              if (match)
                updateMatch({
                  ...match,
                  metadata:
                    e.target.value && e.target.value !== ""
                      ? {
                          _t: "bounty_match",
                          wager: parseInt(e.target.value),
                        }
                      : null,
                });
            }}
            value={match?.metadata?._t == "bounty_match" ? match?.metadata?.wager || "" : ""}
          />
        </div>
      )}

      {isExternalStreamUrlFieldVisible && (
        <div className="grid gap-2">
          <Label htmlFor="externalStreamUrl">External Stream URL</Label>
          <Input id="externalStreamUrl" onChange={onExternalStreamUrlChange} value={externalStreamUrl || ""} />
        </div>
      )}

      {isExternalIdFieldVisible && (
        <div className="grid gap-2">
          <Label htmlFor="externalId">External Id</Label>
          <Input
            id="externalId"
            onChange={(e) => {
              if (match) updateMatch({ ...match, externalId: e.target.value });
            }}
            value={match?.externalId || ""}
          />
        </div>
      )}
    </div>
  );
};
