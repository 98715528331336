import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Prize } from "@/api/tournaments/schemas/prizes";
import useDeleteTournamentPrize from "../hooks/useDeleteTournamentPrize";
import useAlert from "@/providers/AlertProvider/hooks/useAlert";
import { Trash2 } from "lucide-react";
import { generateImageUrl } from "@/helpers/images/generateImageUrl";
import { cn } from "@/lib/utils";

interface DeletePrizeModalProps {
  isOpen: boolean;
  onClose: () => void;
  deletingPrizes: Prize[];
}

export const DeletePrizeModal = ({ isOpen, onClose, deletingPrizes }: DeletePrizeModalProps) => {
  const samplePrize = deletingPrizes[0]; // All fields are the same except for the team for grouped prizes
  const { mutateAsync: deletePrize, isLoading: isDeleting } = useDeleteTournamentPrize();
  const alert = useAlert();

  const onConfirm = async () => {
    let hasAnyError = false;

    for (const prize of deletingPrizes) {
      await deletePrize(prize.id, {
        onError: () => {
          hasAnyError = true;
          void alert.showFailureAlert("Failed to delete prize");
        },
      });
    }

    if (!hasAnyError) {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Delete Prize</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete this prize? This action cannot be undone.
          </DialogDescription>
        </DialogHeader>

        <div className="flex w-full flex-col justify-between gap-2 py-4 text-sm">
          <div className="flex w-full items-center justify-between gap-2">
            <span className="text-muted-foreground">Prize:</span>
            <span className="font-medium">{samplePrize?.prize}</span>
          </div>

          <div className="flex w-full items-center justify-between gap-2">
            <span className="text-muted-foreground">Position:</span>
            <span className="font-medium">
              {samplePrize?.positionStart} - {samplePrize?.positionEnd}
            </span>
          </div>

          <div className="flex w-full items-center justify-between gap-2">
            <span className="text-muted-foreground">Teams:</span>
            <div className="flex gap-2">
              {deletingPrizes.map(({ teamId }, index) => (
                <img
                  key={index}
                  className="size-6"
                  src={generateImageUrl("teams", teamId ?? "", {
                    format: "auto",
                    height: "24",
                    width: "auto",
                  })}
                  alt="Team logo"
                />
              ))}
            </div>
          </div>
        </div>

        <DialogFooter className="gap-2 sm:gap-0">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={() => void onConfirm()}
            disabled={isDeleting}
            className={cn("gap-2", isDeleting && "cursor-not-allowed opacity-50")}
          >
            {isDeleting ? (
              <>
                <span className="size-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
                Deleting...
              </>
            ) : (
              <>
                <Trash2 className="size-4" />
                Delete
              </>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
