import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteScheduledNotification } from "../../../api/notifications";

const useDeleteScheduledNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (...rules: Parameters<typeof deleteScheduledNotification>) => deleteScheduledNotification(...rules),
    onSuccess() {
      void queryClient.invalidateQueries(["scheduledNotifications"]);
    },
  });
};

export default useDeleteScheduledNotification;
