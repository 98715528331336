import PageContainer from "@/components/Layout/PageContainer";
import { Button } from "@/components/ui/button";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Add, Edit, MilitaryTech } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { MatchByTournamentIdResponse } from "../../../api/tournaments/schemas/matches";
import { Stage, StageMetadata } from "../../../api/tournaments/schemas/stages";
import useFetchMatchesByTournamentId from "../hooks/matches/useFetchMatchesByTournamentId";
import useFetchCircuitById from "../hooks/useFetchCircuitById";
import useFetchStages from "../hooks/useFetchStages";
import useFetchTournamentById from "../hooks/useFetchTournamentById";
import { CreateAndEditTournamentModal } from "../Tournaments/components/CreateEditTournamentModal";
import CreateEditStageModal from "./components/CreateEditStageModal";
import { MatchModal } from "./components/MatchModal";
import { StageInfo } from "./components/StageInfo";
import { TournamentPrizesModal } from "./components/TournamentPrizesModal";

export interface StageBuilder {
  id?: string;
  name?: string;
  startDate?: Date;
  endDate?: Date;
  format?: string;
  numberOfTeams?: number | null;
  metadata?: StageMetadata | null;
  index?: number;
}

const emptyStage: StageBuilder = {
  id: undefined,
  name: undefined,
  startDate: undefined,
  endDate: undefined,
  format: undefined,
  numberOfTeams: undefined,
  metadata: undefined,
  index: undefined,
};

const TournamentStages = () => {
  const { hash } = useLocation();
  const [isTournamentPrizeModalOpen, setIsTournamentPrizeModalOpen] = useState<boolean>(false);
  const [selectedStageRaw, setSelectedStage] = useState<Stage | undefined>();
  const [shownMatches, setShownMatches] = useState<MatchByTournamentIdResponse[]>([]);
  const [selectMatch, setSelectedMatch] = useState<MatchByTournamentIdResponse | undefined>();
  const [stageModalStatus, setStageModalStatus] = useState<"new" | "edit" | "closed">("closed");
  const [editStage, setEditStage] = useState<StageBuilder>(emptyStage);
  const [isEditingTournament, setIsEditingTournament] = useState<boolean>(false);

  const { tournamentId } = useParams();

  const { data: tournament, isLoading: isLoadingTournament } = useFetchTournamentById(tournamentId);
  const { data: circuit } = useFetchCircuitById(tournament?.circuitId || "");
  const { data: stages, refetch: refetchStages } = useFetchStages(tournamentId);
  const selectedStage = selectedStageRaw ?? stages?.[0];
  const { data: matches, refetch: refetchMatches } = useFetchMatchesByTournamentId(tournamentId);

  useEffect(() => {
    if (matches !== undefined && selectedStage !== undefined) {
      setShownMatches(matches.filter((match) => match.stageId === selectedStage?.id));
    }
  }, [matches, selectedStage]);

  useEffect(() => {
    if (hash) {
      setSelectedMatch(matches?.find((match) => match.matchId === hash.replace("#", "")));
    }
  }, [hash, matches]);

  if (!tournamentId) {
    return <div>Invalid URL</div>;
  }

  return (
    <div className="flex w-full">
      <PageContainer>
        <div className="flex w-full flex-col">
          {tournament && !isLoadingTournament ? (
            <>
              <div className="mb-4 flex w-full items-start justify-between gap-2">
                <div className="flex flex-col gap-3">
                  <div className="text-xl">{tournament.name}</div>
                  <div className="text-sm text-white/70">Tournament external Id: {tournament.externalId ?? "-"}</div>
                </div>
                <div className="flex items-center gap-2">
                  <Button onClick={() => setIsEditingTournament(true)} variant="outline" className="ml-auto">
                    <Edit className="mr-2 size-4" />
                    Edit Tournament details
                  </Button>
                  <Button
                    onClick={() => {
                      setIsTournamentPrizeModalOpen(true);
                    }}
                    variant="outline"
                  >
                    <MilitaryTech className="mr-2 size-4" />
                    Edit Prizes
                  </Button>
                  <Button
                    onClick={() => {
                      setEditStage(emptyStage);
                      setStageModalStatus("new");
                    }}
                    variant="default"
                  >
                    <Add className="mr-2 size-4" />
                    Add a new Stage
                  </Button>
                </div>
              </div>
              <div className="flex w-full flex-col items-center justify-between">
                <Tabs
                  value={selectedStage?.id}
                  onValueChange={(value) => {
                    const stage = stages?.find((s) => s.id === value);
                    setSelectedStage(stage);
                  }}
                  className="w-full"
                >
                  <TabsList className="w-full justify-start">
                    {stages?.map((stage) => (
                      <TabsTrigger value={stage.id} key={stage.id}>
                        {stage.name}
                      </TabsTrigger>
                    ))}
                  </TabsList>
                </Tabs>
                <StageInfo
                  gameId={circuit?.gameId ?? "cs"}
                  stage={selectedStage}
                  matches={shownMatches}
                  matchClick={(match: MatchByTournamentIdResponse) => {
                    setSelectedMatch(match);
                  }}
                  editClick={(stage) => {
                    setEditStage(stage);
                    setStageModalStatus("edit");
                  }}
                  tournamentMetadata={tournament.metadata}
                />
              </div>
              <CreateEditStageModal
                onChange={(stage) => setEditStage(stage)}
                onClose={() => setStageModalStatus("closed")}
                tournamentId={tournament.id}
                stage={editStage}
                stageModalStatus={stageModalStatus}
                onSubmit={() => {
                  void refetchStages();
                  void refetchMatches();
                }}
              />
              <TournamentPrizesModal
                isOpen={isTournamentPrizeModalOpen}
                onClose={() => {
                  setIsTournamentPrizeModalOpen(false);
                }}
                gameId={circuit?.gameId}
                tournament={tournament}
              />
              {selectMatch && (
                <MatchModal
                  match={selectMatch}
                  handleClose={() => {
                    setSelectedMatch(undefined);
                  }}
                  updateMatch={(match: MatchByTournamentIdResponse) => {
                    setSelectedMatch(match);
                  }}
                  gameId={circuit?.gameId}
                />
              )}
            </>
          ) : (
            <div>Tournament Not Found</div>
          )}

          {isEditingTournament && tournament && (
            <CreateAndEditTournamentModal
              isOpen={isEditingTournament}
              onClose={() => setIsEditingTournament(false)}
              editingTournamentId={tournament.id}
            />
          )}
        </div>
      </PageContainer>
    </div>
  );
};

export default TournamentStages;
