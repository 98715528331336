import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import * as Progress from "@radix-ui/react-progress";

import { useEffect, useState } from "react";
import classNames from "classnames";

export function GlobalLoadingIndicator() {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const isLoading = isFetching || isMutating;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!isLoading) {
      setProgress(0); // reset progress
      return;
    } else {
      const timer = setInterval(
        () =>
          setProgress((progress) => {
            if (progress >= 100) return 0;
            return progress + 5;
          }),
        100,
      );
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <Progress.Root
        value={progress}
        className="absolute left-0 top-0 z-toast h-0.5 w-full overflow-hidden bg-zinc-800"
      >
        <Progress.Indicator
          className={classNames("size-full flex-1 bg-purple-500 opacity-75", {
            "transition-all": progress > 0, // don't transition when going back from 100 to 0
          })}
          style={{ transform: `translateX(-${100 - (progress || 0)}%)` }}
        />
      </Progress.Root>
    );
  }
}
