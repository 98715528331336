import { Edit, DragIndicator, Archive } from "@mui/icons-material";
import { Box, Card, CardContent, Chip, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../../../components/ConfirmModal";
import { usePatron } from "../../../../providers/PatronProvider/hooks/usePatron";
import { Patron } from "../../../../providers/PatronProvider/types";
import { Broadcast } from "../../../../types/Broadcasts";

interface CardItemProps {
  broadcast: Broadcast;
  index?: number;
  draggable?: boolean;
  onArchive?: ({ patron, broadcastId }: { patron: Patron; broadcastId: string }) => void;
}

const CardItem = ({ broadcast, draggable, index, onArchive }: CardItemProps) => {
  const { patron } = usePatron();
  const [confirmArchiveModalOpened, setConfirmArchiveModalOpened] = useState(false);

  const onConfirmedArchive = (id: string) => {
    if (onArchive) onArchive({ patron, broadcastId: id });
    setConfirmArchiveModalOpened(false);
  };

  const navigate = useNavigate();
  return draggable ? (
    <Draggable draggableId={broadcast.id} index={index || 0}>
      {(provided, snapshot) => (
        <Card key={broadcast.id} ref={provided.innerRef} {...provided.draggableProps}>
          <CardContent
            sx={{
              ":last-child": { paddingBottom: "1rem" },
              backgroundColor: snapshot.isDragging ? "primary.main" : "",
              cursor: "pointer",
            }}
            onClick={() => navigate(`${patron}/${broadcast.id}`)}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                sx={{ padding: "0.25rem 0.5rem", cursor: "grab" }}
                {...provided.dragHandleProps}
              >
                <DragIndicator />
              </Box>
              <Chip sx={{ marginLeft: "1rem" }} label="LIVE" color="error" />
              <Typography sx={{ marginLeft: "1rem", flexGrow: 2 }}>{broadcast.title}</Typography>
              <Typography sx={{ color: "lightgray" }}>PRIORITY: {broadcast.priority}</Typography>
              <Edit sx={{ marginLeft: "1rem", cursor: "pointer" }} />
            </Box>
          </CardContent>
        </Card>
      )}
    </Draggable>
  ) : (
    <Card key={broadcast.id}>
      <CardContent
        sx={{
          cursor: "pointer",
        }}
        onClick={() => navigate(`${patron}/${broadcast.id}`)}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Chip sx={{ marginLeft: "1rem" }} label="Offline" color="default" />
          <Typography sx={{ marginLeft: "1rem", flexGrow: 2 }}>{broadcast.title}</Typography>
          <Edit sx={{ marginLeft: "1rem", cursor: "pointer" }} />
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setConfirmArchiveModalOpened(true);
            }}
            sx={{ marginLeft: "1rem", cursor: "pointer" }}
            aria-label="archive broadcast"
            color="warning"
          >
            <Archive />
          </IconButton>
        </Box>
      </CardContent>
      <ConfirmModal
        open={confirmArchiveModalOpened}
        onOpenChange={() => setConfirmArchiveModalOpened(false)}
        onSubmit={() => onConfirmedArchive(broadcast.id)}
        submitText="Archive"
      >
        <>
          <Typography variant="subtitle1" textAlign="left">
            Are you sure you want to archive: <strong>{broadcast.title}</strong>?
          </Typography>
          <Typography variant="body2" textAlign="left">
            This action cannot be undone.
          </Typography>
        </>
      </ConfirmModal>
    </Card>
  );
};

export default CardItem;
