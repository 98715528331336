import { useDeleteMetadata } from "@/api/mediakit/hooks/useDeleteMetadata";
import { useGetMetadata } from "@/api/mediakit/hooks/useGetMetadata";
import { X } from "lucide-react";
import { environment } from "@/config";
import { UploadMultipleFiles } from "./components/UploadMultipleFiles";

export const MediaKitPage = () => {
  const mediakitHost = environment === "production" ? "https://mediakit.blast.tv" : "https://mediakit.blast-dev.tv";

  const { data: metadata, refetch: refetchMetadata } = useGetMetadata();
  const { mutate: deleteMetadata } = useDeleteMetadata();

  return (
    <div className="flex flex-col gap-8 p-4">
      <div className="flex flex-row gap-8">
        <UploadMultipleFiles
          uploadFinished={() => {
            refetchMetadata()
              .then(() => {})
              .catch(() => {});
          }}
        />
      </div>
      <h1 className="text-xl font-bold">Gallery</h1>
      <div className="flex flex-col gap-4">
        {metadata &&
          Object.keys(metadata.categories).map((category) => {
            const medias = metadata.categories[category];
            return (
              <div className="flex flex-col gap-1" key={category}>
                <span className="text-xl font-bold">{category.toUpperCase()}</span>
                <div className="flex w-fit flex-row flex-wrap gap-4">
                  {medias.map((media) => (
                    <div key={media.id} className="relative flex w-fit flex-col gap-1 rounded-sm bg-zinc-800 p-2">
                      <button
                        onClick={() => {
                          deleteMetadata(media.id, {
                            onSuccess: () => {
                              refetchMetadata()
                                .then(() => {})
                                .catch(() => {});
                            },
                          });
                        }}
                      >
                        <X className="absolute right-0 top-0 size-6 text-red-500 hover:cursor-pointer hover:text-red-600" />
                      </button>
                      <div className="flex size-full items-center justify-center">
                        <img
                          src={`${mediakitHost}/${media.path.split("/")[0]}/preview-${media.path.split("/")[1]}`}
                          className="size-24 object-contain"
                        />
                      </div>
                      <span>
                        <b>Tags: </b>
                        {media.tags.join(", ")}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
