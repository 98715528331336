import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useMemo, useState } from "react";
import { usePatchTournament } from "../../../../../api/tournaments/hooks/usePatchTournament";
import { Prize } from "../../../../../api/tournaments/schemas/prizes";
import { Tournament } from "../../../../../api/tournaments/schemas/tournaments";
import { generateImageUrl } from "../../../../../helpers/images/generateImageUrl";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import { AddEditPrizeModal } from "./components/AddEditPrizeModal";
import { DeletePrizeModal } from "./components/DeletePrizeModal";
import useFetchTournamentPrizes from "./hooks/useFetchTournamentPrizes";
import { Pencil, PlusIcon, TrashIcon } from "lucide-react";
import { Label } from "@/components/ui/label";

interface TournamentPrizesModalProps {
  isOpen: boolean;
  onClose: () => void;
  gameId?: string;
  tournament: Tournament;
}

export const TournamentPrizesModal = ({ isOpen, onClose, gameId, tournament }: TournamentPrizesModalProps) => {
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState<boolean>(false);
  const [editingPrizes, setEditingPrizes] = useState<Prize[]>([]);
  const [deletingPrizes, setDeletingPrizes] = useState<Prize[]>([]);

  const tournamentId = tournament.id;
  const { data: prizes } = useFetchTournamentPrizes(tournamentId);
  const [tournamentPrizePool, setTournamentPrizePool] = useState<string>(tournament.prizePool ?? "");
  const { mutate: patchTournamentMutate, isLoading: isUpdatingTournament } = usePatchTournament(tournamentId);
  const alert = useAlert();

  const groupedPrizes = useMemo(
    () =>
      prizes?.reduce<{ [key: string]: Prize[] }>((r, a) => {
        r[a.prize] = r[a.prize] || [];
        r[a.prize].push(a);
        return r;
      }, {}),
    [prizes],
  );

  const rows = useMemo(() => {
    return Object.values(groupedPrizes || {}).sort((a, b) => a[0].positionStart - b[0].positionStart);
  }, [groupedPrizes]);

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>Tournament prizes breakdown</DialogTitle>
        </DialogHeader>

        <Table className="border-b">
          <TableHeader>
            <TableRow>
              <TableHead>Prize</TableHead>
              <TableHead>Teams</TableHead>
              <TableHead>Position</TableHead>
              <TableHead>Sub Prizes</TableHead>
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {rows.map((prizes, index) => {
              const prizeTeams = prizes.map((prize) => prize.teamId).filter((teamId) => !!teamId);
              return (
                <TableRow key={index}>
                  <TableCell>{prizes[0].prize}</TableCell>
                  <TableCell>
                    <div className="flex gap-1">
                      {prizeTeams.map((teamId, index) => (
                        <img
                          key={index}
                          className="size-6"
                          src={generateImageUrl("teams", teamId ?? "", {
                            format: "auto",
                            height: "24",
                            width: "auto",
                          })}
                        />
                      ))}
                      {!prizeTeams.length && <>-</>}
                    </div>
                  </TableCell>
                  <TableCell>
                    {prizes[0].positionStart === prizes[0].positionEnd
                      ? `Position ${prizes[0].positionStart}`
                      : `Position: ${prizes[0].positionStart} - ${prizes[0].positionEnd}`}
                  </TableCell>
                  <TableCell>
                    <div className="flex flex-col gap-1 text-xs">
                      {prizes[0].subPrize?.map((subPrize, i) => (
                        <div key={i} className="w-fit rounded-sm bg-secondary/20 px-2 py-0.5">
                          {subPrize}
                        </div>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell className="w-48 text-right">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        setEditingPrizes(prizes);
                        setIsAddEditModalOpen(true);
                      }}
                    >
                      <Pencil className="size-4" />
                    </Button>
                    <Button variant="ghost" size="icon" onClick={() => setDeletingPrizes(prizes)}>
                      <TrashIcon className="size-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <div className="flex w-full items-center justify-between gap-2">
          <Label>Prize Pool</Label>
          <Input
            placeholder="$500,000"
            className="w-48"
            value={tournamentPrizePool}
            onChange={(e) => setTournamentPrizePool(e.target.value)}
          />
        </div>

        {isAddEditModalOpen && (
          <AddEditPrizeModal
            tournamentId={tournamentId}
            isOpen={isAddEditModalOpen}
            onClose={() => {
              setEditingPrizes([]);
              setIsAddEditModalOpen(false);
            }}
            editingPrizes={editingPrizes}
            gameId={gameId}
          />
        )}
        {deletingPrizes.length > 0 && (
          <DeletePrizeModal
            isOpen={!!deletingPrizes.length}
            onClose={() => setDeletingPrizes([])}
            deletingPrizes={deletingPrizes}
          />
        )}

        <DialogFooter>
          <div className="flex items-center gap-4">
            <Button variant="outline" className="w-full" onClick={() => setIsAddEditModalOpen(true)}>
              <PlusIcon className="mr-2 size-4" />
              Add a Prize
            </Button>
            <Button
              variant="default"
              onClick={() =>
                void patchTournamentMutate(
                  { prizePool: tournamentPrizePool },
                  {
                    onError: () => {
                      alert.showFailureAlert("Failed to update prize pool");
                    },
                  },
                )
              }
              disabled={isUpdatingTournament || tournamentPrizePool === (tournament.prizePool ?? "")}
            >
              {isUpdatingTournament ? (
                <>
                  <span className="mr-2">Updating...</span>
                  {/* Add a loading spinner component here if needed */}
                </>
              ) : (
                <>Save</>
              )}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
