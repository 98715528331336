import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { usePatron } from "../../../providers/PatronProvider/hooks/usePatron";
import { useParams } from "react-router-dom";
import { VideoPlayerModule } from "../../../remoteComponents/VideoPlayerModule";
import useFetchBroadcastById from "../../../hooks/useFetchBroadcastById";
import useFetchMatch from "../BroadcastDetails/hooks/useFetchMatch";
import notEmpty from "../../../shared/helpers/notEmpty";
import { DetailedMatch } from "../../../api/tournaments/schemas/matches";
import { events } from "./data/events";
import { useGetGameServerTime } from "./hooks/useGetGameServerTime";
import { useCreateManualTimelineEvent } from "./hooks/useCreateManualTimelineEvent";
import useAlert from "../../../providers/AlertProvider/hooks/useAlert";
import { useListMatchTimelineEvents } from "../../../api/new-timeline/hooks/useListMatchTimelineEvents";
import { DateTime } from "luxon";
import { useHarvestClip } from "./hooks/useHarvestClip";
import { Refresh } from "@mui/icons-material";

const TimelineEvents: FC = () => {
  const { id } = useParams();
  const { patron } = usePatron();
  const alert = useAlert();

  const { mutate: createManualTimelineEvent } = useCreateManualTimelineEvent();
  const { mutate: harvestClip } = useHarvestClip();
  const { data: broadcast } = useFetchBroadcastById({
    patron,
    id: id as string,
  });
  const matchQueryResults = useFetchMatch(broadcast?.seriesIds || []);
  const isLoadingMatch = matchQueryResults.some((result) => result.isLoading);

  const matches = useMemo(() => {
    if (isLoadingMatch) return [];
    return matchQueryResults.map((result) => result.data).filter(notEmpty);
  }, [isLoadingMatch, matchQueryResults]);

  const videoElementRef = useRef<HTMLVideoElement | null>(null);
  const videoElement = videoElementRef.current;
  const [streamStartIsoString, setStreamStartIsoString] = useState<string | undefined>(undefined);
  const [videoStartTime, setVideoStartTime] = useState<number>(0);
  const [videoEndTime, setVideoEndTime] = useState<number>(0);
  const [eventType, setEventType] = useState<string>("");
  const [mapNumber, setMapNumber] = useState<number>(0);
  const [roundNumber, setRoundNumber] = useState<number>(0);
  const [selectedMatchId, setSelectedMatchId] = useState<string>();
  const [selectedMatch, setSelectedMatch] = useState<DetailedMatch>();

  const getVideoStartTime = useCallback(() => {
    if (videoElement && videoElement?.currentTime) {
      setVideoStartTime(Math.round(videoElement.currentTime));
    }
  }, [videoElement]);

  const getVideoEndTime = useCallback(() => {
    if (videoElement && videoElement?.currentTime) {
      setVideoEndTime(Math.round(videoElement.currentTime));
    }
  }, [videoElement]);

  useEffect(() => {
    if (selectedMatchId) {
      setSelectedMatch(matches.find((match) => match.id === selectedMatchId));
    }
  }, [matches, selectedMatchId]);

  const getGameServerTime = useGetGameServerTime({
    programStartDateIsoString: streamStartIsoString,
    videoOffsetSeconds: broadcast?.videoOffset,
  });

  const generateVideoUrl = (videoSrc: string) =>
    broadcast?.videoStartTime ? `${videoSrc}?start=${broadcast?.videoStartTime}` : videoSrc;

  const { data: timelineEvents, isLoading: isTimelineEventsLoading } = useListMatchTimelineEvents({
    matchId: selectedMatch?.id,
  });

  return (
    <Grid container spacing={8}>
      <Grid xs={6} item>
        <Grid container spacing={2} flexDirection={"column"}>
          <Grid item>
            <VideoPlayerModule
              src={broadcast?.videoSrc ? generateVideoUrl(broadcast.videoSrc) : ""}
              controls
              ref={videoElementRef}
              onProgramDateTime={setStreamStartIsoString}
              autoPlay
              muted
            />
          </Grid>
          <Grid item>
            <Typography variant="h5">
              Match Timeline Events <i>(Select match in form, in the right side)</i>:
            </Typography>
            {selectedMatch && isTimelineEventsLoading && <CircularProgress />}
            {timelineEvents && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Event Type</TableCell>
                      <TableCell>Map Number</TableCell>
                      <TableCell>Round Number</TableCell>
                      <TableCell>Occurred At (local time)</TableCell>
                      <TableCell>Is Manual</TableCell>
                      <TableCell align="center">Reharvest Clip</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {timelineEvents
                      ?.filter(
                        (event) =>
                          event.eventType !== "MATCH_START" &&
                          event.eventType !== "MAP_START" &&
                          event.eventType !== "ROUND_END",
                      )
                      .map((timelineEvent) => (
                        <TableRow
                          key={timelineEvent.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{timelineEvent.eventType}</TableCell>
                          <TableCell>{timelineEvent.mapNumber}</TableCell>
                          <TableCell>{timelineEvent.roundNumber}</TableCell>
                          <TableCell>
                            {DateTime.fromISO(timelineEvent.occurredAt).toLocal().toFormat("dd/MM/yyyy HH:mm:ss")}
                          </TableCell>
                          <TableCell>{String(timelineEvent.isManualEvent)}</TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() =>
                                harvestClip(
                                  {
                                    matchId: timelineEvent.matchId,
                                    eventId: timelineEvent.id,
                                  },
                                  {
                                    onSuccess: () => {
                                      alert.showSuccessAlert("Successfully harvested clip");
                                    },
                                    onError: () => {
                                      alert.showFailureAlert("Failed to harvest clip");
                                    },
                                  },
                                )
                              }
                            >
                              <Refresh color="primary" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        {/* CREATE MANUAL TIMELINE EVENT */}
        <Grid container spacing={2} flexDirection={"column"}>
          <Grid item>
            <Grid item>
              <Typography color={"GrayText"} variant="body2">
                Broadcast Start Time:
              </Typography>
              <TextField type="text" value={streamStartIsoString} disabled={true} fullWidth />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  label="Start Time (Seconds)"
                  value={videoStartTime}
                  disabled={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField type="number" label="End Time (Seconds)" value={videoEndTime} disabled={true} fullWidth />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="match-select-label">Match</InputLabel>
              <Select
                labelId="match-select-label"
                value={selectedMatchId}
                label="Match"
                onChange={(e) => setSelectedMatchId(e.target.value)}
              >
                {matches.map((match) => {
                  return (
                    <MenuItem key={match.id} value={match.id}>
                      {match.name} - {match.teamA?.name} vs {match.teamB?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="match-map-select-label">Map</InputLabel>
                  <Select
                    labelId="match-map-select-label"
                    value={mapNumber}
                    label="Map"
                    onChange={(e) => setMapNumber(parseInt(e.target.value.toString()))}
                  >
                    {selectedMatch?.maps
                      .sort((a, b) => (a.scheduledAt?.getTime() || 0) - (b.scheduledAt?.getTime() || 0))
                      .map((map, index) => {
                        return (
                          <MenuItem key={map.id} value={index + 1}>
                            {index + 1} - {map.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  label="Round Number (Enter start round number, if event spans over multiple rounds)"
                  fullWidth
                  value={roundNumber}
                  onChange={(e) => setRoundNumber(parseInt(e.target.value.toString()))}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="event-type-select-label">Event Type</InputLabel>
              <Select
                labelId="event-type-select-label"
                value={eventType}
                label="Event Type"
                onChange={(e) => setEventType(e.target.value)}
              >
                {events.map((event) => {
                  return (
                    <MenuItem key={event.id} value={event.id}>
                      <span style={{ marginRight: "8px" }}>{event.name}</span>
                      <i>({event.description})</i>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container flexDirection={"row"} justifyContent={"space-between"}>
              <Grid item>
                <Grid container flexDirection={"row"} spacing={2}>
                  <Grid item>
                    <Button variant="outlined" onClick={getVideoStartTime}>
                      Get Start Time
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={getVideoEndTime}>
                      Get End Time
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    const gameServerStartTime = getGameServerTime(videoStartTime);
                    const gameServerEndTime = getGameServerTime(videoEndTime);

                    // Applies to both undefined and empty strings
                    if (!selectedMatchId) {
                      alert.showInfoAlert("Please select a match.");
                      return;
                    }

                    // Applies to both undefined and 0
                    if (!mapNumber) {
                      alert.showInfoAlert("Please select a map");
                      return;
                    }
                    if (!roundNumber) {
                      alert.showInfoAlert("Please enter a round number");
                      return;
                    }

                    if (!eventType) {
                      alert.showInfoAlert("Please select an event type");
                      return;
                    }

                    if (!gameServerStartTime || !gameServerEndTime) {
                      alert.showInfoAlert("Please select start and end time");
                      return;
                    }

                    createManualTimelineEvent(
                      {
                        matchId: selectedMatchId,
                        mapNumber,
                        roundNumber,
                        eventType,
                        occurredAt: gameServerStartTime,
                        endedAt: gameServerEndTime,
                      },
                      {
                        onSuccess: () => {
                          alert.showSuccessAlert("Successfully created manual event");
                        },
                        onError: () => {
                          alert.showFailureAlert("Failed to create manual event");
                        },
                      },
                    );
                  }}
                >
                  Create Event
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TimelineEvents;
