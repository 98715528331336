import { z } from "zod";
import { MapNameSchema } from "./MapNameSchema";

export const TemplateSchema = z.object({
  id: z.string(),
  logOffsetSeconds: z.number(),
  matchType: z.enum(["BO1", "BO2", "BO3", "BO5", "BO7"]),
  teams: z.tuple([z.string(), z.string()]),
  videoPath: z.string(),
  maps: z.array(
    z.object({
      name: MapNameSchema,
      logPath: z.string(),
      orderIndex: z.number(),
    }),
  ),
});
