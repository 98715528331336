import { useMutation, useQueryClient } from "@tanstack/react-query";
import { RankingTransaction } from "../../../../api/tournaments/rankings/schemas";
import { deleteRankingTransaction } from "@/api/tournaments/rankings";
import useAlert from "@/providers/AlertProvider/hooks/useAlert";

export default function useDeleteTransaction({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) {
  const queryClient = useQueryClient();
  const alert = useAlert();
  return useMutation({
    mutationKey: ["transaction", "delete"],
    mutationFn: async ({ transactionId }: { transactionId: string }): Promise<RankingTransaction | undefined> => {
      if (transactionId) {
        const transactionResponse = await deleteRankingTransaction(transactionId);
        return transactionResponse;
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["rankings", "transactions", "rl"] }); // refetch transactions
      alert.showSuccessAlert("Successfully deleted transaction");
      onSuccess();
    },
    onError: () => {
      alert.showFailureAlert("Error while deleting transaction");
      onError();
    },
  });
}
