import { useMutation } from "@tanstack/react-query";

import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";

import { createSuspension } from "../../../../../api/chat";
import { Suspension } from "../../../../../types/Suspension";

const useSuspenseUser = () => {
  const { showFailureAlert } = useAlert();

  return useMutation({
    mutationKey: ["suspenseUser"],
    mutationFn: (data: Omit<Suspension, "suspensionId" | "endTime" | "createdAt">) =>
      createSuspension({
        ...data,
      }),
    onError: () => {
      showFailureAlert("Error while suspending the user");
    },
  });
};

export default useSuspenseUser;
