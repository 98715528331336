import axios from "axios";
import { createUploadLink } from "..";

export const uploadImage = async ({
  image,
  folder,
  filename,
}: {
  image: File;
  folder: string;
  filename: string;
}): Promise<boolean> => {
  let uploadUrl = "";
  try {
    const { uploadUrl: createdUploadUrl } = await createUploadLink(folder, filename);
    uploadUrl = createdUploadUrl;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error creating upload link: ", error);
    return false;
  }

  try {
    await axios.put(uploadUrl, image, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Length": image.size,
        "Content-Type": image.type,
      },
    });
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error uploading image: ", error);
    return false;
  }
};
