import { Combobox } from "@/components/Combobox";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ChangeEvent, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetTournaments } from "../../../api/tournaments/getTournaments/useGetTournaments";
import useFetchCircuits from "../hooks/useFetchCircuits";
import { CreateAndEditTournamentModal } from "./components/CreateEditTournamentModal";
import { TournamentsTable } from "./components/TournamentsTable";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { CircleAlert } from "lucide-react";

const TournamentsPage = () => {
  const { gameId, tournamentId } = useParams();
  const [searchParams] = useSearchParams();

  const [editingTournamentId, setEditingTournamentId] = useState<string | undefined>(tournamentId);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState<boolean>(!!editingTournamentId);
  const [filterValue, setFilterValue] = useState<string | null>(null);
  const [circuitId, setCircuitId] = useState<string | undefined>(searchParams.get("circuitId") || undefined);
  const [showActiveOnly, setShowActiveOnly] = useState(false);

  const { data: allTournaments, isError, isLoading } = useGetTournaments({ game: gameId });
  const { data: circuits } = useFetchCircuits(gameId);
  const navigate = useNavigate();

  const shownTournaments = useMemo(() => {
    if (allTournaments) {
      let filtered = allTournaments;

      if (showActiveOnly) {
        filtered = filtered.filter((tournament) => !tournament.endDate || new Date(tournament.endDate) > new Date());
      }

      if (circuitId && filterValue) {
        return filtered.filter(
          (tournament) =>
            tournament.circuitId === circuitId && tournament.name.toLowerCase().includes(filterValue.toLowerCase()),
        );
      }
      if (filterValue) {
        return filtered.filter((tournament) => tournament.name.toLowerCase().includes(filterValue.toLowerCase()));
      }
      if (circuitId) {
        return filtered.filter((tournament) => tournament.circuitId === circuitId);
      }
      return filtered;
    } else {
      return [];
    }
  }, [circuitId, allTournaments, filterValue, showActiveOnly]);

  const shownCircuits = useMemo(() => {
    if (gameId && circuits) {
      return circuits.filter((circuit) => circuit.gameId === gameId);
    } else {
      return [];
    }
  }, [gameId, circuits]);

  function handleFilterChange(event: ChangeEvent<HTMLInputElement>) {
    const filterValue = event.target?.value;
    if (!filterValue) {
      setFilterValue(null);
    } else {
      setFilterValue(filterValue);
    }
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col gap-6 p-7">
        <h1 className="text-3xl font-bold">Tournaments</h1>

        <div className="sticky top-0 z-10 flex items-center justify-between border-b bg-background/95 py-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <div className="flex items-center gap-3">
            <Input placeholder="Search tournaments..." onChange={handleFilterChange} className="w-[250px]" />
            <Combobox
              value={circuitId ?? ""}
              options={shownCircuits?.map((circuit) => ({
                value: circuit.id,
                label: circuit.name,
              }))}
              placeholder="Select circuit..."
              className="w-80"
              onChange={(circuitId) => setCircuitId(circuitId)}
            />
            <div className="flex items-center space-x-2">
              <Switch id="show-active" checked={showActiveOnly} onCheckedChange={setShowActiveOnly} />
              <Label htmlFor="show-active">Only show active</Label>
            </div>
          </div>

          <Button variant="outline" onClick={() => setIsAddEditModalOpen(true)}>
            Add Tournament
          </Button>
        </div>

        {!isLoading && isError && (
          <div className="flex items-center gap-2 text-sm">
            <CircleAlert className="text-red-500" /> Error Fetching tournaments!
          </div>
        )}

        <div className="flex flex-wrap gap-4">
          <TournamentsTable
            tournaments={shownTournaments}
            onEdit={(tournamentId) => {
              setEditingTournamentId(tournamentId);
              setIsAddEditModalOpen(true);
            }}
            onNavigate={(tournamentId) => void navigate(`/to/tournaments/${gameId}/${tournamentId}`)}
          />
        </div>
        <CreateAndEditTournamentModal
          isOpen={isAddEditModalOpen}
          editingTournamentId={editingTournamentId}
          selectedCircuitId={circuitId}
          onClose={() => {
            setIsAddEditModalOpen(false);
            setEditingTournamentId(undefined);
          }}
        />
      </div>
    </div>
  );
};

export default TournamentsPage;
