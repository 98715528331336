import { ReactNode } from "react";
import classNames from "classnames";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Typography, DialogContentText as DCT } from "@mui/material";

export function DialogContentText({
  title,
  contentTextValue,
  copy = false,
  className,
}: {
  title: string;
  contentTextValue?: ReactNode;
  copy?: boolean;
  className?: string;
}) {
  return (
    <div className={classNames("mt-1 pb-2", className)}>
      <Typography variant="h6">{title}</Typography>
      <DCT>
        {contentTextValue}
        {copy && (
          <ContentCopyIcon
            sx={{ verticalAlign: "middle", ml: 1, cursor: "pointer" }}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-base-to-string
              void navigator.clipboard.writeText(String(contentTextValue));
            }}
          />
        )}
      </DCT>
    </div>
  );
}
