import { useGetTournamentTeams } from "@/api/tournaments/getTournamentTeams/useGetTournamentTeams";
import { MatchByTournamentIdResponse } from "@/api/tournaments/schemas/matches";
import { Team } from "@/api/tournaments/schemas/teams";
import { Combobox } from "@/components/Combobox";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { generateImageUrl } from "@/helpers/images/generateImageUrl";
import { toast } from "@/hooks/use-toast";
import useUpdateMatch from "@/routes/Tournaments/hooks/matches/useUpdateMatch";
import useFetchTeams from "@/routes/Tournaments/hooks/teams/useFetchTeams";
import { useEffect, useMemo, useState } from "react";

function Row({
  gameId,
  match,
  matchClick,
  teamsOptions,
  tournamentId,
  teams,
}: {
  gameId: string;
  match: MatchByTournamentIdResponse;
  matchClick: (match: MatchByTournamentIdResponse) => void;
  teamsOptions: { label: string; value: string; image?: string }[];
  teams: Team[];
  tournamentId: string;
}) {
  const matchTeamAScore = match.teams?.[0]?.score;
  const matchTeamBScore = match.teams?.[1]?.score;
  const matchTeamA = match.teams?.[0]?.team?.id;
  const matchTeamB = match.teams?.[1]?.team?.id;
  const [teamAScore, setTeamAScore] = useState(matchTeamAScore);
  const [teamBScore, setTeamBScore] = useState(matchTeamBScore);
  const [teamA, setTeamA] = useState(matchTeamA);
  const [teamB, setTeamB] = useState(matchTeamB);

  useEffect(() => {
    setTeamAScore(matchTeamAScore);
    setTeamBScore(matchTeamBScore);
    setTeamA(matchTeamA);
    setTeamB(matchTeamB);
  }, [matchTeamAScore, matchTeamBScore, matchTeamA, matchTeamB]);

  const { mutate: submitUpdate } = useUpdateMatch({
    tournamentId,
    onSuccess: () => {
      toast({
        title: "Successfully updated match",
      });
    },
    onError: () => {
      toast({
        title: "Error while updating match data",
      });
    },
  });

  const hasChanged = useMemo(() => {
    return (
      teamAScore !== match.teams?.[0]?.score ||
      teamBScore !== match.teams?.[1]?.score ||
      teamA !== match.teams?.[0]?.team.id ||
      teamB !== match.teams?.[1]?.team.id
    );
  }, [teamAScore, teamBScore, teamA, teamB, match]);

  const handleSubmit = () => {
    const teamAMatch = teams.find((team) => team.id === teamA);
    const teamBMatch = teams.find((team) => team.id === teamB);

    if (!teamAMatch || !teamBMatch) {
      toast({
        title: "Team not found",
      });
      return;
    }

    submitUpdate({
      gameId,
      match: {
        ...match,

        teams: [
          {
            teamIndex: 10,
            team: teamAMatch,
            score: teamAScore,
          },
          {
            teamIndex: 20,
            team: teamBMatch,
            score: teamBScore,
          },
        ],
      },
      originalMatch: match,
    });
  };
  return (
    <TableRow key={match.matchId}>
      <TableCell className="w-fit text-left">{match.matchIndex}</TableCell>
      <TableCell className="w-fit text-left">{match.matchType}</TableCell>
      <TableCell className="">
        <span className="line-clamp-1 w-full">{match.matchName}</span>
      </TableCell>

      <TableCell className="items-start text-left">
        <Combobox value={teamA} options={teamsOptions} onChange={(value) => setTeamA(value)} className="w-48" />
      </TableCell>
      <TableCell className="items-start text-left">
        <Combobox value={teamB} options={teamsOptions} onChange={(value) => setTeamB(value)} className="w-48" />
      </TableCell>
      <TableCell className="text-right">
        <Input
          type="number"
          className="w-20"
          value={teamAScore ?? ""}
          onChange={(e) => setTeamAScore(Number(e.target.value))}
        />
      </TableCell>
      <TableCell className="text-left">
        <Input
          type="number"
          className="w-20"
          onChange={(e) => setTeamBScore(Number(e.target.value))}
          value={teamBScore ?? ""}
        />
      </TableCell>
      <TableCell className="text-right">
        <div className="flex place-content-end gap-2">
          <Button variant="outline" onClick={() => matchClick(match)}>
            Edit
          </Button>
          <Button variant={hasChanged ? "default" : "ghost"} onClick={handleSubmit} disabled={!hasChanged}>
            Save
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
}

export function MatchesTable({
  matches,
  matchClick,
  gameId,
  tournamentId,
}: {
  matches: MatchByTournamentIdResponse[];
  matchClick: (match: MatchByTournamentIdResponse) => void;
  gameId: string;
  tournamentId: string;
}) {
  const { data: tournamentTeams } = useGetTournamentTeams(tournamentId);
  const { data: teams } = useFetchTeams(gameId);
  const teamsOptions = useMemo(() => {
    const tournamentTeamsFiltered = tournamentTeams?.filter((team) => team.team?.id);
    return tournamentTeamsFiltered?.map((team) => ({
      label: team.team?.name ?? "",
      value: team.team?.id ?? "",
      image: generateImageUrl("teams", team.team?.id ?? ""),
    }));
  }, [tournamentTeams]);
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="text-left">Index</TableHead>
          <TableHead className="text-left">Type</TableHead>
          <TableHead className="text-left">Name</TableHead>
          <TableHead className="text-left">Team A</TableHead>
          <TableHead className="text-left">Team B</TableHead>
          <TableHead className="text-left">Team A Score</TableHead>
          <TableHead className="text-left">Team B Score</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {matches.map((match) => (
          <Row
            key={match.matchId}
            gameId={gameId}
            match={match}
            matchClick={matchClick}
            teamsOptions={teamsOptions ?? []}
            tournamentId={tournamentId}
            teams={teams ?? []}
          />
        ))}
      </TableBody>
    </Table>
  );
}
