import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { RocketLeagueStatsTeam } from "@/api/rocketLeagueStatistics/getRLCSStatTeams";
import { useState } from "react";
import { Label } from "@/components/ui/label";
import { ComboboxRlPlayers } from "../../PlayersGrouping/components/ComboboxRlPlayers";
import { Team } from "@/api/tournaments/schemas/teams";

export function UpsertTeamModal({
  open,
  tournamentTeams,
  team,
  handleClose,
  handleSubmit,
  nameDisabled = false,
}: {
  open: boolean;
  team: RocketLeagueStatsTeam;
  tournamentTeams: Team[];
  handleClose: () => void;
  handleSubmit: (updatedTeam: RocketLeagueStatsTeam) => void;
  nameDisabled?: boolean;
}) {
  const [updatedTeam, setUpdatedTeam] = useState<RocketLeagueStatsTeam>(team);

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && handleClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Assign team</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Label htmlFor="teamId">Team ID</Label>
            <Input id="teamId" placeholder="Team ID" value={team.id} disabled />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="teamNames">Team names</Label>
            <Input id="teamNames" placeholder="Team name" value={updatedTeam.teamNames.join(", ")} disabled />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="teamDisplayName">Team Group Name</Label>
            <Input
              id="teamDisplayName"
              placeholder="Team group name"
              disabled={nameDisabled}
              value={updatedTeam.teamDisplayName}
              onChange={(e) => setUpdatedTeam({ ...updatedTeam, teamDisplayName: e.target.value })}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="assignedTeam">Assigned Team</Label>
            <ComboboxRlPlayers
              options={tournamentTeams.map((team) => ({ value: team.id, label: team.name }))}
              value={updatedTeam.assignedTeamId || ""}
              onChange={(value) => {
                if (nameDisabled) {
                  setUpdatedTeam({
                    ...updatedTeam,
                    assignedTeamId: value,
                    teamDisplayName: tournamentTeams.find((team) => team.id === value)?.name || "",
                  });
                } else {
                  setUpdatedTeam({ ...updatedTeam, assignedTeamId: value });
                }
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <DialogFooter>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={!updatedTeam.assignedTeamId || !updatedTeam.teamDisplayName}
              onClick={() => handleSubmit(updatedTeam)}
            >
              {team.assignedTeamId ? "Update" : "Assign"}
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
}
