import { Check, Close, Edit } from "@mui/icons-material";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import { Avatar } from "../../../../../types/Avatar";
import { useEffect, useState } from "react";
import { generateImageUrl } from "../../../../../helpers/images/generateImageUrl";
import { generateAssetsId } from "../../../../../helpers/images/generateImageUrl/generateAssetsId";
import { usePatron } from "../../../../../providers/PatronProvider/hooks/usePatron";

interface AvatarsTableProps {
  avatars: Avatar[] | undefined;
  setEditingAvatar: (avatarId: string) => void;
}

export const AvatarsTable = ({ avatars, setEditingAvatar }: AvatarsTableProps) => {
  const [sortedAvatars, setSortedAvatars] = useState<Avatar[]>([]);
  const { patron } = usePatron();

  useEffect(() => {
    if (!avatars) return;

    const sortedAvatars = avatars.sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1));
    setSortedAvatars(sortedAvatars);
  }, [avatars]);

  return (
    <TableContainer style={{ height: "80vh" }}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Icon</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Order index</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Rarity</TableCell>
            <TableCell align="center">Hidden</TableCell>
            <TableCell align="center">Unlock Only?</TableCell>
            <TableCell align="center">Claimable</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAvatars?.map((avatar) => (
            <TableRow key={avatar.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                <img
                  src={generateImageUrl("avatars", generateAssetsId(avatar.id, "2d"), {
                    width: "80",
                    height: "80",
                    format: "auto",
                    patron,
                  })}
                  alt={avatar.name}
                  height={40}
                  width={40}
                  style={{ marginRight: "5px" }}
                />
                <img
                  src={generateImageUrl("avatars", generateAssetsId(avatar.id, "3d"), {
                    width: "80",
                    height: "80",
                    format: "auto",
                    patron,
                  })}
                  alt={avatar.name}
                  height={40}
                  width={40}
                  style={{ marginLeft: "5px" }}
                />
              </TableCell>
              <TableCell>{avatar.name}</TableCell>
              <TableCell>{avatar.orderIndex}</TableCell>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {avatar.color}{" "}
                  <div
                    style={{
                      marginLeft: "10px",
                      height: "15px",
                      width: "15px",
                      backgroundColor: `${avatar.color}`,
                    }}
                  ></div>
                </div>
              </TableCell>
              <TableCell>{avatar.rarity ?? "N/A"}</TableCell>
              <TableCell align="center">
                {avatar.hidden ? <Check color="success" /> : <Close color="error" />}
              </TableCell>
              <TableCell align="center">
                {avatar.isUnlockOnly ? <Check color="success" /> : <Close color="error" />}
              </TableCell>
              <TableCell align="center">
                {avatar.isClaimable ? <Check color="success" /> : <Close color="error" />}
              </TableCell>
              <TableCell>
                <Button onClick={() => setEditingAvatar(avatar.id)}>
                  <Edit />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
