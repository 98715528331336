import { useMemo } from "react";
import { Emoji } from "../../../../../../../../../types/Emoji";
import useFetchEmojis from "../../../../../../../../UserProfile/hooks/useFetchEmojis";
import { SelectEmpty } from "./components/selectEmpty";
import SelectWithImage, { SelectWithImageOption } from "./components/selectWithImage";
import { generateImageUrl } from "../../../../../../../../../helpers/images/generateImageUrl";

type CaseRarity = "silver" | "gold" | "diamond";

const convertRarity = (rarity: CaseRarity) => {
  const rarities = {
    silver: "silverRarity",
    diamond: "diamondRarity",
    gold: "goldRarity",
  };

  return rarities[rarity] as "silverRarity" | "diamondRarity" | "goldRarity";
};

interface EmojiCaseProps {
  rarity: CaseRarity;
  pickedEmojis: string[];
  hiddenEmojis: string[];
  onClick: (
    rarity: "diamondRarity" | "silverRarity" | "goldRarity",
    oldValue: string | null,
    newValue: string | null,
  ) => void;
}

const convertEmojisToOptions = (emojis?: Emoji[]): SelectWithImageOption[] => {
  return (
    emojis?.map((emoji) => ({
      id: emoji.id,
      name: emoji.name,
      image: generateImageUrl("emojis", emoji.id, {
        width: "80",
        format: "auto",
      }),
    })) ?? []
  );
};

export const EmojiCase = ({ rarity, pickedEmojis, hiddenEmojis, onClick }: EmojiCaseProps) => {
  const { data: emojis } = useFetchEmojis();

  const emojisToUse = useMemo(() => {
    const converted = convertEmojisToOptions(emojis?.filter((value) => value.isUnlockOnly));

    return converted.filter((value) => !hiddenEmojis.includes(value.id));
  }, [emojis, hiddenEmojis]);

  const pickedEmojisToUse = useMemo(() => {
    const converted = convertEmojisToOptions(emojis);

    return converted
      .filter((value) => pickedEmojis.includes(value.id))
      .sort((a, b) => {
        return pickedEmojis.indexOf(a.id) - pickedEmojis.indexOf(b.id);
      });
  }, [emojis, pickedEmojis]);

  return (
    <>
      <div className="w-full gap-3">
        <span className="pb-3 pl-3 capitalize font-style-label-3">{rarity}</span>
        <div className="grid grid-cols-3 gap-3 pt-3">
          {pickedEmojisToUse.map((value) => {
            return (
              <SelectWithImage
                key={value.id}
                values={emojisToUse}
                selected={value}
                onChange={(newValue) => onClick(convertRarity(rarity), value.id, newValue.id)}
                label={rarity}
              />
            );
          })}
          <SelectWithImage
            values={emojisToUse}
            onChange={(value) => onClick(convertRarity(rarity), null, value.id)}
            label={"+"}
          />
          <SelectEmpty
            onRemove={() => {
              const lastEntry = pickedEmojisToUse[pickedEmojisToUse.length - 1];

              if (!lastEntry) return;

              onClick(convertRarity(rarity), lastEntry.id, null);
            }}
            showRemove={pickedEmojisToUse.length > 0}
          />
        </div>
      </div>
    </>
  );
};
