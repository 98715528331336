import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { cn } from "@/lib/utils";
import { ExternalLink, Plus, Save, Trash } from "lucide-react";
import { useMemo, useState } from "react";
import { useAddTournamentSponsor } from "../../../../../../api/tournaments/addTournamentSponsor/useAddTournamentSponsor";
import { usePatchTournament } from "../../../../../../api/tournaments/hooks/usePatchTournament";
import { generateImageUrl } from "../../../../../../helpers/images/generateImageUrl";
import useAlert from "../../../../../../providers/AlertProvider/hooks/useAlert";
import useFetchTournamentById from "@/routes/Tournaments/hooks/useFetchTournamentById";
import { Loader } from "@/shared/components/Loader";

export const TournamentSponsorsAccordion = ({
  tournamentId,
  sponsorsHeader,
  setSponsorsHeader,
}: {
  tournamentId: string;
  sponsorsHeader: string;
  setSponsorsHeader: (header: string) => void;
}) => {
  const { data: tournament } = useFetchTournamentById(tournamentId);

  const [isAddSponsorModalOpen, setIsAddSponsorModalOpen] = useState(false);
  const [deletingSponsor, setDeletingSponsor] = useState<{ logoKey: string; url: string } | undefined>(undefined);

  return (
    <Accordion type="single" collapsible>
      <AccordionItem value="sponsors">
        <AccordionTrigger>
          <div className="flex items-center gap-4">
            <span className="font-bold">Sponsors</span>
            <span className="text-sm text-muted-foreground">
              {tournament?.metadata?.sponsors?.items.length ?? "None"} assigned
            </span>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="flex items-start gap-4">
            <div className="grid w-full gap-1.5">
              <Label htmlFor="header">Header text</Label>
              <Input
                id="header"
                placeholder="This tournament is sponsored by"
                value={sponsorsHeader}
                onChange={(e) => setSponsorsHeader(e.target.value)}
              />
              <p className="text-sm text-muted-foreground">
                This text appears above the list of sponsors. It is optional, and can be left blank.
              </p>
            </div>
          </div>
          <Separator className="my-4" />
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Logo</TableHead>
                <TableHead>Link</TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {tournament?.metadata?.sponsors?.items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <img
                      className="h-8 object-contain object-center"
                      src={generateImageUrl("tournament-sponsor", item.logoKey, {
                        format: "auto",
                        height: "32",
                      })}
                      alt="logo"
                    />
                  </TableCell>
                  <TableCell>
                    <a href={item.url} target="_blank" rel="noreferrer" className="flex items-center hover:underline">
                      {item.url}
                      <ExternalLink className="ml-2 size-4" />
                    </a>
                  </TableCell>
                  <TableCell>
                    <Button variant="destructive" size="icon" onClick={() => setDeletingSponsor(item)}>
                      <Trash className="size-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={3}>
                  <Button variant="outline" size="sm" onClick={() => setIsAddSponsorModalOpen(true)}>
                    <Plus className="mr-2 size-4" />
                    Add a Sponsor
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionContent>
      </AccordionItem>
      {isAddSponsorModalOpen && (
        <AddSponsorModal
          isOpen={isAddSponsorModalOpen}
          onClose={() => setIsAddSponsorModalOpen(false)}
          tournamentId={tournamentId}
        />
      )}
      {deletingSponsor && (
        <DeleteSponsorModal
          isOpen={!!deletingSponsor}
          onClose={() => setDeletingSponsor(undefined)}
          sponsor={deletingSponsor}
          tournamentId={tournamentId}
        />
      )}
    </Accordion>
  );
};

const DeleteSponsorModal = ({
  isOpen,
  onClose,
  sponsor,
  tournamentId,
}: {
  isOpen: boolean;
  onClose: () => void;
  sponsor: { logoKey: string; url: string };
  tournamentId: string;
}) => {
  const alert = useAlert();
  const { data: tournament } = useFetchTournamentById(tournamentId);
  const { mutate: patchTournament, isLoading: isPatchingTournament } = usePatchTournament(tournamentId);

  const handleDeleteSponsor = () => {
    if (!tournament?.metadata?.sponsors?.items) return; // Nothing to delete

    patchTournament(
      {
        metadata: {
          ...tournament.metadata,
          sponsors: {
            ...tournament.metadata.sponsors,
            items: tournament?.metadata?.sponsors?.items.filter((item) => item.logoKey !== sponsor.logoKey),
          },
        },
      },
      {
        onError: () => {
          alert.showFailureAlert("Failed to delete sponsor");
        },
        onSuccess: () => {
          alert.showSuccessAlert("Sponsor deleted successfully");
          onClose();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete Sponsor?</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <img
            className="h-12 object-contain object-center"
            src={generateImageUrl("tournament-sponsor", sponsor.logoKey, { format: "auto", height: "48" })}
            alt="logo"
          />
          <DialogDescription>{sponsor.url}</DialogDescription>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={handleDeleteSponsor} disabled={isPatchingTournament}>
            {isPatchingTournament ? (
              <>
                <Loader className="w-40" />
                Deleting...
              </>
            ) : (
              <>
                <Trash className="mr-2 size-4" />
                Delete
              </>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const AddSponsorModal = ({
  isOpen,
  onClose,
  tournamentId,
}: {
  isOpen: boolean;
  onClose: () => void;
  tournamentId: string;
}) => {
  const { mutate: addTournamentSponsor, isLoading: isAddingSponsor } = useAddTournamentSponsor({
    tournamentId,
  });
  const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
  const imageUrl = useMemo(() => (selectedImage ? URL.createObjectURL(selectedImage) : null), [selectedImage]);
  const [link, setLink] = useState("");
  const alert = useAlert();

  const handleAddSponsor = () => {
    if (!selectedImage || !link) return;

    addTournamentSponsor(
      { imageFile: selectedImage, url: link },
      {
        onError: () => {
          alert.showFailureAlert("Failed to add sponsor");
        },
        onSuccess: () => {
          alert.showSuccessAlert("Sponsor added successfully");
          onClose();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Add a Sponsor</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="grid gap-1.5">
            <Label htmlFor="link">Link</Label>
            <Input id="link" value={link} onChange={(e) => setLink(e.target.value)} />
          </div>
          <div
            className={cn("group relative mx-auto flex h-40 w-96 items-center justify-center rounded-md border", {
              "bg-transparent": imageUrl,
              "bg-muted": !imageUrl,
            })}
          >
            {imageUrl && (
              <img
                src={imageUrl}
                alt="preview"
                className="size-full object-contain transition-all group-hover:opacity-10"
              />
            )}
            <Button
              variant="secondary"
              className={cn("absolute left-0 top-0 h-full w-full opacity-0 transition-all group-hover:opacity-100", {
                "opacity-100": !imageUrl,
              })}
              asChild
            >
              <label>
                {imageUrl ? "Replace image" : "Add an image"}
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => {
                    if (!e.target.files?.length) return;
                    setSelectedImage(e.target.files[0]);
                  }}
                />
              </label>
            </Button>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleAddSponsor} disabled={isAddingSponsor}>
            {isAddingSponsor ? (
              <>
                <Loader className="w-40" />
                Adding...
              </>
            ) : (
              <>
                <Save className="mr-2 size-4" />
                Add
              </>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
