import { useNavigate, useParams } from "react-router-dom";
import useGetClipCuratorOperations from "../hooks/useGetClipCuratorOperations";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ClipCuratorOperation } from "../../../api/video/schemas";
import { Button, Chip, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { clipsCuratorBaseUrl } from "../../../config";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useInterval } from "usehooks-ts";
import { Info } from "@mui/icons-material";

const ClipCuratorOperations = () => {
  const [allOperations, setAllOperations] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useGetClipCuratorOperations(allOperations);
  const queryClient = useQueryClient();

  useInterval(() => {
    // eslint-disable-next-line no-console
    queryClient.invalidateQueries(["clips-curator", "operations", allOperations ? "all" : "my"]).catch(console.error);
  }, 5000);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("all")) {
      setAllOperations(true);
    }
  }, []);

  useEffect(() => {
    if (allOperations) {
      navigate(`/content/clip-curator/operations/?all=true`);
    } else {
      navigate(`/content/clip-curator/operations/`);
    }
  }, [allOperations, navigate]);

  const columns: GridColDef[] = [
    { field: "operationType", headerName: "Export Type", width: 180 },
    {
      field: "requesterEmail",
      headerName: "Requester Email",
      width: 180,
      renderCell: (params) => (params.value as ClipCuratorOperation["requesterEmail"]) || "N/A",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 180,
      // @ts-expect-error adding type check to build, wont fix
      valueGetter: (params) => new Date(params.value as ClipCuratorOperation["createdAt"]).getTime(),
      renderCell: (params) => new Date(params.value as ClipCuratorOperation["createdAt"]).toLocaleString(),
    },
    {
      field: "state",
      headerName: "Status",
      width: 180,
      renderCell: (params) => {
        return (
          <Chip
            label={params.value as string}
            color={params.value === "COMPLETED" ? "success" : params.value === "FAILED" ? "error" : "default"}
          />
        );
      },
    },
    {
      field: "operationId",
      headerName: "",
      width: 130,
      renderCell: (params) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        switch (params.row.state) {
          case "PENDING":
          case "RUNNING":
            return <CircularProgress />;
          case "COMPLETED":
            return (
              <Button
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                disabled={params.row.state !== "COMPLETED"}
                onClick={() =>
                  window.open(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                    params.row?.output,
                    "_blank",
                  )
                }
                variant="outlined"
              >
                Download
              </Button>
            );
          case "FAILED":
            return (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              <Tooltip title={params.row.output}>
                <IconButton>
                  <Info />
                </IconButton>
              </Tooltip>
            );
          default:
            return null;
        }
      },
    },
  ];

  if (id) {
    // TODO: Move out into separate component, and access operation by id from API
    const operation = data?.find((operation) => operation.operationId === id);

    return (
      <div className="flex flex-col gap-y-4 p-4">
        <div className="flex items-center gap-x-2">
          <Button onClick={() => navigate(`/content/clip-curator/operations/`)} variant="outlined">
            Back
          </Button>
          <h1 className="font-style-desktop-h4">Export [{id}]</h1>
          <Button
            onClick={() => {
              queryClient
                .invalidateQueries(["clips-curator", "operations", allOperations ? "all" : "my"])
                // eslint-disable-next-line no-console
                .catch(console.error);
            }}
          >
            Refresh data
          </Button>
        </div>
        <Button
          size="large"
          variant="contained"
          onClick={() =>
            window.open(`${clipsCuratorBaseUrl}/${operation?.operationType}/${operation?.operationId}.mp4`, "_blank")
          }
        >
          Access result
        </Button>
        <pre>
          <code>{JSON.stringify(operation, null, 2)}</code>
        </pre>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-4 p-4">
      <h1 className="font-style-desktop-h3">{allOperations ? "All" : "My"} exports</h1>
      <div className="mr-auto flex items-center gap-x-2">
        <Button
          onClick={() => {
            navigate(`/content/clip-curator/`);
          }}
          className="ml-auto mr-8"
          variant="contained"
        >
          Back
        </Button>
        <Chip
          onClick={() => {
            setAllOperations(false);
          }}
          color={allOperations ? "default" : "primary"}
          label="My exports"
        />
        <Chip
          onClick={() => {
            setAllOperations(true);
          }}
          color={allOperations ? "primary" : "default"}
          label="All exports"
        />
      </div>
      {data?.length > 0 ? (
        <DataGrid
          columns={columns}
          rowSelection={false}
          getRowId={(row: ClipCuratorOperation) => row.operationId}
          loading={isLoading}
          rows={data || []}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
        />
      ) : (
        <h2>No exports found</h2>
      )}
    </div>
  );
};

export default ClipCuratorOperations;
