import { Combobox } from "@/components/Combobox";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { generateImageUrl } from "@/helpers/images/generateImageUrl";
import { Loader } from "@/shared/components/Loader";
import { AlertTriangle, Info, Pencil, RefreshCw, Save, Trash } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useCreateTournamentTeam } from "../../../../../../api/tournaments/createTournamentTeam/useCreateTournamentTeam";
import { useDeleteTournamentTeam } from "../../../../../../api/tournaments/deleteTournamentTeam/useDeleteTournamentTeam";
import { useGetTournamentTeams } from "../../../../../../api/tournaments/getTournamentTeams/useGetTournamentTeams";
import { TournamentTeamSlot } from "../../../../../../api/tournaments/schemas/tournaments";
import { assetsURL } from "../../../../../../config";
import useAlert from "../../../../../../providers/AlertProvider/hooks/useAlert";
import useFetchTeams from "../../../../hooks/teams/useFetchTeams";
import useFetchCircuitById from "../../../../hooks/useFetchCircuitById";
import useFetchTournamentById from "../../../../hooks/useFetchTournamentById";

export const TournamentTeamsAccordion = ({ tournamentId, circuitId }: { tournamentId: string; circuitId: string }) => {
  const { data: tournament } = useFetchTournamentById(tournamentId);
  const { data: circuit } = useFetchCircuitById(circuitId);
  const { data: tournamentTeams, isLoading: isLoadingTournamentTeams } = useGetTournamentTeams(tournamentId);
  const [isAddEditTeamSlotModalOpen, setIsAddEditTeamSlotModalOpen] = useState(false);
  const [editingTeamSlot, setEditingTeamSlot] = useState<TournamentTeamSlot | undefined>();
  const { mutateAsync: createTournamentTeam, isLoading: isCreatingTournamentTeam } = useCreateTournamentTeam({
    tournamentId,
  });

  const handleAddMissingSpots = async () => {
    const missingSpots = (tournament?.numberOfTeams ?? 0) - (tournamentTeams?.length ?? 0);
    // Max of index field in the tournamentTeams array
    const maxIndex = Math.max(...(tournamentTeams?.map((teamSlot) => teamSlot.index) ?? []), 0);
    await Promise.all(
      Array.from({ length: missingSpots }).map((_, i) => {
        return createTournamentTeam({
          tournamentId,
          index: maxIndex + i + 1,
          teamId: null,
          qualificationMethod: undefined,
          metadata: null,
        });
      }),
    );
  };

  return (
    <>
      <Accordion type="single" collapsible className="mt-4">
        <AccordionItem value="teams">
          <AccordionTrigger>
            <div className="flex items-center gap-2">
              <span className="font-bold">Teams</span>
              {!isLoadingTournamentTeams && (tournamentTeams?.length ?? 0) < (tournament?.numberOfTeams ?? 0) && (
                <span className="ml-4 flex items-center text-sm text-muted-foreground">
                  <Info className="mr-2 size-4" />
                  {`Missing ${(tournament?.numberOfTeams ?? 0) - (tournamentTeams?.length ?? 0)} slots out of ${
                    tournament?.numberOfTeams ?? 0
                  }`}
                </span>
              )}
              {!isLoadingTournamentTeams && (tournamentTeams?.length ?? 0) > (tournament?.numberOfTeams ?? 0) && (
                <span className="ml-4 text-sm text-muted-foreground">
                  <AlertTriangle className="mr-2 size-4" />
                  Too many slots defined! Delete{" "}
                  {`${(tournamentTeams?.length ?? 0) - (tournament?.numberOfTeams ?? 0)} slots`}
                </span>
              )}
              {!isLoadingTournamentTeams && (tournamentTeams?.length ?? 0) === (tournament?.numberOfTeams ?? 0) && (
                <span className="ml-4 text-sm text-muted-foreground">
                  {`${tournamentTeams?.filter((teamSlot) => teamSlot.team).length ?? 0} teams assigned to slots`}
                </span>
              )}
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>#</TableHead>
                  <TableHead>Team</TableHead>
                  <TableHead>Team ID</TableHead>
                  <TableHead>Team External ID</TableHead>
                  <TableHead>Qualification Method</TableHead>
                  <TableHead>Bounty Value</TableHead>
                  <TableHead>Seed</TableHead>
                  <TableHead />
                </TableRow>
              </TableHeader>
              <TableBody>
                {tournamentTeams?.map((teamSlot, index) => (
                  <TournamentTeamRow
                    key={index}
                    teamSlot={teamSlot}
                    index={index}
                    tournamentId={tournamentId}
                    onEdit={(teamSlot) => {
                      setIsAddEditTeamSlotModalOpen(true);
                      setEditingTeamSlot(teamSlot);
                    }}
                  />
                ))}

                {(tournamentTeams?.length ?? 0) < (tournament?.numberOfTeams ?? 0) && (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div className="flex items-center gap-4">
                        <Button
                          onClick={() => void handleAddMissingSpots()}
                          disabled={isCreatingTournamentTeam}
                          size="sm"
                          className="gap-2"
                        >
                          {isCreatingTournamentTeam ? <Loader className="w-40" /> : <RefreshCw className="size-4" />}
                          Add {(tournament?.numberOfTeams ?? 0) - (tournamentTeams?.length ?? 0)} missing slots
                        </Button>
                        <p className="text-sm text-muted-foreground">
                          {`The "Number of Teams" is set to ${tournament?.numberOfTeams ?? 0} but there are only ${tournamentTeams?.length ?? 0} slots defined. This will automatically add the missing slots (with no teams attached).`}
                          <br />
                          {"This will not affect the frontend since there will be no data attached to the slots."}
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {(tournamentTeams?.length ?? 0) > (tournament?.numberOfTeams ?? 0) && (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <span className="flex items-center text-destructive">
                        <AlertTriangle className="mr-2 size-4" />
                        {`The "Number of Teams" is set to ${tournament?.numberOfTeams ?? 0} but there are ${tournamentTeams?.length ?? 0} slots defined. Delete the extra slots to avoid conflicts.`}
                      </span>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <AddEditTeamSlotModal
        gameId={circuit?.gameId}
        tournamentId={tournamentId}
        editingTeamSlot={editingTeamSlot}
        onClose={() => setIsAddEditTeamSlotModalOpen(false)}
        isOpen={isAddEditTeamSlotModalOpen}
      />
    </>
  );
};

const TournamentTeamRow = ({
  teamSlot,
  index,
  tournamentId,
  onEdit,
}: {
  teamSlot: TournamentTeamSlot;
  index: number;
  tournamentId: string;
  onEdit: (teamSlot: TournamentTeamSlot) => void;
}) => {
  const alert = useAlert();
  const { mutate: deleteTeam } = useDeleteTournamentTeam(tournamentId);
  const { team, qualificationMethod, metadata } = teamSlot;

  const handleDeleteTeam = () => {
    deleteTeam(
      {
        tournamentId,
        index: teamSlot.index,
      },
      {
        onSuccess: () => {
          alert.showSuccessAlert("Team deleted successfully!");
        },
        onError: () => {
          alert.showFailureAlert("Failed to delete team");
        },
      },
    );
  };

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        {team ? (
          <div className="flex items-center gap-3">
            <img
              className="size-8"
              height={32}
              width={32}
              src={`${assetsURL}/images/teams/${team.id}?height=64&width=64&format=auto&cache_busting=${Date.now()}`}
              alt={`${team.name} logo`}
            />
            <strong>{team.shortName}</strong>
          </div>
        ) : (
          <span className="text-muted-foreground">-</span>
        )}
      </TableCell>
      <TableCell className="text-xs">{team?.id ?? "-"}</TableCell>
      <TableCell className="text-xs">{team?.externalId ?? "-"}</TableCell>
      <TableCell className="text-xs">{qualificationMethod ?? "-"}</TableCell>
      <TableCell className="text-xs">{metadata?.bountyValue ?? "-"}</TableCell>
      <TableCell className="text-xs">{metadata?.seed ?? "-"}</TableCell>
      <TableCell>
        <div className="flex items-center">
          <Button variant="outline" size="icon" onClick={() => onEdit(teamSlot)}>
            <Pencil className="size-4" />
          </Button>
          <Button variant="outline" size="icon" className="ml-2" onClick={handleDeleteTeam}>
            <Trash className="size-4" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

const AddEditTeamSlotModal = ({
  gameId,
  tournamentId,
  editingTeamSlot,
  onClose,
  isOpen,
}: {
  gameId: string | undefined;
  tournamentId: string;
  editingTeamSlot: TournamentTeamSlot | undefined;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { data: teams } = useFetchTeams(gameId);
  const [selectedOption, setSelectedOption] = useState<string | undefined>(editingTeamSlot?.team?.id ?? undefined);
  const [qualificationMethod, setQualificationMethod] = useState<string | undefined>(
    editingTeamSlot?.qualificationMethod ?? undefined,
  );
  const [bountyValue, setBountyValue] = useState<number | undefined>(
    editingTeamSlot?.metadata?.bountyValue ?? undefined,
  );
  const [seed, setSeed] = useState<number | undefined>(editingTeamSlot?.metadata?.seed ?? undefined);

  const alert = useAlert();

  const { mutate: createTournamentTeam, isLoading: isCreatingTournamentTeam } = useCreateTournamentTeam({
    tournamentId,
  });
  const { mutateAsync: deleteTournamentTeam } = useDeleteTournamentTeam(tournamentId);

  const options = useMemo(() => {
    if (!teams) return [];

    return [
      { value: "", label: "No team" },
      ...(teams?.map((team) => {
        return { value: team.id, label: team.name, image: generateImageUrl("teams", team.id) };
      }) ?? []),
    ];
  }, [teams]);

  useEffect(() => {
    const selectedTeamId = editingTeamSlot?.team?.id;
    if (selectedTeamId) {
      setSelectedOption(selectedTeamId);
    }
  }, [editingTeamSlot, options]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedOption(undefined);
      setQualificationMethod(undefined);
      setBountyValue(undefined);
      setSeed(undefined);
    }
  }, [isOpen]);

  const handleSave = async () => {
    if (editingTeamSlot) {
      // Delete existing team slot, to be recreated with the same index
      await deleteTournamentTeam(
        { tournamentId, index: editingTeamSlot.index },
        {
          onError: () => {
            alert.showFailureAlert("Cannot update, failed to delete team slot");
          },
        },
      );
    }

    createTournamentTeam(
      {
        tournamentId,
        teamId: selectedOption ?? null,
        qualificationMethod: qualificationMethod,
        metadata: {
          seed,
          bountyValue,
        },
        index: editingTeamSlot?.index,
      },
      {
        onSuccess: () => {
          alert.showSuccessAlert("Team slot added to tournament successfully!");
          setSelectedOption(undefined);
          setQualificationMethod(undefined);
          setBountyValue(undefined);
          setSeed(undefined);
          onClose();
        },
        onError: () => {
          alert.showFailureAlert("Failed to add team to the tournament");
        },
      },
    );
  };

  const handleClose = () => {
    setSelectedOption(undefined);
    setQualificationMethod(undefined);
    setBountyValue(undefined);
    setSeed(undefined);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          handleClose();
        }
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Select a Team</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <Combobox options={options} value={selectedOption ?? ""} onChange={(value) => setSelectedOption(value)} />

          <Input
            placeholder="Qualification Method"
            value={qualificationMethod}
            onChange={(e) => setQualificationMethod(e.target.value)}
          />
          <Input
            type="number"
            placeholder="Bounty Value"
            value={bountyValue}
            onChange={(e) => {
              const value = Number(e.target.value);
              setBountyValue(isNaN(value) ? undefined : value);
            }}
          />
          <Input
            type="number"
            placeholder="Seed"
            value={seed}
            onChange={(e) => {
              const value = Number(e.target.value);
              setSeed(isNaN(value) ? undefined : value);
            }}
          />

          <div className="flex flex-row gap-2">
            <Button className="w-full" onClick={() => void handleSave()} disabled={isCreatingTournamentTeam}>
              {isCreatingTournamentTeam ? (
                <Loader className="w-40" />
              ) : (
                <>
                  <Save className="mr-2 size-4" />
                  Save
                </>
              )}
            </Button>
            <Button variant="outline" className="w-full" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
