import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import PageContainer from "../../components/Layout/PageContainer";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useGetEvents } from "../../api/monitoring/hooks/useGetEvents";
import { useState } from "react";
import { DateTime } from "luxon";
import { Info } from "@mui/icons-material";
import { MonitoringSNSEvent } from "../../api/monitoring/schemas";
import { SNSEventDetails } from "./components/SNSEventDetails";

export const MonitoringSNS = () => {
  const [selectedEvent, setSelectedEvent] = useState<MonitoringSNSEvent | null>(null);
  const [topicName, setTopicName] = useState<string>("");
  const [startTimestamp, setStartTimestamp] = useState<DateTime | null>(null);
  const [endTimestamp, setEndTimestamp] = useState<DateTime | null>(null);

  const { data, refetch: getEvents } = useGetEvents({
    topicName,
    startTimestamp: startTimestamp ? (startTimestamp?.toUTC().toISO() ?? undefined) : undefined,
    endTimestamp: startTimestamp ? (endTimestamp?.toUTC().toISO() ?? undefined) : undefined,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <PageContainer>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-4">
            <TextField
              placeholder="Topic Name"
              value={topicName}
              onChange={(e) => setTopicName(e.currentTarget.value)}
            />
            <Button variant="contained" onClick={() => void getEvents()}>
              Search
            </Button>
          </div>
          <div className="flex flex-row gap-4">
            <DateTimePicker
              label="Start Timestamp"
              format="yyyy-MM-dd HH:mm"
              ampm={false}
              value={startTimestamp}
              onChange={(e) => e !== null && setStartTimestamp(e)}
            />
            <DateTimePicker
              label="End Timestamp"
              format="yyyy-MM-dd HH:mm"
              ampm={false}
              value={endTimestamp}
              onChange={(e) => e !== null && setEndTimestamp(e)}
            />
          </div>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.events &&
                data?.events.length > 0 &&
                data.events.map((event, index) => (
                  <TableRow key={index}>
                    <TableCell>{event.service}</TableCell>
                    <TableCell>{event.subject}</TableCell>
                    <TableCell>{event.timestamp}</TableCell>
                    <TableCell>
                      <Tooltip title="Log details">
                        <IconButton onClick={() => setSelectedEvent(event)}>
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!!selectedEvent && <SNSEventDetails onClose={() => setSelectedEvent(null)} event={selectedEvent} />}
      </PageContainer>
    </LocalizationProvider>
  );
};
