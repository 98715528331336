import { Breadcrumbs, Typography } from "@mui/material";
import DeliveryTable from "./components/DeliveryTable";
import PointsCollectionsProvider from "./usePointsCollections/PointsCollectionsProvider";

const DeliveryContents = () => {
  return (
    <div className="flex w-full flex-col gap-7 p-4">
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.secondary" fontWeight="bold">
          Points
        </Typography>
        <Typography color="text.primary" fontWeight="bold">
          Purchase Delivery
        </Typography>
      </Breadcrumbs>

      <DeliveryTable />
    </div>
  );
};

export const Delivery = () => {
  return (
    <PointsCollectionsProvider>
      <DeliveryContents />
    </PointsCollectionsProvider>
  );
};
