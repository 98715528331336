import { CopyIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Input } from "@/components/ui/input";
import useAlert from "../../../../../../../../../providers/AlertProvider/hooks/useAlert";
import { baseApiURL, environment } from "../../../../../../../../../config";

interface LinkAccordionProps {
  type: "timeline" | "statistics";
  matchId: string;
  numberOfMaps: number;
}

export const LinkAccordion = ({ type, matchId, numberOfMaps }: LinkAccordionProps) => {
  const alert = useAlert();

  const copyLinks = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => alert.showSuccessAlert("Copied!"))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("Failed to copy to clipboard:", err);
        alert.showFailureAlert("Failed to copy text.");
      });
  };

  const generateDataUrl = (type: "timeline" | "statistics", matchId: string, mapNumber: number) => {
    const timelineHost = {
      development: "https://timeline-api.blast-dev.tv",
      production: "https://timeline-api.blast.tv",
    };
    const timelineUrl = `${
      timelineHost[environment === "production" ? "production" : "development"]
    }/v2/serverlog/${matchId}/maps/${mapNumber}`;
    const statisticsUrl = `${baseApiURL}/v1/ingest/cs/serverlogs/matches/${matchId}/maps/${mapNumber}`;

    switch (type) {
      case "timeline":
        return timelineUrl;
      case "statistics":
        return statisticsUrl;
      default:
        throw new Error("Unknown data url type, for generating data urls");
    }
  };

  return (
    <Accordion type="single" collapsible className="w-full max-w-[1500px]">
      <AccordionItem value="item-1">
        <AccordionTrigger className="px-4">
          <div className="flex w-full flex-row items-center justify-between">
            <span>{type.charAt(0).toUpperCase() + type.slice(1)} URLs</span>
            <Button
              variant="ghost"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                const timelineUrls = Array.from({ length: numberOfMaps }).map((_, i) =>
                  generateDataUrl(type, matchId, i + 1),
                );
                copyLinks(timelineUrls.join("\n"));
              }}
            >
              <CopyIcon className="size-4" />
            </Button>
          </div>
        </AccordionTrigger>
        <AccordionContent className="px-4">
          <div className="flex flex-col gap-5">
            {Array.from({ length: numberOfMaps }).map((_, i) => (
              <div key={i} className="flex flex-col gap-2">
                <label htmlFor={`map-${i + 1}`}>Map {i + 1}</label>
                <Input id={`map-${i + 1}`} value={generateDataUrl(type, matchId, i + 1)} readOnly />
              </div>
            ))}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
