import { useEffect, useMemo, useState } from "react";
import useFetchCategories from "./hooks/useFetchCategories";
import useFetchLeaderboard from "./hooks/useFetchLeaderboard";
import { Button } from "@blastorg/portal-pattern-library";
import { RLRankingTable } from "./components/RLRankingTable";
import { Select, SelectItem, SelectTrigger, SelectContent, SelectValue } from "@/components/ui/select";
import useFetchTransactions from "./hooks/useFetchTransactions";
import { RankingTransaction } from "@/api/tournaments/rankings/schemas";
import { RLTransactionTable } from "./components/RLTransactionTable";
import { AddTransactionModal } from "./components/AddTransactionModal";

export const RLRankings = () => {
  const [category, setCategory] = useState<string | undefined>(undefined);
  const [subCategory, setSubCategory] = useState<string | undefined>(undefined);
  const [showAddTransactionModal, setShowAddTransactionModal] = useState<boolean>(false);
  const { data: categoryRecords } = useFetchCategories("rl");

  useEffect(() => {
    setCategory("");
  }, [categoryRecords]);

  const availableSubCategories = useMemo(() => {
    return categoryRecords?.find((cat: { category: string }) => cat.category === category)?.subCategories ?? [];
  }, [category, categoryRecords]);

  const { data: leaderboardRankings } = useFetchLeaderboard(category || "", subCategory || "");
  const { data: transactions } = useFetchTransactions("rl");

  const leaderboard = useMemo(() => {
    if (leaderboardRankings === undefined) {
      return [];
    }
    if (!subCategory) {
      return leaderboardRankings;
    }
    return leaderboardRankings.filter((lr: { subCategory: string }) => lr.subCategory === subCategory);
  }, [leaderboardRankings, subCategory]);

  const filtedTransactions = useMemo(() => {
    if (transactions === undefined) {
      return [];
    }
    if (!category) {
      return transactions;
    }
    return transactions.filter(
      (t: RankingTransaction) => t.category === category && (subCategory ? t.subCategory === subCategory : true),
    );
  }, [transactions, category, subCategory]);

  return (
    <>
      {showAddTransactionModal && (
        <AddTransactionModal
          onClose={() => setShowAddTransactionModal(false)}
          open={showAddTransactionModal}
          selectedCategory={category}
          selectedSubCategory={subCategory}
        />
      )}
      <div className="mb-4">
        <h1>RLRankings</h1>
      </div>
      <div className="flex max-h-svh flex-row gap-40">
        <div className="flex h-4/5 w-1/2 flex-col pl-4">
          <div className="flex flex-row">
            <div className="w-48">
              <Select value={category} onValueChange={(e) => setCategory(e)}>
                <SelectTrigger>
                  <SelectValue placeholder="Category" />
                </SelectTrigger>
                <SelectContent>
                  {categoryRecords?.map((category) => (
                    <SelectItem key={category.category} value={category.category}>
                      {category.category}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="w-48">
              <Select value={subCategory} onValueChange={(e) => setSubCategory(e)} disabled={!category?.length}>
                <SelectTrigger>
                  <SelectValue placeholder="Sub-Category" />
                </SelectTrigger>
                <SelectContent>
                  {availableSubCategories.map((subCategory) => (
                    <SelectItem key={subCategory} value={subCategory}>
                      {subCategory}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Button
              id="add-transaction"
              variant="default"
              className="h-full w-48 bg-slate-300 p-0 hover:bg-slate-600"
              onClick={() => setShowAddTransactionModal(true)}
            >
              Add Transaction
            </Button>
          </div>

          <RLTransactionTable data={filtedTransactions} />
        </div>
        <div className="h-4/5">
          <RLRankingTable data={leaderboard} category={category} subCategory={subCategory} />
        </div>
      </div>
    </>
  );
};
