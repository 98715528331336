import { TrashIcon } from "@heroicons/react/24/outline";

interface SelectEmptyProps {
  onRemove?: () => void;
  showRemove?: boolean;
}

export function SelectEmpty({ onRemove, showRemove }: SelectEmptyProps) {
  return (
    <div className="flex h-14 w-full cursor-pointer items-center justify-between rounded">
      {showRemove && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onRemove?.();
          }}
          className="group flex h-full place-content-center items-center rounded border border-dashed border-red-500 px-3 hover:bg-red-500"
        >
          <TrashIcon className="size-5 group-hover:text-white" />
        </div>
      )}
    </div>
  );
}
