import { Draggable } from "react-beautiful-dnd";
import { ModerationRule } from "../../../../types/Moderation";
import { Delete, DragIndicator, Edit } from "@mui/icons-material";
import { getModerationRuleText } from "../../helpers/getModerationRuleText";
import { IconButton } from "@mui/material";
import classNames from "classnames";

interface RuleItemProps {
  rule: ModerationRule;
  index: number;
  onDelete: (rule: ModerationRule) => void;
  onEdit: () => void;
}

const RuleItem = ({ rule, index, onDelete, onEdit }: RuleItemProps) => {
  return (
    <Draggable draggableId={rule.id} index={index}>
      {(provided, snapshot) => (
        <div
          key={rule.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classNames("my-2 flex items-center gap-4 p-4 backdrop-blur-md", {
            "bg-white/20": snapshot.isDragging,
            "bg-white/10": !snapshot.isDragging,
          })}
        >
          <DragIndicator />
          <span className="text-white/50 font-style-label-3">{rule.id}</span>
          <span className="text-white font-style-b2-body-copy">{rule.description ?? "-"}</span>
          <span className="ml-auto block rounded-small bg-yellow-200/10 p-2 text-white font-style-b2-body-copy">
            {getModerationRuleText(rule)}
          </span>
          <IconButton color="primary" onClick={() => onEdit()}>
            <Edit fontSize="small" />
          </IconButton>
          <IconButton color="error" onClick={() => onDelete(rule)}>
            <Delete fontSize="small" />
          </IconButton>
        </div>
      )}
    </Draggable>
  );
};

export default RuleItem;
